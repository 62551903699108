import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import ActionButton from '../../components/layout/actionButton'
import { OngoingWashesWrapper } from './ongoingWashes.styles'
import { IStore } from '../../interfaces/store.interfaces'

const ongoingWashesModule = (): ReactElement => {
  const ongoingWashListLength = 2

  const todaysReservations = useSelector((state: IStore) => state.todaysReservations?.reservationlist)
  const filteredReservations = todaysReservations
    .filter(
      ({ lastStatus, startTime, duration }) =>
        lastStatus !== 'FINISHED' && moment(startTime).add(duration, 'minutes').diff(moment()) > 0,
    )
    .slice(0, ongoingWashListLength)
  return (
    <OngoingWashesWrapper $ongoing={!!filteredReservations?.length}>
      {!!filteredReservations.length &&
        filteredReservations.map(({ vehicle, lastStatus, startTime, surface, id, slot, duration }) => (
          <ActionButton
            licenseNumber={vehicle?.licensePlate}
            lastStatus={lastStatus}
            startTime={startTime}
            duration={duration}
            reservationId={id}
            surface={surface}
            slot={slot}
            key={id}
          />
        ))}
    </OngoingWashesWrapper>
  )
}

export default ongoingWashesModule
