/* eslint-disable @typescript-eslint/no-explicit-any */
export const hungarianDict: any = {
  // MENU
  MENU_DASHBOARD: 'Dashboard',
  MENU_RESERVATIONS: 'Foglalások',
  MENU_WASH_DIARY: 'Mosási napló',
  MENU_MONITOR: 'Monitor',
  MENU_SETTINGS: 'Beállítások',
  MENU_NEW_RESERVATION: 'Új foglalás',

  // USERS SCREEN
  LAST_SIGNED_IN: 'Utoljára belépve',
  SIGN_IN_BTN: 'Belépés',

  // APPOINTMENTS SCREEN
  NEW_APPOINTMENT: 'Új foglalás',
  NO_APPOINTMENT_TODAY: 'Nincs mára időpont',
  APPOINTMENT: 'időpont',
  ACTIVE: 'Aktív',
  TODAY: 'Ma',
  FULL_NAME: 'Teljes név',
  SIZE: 'Méret',
  EMAIL_ADDRESS: 'Email cím',
  PHONE_NUMBER: 'Telefonszám',
  DETAILS: 'részletek',
  START_WASHING_NOW: 'Mosás elindítása most',
  TIME_LEFT: 'Hátra van',
  CUSTOMER_DETAILS: 'Megrendelő adatok',
  TYPE: 'Típus',
  DEFAULT_CATEGORY_TITLE: 'Ismeretlen kategoria',

  // PROMOTIONS SETTINGS
  NO_PROMOTIONS: 'Jelenleg nem rendelekezel kedvezmény kóddal.',

  // PRICE LIST SETTINGS
  SERVICES: 'Szolgáltatások',

  // RESERVATION DATAILS
  DATE: 'Dátum',
  TIME: 'Időpont',
  WORK_DURATION: 'Munkaidő',
  MINUTE: 'perc',
  LATEST_ORDERS: 'Legutóbbi rendelései',
  ORDERS: 'megrendelések',
  COMMENT: 'megjegyzés',
  COMMENTS: 'Megjegyzések',
  SEND_MESSAGE: 'Üzenet küldése',
  PROMOTION_CODE: 'Kedvezmény',
  PROMOTION_DESCRIPTION: 'Beállíthat kedvezményt a vendégnek.',
  CUSTOMER_OR_LICENSEPLATE: 'Ügyfél név vagy rendszám',
  SOURCE_OF_DISCOUNT: 'A kedvezmény forrása:',

  // NEW APPOINTMENT USER SEARCH
  LICENSE_PLATE: 'rendszám',
  CAR_SIZE: 'Autó mérete',
  NAME: 'Név',
  LAST_ORDER: 'Legutóbbi rendelés',
  NEXT: 'Tovább',
  BACK: 'Vissza',
  FINISH: 'Kész',
  PIECES: 'db',

  // NEW RESERVATION MODAL STEPS
  CUSTOMER_STEP: 'Ügyfél',
  PACKAGE_STEP: 'Csomag',
  APPOINTMENT_STEP: 'Időpont',
  SUMMARY_STEP: 'Összegzés',

  // NEW RESERVATION ERROR
  NO_CUSTOMER_SELECTED: 'Nincs kiválasztott ügyfél',
  NO_PACKAGE_SELECTED: 'Nincs kiválasztott csomag',
  SELECT_PACKAGE: 'Szolgáltatás választása',
  SUMMARY: 'Összegzés',

  // NEW RESERVATION PAYMENT DETAILS
  PAYMENT: 'Fizetés',
  SUBTOTAL: 'Részösszeg',
  TAX: 'Áfa',
  PROMO_CODE: 'Kedvezmény kód',
  TOTAL: 'Végösszeg',
  TOTAL_W_DISCOUNT: 'Végösszeg kedvezménnyel',
  PRICE: 'Ár',

  // LOGIN
  PASSWORD: 'Jelszó',
  LOGIN_TITLE: 'Belépés',
  LOGIN_DESCRIPTION: 'Lépj be e-mail címeddel és jelszavaddal',
  FORGOT_PASSWORD_TITLE: 'Elfelejtett jelszó',
  FORGOT_PASSWORD_BTN_TITLE: 'Küldés',
  FORGOT_PASSWORD_BACK_BTN_TITLE: 'Vissza a belépéshez',
  FORGOT_PASSWORD_DESCRIPTION: 'Add meg az e-mail címed, ahova elküldjük az új jelszavadat.',
  EMAIL_ERROR: 'Hibás e-mail cím',
  LOGIN_ERROR: 'Hiba történt a bejelentkezés során.',
  LOG_OUT_OF_WASHER: 'Kijelentkezés a mosóból',
  SWITCH_USER: 'Felhasználóváltás',

  // PACKAGES SURFACE
  EXTERIOR: 'Külső',
  INTERIOR: 'Belső',
  INTERIOR_EXTERIOR: 'Külső - Belső',

  // WASH DIARY
  WASHING_DIARY: 'Mosási napló',
  SURFACE: 'Típus',
  WASHING: 'mosás',
  START_TIME: 'Kezdete',
  END_TIME: 'Vége ',
  NET_DURATION: 'Tényleges hossz',
  NET_DURATION_SHORT: 'Hossz',
  RESERVATION_ID: 'Foglalási azonosító',
  FIND_BY_CUSTOMER: 'Keresés az ügyfelek között',
  FIND_BY_CUSTOMER_OR_LICENSEPLATE: 'Keresés név vagy rendszám alapján',
  ORDER: 'rendelés',
  RESERVATION_SUCCESS_TITLE: 'Sikeres foglalás',
  RESERVATION_FAIL_TITLE: 'Sikertelen foglalás',
  RESERVATION_SUCCESS_DESCRIPTION: 'A foglalás mentése sikeres.',
  RESERVATION_FAIL_DESCRIPTION: 'A foglalás mentése sikertelen.',
  RESERVATION_BTN_OK: 'Befejezés',
  RESERVATION_BTN_START_WASH: 'Mosási folyamat indítása',

  // FINISH WITH NEW CAR MODAL
  CAR_DETAILS: 'Autó adatai',
  CAR_MAKE: 'Gyártó',
  CAR_MODEL: 'Modell',
  NEW_CUSTOMER_STEP: 'Új Ügyfél',

  // WASH STATE CHANGE BUTTONS
  FINISH_WASHING: 'Fizetés',
  CANCEL_WASHING: 'Lemondás',
  PAUSE_WASHING: 'Szüneteltetés',
  START_WASHING: 'Indítás',

  // CANCELLED RESERVATIONS
  PCS: 'db',
  CANCELLED_RESERVATIONS: 'Lemondott foglalások',

  // GENERAL SETTINGS
  SETTINGS: 'Beállítások',
  GENERAL_SETTINGS: 'Általános beállítások',
  WASHER_NAME: 'Mosó neve',
  COUNTRY: 'Ország',
  CITY: 'Város',
  STATE: 'Megye',
  DISTRICT: 'Kerület',
  POSTAL_CODE: 'Irányítószám',
  STREET: 'Utca',
  HOUSE: 'Ház',
  FLOOR: 'Emelet',
  DOOR: 'Ajtó',
  FACEBOOK: 'Facebook',
  CONTACTS: 'Elérhetőségek',
  SAVING_DONE: 'Mentés sikeres',
  SAVE: 'Mentés',
  SETTINGS_SAVE_ERROR: 'Mentés sikertelen',

  // SETTINGS TABS
  GENERAL_SETTINGS_SHORT: 'Általános',
  OPEN_HOURS_SETTINGS_SHORT: 'Nyitvatartás',
  PROMOTION_SETTINGS_SHORT: 'Kedvezmények',
  MARKETING_CAMPAIGN_SETTINGS_SHORT: 'Kampányok',
  OPEN_HOURS_SETTINGS: 'Nyitvatartás',
  PROMOTION_SETTINGS: 'Kedvezmény kódok',
  HELP_SETTINGS: 'Támogatás',
  HELP_SETTINGS_SHORT: 'Támogatás',
  USER_SETTINGS: 'Alkalmazottak',
  USER_SETTINGS_SHORT: 'Alkalmazottak',
  PRICE_LIST_SETTINGS: 'Régiós Árlista',
  PRICE_LIST_SETTINGS_SHORT: 'Régiós Árlista',
  PRICE_LIST_LOADING: 'Az árlista hamarosan betöltődik',
  // CANCEL WASHING MODAL
  CANCEL_WASH_DESCRIPTION: 'Biztosan lemondod a kiválasztott mosást?',
  CANCEL_WASH_TITLE: 'Mosás lemondása',
  OK_CANCEL: 'Igen',
  NO_CANCEL: 'Mégsem',
  SEARCH_INFO: 'Keresőbe írd be az ügyfél nevét vagy rendszámát, vagy válassz egy autóméretet.',

  // COMMENTING
  LOCAL_COMMENT: 'Lokális megjegyzés',
  COMMENT_SEND_SUCCESS: 'Üzenet elküldve',
  COMMENT_SEND_FAIL: 'Üzenet küldése sikertelen.',
  WAITING_FOR_COMMENT_POSTING: 'Küldés...',

  // PROMOTIONS
  PROMOTION_SUCCESS_TITLE: 'Kedvezmény létrehozva',
  PROMOTION_SUCCESS_DESCRIPTION: 'Kedvezmény sikeresen létrehozva.',
  PROMOTION_BTN_OK: 'Rendben',
  PROMOTION_BTN_CREATE: 'Létrehozás',
  AMOUNT: 'Kedvezmény értéke',
  UNIT: 'Fix / Százalékos',
  COIN: 'Fix összeg',
  PERCENT: 'Százalék',
  CURRENCY: 'Valuta',
  NEW_PROMOTION_TITLE: 'Új Kedvezmény létrehozása',
  NEW_PROMO_DESCRIPTION: 'Adja meg az alábbi adatokat az új Kedvezmény létrehozásához',
  ADD_NEW_PROMOTION_BUTTON: 'Új létrehozása',
  PROMO_TABLE_FIX_OR_PERCENT: 'Fix / százalék',
  PROMO_TABLE_AMOUNT: 'Érték',

  // MARKETING CAMPAIGNS
  MARKETING_CAMPAIGN_SETTINGS: 'Marketing kampányok',
  MARKETING_TABLE_TITLE: 'Név',
  MARKETING_TABLE_DURATION: 'Idötartam',
  MARKETING_TABLE_PACKAGE: 'Csomag',
  MARKETING_TABLE_PROMO_AMOUNT: 'Kedvezmény értéke',
  ACTIVITY: 'Aktivitás',
  CUSTOMER_PROMOTION_INFO:
    'Ez a foglalás egy a felhasználó által rögzített kedvezményt tartalmaz, ezért az nem módosítható.',

  // NEW RESERVATION MODAL
  CONTINUE_WITH_DIFFERENT_VEHICLE: 'Más autó megadása',
  CHANGE_PROMOTION_TITLE: 'Kedvezmény lecserélése',
  CHANGE_PROMO_DESCRIPTION: 'Biztosan lecseréled a kedvezményt?',
  YES: 'Igen',

  // NEW PROFILE
  NEW_PROFILE: 'Új Ügyfél',
  PROFILE_DETAILS: 'Profil részletei',
  FIRST_NAME: 'Keresztnév',
  LAST_NAME: 'Vezetéknév',
  PHONE: 'Telefon',
  NEW_PROFILE_BTN: 'Új Ügyfél',
  PROFILE_SUCCESS_TITLE: 'Sikeres mentés',
  PROFILE_FAIL_TITLE: 'Mentés sikertelen',
  PROFILE_SUCCESS_DESCRIPTION: 'Most már megtalálható lesz az ügyfél a keresöben',
  PROFILE_FAIL_DESCRIPTION: 'A profil mentése sikertelen.',
  PROFILE_BTN_OK: 'Befejezés',
  STREET_SHORT: 'u.',

  // WASHER SWITCHER
  SWITCH_WASHER: 'Mosó választása',

  // PRICE ADJUSTMENT
  PRICE_ADJUSTMENT_TITLE: 'Ármódosítás',
  PRICE_ADJUSTMENT: 'Ármódosító összeg',
  FINISH_WITH_PRICE_ADJUSTMENT: 'Fizetés',
  PRICE_ADJUSTMENT_INFO: 'Ármódosítás oka',
  FINISH_WASH_STEP: 'Fizetés',
  PRICE_ADJUSTMENT_DESCRIPTION:
    'Szükség esetén itt módosítható a foglalás ára. A módosításhoz add meg az összeget (pl.: -500) és a módosítás okát.',

  // LOADING
  LOADING_BTN: 'Mentés...',

  // OPENING HOURS
  OPEN_HOURS_DESCRIPTION: 'Itt módosíthatóak a nyitvatartási és üzemszüneti adatok.',
  ALTERNATING_WEEKS_FALSE: 'Csak “A” hét van',
  ALTERNATING_WEEKS_TRUE: 'Legyen “A” / “B” hét is',
  EVEN: 'B hét',
  ODD: 'A hét',
  Mo: 'Hétfő',
  Tu: 'Kedd',
  We: 'Szerda',
  Th: 'Csütörtök',
  Fr: 'Péntek',
  Sa: 'Szombat',
  Su: 'Vasárnap',
  Mo_SHORT: 'Hé',
  Tu_SHORT: 'Ke',
  We_SHORT: 'Sz',
  Th_SHORT: 'Cs',
  Fr_SHORT: 'Pé',
  Sa_SHORT: 'Sz',
  Su_SHORT: 'Va',
  SAVE_NEW_SHIFT_BTN: 'Mentés',
  CANCEL_NEW_SHIFT_BTN: 'Mégse',

  // DOWNTIMES
  USE_DOWNTIMES_TITLE: 'Beállítok kivételeket (pl. ünnepnapok, karbantartás stb.)',
  DOWNTIMES_TITLE: 'Kivételek',
  DOWNTIMES_DESCRIPTION: 'Az itt beállított időintervallumban, nem üzemelünk.',
  NAMING: 'Elnevezés',
  DATE_FROM: 'Dátum -tól',
  DATE_TO: 'Dátum -ig',
  SAVE_NEW_DOWNTIME_BTN: 'Mentés',
  ADD_SHIFT_BTN: 'Újabb időintervallum hozzáadása',
  REMOVE_SHIFT_BTN: 'Törlés',
  DELETE_DOWNTIME_BTN: 'Törlés',
  ADD_NEW_DOWNTIME_BTN: 'Új kivétel hozzáadása',
  CANCEL_NEW_DOWNTIME_BTN: 'Mégse',

  // NEW RESERVATION
  WASH_STARTED: 'Mosás elindítva',
  CANCEL_NEW_RESERVATION_TITLE: 'Új foglalás megszakítása',
  CANCEL_NEW_RESERVATION_DESCRIPTION: 'Megszakítod az új foglalást?',

  // WASH DETAILS
  REMOVE_PROMOTION_TITLE: 'Kedvezmény törlése',
  REMOVE_PROMO_DESCRIPTION: 'Biztosan törlöd a foglaláshoz kapcsolódó kedvezményt?',
  NO_PROMOTION: 'Nincs',
  SEARCH: 'Keresés...',
  TIME_PASSED: 'Eltelt',
  WASH_COMMENT: 'Megjegyzés foglaláshoz',
  VEHICLE_COMMENT: 'Megjegyzés járműhöz',
  WASH_IMAGES: 'Képek',
  PRICE_AFTER_ADJUSTMENT: 'Módosított ár',
  DAYVIEW: 'Nap',
  WEEKVIEW: 'Hét',
  CONTACT: 'Ügyfél',
  WASH_TIME: 'Időtartam',

  // PAYMENT SCREEN
  PAYMENT_METHOD_TITLE: 'Fizetési mód',
  PAYMENT_METHOD_DESCRIPTION: 'Fizetési mód választása (Készpénz, Bankkártya, Havi elszámolás). Kötelező megadni.',
  CASH: 'Készpénz',
  CARD: 'Bankkártya',
  MONTHLY: 'Havi elszámolás',

  // UPDATE SCREEN
  VERSION_UPDATE_TITLE: 'Új verzió érhető el',
  VERSION_UPDATE_PROGRESS_TITLE: 'Frissítés',
  VERSION_UPDATE_DESCRIPTION:
    'A frissítés során az oldal többször újra fog tölteni, frissítés után az alkalmazásba újra be kell jelentkezni.',
  VERSION_UPDATE_PROGRESS_DESCRIPTION: 'Frissítés folyamatban...',
  VERSION_UPDATE_BTN: 'Rendben',
  SEC: 'mp',
  MORE_THAN: 'Több mint',

  // HELP SETTINGS
  HELP_BTN: 'Kapcsolatfelvétel',
  HELP_DESCRIPTION:
    'Ha barmi probléma merülne fel a szoftver használata során az alábbi gombbal megnyíló menüben lehet segítsêget kérni',

  // USER SETTINGS
  NEW_USER_BTN: 'Új felhasználó',
  NEW_USER_TITLE: 'Új alkalmazott hozzáadása',
  NEW_USER_DESCRIPTION: 'Adja meg az e-mail címét, és a szerepkörét',
  ROLE: 'Szerepkör',
  INVITEE: 'E-mail cím',
  NEW_USER_BTN_CREATE: 'Meghívó küldése e-mailben',
  ADD_NEW_USER_BUTTON: 'Új alkalmazott',
  USER_FAIL_TITLE: 'Meghívás sikertelen',
  USER_FAIL_DESCRIPTION: 'A meghívó kiküldése során probléma merült fel',
  USER_SUCCESS_TITLE: 'Meghívás sikeres',
  USER_SUCCESS_DESCRIPTION: 'A meghívó email kiküldése megtörtént',
  USER_BTN_OK: 'Rendben',
  NO_USERS: 'Nincs felhasználó',

  // SIGN UP
  SIGNUP_TITLE: 'Regisztráció',
  SIGNUP_DESCRIPTION: 'Regisztrációhoz kérjük add meg az alábbi adatokat',
  FIRSTNAME: 'Vezetéknév',
  LASTNAME: 'Keresztnév',
  SIGNUP_ERROR: 'Regisztráció sikertelen',

  // EVENT MOVE
  MOVE_EVENT_TITLE: 'Mosás mozgatása',
  MOVE_EVENT_DESCRIPTION:
    'A mosás mozgatásán kívül a mosás időtartamának módosítására is van lehetőség. Az időtartam percben van megadva és oszthatónak kell lennie 15-tel. Ebben a felkerekítés gomb is segít.',
  MOVE_EVENT_DURATION_ERROR: 'Osztható kell legyen 15-tel.',
  ROUND_UP_DURATION_BTN: 'Idötartam felkerekítése',
  DURATION: 'Időtartam (perc)',
  MOVE_EVENT_BTN: 'Mosás mentése',
  JUMP_TO_PREV_WEEK: 'Ugrás az előző hétre',
  JUMP_TO_NEXT_WEEK: 'Ugrás a következő hétre',
  MOVE_EVENT_NEW_START_TIME: 'Új időpont:',
  EDIT_DURATION_BTN: 'Időtartam szerkesztése',

  // MARKETING CAMPAIGN SETTINGS
  NO_MARKETINGCAMPAIGNS: 'Nincs megjeleníthetö kampány',
  NO_ENDTIME: 'Határozatlan ideig',
  NO_RESULT: 'Nincs találat',

  // COMPLETE WASHING STATE AND MODAL
  COMPLETE_WASHING: 'Kész',
  COMPLETE_WASH_STEP: 'Mosás lezárása',
  COMPLETE_WITH_NEW_CUSTOMER: 'Mosás lezárása',
  // -----
  YESTERDAY: 'tegnap',
  USERTAB_ACTIVE: 'Aktív',
  USERTAB_INACTIVE: 'Inaktív',
  USERTAB_INVITED: 'Meghivott',

  RESET_USER_PASSWORD_TITLE: 'Új jelszó megadása',
  RESET_USER_PASSWORD_DESCRIPTION: 'Új jelszó megadása az alábbi alkalmazott számára:',
  NEW_PASSWORD: 'Új jelszó',
  PASSWORD_BTN_CREATE: 'Mentés',
  PASSWORD_BTN_CANCEL: 'Mégsem',
  NEW_PASSWORD_ERROR: 'Hiba a jelszómódosítás közben',
  INVITE_RESEND_STATUS_INIT: 'Meghívó újraküldése',
  INVITE_RESEND_STATUS_LOADING: 'Küldés folyamatban',
  INVITE_RESEND_STATUS_SUCCESS: 'Küldés sikeres',
  INVITE_RESEND_STATUS_FAIL: 'Küldés sikertelen',
  RESEND_INVITATION: 'Meghívó újraküldés',
  CHANGE_USER_PASSWORD: 'Jelszó változtatás',
  ADDRESS: 'Cím',
  UNIVERSAL_RESULT_BTN_OK: 'Rendben',
  // RESET PASSWORD RESULT MODAL
  RESET_PASSWORD_FAIL_TITLE: 'Új jelszó megadása',
  RESET_PASSWORD_FAIL_DESCRIPTION: 'Új jelszó megadása sikertelen.',
  RESET_PASSWORD_SUCCESS_TITLE: 'Új jelszó megadása',
  RESET_PASSWORD_SUCCESS_DESCRIPTION: 'Új jelszó megadása sikeres.',
  // RESEND INVITATION RESULT MODAL
  RESEND_INVITATION_FAIL_TITLE: 'Hiba történt a meghívó email küldése során',
  RESEND_INVITATION_FAIL_DESCRIPTION: 'Meghívó email újraküldése sikertelen.',
  RESEND_INVITATION_SUCCESS_TITLE: 'Meghívó újraküldés sikeres',
  RESEND_INVITATION_SUCCESS_DESCRIPTION: 'Meghívó email újraküldése sikeres.',
  // DASHBOARD
  PROFIT: 'Bevétel',
  AVG_SHORT: 'átl.',
  NUMBER_OF_WASHES: 'Mosások száma',
  AVERAGE_RATING: 'Átlagos értékelés',
  CLIENT_COUNT: 'Ügyfélszám',
  FROM_THIS: 'ebből',
  NEW_CLIENT: 'új ügyfél',
  THIS_WEEK: 'Ezen a héten',
  THIS_MONTH: 'Ebben a hónapban',
  LAST_WEEK: 'Múlt héten',
  LAST_MONTH: 'Előző hónap',
  LAST_THIRTY_DAYS: 'Elmúlt 30 nap',
  LAST_THREESIXFIVE_DAYS: 'Elmúlt 365 nap',
  LAST_YEAR: 'Előző év',
  PACKAGE_DURATIONS_CHART: 'Csomagokkal eltöltött idö (perc)',
  PACKAGE_CHART: 'Csomagok százalékos megoszlása',
  CAPACITY_CHART: 'Nyitvatartással töltött órák száma',
  HOUR_EXPECTED: 'A kijelölt időszakban elvárt nyitvatartási idő:',
  HOUR: 'óra',
  CUSTOM_DATE: 'Egyedi dátum',
  DURATION_OF_PACKAGE: 'mosással töltött átlagos idő (perc)',
  ACCESS_DENIED: 'Hozzáférés megtagadva',
  CAPACITY_SLOT: 'Beálló',
  CAPACITY_USAGE: 'Kihasználtság',
}

export const englishDict: any = {
  // MENU
  MENU_DASHBOARD: 'Dashboard',
  MENU_RESERVATIONS: 'Calendar',
  MENU_WASH_DIARY: 'Wash History',
  MENU_MONITOR: 'Monitor',
  MENU_SETTINGS: 'Settings',
  MENU_NEW_RESERVATION: 'New Booking',

  // USERS SCREEN
  LAST_SIGNED_IN: 'Last Login',
  SIGN_IN_BTN: 'Log In',

  // APPOINTMENTS SCREEN
  NEW_APPOINTMENT: 'New Booking',
  NO_APPOINTMENT_TODAY: 'No reservation',
  APPOINTMENT: 'Time',
  ACTIVE: 'Active',
  TODAY: 'Today',
  FULL_NAME: 'Full Name',
  SIZE: 'Size',
  EMAIL_ADDRESS: 'Email Address',
  PHONE_NUMBER: 'Phone Number',
  DETAILS: 'Details',
  START_WASHING_NOW: 'Start Timer',
  TIME_LEFT: 'Time left',
  CUSTOMER_DETAILS: 'Customer Details',
  TYPE: 'Type',
  DEFAULT_CATEGORY_TITLE: 'Unknown Category',

  // PROMOTIONS SETTINGS
  NO_PROMOTIONS: 'There is no Promotion to display.',

  // PRICE LIST SETTINGS
  SERVICES: 'Services',

  // RESERVATION DATAILS
  DATE: 'Date',
  TIME: 'Time',
  WORK_DURATION: 'Duration',
  MINUTE: 'minute',
  LATEST_ORDERS: 'Order History',
  ORDERS: 'Orders',
  COMMENT: 'comments',
  COMMENTS: 'Comments',
  SEND_MESSAGE: 'Send Message',
  PROMOTION_CODE: 'Discount',
  PROMOTION_DESCRIPTION: 'Set Discount for Customer',
  CUSTOMER_OR_LICENSEPLATE: 'Customer name, email or numberplate',
  SOURCE_OF_DISCOUNT: 'Source of Discount',

  // NEW APPOINTMENT USER SEARCH
  LICENSE_PLATE: 'License Plate',
  CAR_SIZE: 'Vehice Size',
  NAME: 'Name',
  LAST_ORDER: 'Previous Order',
  NEXT: 'Next',
  BACK: 'Back',
  FINISH: 'Done',
  PIECES: 'pieces',

  // NEW RESERVATION MODAL STEPS
  CUSTOMER_STEP: 'Customer',
  PACKAGE_STEP: 'Solution',
  APPOINTMENT_STEP: 'Time',
  SUMMARY_STEP: 'Summary',

  // NEW RESERVATION ERROR
  NO_CUSTOMER_SELECTED: 'Select a customer',
  NO_PACKAGE_SELECTED: 'No solution selected',
  SELECT_PACKAGE: 'Select a solution',
  SUMMARY: 'Summary',

  // NEW RESERVATION PAYMENT DETAILS
  PAYMENT: 'Payment',
  SUBTOTAL: 'Subtotal',
  TAX: 'TAX',
  PROMO_CODE: 'Discount Code',
  TOTAL: 'Total',
  TOTAL_W_DISCOUNT: 'Discounted Total',
  PRICE: 'Price',

  // LOGIN
  PASSWORD: 'Password',
  LOGIN_TITLE: 'Login',
  LOGIN_DESCRIPTION: 'User your email and password to login',
  FORGOT_PASSWORD_TITLE: 'Forgotten Password',
  FORGOT_PASSWORD_BTN_TITLE: 'Send',
  FORGOT_PASSWORD_BACK_BTN_TITLE: 'Back to Login',
  FORGOT_PASSWORD_DESCRIPTION: 'Type the email address where we should send the password to.',
  EMAIL_ERROR: 'Wrong Email address',
  LOGIN_ERROR: 'Error during authentication.',
  LOG_OUT_OF_WASHER: 'Logout from interface',
  SWITCH_USER: 'Switch User',

  // PACKAGES SURFACE
  EXTERIOR: 'Exterior',
  INTERIOR: 'Interior',
  INTERIOR_EXTERIOR: 'Exterior - Interior',

  // WASH DIARY
  WASHING_DIARY: 'Wash Diary',
  SURFACE: 'Surface Type',
  WASHING: 'Washing',
  START_TIME: 'Start',
  END_TIME: 'Finished ',
  NET_DURATION: 'Net Duration',
  NET_DURATION_SHORT: 'Total Duration',
  RESERVATION_ID: 'Reservation ID',
  FIND_BY_CUSTOMER: 'Find by Customer',
  FIND_BY_CUSTOMER_OR_LICENSEPLATE: 'Find by Customer or License Plate',
  ORDER: 'Order',
  RESERVATION_SUCCESS_TITLE: 'Successful reservation',
  RESERVATION_FAIL_TITLE: 'Reservation failed',
  RESERVATION_SUCCESS_DESCRIPTION: 'Reservation has been recorded',
  RESERVATION_FAIL_DESCRIPTION: 'Reservation failed',
  RESERVATION_BTN_OK: 'Reserve',
  RESERVATION_BTN_START_WASH: 'Start washing process',

  // FINISH WITH NEW CAR MODAL
  CAR_DETAILS: 'Vehicle Data',
  CAR_MAKE: 'Manufacturer',
  CAR_MODEL: 'Model',
  NEW_CUSTOMER_STEP: 'New Customer',

  // WASH STATE CHANGE BUTTONS
  FINISH_WASHING: 'PayNow',
  CANCEL_WASHING: 'Resign',
  PAUSE_WASHING: 'Pause',
  START_WASHING: 'Start',

  // CANCELLED RESERVATIONS
  PCS: 'piece',
  CANCELLED_RESERVATIONS: 'Resigned Reservations',

  // GENERAL SETTINGS
  SETTINGS: 'Settings',
  GENERAL_SETTINGS: 'General Settings',
  WASHER_NAME: 'Unit Name',
  COUNTRY: 'Country',
  CITY: 'City',
  STATE: 'County',
  DISTRICT: 'District',
  POSTAL_CODE: 'Zip',
  STREET: 'Street',
  HOUSE: 'Building',
  FLOOR: 'Floor',
  DOOR: 'Door',
  FACEBOOK: 'Facebook',
  CONTACTS: 'Contacts',
  SAVING_DONE: 'Saved Successfully',
  SAVE: 'Save',
  SETTINGS_SAVE_ERROR: 'Failed saving changes',

  // SETTINGS TABS
  GENERAL_SETTINGS_SHORT: 'General',
  OPEN_HOURS_SETTINGS_SHORT: 'Opening Hours',
  PROMOTION_SETTINGS_SHORT: 'Discounts',
  MARKETING_CAMPAIGN_SETTINGS_SHORT: 'Marketing Activities',
  OPEN_HOURS_SETTINGS: 'Opening Hours',
  PROMOTION_SETTINGS: 'Discount Settting',
  HELP_SETTINGS: 'Support Options',
  HELP_SETTINGS_SHORT: 'Support',
  USER_SETTINGS: 'Employee Management',
  USER_SETTINGS_SHORT: 'Employees',
  PRICE_LIST_SETTINGS: 'Regional Prices',
  PRICE_LIST_SETTINGS_SHORT: 'Regional Prices',
  PRICE_LIST_LOADING: 'Pricelist loading',
  // CANCEL WASHING MODAL
  CANCEL_WASH_DESCRIPTION: 'Are you sure to cancel reservation',
  CANCEL_WASH_TITLE: 'Cancel Reservation',
  OK_CANCEL: 'Yes',
  NO_CANCEL: 'No',
  SEARCH_INFO: 'Enter customer data or select vehicle size',

  // COMMENTING
  LOCAL_COMMENT: 'Local comment',
  COMMENT_SEND_SUCCESS: 'Messages Sent',
  COMMENT_SEND_FAIL: 'Sending Failed',
  WAITING_FOR_COMMENT_POSTING: 'Sending...',

  // PROMOTIONS
  PROMOTION_SUCCESS_TITLE: 'Discount logic saved',
  PROMOTION_SUCCESS_DESCRIPTION: 'Discount logic has been recorded.',
  PROMOTION_BTN_OK: 'Ok',
  PROMOTION_BTN_CREATE: 'Create',
  AMOUNT: 'Discount Amount',
  UNIT: 'Fix / Percentage',
  COIN: 'Fixed amount',
  PERCENT: 'Percentage',
  CURRENCY: 'Currency',
  NEW_PROMOTION_TITLE: 'Create new discount',
  NEW_PROMO_DESCRIPTION: 'Fill out parameters to create discount logic',
  ADD_NEW_PROMOTION_BUTTON: 'Create New Discount',
  PROMO_TABLE_FIX_OR_PERCENT: 'Fix / Percentage',
  PROMO_TABLE_AMOUNT: 'Value',

  // MARKETING CAMPAIGNS
  MARKETING_CAMPAIGN_SETTINGS: 'Marketing Activities',
  MARKETING_TABLE_TITLE: 'Name',
  MARKETING_TABLE_DURATION: 'Duration',
  MARKETING_TABLE_PACKAGE: 'Solution',
  MARKETING_TABLE_PROMO_AMOUNT: 'Discounted Amount',
  ACTIVITY: 'Activity',
  CUSTOMER_PROMOTION_INFO: 'This reservation has been made with a discount coupon, it cannot be modified.',

  // NEW RESERVATION MODAL
  CONTINUE_WITH_DIFFERENT_VEHICLE: 'Different Car',
  CHANGE_PROMOTION_TITLE: 'MOdify Discount Logic',
  CHANGE_PROMO_DESCRIPTION: 'Are you sure to modify discount logic?',
  YES: 'Yes',

  // NEW PROFILE
  NEW_PROFILE: 'New Customer',
  PROFILE_DETAILS: 'Customer Profile Details',
  FIRST_NAME: 'Name',
  LAST_NAME: 'Family Name',
  PHONE: 'Phone',
  NEW_PROFILE_BTN: 'New Customer',
  PROFILE_SUCCESS_TITLE: 'Saved Successfully',
  PROFILE_FAIL_TITLE: 'An error has been occurred while saving customer',
  PROFILE_SUCCESS_DESCRIPTION: 'From now on customer can be found in database',
  PROFILE_FAIL_DESCRIPTION: 'An error has been occurred while saving customer.',
  PROFILE_BTN_OK: 'Done',
  STREET_SHORT: 'str',

  // WASHER SWITCHER
  SWITCH_WASHER: 'Select Unit',

  // PRICE ADJUSTMENT
  PRICE_ADJUSTMENT_TITLE: 'Change Price',
  PRICE_ADJUSTMENT: 'Amount',
  FINISH_WITH_PRICE_ADJUSTMENT: 'Pay',
  PRICE_ADJUSTMENT_INFO: 'Reason of modification',
  FINISH_WASH_STEP: 'Pay',
  PRICE_ADJUSTMENT_DESCRIPTION:
    'Price can be changed if necessary, to modify final price state the amount and the reason of change.',

  // LOADING
  LOADING_BTN: 'Saving...',

  // OPENING HOURS
  OPEN_HOURS_DESCRIPTION: 'Opening Hours can be changed here.',
  ALTERNATING_WEEKS_FALSE: 'Only use a weekly opening hours setting',
  ALTERNATING_WEEKS_TRUE: 'Biweekly opening hours',
  EVEN: 'B week',
  ODD: 'A week',
  Mo: 'Monday',
  Tu: 'Tuesday',
  We: 'Wednesday',
  Th: 'Thursday',
  Fr: 'Friday',
  Sa: 'Saturday',
  Su: 'Sunday',
  Mo_SHORT: 'Mo',
  Tu_SHORT: 'Tu',
  We_SHORT: 'We',
  Th_SHORT: 'Th',
  Fr_SHORT: 'Fr',
  Sa_SHORT: 'Sa',
  Su_SHORT: 'Su',
  SAVE_NEW_SHIFT_BTN: 'Save',
  CANCEL_NEW_SHIFT_BTN: 'Cancel',

  // DOWNTIMES
  USE_DOWNTIMES_TITLE: 'Add Expection in operning hours',
  DOWNTIMES_TITLE: 'Exception',
  DOWNTIMES_DESCRIPTION: 'These terms will be marked as closed in the myGreenline application',
  NAMING: 'Reason',
  DATE_FROM: 'Date From',
  DATE_TO: 'Date To',
  SAVE_NEW_DOWNTIME_BTN: 'Save',
  ADD_SHIFT_BTN: 'Define another period ',
  REMOVE_SHIFT_BTN: 'Delete',
  DELETE_DOWNTIME_BTN: 'Delete',
  ADD_NEW_DOWNTIME_BTN: 'Add new exception',
  CANCEL_NEW_DOWNTIME_BTN: 'Cancel',

  // NEW RESERVATION
  WASH_STARTED: 'Washing started',
  CANCEL_NEW_RESERVATION_TITLE: 'Cancel the process of reservation',
  CANCEL_NEW_RESERVATION_DESCRIPTION: 'Are you sure to cancel reservation?',

  // WASH DETAILS
  REMOVE_PROMOTION_TITLE: 'Delete Discount',
  REMOVE_PROMO_DESCRIPTION: 'Are you sure to cancel discount?',
  NO_PROMOTION: 'No Promotion',
  SEARCH: 'Searching...',
  TIME_PASSED: 'Time has beeen passed',
  WASH_COMMENT: 'Comment to reservation',
  VEHICLE_COMMENT: 'Commen to vehicle',
  WASH_IMAGES: 'Images',
  PRICE_AFTER_ADJUSTMENT: 'Modified Price',
  DAYVIEW: 'Day',
  WEEKVIEW: 'Week',
  CONTACT: 'Customer',
  WASH_TIME: 'Duration',

  // PAYMENT SCREEN
  PAYMENT_METHOD_TITLE: 'Payment Method',
  PAYMENT_METHOD_DESCRIPTION: 'Select Payment Method',
  CASH: 'Cash',
  CARD: 'Bankcard',
  MONTHLY: 'Transfer',

  // UPDATE SCREEN
  VERSION_UPDATE_TITLE: 'A new version is available, please refresh.',
  VERSION_UPDATE_PROGRESS_TITLE: 'Refreshing...',
  VERSION_UPDATE_DESCRIPTION: 'Page might refresh several times and user have to log in again.',
  VERSION_UPDATE_PROGRESS_DESCRIPTION: 'Refreshing in progress',
  VERSION_UPDATE_BTN: 'Ok',
  SEC: 'sec',
  MORE_THAN: 'More than',

  // HELP SETTINGS
  HELP_BTN: 'Contact',
  HELP_DESCRIPTION:
    'In case any problem has been occurred while using the interface you can get in touch with us using the following widget.',

  // USER SETTINGS
  NEW_USER_BTN: 'New Employee',
  NEW_USER_TITLE: 'Add New Employee',
  NEW_USER_DESCRIPTION: 'Enter the employee role and email address.',
  ROLE: 'Role',
  INVITEE: 'E-mail address',
  NEW_USER_BTN_CREATE: 'Send invitation through Email',
  ADD_NEW_USER_BUTTON: 'New Employee',
  USER_FAIL_TITLE: 'Invitation Failed',
  USER_FAIL_DESCRIPTION: 'An error occurred while sending the invitation',
  USER_SUCCESS_TITLE: 'Successful invitation',
  USER_SUCCESS_DESCRIPTION: 'The invitation email has been sent successfully',
  USER_BTN_OK: 'Done',
  NO_USERS: 'No User has been added',

  // SIGN UP
  SIGNUP_TITLE: 'Registration',
  SIGNUP_DESCRIPTION: 'Please type the following information for registration',
  FIRSTNAME: 'Family Name',
  LASTNAME: 'Given Name',
  SIGNUP_ERROR: 'Registration failed.',

  // EVENT MOVE
  MOVE_EVENT_TITLE: 'Move Reservation',
  MOVE_EVENT_DESCRIPTION:
    'System gives you the option not just to change reservation time, but to cahnge duration if necessary, system only accept quarters when setting duration.',
  MOVE_EVENT_DURATION_ERROR: 'Duration has to be a quarter.',
  ROUND_UP_DURATION_BTN: 'Round duration up to a quarter.',
  DURATION: 'Duration(minutes)',
  MOVE_EVENT_BTN: 'Sace Reservation',
  JUMP_TO_PREV_WEEK: 'Next Week',
  JUMP_TO_NEXT_WEEK: 'Previous Week',
  MOVE_EVENT_NEW_START_TIME: 'New scheduled time',
  EDIT_DURATION_BTN: 'Reschedule time',

  // MARKETING CAMPAIGN SETTINGS
  NO_MARKETINGCAMPAIGNS: 'There is no marketing activity to show',
  NO_ENDTIME: 'Undefined date',
  NO_RESULT: 'No result',

  // COMPLETE WASHING STATE AND MODAL
  COMPLETE_WASHING: 'Ready',
  COMPLETE_WASH_STEP: 'Finish Wash Process',
  COMPLETE_WITH_NEW_CUSTOMER: 'Close Reservation',
  // -----
  YESTERDAY: 'Yesterday',
  USERTAB_ACTIVE: 'Active',
  USERTAB_INACTIVE: 'Inactive',
  USERTAB_INVITED: 'Invited',

  RESET_USER_PASSWORD_TITLE: 'New Password',
  RESET_USER_PASSWORD_DESCRIPTION: 'Set new password for user:',
  NEW_PASSWORD: 'New Password',
  PASSWORD_BTN_CREATE: 'Save',
  PASSWORD_BTN_CANCEL: 'Cancel',
  NEW_PASSWORD_ERROR: 'Error during password change',
  INVITE_RESEND_STATUS_INIT: 'Resend password',
  INVITE_RESEND_STATUS_LOADING: 'Sending in progress...',
  INVITE_RESEND_STATUS_SUCCESS: 'Sending succeed',
  INVITE_RESEND_STATUS_FAIL: 'failed to send',
  RESEND_INVITATION: 'Resend Invitation',
  CHANGE_USER_PASSWORD: 'Change Password',
  ADDRESS: 'Address',
  UNIVERSAL_RESULT_BTN_OK: 'OK',
  // RESET PASSWORD RESULT MODAL
  RESET_PASSWORD_FAIL_TITLE: 'New Password',
  RESET_PASSWORD_FAIL_DESCRIPTION: 'Failed to set new password.',
  RESET_PASSWORD_SUCCESS_TITLE: 'Set new password',
  RESET_PASSWORD_SUCCESS_DESCRIPTION: 'Password set successfully.',
  // RESEND INVITATION RESULT MODAL
  RESEND_INVITATION_FAIL_TITLE: 'An error occurred while sending invitation.',
  RESEND_INVITATION_FAIL_DESCRIPTION: 'An error occurred while resending invitation.',
  RESEND_INVITATION_SUCCESS_TITLE: 'Invitation sent successfully',
  RESEND_INVITATION_SUCCESS_DESCRIPTION: 'Invitation sent successfully',
  // DASHBOARD
  PROFIT: 'Revenue',
  AVG_SHORT: 'avg.',
  NUMBER_OF_WASHES: 'Number of washes',
  AVERAGE_RATING: 'Average feedback',
  CLIENT_COUNT: 'Number of customers',
  FROM_THIS: 'from this',
  NEW_CLIENT: 'New customer',
  THIS_WEEK: 'This week',
  THIS_MONTH: 'This month',
  LAST_WEEK: 'Last week',
  LAST_MONTH: 'Previous month',
  LAST_THIRTY_DAYS: 'Last 30 days',
  LAST_THREESIXFIVE_DAYS: 'Last 365 days',
  LAST_YEAR: 'Previous Year',
  PACKAGE_DURATIONS_CHART: 'Time spent with solutions (min)',
  PACKAGE_CHART: 'Solutions division',
  CAPACITY_CHART: 'Actual Opening time',
  HOUR_EXPECTED: 'Suggested opening time:',
  HOUR: 'hours',
  CUSTOM_DATE: 'Custom Dates',
  DURATION_OF_PACKAGE: 'time spent by solution (min)',
  ACCESS_DENIED: 'Access Denied',
  CAPACITY_SLOT: 'Lines',
  CAPACITY_USAGE: 'Exploitation',
}

export const atDict: any = {
  ACTIVE: 'Aktiv',
  ACTIVITY: 'Aktivität',
  ADD_NEW_DOWNTIME_BTN: 'Neue Ausfallzeit hinzufügen',
  ADD_NEW_PROMOTION_BUTTON: 'Erstelle einen neuen',
  ADD_NEW_USER_BUTTON: 'Neuer Benutzer',
  ADD_SHIFT_BTN: 'Zeit hinzufügen',
  ALTERNATING_WEEKS_FALSE: 'Nur A Woche',
  ALTERNATING_WEEKS_TRUE: 'Abwechselnd wochen (A und B)',
  AMOUNT: 'Rabattwert',
  APPOINTMENT: 'Termin',
  APPOINTMENT_STEP: 'Termin',
  BACK: 'Zurück',
  CANCELLED_RESERVATIONS: 'Stornierte Buchungen',
  CANCEL_NEW_DOWNTIME_BTN: 'Abbrechen',
  CANCEL_NEW_RESERVATION_DESCRIPTION: 'Sie stornieren die neue Reservierung',
  CANCEL_NEW_RESERVATION_TITLE: 'Neue Reservierung stornieren',
  CANCEL_NEW_SHIFT_BTN: 'Abrechen',
  CANCEL_WASH_DESCRIPTION: 'Sind Sie sicher, dass Sie Ihre ausgewählte Autopflege stornieren',
  CANCEL_WASHING: 'Abbrechen',
  CANCEL_WASH_TITLE: 'Autopflege abbrechen',
  CARD: 'Kreditkarten',
  CAR_DETAILS: 'Fahrzeugdetails',
  CAR_MAKE: 'Hersteller',
  CAR_MODEL: 'Fahrzeug Typ',
  CAR_SIZE: 'Fahrzeuggröße',
  CASH: 'Bargeld',
  CHANGE_PROMO_DESCRIPTION: 'Werden Sie den Rabatt auf jeden Fall umtauschen?',
  CHANGE_PROMOTION_TITLE: 'Rabatt ändern',
  CITY: 'Stadt',
  COIN: 'Fix Betrag',
  COMMENT: 'Anmerkung',
  COMMENTS: 'Anmerkungen',
  COMMENT_SEND_FAIL: 'Nachricht konnte nicht gesendet werden',
  COMMENT_SEND_SUCCESS: 'Nachricht gesendet',
  COMPLETE_WASHING: 'Fertig',
  COMPLETE_WASH_STEP: 'Autopflege beenden',
  COMPLETE_WITH_NEW_CUSTOMER: 'Autopflege beenden',
  CONTACT: 'Kunde',
  CONTACTS: 'Kontakte',
  CONTINUE_WITH_DIFFERENT_VEHICLE: 'Geben Sie ein anderes Auto an',
  COUNTRY: 'Land',
  CURRENCY: 'Währung',
  CUSTOMER_DETAILS: 'Kundeninformation',
  CUSTOMER_OR_LICENSEPLATE: 'Kundenname oder Nummernschild',
  SOURCE_OF_DISCOUNT: 'Der Grund für den Rabatt: ',
  CUSTOMER_PROMOTION_INFO:
    'Diese Buchung beinhaltet einen vom Benutzer festgestelten Rabatt\nund kann nicht geändert werden',
  CUSTOMER_STEP: 'Kunde',
  DATE: 'Datum',
  DATE_FROM: 'Datum ab',
  DATE_TO: 'Datum bis',
  DAYVIEW: 'Tag',
  DEFAULT_CATEGORY_TITLE: 'Unbekannte Kategorie',
  NO_PROMOTIONS: 'Sie haben derzeit keinen Rabattcode.',
  SERVICES: 'Dienstleistungen',
  DELETE_DOWNTIME_BTN: 'Löschen',
  DETAILS: 'Details',
  DISTRICT: 'Kreis',
  DOOR: 'Tür',
  DOWNTIMES_DESCRIPTION: 'Wir arbeiten nicht zu der hier eingestellten Zeit',
  DOWNTIMES_TITLE: 'Ausnahmen',
  DURATION: 'Zeitraum',
  EDIT_DURATION_BTN: 'Zeitraum bearbeiten',
  EMAIL_ADDRESS: 'E-Mail Adresse',
  EMAIL_ERROR: 'Falshe E-Mail Adresse',
  END_TIME: 'Ende',
  EVEN: 'B Woche',
  EXTERIOR: 'Aussen',
  FACEBOOK: 'Facebook',
  FIND_BY_CUSTOMER: 'Suche unter Kunden',
  FIND_BY_CUSTOMER_OR_LICENSEPLATE: 'Suche nach Name oder Nummernschild',
  FINISH: 'Fertig',
  FINISH_WASHING: 'Zahlung',
  FINISH_WASH_STEP: 'Zahlung',
  FINISH_WITH_PRICE_ADJUSTMENT: 'Zahlung',
  FIRST_NAME: 'Nachname',
  FLOOR: 'Ebene',
  FORGOT_PASSWORD_BACK_BTN_TITLE: 'Zurück zur Anmeldung',
  FORGOT_PASSWORD_BTN_TITLE: 'Senden',
  FORGOT_PASSWORD_DESCRIPTION: 'Geben Sie Ihre E-Mail Adresse ein,an die wir Ihr neues Passwort \nsenden.',
  FORGOT_PASSWORD_TITLE: 'Passwort vergessen',
  FULL_NAME: 'Vollständiger Name',
  GENERAL_SETTINGS: 'Allgemeines Einstellungen',
  GENERAL_SETTINGS_SHORT: 'Allgemeines',
  HELP_BTN: 'Kontakt',
  HELP_DESCRIPTION:
    'Bei Problemen mit der Software,kann man in dem Menü Hilfe angefordert werden, das sich mit diese Taste öffnet',
  HELP_SETTINGS: 'Hilfe',
  HELP_SETTINGS_SHORT: 'Hilfe',
  HOUSE: 'Haus',
  INTERIOR: 'Innen',
  INTERIOR_EXTERIOR: 'Aussen-Innen',
  INVITEE: 'E-Mail Adresse',
  JUMP_TO_NEXT_WEEK: 'weiter zur nächste Woche',
  JUMP_TO_PREV_WEEK: 'weiter zur nächste Woche',
  LAST_NAME: 'Vorname',
  LAST_ORDER: 'Letzte Bestellung',
  LATEST_ORDERS: 'Letzte Bestellungen',
  LICENSE_PLATE: 'Nummernschild',
  LOADING_BTN: 'Speichern',
  LOCAL_COMMENT: 'Lokaler Hinweis',
  LOGIN_DESCRIPTION: 'Melden Sie sich mit Ihre E-Mail Adresse ung Ihrem Passwort an',
  LOGIN_ERROR: 'Beim Einloggen ist ein Fehler aufgetreten',
  LOGIN_TITLE: 'Anmeldung',
  LOG_OUT_OF_WASHER: 'Aus der Autopflege abmelden',
  MARKETING_CAMPAIGN_SETTINGS: 'Marketingkampagnen',
  MARKETING_CAMPAIGN_SETTINGS_SHORT: 'Kampagnen',
  MARKETING_TABLE_DURATION: 'Zeitram',
  MARKETING_TABLE_PACKAGE: 'Packet',
  MARKETING_TABLE_PROMO_AMOUNT: 'Rabattwert',
  MARKETING_TABLE_TITLE: 'Name',
  MENU_NEW_RESERVATION: 'Neue Buchung',
  MENU_RESERVATIONS: 'Reservierungen',
  MENU_SETTINGS: 'Einstellungen',
  MENU_WASH_DIARY: 'Autopflege Tagebuch',
  MINUTE: 'Minute',
  MONTHLY: 'Monatliche Abrechnung',
  MORE_THAN: 'Mehr als',
  MOVE_EVENT_BTN: 'Autopflege speichern',
  MOVE_EVENT_DESCRIPTION:
    'Neben dem Verschieben der Autopflege ist es möglich die dauer der pflege zu verändern, die dauer wird in Minuten angegeben und muss durch 15 teilbar sein, dabei hilft Aufrunden Taste',
  MOVE_EVENT_DURATION_ERROR: 'Teilbar durch 15',
  MOVE_EVENT_NEW_START_TIME: 'neuer Termin',
  MOVE_EVENT_TITLE: 'Autopflege bewegen',
  NAME: 'Name',
  NAMING: 'Benennung',
  NET_DURATION: 'Tatsächliche Länge',
  NEW_APPOINTMENT: 'Neue Buchung',
  NEW_CUSTOMER_STEP: 'Neukunde',
  NEW_PROFILE: 'Neukunde',
  NEW_PROFILE_BTN: 'Neukunde',
  NEW_PROMO_DESCRIPTION: 'Geben Sie die folgenden Imformationen ein, um einen neuen Rabatt zu erstellen',
  NEW_PROMOTION_TITLE: 'Neuen Rabatt erstellen',
  NEW_USER_BTN: 'Neuer Benutzer',
  NEW_USER_BTN_CREATE: 'Senden Sie eine Einladung per E-Mail',
  NEW_USER_DESCRIPTION: 'Geben Sie Ihre E-Mail-Adresse und Rolle ein ',
  NEW_USER_TITLE: 'Neuen Benutzer hinzufügen',
  NEXT: 'Weiter',
  NO_APPOINTMENT_TODAY: 'Heute gibt es kein Termin',
  NO_CANCEL: 'Nicht',
  NO_CUSTOMER_SELECTED: 'Kein Kunde ausgewählt',
  NO_ENDTIME: 'auf unbestimmte Zeit',
  NO_MARKETINGCAMPAIGNS: 'es sind keine Kampagnenzum Anzeigen vorhanden',
  NO_PACKAGE_SELECTED: 'Kein Paket ausgewählt',
  NO_PROMOTION: 'Nein',
  NO_RESULT: 'keine Ergebnisse',
  NO_USERS: 'keine Benutzer',
  ODD: 'A Woche',
  OPEN_HOURS_DESCRIPTION: 'Hier können Sie die Öffnungs- und Ausfallzeitdaten ändern',
  OPEN_HOURS_SETTINGS: 'Öffnungszeiten',
  OPEN_HOURS_SETTINGS_SHORT: 'Öffnungszeiten',
  ORDER: 'Auftrag',
  ORDERS: 'Bestellungen',
  PACKAGE_STEP: 'Packet',
  PASSWORD: 'Passwort',
  PAUSE_WASHING: 'Pause',
  PAYMENT: 'Zahlung',
  PAYMENT_METHOD_DESCRIPTION: 'Zahlungsmöglichkeit (Bargeld,Kreditkarte,monatliche Abrechnung)\nErforderlich',
  PAYMENT_METHOD_TITLE: 'Zahlungsmöglichkeit',
  PERCENT: 'Prozent',
  PHONE_NUMBER: 'Telefonnummer',
  PIECES: 'Stück',
  POSTAL_CODE: 'Postleitzahl',
  PRICE: 'Preis',
  PRICE_ADJUSTMENT: 'Betrag des Preisanpassungs',
  PRICE_ADJUSTMENT_DESCRIPTION:
    'Bei Bedarf können Sie hier den Preis der Reservierung ändern,\num den Betrag( z.B. 10€?) und den Grund für die Änderung ändern',
  PRICE_ADJUSTMENT_INFO: 'Grund für Preisänderung',
  PRICE_ADJUSTMENT_TITLE: 'Preisanpassung',
  PRICE_AFTER_ADJUSTMENT: 'Geänderter Preis',
  PROFILE_BTN_OK: 'Fertigstellung',
  PROFILE_DETAILS: 'Profildetails',
  PROFILE_FAIL_DESCRIPTION: 'Profil konnte nicht gespeichert werden',
  PROFILE_FAIL_TITLE: 'Speichern fehlgeschlagen',
  PROFILE_SUCCESS_DESCRIPTION: 'Der Kunde wird nun in der Suchmaschine gefunden',
  PROFILE_SUCCESS_TITLE: 'Speichern erfolgreich',
  PROMO_CODE: 'Rabattcode',
  PROMO_TABLE_AMOUNT: 'Wert',
  PROMO_TABLE_FIX_OR_PERCENT: 'Fix/Prozent',
  PROMOTION_BTN_CREATE: 'Erstellen',
  PROMOTION_BTN_OK: 'ok',
  PROMOTION_CODE: 'Rabatt',
  PROMOTION_DESCRIPTION: 'Sie können einen Rabatt für Kunden einstellen',
  PROMOTION_SETTINGS: 'Rabattcodes',
  PROMOTION_SETTINGS_SHORT: 'Rabatte',
  PROMOTION_SUCCESS_DESCRIPTION: 'Rabatt erfolgreich erstellt',
  PROMOTION_SUCCESS_TITLE: 'Rabatt erstellt',
  REMOVE_PROMO_DESCRIPTION: 'Sind Sie sicher, dass Sie den mit Ihrer Buchung verbundenen Rabatt\nstornieren?',
  REMOVE_PROMOTION_TITLE: 'Rabatt stornieren',
  REMOVE_SHIFT_BTN: 'Löschen',
  RESERVATION_BTN_OK: 'Enden',
  RESERVATION_BTN_START_WASH: 'Autopflege starten',
  RESERVATION_FAIL_DESCRIPTION: 'Reservierung konnte nicht gespeichert werden',
  RESERVATION_FAIL_TITLE: 'Reservierung fehlgeschlagen',
  RESERVATION_ID: 'Buchungs-ID',
  RESERVATION_SUCCESS_DESCRIPTION: 'Reservierung erfolreich gespeichert',
  RESERVATION_SUCCESS_TITLE: 'Reservierung erfolgreich',
  ROLE: 'Rolle',
  ROUND_UP_DURATION_BTN: 'Zeitraum aufrunden',
  SAVE: 'Speichern',
  SAVE_NEW_DOWNTIME_BTN: 'Speichern',
  SAVE_NEW_SHIFT_BTN: 'Speichern',
  SAVING_DONE: 'Speichern erfolgreich',
  SEARCH: 'Suche',
  SEARCH_INFO:
    'Geben Sie den Namen oder das Nummernschild des Kunden in die Suchmaschine ein oder wählen Sie eine Fahrzeuggröße aus.',
  SEC: 'Sekundum',
  SELECT_PACKAGE: 'Serviceauswahl',
  SEND_MESSAGE: 'Nachricht senden',
  SETTINGS_SAVE_ERROR: 'Speichern fehlgeschlagen',
  SIGN_IN_BTN: 'Einloggen',
  SIGNUP_DESCRIPTION: 'Zu Registrierung,geben Sie bitte die folgenden Informationen ein  ',
  SIGNUP_ERROR: 'Registrierung fehlgeschlagen',
  SIGNUP_TITLE: 'Anmeldung',
  SIZE: 'Größe',
  START_TIME: 'Start',
  START_WASHING: 'Start',
  START_WASHING_NOW: 'Autopflege jetzt starten',
  STATE: 'Bezirk',
  STREET: 'Straße',
  STREET_SHORT: 'Str.',
  SUBTOTAL: 'Zwischensumme',
  SUMMARY: 'Zusammenfassung',
  SUMMARY_STEP: 'Zusammenfassung',
  SURFACE: 'Fahrzeug Typ',
  SWITCH_WASHER: 'Autopflegev wählen',
  TIME: 'Termin',
  TIME_LEFT: 'Verblieben',
  TIME_PASSED: 'Es ist vorbei',
  TODAY: 'Heute',
  TOTAL_W_DISCOUNT: 'Gesamtsumme mit Rabatt',
  TYPE: 'Typ',
  UNIT: 'Fix/ Prozent',
  USE_DOWNTIMES_TITLE: 'Ausfallzeittitel verwenden',
  USER_BTN_OK: 'Gut',
  USER_FAIL_DESCRIPTION: 'Beim senden der Enladung ist ein Problem aufgetreten',
  USER_FAIL_TITLE: 'Einladung fehlgeschlagen',
  USER_SETTINGS: 'Benutzer',
  USER_SETTINGS_SHORT: 'Benutzer',
  PRICE_LIST_SETTINGS: 'Regionale Preisliste',
  PRICE_LIST_SETTINGS_SHORT: 'Regionale Preisliste',
  PRICE_LIST_LOADING: 'Die Preisliste wird in Kürze geladen',

  USER_SUCCESS_DESCRIPTION: 'Die Einlandungs-E-Mail wurde gesendet',
  USER_SUCCESS_TITLE: 'Einlanung erfolgreich',
  VEHICLE_COMMENT: '',
  VERSION_UPDATE_BTN: 'Gut',
  VERSION_UPDATE_DESCRIPTION:
    'Während des Updates wird die Seite nach dem Update mehmals neu geladen, Sie müssensich erneut bei der Anwendung anmelden',
  VERSION_UPDATE_PROGRESS_DESCRIPTION: 'Aktualisierung läuft',
  VERSION_UPDATE_PROGRESS_TITLE: 'Aktualisieren',
  VERSION_UPDATE_TITLE: 'Eine neue Version ist verfügbar',
  WAITING_FOR_COMMENT_POSTING: 'Senden',
  WASH_COMMENT: 'Hinweis für Fahrzeug',
  WASHER_NAME: 'Name der Autopflege',
  WASH_IMAGES: 'Bilder',
  WASHING: 'Autopflege',
  WASHING_DIARY: 'Autopflege Tagebuch',
  WASH_STARTED: 'Autopflege hat begonnen',
  WASH_TIME: 'Zeitraum',
  WEEKVIEW: 'Woche',
  WORK_DURATION: 'Arbeitszeit',
  YES: 'Ja',
  // ------
  YESTERDAY: 'Gestern',
  MENU_MONITOR: 'Monitor',
  MENU_DASHBOARD: 'Dashboard',
  Mo_SHORT: 'Mo',
  Tu_SHORT: 'Di',
  We_SHORT: 'Mi',
  Th_SHORT: 'Do',
  Fr_SHORT: 'Fr',
  Sa_SHORT: 'Sa',
  Su_SHORT: 'So',
  OK_CANCEL: 'Ja',
  USERTAB_ACTIVE: 'Aktiv',
  USERTAB_INACTIVE: 'Inaktiv',
  USERTAB_INVITED: 'Eingeladen',

  RESET_USER_PASSWORD_TITLE: 'Neues Passwort setzen',
  RESET_USER_PASSWORD_DESCRIPTION: 'Neues Passwort für Mitarbeiter setzen:',
  NEW_PASSWORD: 'Neues Passwort',
  PASSWORD_BTN_CREATE: 'Speichern',
  PASSWORD_BTN_CANCEL: 'Abbrechen',
  NEW_PASSWORD_ERROR: 'Fehler beim Ändern des Passworts',
  INVITE_RESEND_STATUS_INIT: 'Einladung erneut senden',
  INVITE_RESEND_STATUS_LOADING: 'Senden...',
  INVITE_RESEND_STATUS_SUCCESS: 'Erfolgreich gesendet',
  INVITE_RESEND_STATUS_FAIL: 'Senden fehlgeschlagen',
  RESEND_INVITATION: 'Einladung erneut versenden',
  CHANGE_USER_PASSWORD: 'Benutzerpasswort ändern',
  ADDRESS: 'Adresse',
  UNIVERSAL_RESULT_BTN_OK: 'Ok',
  RESET_PASSWORD_FAIL_TITLE: 'Benutzerpasswort ändern',
  RESET_PASSWORD_FAIL_DESCRIPTION: 'Benutzerpasswort ändern fehlgeschlagen.',
  RESET_PASSWORD_SUCCESS_TITLE: 'Benutzerpasswort ändern',
  RESET_PASSWORD_SUCCESS_DESCRIPTION: 'Benutzerpasswort ändern erfolgreich.',
  RESEND_INVITATION_FAIL_TITLE: 'Erneutes Versenden der Einladung fehlgeschlagen',
  RESEND_INVITATION_FAIL_DESCRIPTION: 'Erneutes Senden der Einladungs-E-Mail fehlgeschlagen',
  RESEND_INVITATION_SUCCESS_TITLE: 'Einladung erneut erfolgreich gesendet',
  RESEND_INVITATION_SUCCESS_DESCRIPTION: 'Erneutes Senden der Einladungs-E-Mail erfolgreich',
  // DASHBOARD
  PROFIT: 'Höhe der Einnahmen',
  AVG_SHORT: 'durchschn.',
  NUMBER_OF_WASHES: 'Anzahl der Wäschen',
  AVERAGE_RATING: 'Durchschnittliche Bewertung',
  CLIENT_COUNT: 'Kundenanzahl',
  NEW_CLIENT: 'Neuer Kunde',
  THIS_WEEK: 'Diese Woche',
  THIS_MONTH: 'diesen Monat',
  LAST_WEEK: 'Letzte Woche',
  LAST_MONTH: 'Letzte Monate',
  LAST_THIRTY_DAYS: 'letzten 30 Tage',
  LAST_THREESIXFIVE_DAYS: 'Letzten 365 Tage',
  LAST_YEAR: 'Letztes Jahr',
  PACKAGE_DURATIONS_CHART: 'Mit Paketen verbrachte Zeit (minute)',
  PACKAGE_CHART: 'Pakete Prozentsatz',
  CAPACITY_CHART: 'Kapazitätsauslastung',
  HOUR_EXPECTED: 'Stunde erwartet',
  HOUR: 'Stunde',
  CUSTOM_DATE: 'Benutzerdefiniertes Datum',
  DURATION_OF_PACKAGE: 'Paketdauer',
  CAPACITY_SLOT: 'Slot',
  CAPACITY_USAGE: 'Nutzung',
  FROM_THIS: 'davon',
}

export const testDict: any = {}
