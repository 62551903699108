import moment from 'moment'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, LicensePlate, Text } from '../../components/layout'
import { IEvent } from '../../interfaces/event.interfaces'
import { EventItem, Title } from './compactEvent.styles'

const Event = ({ event }: { event: IEvent }): ReactElement => {
  const { start, duration, vehicle, category, active, surface } = event
  const { t } = useTranslation()

  return (
    <EventItem $active={active}>
      {vehicle?.licensePlate ? (
        <LicensePlate $dark $disabled={!active}>
          {vehicle.licensePlate}
        </LicensePlate>
      ) : (
        <div />
      )}
      <Title>{`${surface && t(surface)} ${category?.title && t(category.title)}`}</Title>
      <Grid $xsCol="auto auto">
        <Text $size="14px">
          {`${moment(start).format('HH:mm')} - ${moment(start)
            .add(duration || 0, 'minutes')
            .format('HH:mm')}`}
        </Text>
        <Text $size="14px" $weight={600}>{`${duration} perc`}</Text>
      </Grid>
    </EventItem>
  )
}
export default Event
