/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/macro'
import { Box, Button } from '@material-ui/core'
import { colors } from '../../../theme.styles'
import { media } from '..'

export const TabSwitcher = styled(Box)<{ $items: number }>`
  justify-self: start;
  display: grid;
  gap: 0px;
  ${({ $items }) =>
    $items &&
    `
    grid-template-columns: repeat(${$items}, 150px);
    & > :not(:nth-child(0)) { 
      border-radius: 0px 0px 0px 0px !important;
    }
    & > :nth-child(1) {
      border-radius: 6px 0px 0px 6px !important;
      margin-right: 0px;
    }
    & > :nth-child(${$items}) {
      border-radius: 0px 6px 6px 0px !important;
      margin-right: 0px;
    }
  `}
`

export const Tab = styled(Button)<{ component?: any; to?: string; $fontSize?: string; $active?: boolean }>`
  border: 1px solid ${colors.veryLightGrey};
  background-color: white;
  border-radius: 6px;
  margin-right: 0px;
  padding: 5px 0px;
  ${media.lg} {
    padding: 10px 5px;
  }
  & .MuiButton-label {
    font-size: ${({ $fontSize }) => $fontSize && `${$fontSize}`};
    text-transform: capitalize;
    color: ${colors.cyan};
    margin: 0px -5px;
    line-height: 1;
  }
  ${({ $active }) =>
    $active &&
    `
    border: 1px solid ${colors.lime};
    background-color: ${colors.lime};
    & .MuiButton-label {
      color: white;
    }
    :hover {
      background-color: ${colors.lime};
    }
  `}
`
