import { price as serviceName } from '../actions'
import { IPrice } from '../interfaces/price.interfaces'
import { IAction } from '../interfaces/store.interfaces'

const initialState = {} as IPrice

export const getPriceReducer = (state = initialState, action: IAction): IPrice => {
  const { type, payload } = action
  switch (type) {
    case `GET_${serviceName}_BEGIN`:
      return state
    case `GET_${serviceName}_SUCCESS`:
      return payload
    case `GET_${serviceName}_FAILED`:
      return state
    default:
      return state
  }
}
