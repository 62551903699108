import styled from 'styled-components'
import { Grid, media } from '../../components/layout'

export const OngoingWashesWrapper = styled(Grid)<{ $ongoing?: boolean }>`
  ${media.md} {
    grid-template-columns: auto auto auto;
    justify-content: end;
    justify-items: end;
  }
  ${({ $ongoing }) =>
    !$ongoing &&
    `
    display: none;
  `}
`
