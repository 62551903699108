/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreator, Dispatch } from 'redux'
import { findProfile as serviceName } from '.'
import { API_URI } from '../config'
import { headers } from '../helpers/headers.helper'

export const findProfile: ActionCreator<any> = (query?: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: `GET_${serviceName}_BEGIN`,
    })
    const res = await fetch(`${API_URI}/profiles?query=${query}`, {
      headers,
    })
    const data = await res.json()
    dispatch({
      type: `GET_${serviceName}_SUCCESS`,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: `GET_${serviceName}_FAILED`,
      payload: error,
    })
  }
}

export const emptyProfileList: ActionCreator<any> = () => async (dispatch: Dispatch) => {
  dispatch({
    type: `EMPTY_PROFILE_LIST_SUCCESS`,
    payload: [],
  })
}
