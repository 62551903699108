import { Button, Container, Grid } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { UserAvatar, UserCard, InfoWrapper, Logo } from './userselect.styles'
import { IStore } from '../../interfaces/store.interfaces'
import { Text } from '../../components/layout/text'
import { logo } from '../../assets/assets'

const UserselectPage = (): ReactElement => {
  const { t } = useTranslation()
  const users = useSelector((state: IStore) => state.users)
  return (
    <div>
      <Container>
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Logo alt="greenline" src={logo} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {!!users.length &&
            users.map(({ id, firstName, lastName }) => (
              <Grid item lg={3} key={id}>
                <UserCard elevation={0}>
                  <Grid spacing={3} container direction="column" justify="center" alignItems="center">
                    <Grid item>
                      <UserAvatar sizes="120px">{firstName ? firstName[0] : 'G'}</UserAvatar>
                    </Grid>
                    <Grid item>
                      <Text $weight={600} $size="20px" variant="h2">
                        {`${firstName} ${lastName}`}
                      </Text>
                    </Grid>
                    <InfoWrapper item>
                      <Text $size="12px" $align="center" variant="body1">
                        {t('LAST_SIGNED_IN')}
                      </Text>
                      {/* <SigninDescription variant="body1">{last_seen}</SigninDescription> */}
                      <Button disableElevation variant="contained" color="primary">
                        {t('SIGN_IN_BTN')}
                      </Button>
                    </InfoWrapper>
                  </Grid>
                </UserCard>
              </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  )
}
export default UserselectPage
