/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons'
import { Fade, IconButton, Modal } from '@material-ui/core'
import { ImagePreviewContainer } from '../../components/layout/commonUiElements'
import { ImageModalWrapper } from '../../components/layout/modal'
import WashImage from '../../components/layout/washImage'
import { Grid } from '../../components/layout'

interface IImageSliderModule {
  setActiveImage: any
  activeImage: number
  imageOpen: boolean
  setImageOpen: any
  images: string[]
}

const ImageSlider = ({
  setActiveImage,
  setImageOpen,
  activeImage,
  imageOpen,
  images,
}: IImageSliderModule): ReactElement => {
  const onNextImage = (image: string) => {
    if (images.length - 1 > images.indexOf(image)) setActiveImage(activeImage + 1)
  }

  const onPrevImage = (image: string) => {
    if (images.indexOf(image) > 0) setActiveImage(activeImage - 1)
  }
  return (
    <Modal
      onKeyDown={(e) => {
        if (e.keyCode === 39) {
          onNextImage(images[activeImage || 0] || '')
        }
        if (e.keyCode === 37) {
          onPrevImage(images[activeImage || 0] || '')
        }
        if (e.keyCode === 27) {
          setImageOpen(false)
        }
      }}
      closeAfterTransition
      open={imageOpen}
      onClose={() => setImageOpen(false)}
    >
      <Fade in={imageOpen}>
        <ImageModalWrapper>
          <IconButton
            style={{ position: 'absolute', top: 0, right: 0, zIndex: 33 }}
            onClick={() => setImageOpen(false)}
            size="medium"
          >
            <Close style={{ fontSize: 50, color: 'white' }} />
          </IconButton>
          <Grid $xxsCol="auto 1fr auto" $alignItems="center" $justifyItems="center">
            <IconButton size="medium" onClick={() => onPrevImage(images[activeImage || 0] || '')}>
              <ChevronLeft style={{ fontSize: 50, color: 'white' }} />
            </IconButton>
            <ImagePreviewContainer>
              <WashImage image={images?.[activeImage || 0]} />
            </ImagePreviewContainer>
            <IconButton size="medium" onClick={() => onNextImage(images[activeImage || 0] || '')}>
              <ChevronRight style={{ fontSize: 50, color: 'white' }} />
            </IconButton>
          </Grid>
        </ImageModalWrapper>
      </Fade>
    </Modal>
  )
}

export default ImageSlider
