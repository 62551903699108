import { openHours as serviceName } from '../actions'
import { IOpeningHours } from '../interfaces/openHours.interfaces'
import { IAction } from '../interfaces/store.interfaces'

const initialState = {} as IOpeningHours

export const getOpenHoursReducer = (state = initialState, action: IAction): IOpeningHours => {
  const { type, payload } = action
  switch (type) {
    case `GET_${serviceName}_BEGIN`:
      return state
    case `GET_${serviceName}_SUCCESS`:
      return payload
    case `GET_${serviceName}_FAILED`:
      return state
    default:
      return state
  }
}
