import { packages as serviceName } from '../actions'
import { IPackage } from '../interfaces/package.interfaces'
import { IAction } from '../interfaces/store.interfaces'

const initialState = [] as IPackage[]

export const getPackagesReducer = (state = initialState, action: IAction): IPackage[] => {
  const { type, payload } = action
  switch (type) {
    case `GET_${serviceName}_BEGIN`:
      return state
    case `GET_${serviceName}_SUCCESS`:
      return payload
    case `GET_${serviceName}_FAILED`:
      return state
    default:
      return state
  }
}
