export const userColors = [
  '#EFA7BB',
  '#F9D8B2',
  '#D5E6A5',
  '#A5C4E6',
  '#BFC4CC',
  '#EFA7BB',
  '#F9D8B2',
  '#D5E6A5',
  '#A5C4E6',
  '#BFC4CC',
]
