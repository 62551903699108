import { useTranslation } from 'react-i18next'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Title, HeaderWrapper, AppointmentsCount, OngoingWrapper } from './resourceHeader.styles'
import { ISlotSelectorProps } from '../../interfaces/calendar.interfaces'
import { Grid, LicensePlate, Text } from '../../components/layout'
import { IStore } from '../../interfaces/store.interfaces'

const ResourceHeader = (props: ISlotSelectorProps, currentDay: Date): ReactElement => {
  const { t } = useTranslation()
  const { resource } = props
  const { slot, resourceTitle } = resource
  const reservations = useSelector((state: IStore) => state.reservations?.reservationlist)
  const appointmentsList =
    !!reservations.length &&
    reservations.filter(
      ({ slot: eventSlot, startTime }) => eventSlot.toString() === slot && moment(currentDay).isSame(startTime, 'day'),
    )

  const ongoingAppointmentsList =
    appointmentsList &&
    !!appointmentsList.length &&
    appointmentsList.filter(({ lastStatus }) => lastStatus === 'ON_GOING')

  return (
    <HeaderWrapper>
      {!!ongoingAppointmentsList && !!ongoingAppointmentsList.length && (
        <OngoingWrapper>
          <Text $lettercase="uppercase" $weight={600}>
            {t('ACTIVE')}
          </Text>
          {ongoingAppointmentsList[0].vehicle?.licensePlate && (
            <LicensePlate $event>{ongoingAppointmentsList[0].vehicle.licensePlate}</LicensePlate>
          )}
        </OngoingWrapper>
      )}
      <Grid $col="1fr auto">
        <Title $active={ongoingAppointmentsList && !!ongoingAppointmentsList.length}>{resourceTitle}</Title>
        <AppointmentsCount>
          {appointmentsList ? `${appointmentsList?.length} ${t('APPOINTMENT')}` : t('NO_APPOINTMENT_TODAY')}
        </AppointmentsCount>
      </Grid>
    </HeaderWrapper>
  )
}
export default ResourceHeader
