import React, { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'
import { useCookies } from 'react-cookie'
import { ModalWrapper, ModalHeader, ModalCloseBtn, ModalBody } from '../../../components/layout/modal'
import { toggleNewUserModal, toggleUniversalResultModal } from '../../../actions/uiStatesAction'
import { getInvitedUsers } from '../../../actions/getInvitedUsersActions'
import { TextInput } from '../../../components/layout/form/form.styles'
import { API_URI, WASHERID_COOKIE_KEY } from '../../../config'
import { IStore } from '../../../interfaces/store.interfaces'
import { headers } from '../../../helpers/headers.helper'
import { Text, Grid } from '../../../components/layout'

const NewProfileModal = (): ReactElement => {
  const mailformat = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [invitee, setInvitee] = useState('')
  // const [role, setRole] = useState('USER')
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const isOpen = useSelector((state: IStore) => state.uiStates.newUserModalOpen)

  const postUrl = `${API_URI}/washers/${washerId}/users/invitations`
  const mailError = invitee.length > 0 && !invitee.match(mailformat)
  const data = { invitee }
  const onSubmit = async () => {
    setLoading(true)
    const response = await fetch(`${postUrl}`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })

    if (response.status !== 200) {
      setLoading(false)
      dispatch(toggleUniversalResultModal('ERROR', 'USER_FAIL_TITLE', 'USER_FAIL_DESCRIPTION'))
    }
    if (response.status === 200) {
      setLoading(false)
      dispatch(toggleNewUserModal())
      dispatch(toggleUniversalResultModal('SUCCESS', 'USER_SUCCESS_TITLE', 'USER_SUCCESS_DESCRIPTION'))
      dispatch(getInvitedUsers(washerId))
    }
  }

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper $small>
          <ModalHeader $small>
            <Text $mdSize="30px" $xxsSize="20px" $weight={500} $align="center">
              {t('NEW_USER_TITLE')}
            </Text>
            <ModalCloseBtn onClick={() => dispatch(toggleNewUserModal())}>×</ModalCloseBtn>
          </ModalHeader>
          <ModalBody>
            <Grid $gap="20px" $padding="40px">
              <Text $size="16px" $align="center">
                {t('NEW_USER_DESCRIPTION')}
              </Text>
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInvitee(e.target.value)}
                placeholder={t('INVITEE')}
                error={mailError}
                label={t('INVITEE')}
                variant="outlined"
                id="invitee"
                $semiRounded
                $search
              />
              {/* <FormControl variant="outlined">
                <InputLabel id="role">
                  <Text $size="18px" $weight={600} $textColor="black">
                    {t('ROLE')}
                  </Text>
                </InputLabel>
                <SelectInput
                  labelId="unit"
                  id="unit"
                  value={role}
                  onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                    setRole(event.target.value as string)
                  }
                >
                  <MenuItem value="USER">{t('USER')}</MenuItem>
                  <MenuItem value="ADMIN">{t('ADMIN')}</MenuItem>
                </SelectInput>
              </FormControl> */}
              <Button
                onClick={() => onSubmit()}
                variant="contained"
                disabled={mailError || !invitee.length}
                disableElevation
                color="primary"
              >
                {loading ? t('LOADING_BTN') : t('NEW_USER_BTN_CREATE')}
              </Button>
            </Grid>
          </ModalBody>
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default NewProfileModal
