/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreator, Dispatch } from 'redux'
import { ureservations as serviceName } from '.'
import { API_URI } from '../config'
import { headers } from '../helpers/headers.helper'

const urlQuery = '/reservations?day-offset='

export const getReservations: ActionCreator<any> =
  (washerId: number, dayOffset?: number, dayCount?: number) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: `GET_${serviceName}_BEGIN`,
      })
      const res = await fetch(`${API_URI}/washers/${washerId}${urlQuery}${dayOffset}&day-count=${dayCount || 1}`, {
        headers,
      })
      const reservationlist = await res.json()
      if (res.status === 200) {
        dispatch({
          type: `GET_${serviceName}_SUCCESS`,
          payload: { reservationlist },
        })
      }
      if (res.status !== 200) {
        dispatch({
          type: `GET_${serviceName}_FAILED`,
        })
      }
    } catch (error) {
      dispatch({
        type: `GET_${serviceName}_FAILED`,
      })
    }
  }

export const getReservationById: ActionCreator<any> = (washerId: number, id?: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: `GET_${serviceName}_BEGIN`,
    })
    const res = await fetch(`${API_URI}/washers/${washerId}/reservations/${id}`, {
      headers,
    })
    const reservation = await res.json()
    if (res.status === 200) {
      dispatch({
        type: `GET_${serviceName}_SUCCESS`,
        payload: { reservationlist: [reservation] },
      })
    }
    if (res.status !== 200) {
      dispatch({
        type: `GET_${serviceName}_FAILED`,
      })
    }
  } catch (error) {
    dispatch({
      type: `GET_${serviceName}_FAILED`,
    })
  }
}
