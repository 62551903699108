/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/macro'
import { Avatar, Box, Button, Typography } from '@material-ui/core'
import { colors, fonts } from '../../theme.styles'
import { media } from '../../components/layout'

export const EventItem = styled('button')<{
  $finished?: boolean
  $duration?: number
  $active?: boolean
  $completed?: boolean
  $calView?: string
}>`
  background-color: ${colors.lime};
  margin: 7px 14px 7px 7px;
  box-sizing: border-box;
  height: 90% !important;
  width: 98% !important;
  appearance: none;
  text-align: start;
  display: grid;
  outline: none;
  border: none;
  border-radius: 15px;
  overflow: hidden;
  max-height: 100%;
  cursor: pointer;
  color: white;
  padding: 6px 8px;
  ${media.lg} {
    padding: 11px 18px;
    ${({ $calView }) =>
      $calView === 'week' &&
      `
      padding: 8px 11px;
      margin:0px !important;
      width: 100% !important;
    `}
  }
  ${({ $active }) =>
    $active &&
    `
   background-color: ${colors.magenta};
  `}
  ${({ $completed }) =>
    $completed &&
    `
   background-color: #2196f3;
  `}
  ${({ $finished }) =>
    $finished &&
    `
   background-color: ${colors.cyan};
  `}
`

export const Title = styled(Typography)`
  font-family: ${fonts.main};
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  ${media.lg} {
    font-size: 16px;
  }
`

export const WashSize = styled(Avatar)<{ $weekEvent?: boolean }>`
  background-color: white;
  color: ${colors.cyan};
  font-weight: 600;
  font-size: 14px;
  width: 40px;
  ${media.lg} {
    font-size: 16px;
  }
  ${({ $weekEvent }) =>
    $weekEvent &&
    `
    font-size: 10px!important;
    background-color: white;
    height: 20px;
    width: 20px;
  `}
`

export const CloseBtn = styled.button`
  transition: all .1s ease-in-out;
  background-color: transparent;
  position: absolute;
  appearance: none;
  color: ${colors.grey};
  font-size: 30px;
  border: none;
  opcaity: 0.5;
  right: 5px;
  top: 0;
  &:hover {
    color: ${colors.black};
    opcaity: 1;
  },
`

export const HeaderSection = styled(Box)`
  grid-template-columns: 1fr auto 45px;
  padding: 15px 15px 10px 10px;
  display: grid;
  max-width: 500px;
  align-items: center;
  ${media.md} {
    padding: 30px;
  }
`

export const Section = styled(Box)`
  border-top: 1px solid ${colors.veryLightGrey};
  grid-template-columns: 1fr;
  align-content: center;
  align-items: center;
  padding: 10px;
  display: grid;
  gap: 10px;
  ${media.md} {
    grid-template-columns: 2fr 1fr;
    padding: 30px;
    gap: 30px;
  }
`

export const ControlSection = styled(Box)`
  border-top: 1px solid ${colors.veryLightGrey};
  grid-template-columns: 1fr;
  align-content: center;
  align-items: center;
  padding: 10px;
  display: grid;
  gap: 10px;
  ${media.md} {
    grid-template-columns: 1fr;
    padding: 30px;
    gap: 30px;
  }
`

export const DetailsBtn = styled(Button)<{ component?: any; to: string }>`
  & .MuiButton-label {
      text-transform: capitalize;
      color: ${colors.cyan};
      font-weight: 600;
      font-size: 14px;
    },
    &:after {
      color: ${colors.cyan};
      position: relative;
      margin-bottom: 2px;
      margin-left: 3px;
      font-size: 30px;
      content: "›";
    },
`

export const StartIcon = styled(Box)`
  background-color: ${colors.lime};
  height: 20px;
  width: 20px;
`

export const StartBtn = styled(Button)`
    border-radius: 15px;
    width: 100%!important;
    & .MuiButton-label {
      grid-template-columns: auto 1fr auto;
      color: ${colors.black};
      font-weight: 600;
      padding: 10px;
      display: grid;
      gap: 20px;
    },
`

export const PopoverContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`
