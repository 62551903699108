import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { useTranslation } from 'react-i18next'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '../../interfaces/store.interfaces'
import { Grid, Text } from '../../components/layout'
import { colors } from '../../theme.styles'
import { LineChart } from './chart.styles'

const CapacityUsageChart = (): ReactElement => {
  ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
  const { t } = useTranslation()
  const capacity = useSelector((state: IStore) => state.statistics?.capacity)

  return (
    <Grid $alignItems="start" $alignContent="start">
      <Text $weight={500} $textColor={colors.grey} $lineHeight="1">
        {t('CAPACITY_CHART')}
      </Text>
      <Text $xxsSize="32px" $weight={600} $textColor={colors.body} $lineHeight="1">
        {`${capacity?.openHourNr} ${t('HOUR')}`}
      </Text>
      <Text $textColor={colors.grey} $xxsSize="14px">
        {`${t('HOUR_EXPECTED')} ${capacity?.expectedHourNr} 
        (${Math.round(((capacity?.openHourNr || 0) / (capacity?.expectedHourNr || 1)) * 100)}%)`}
      </Text>
      {!!capacity?.slots.length &&
        capacity.slots.map(({ index, activeMinutes, inactiveMinutes }) => {
          const progress = activeMinutes / ((activeMinutes + inactiveMinutes) / 100)
          return (
            <Grid $marginTop="20px" key={index}>
              <Grid $xxsCol="1fr auto">
                <Text $textColor={colors.grey}>{`${t('CAPACITY_SLOT')} ${index}`}</Text>
                <Text $textColor={colors.grey}>{`${t('CAPACITY_USAGE')}: ${Math.round(progress)}%`}</Text>
              </Grid>
              <LineChart variant="determinate" value={progress} />
            </Grid>
          )
        })}
    </Grid>
  )
}

export default CapacityUsageChart
