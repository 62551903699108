import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'
import { ModalWrapper, ModalCloseBtn, ButtonWrapper, ModalResultContent } from '../../../components/layout/modal'
import { clearUniversalResultModal } from '../../../actions/uiStatesAction'
import { IStore } from '../../../interfaces/store.interfaces'
import { TextBlock } from '../../../components/layout/text'
import { Text, Icon } from '../../../components/layout'

const UniversalResultModal = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const description = useSelector((state: IStore) => state.uiStates.universalResultModal.description)
  const modalType = useSelector((state: IStore) => state.uiStates.universalResultModal.type)
  const isOpen = useSelector((state: IStore) => state.uiStates.universalResultModal.open)
  const title = useSelector((state: IStore) => state.uiStates.universalResultModal.title)

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper $small>
          <ModalResultContent $justifyItems="center">
            <ModalCloseBtn onClick={() => dispatch(clearUniversalResultModal())}>×</ModalCloseBtn>
            <Icon $success={modalType === 'SUCCESS'} $cancel={modalType === 'ERROR'} $width="100px" $height="100px" />
            <Text $xxsSize="20px" $lgSize="30px" $weight={500}>
              {t(title)}
            </Text>
            <TextBlock $align="center" $xxsSize="12px" $lgSize="20px">
              {t(description)}
            </TextBlock>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  dispatch(clearUniversalResultModal())
                }}
                variant="contained"
                disableElevation
                color="primary"
              >
                {t('UNIVERSAL_RESULT_BTN_OK')}
              </Button>
            </ButtonWrapper>
          </ModalResultContent>
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default UniversalResultModal
