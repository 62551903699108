/* eslint-disable @typescript-eslint/no-explicit-any */
import { MonetizationOn, People, StarRate, Event, LocalCarWash } from '@material-ui/icons'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { CircularProgress, Container, MenuItem } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { CapacityUsageChart, PackageDurationsChart, PackageValuesChart } from '../../modules/charts'
import { PREFERRED_CALENDARVIEW_COOKIE_KEY, WASHERID_COOKIE_KEY } from '../../config'
import { DashboardDatePicker } from '../../components/layout/form/form.styles'
import { DropDownMenuButton } from '../../components/layout/commonUiElements'
import { getStatistics } from '../../actions/getStatisticsActions'
import { UserMenu } from '../../modules/header/header.styles'
import { IStore } from '../../interfaces/store.interfaces'
import { Grid, Icon, Text } from '../../components/layout'
import { Box } from './dashboardpage.styles'
import { colors } from '../../theme.styles'

const iconStyle = { width: '1.3em', height: '1.3em' }

const DashboardPage = (): ReactElement => {
  const [cookies] = useCookies([WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const washerId = cookies[WASHERID_COOKIE_KEY]
  const buttonRef = useRef(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const statistics = useSelector((state: IStore) => state.statistics)
  const roles = useSelector((state: IStore) => state.user?.roles)

  const initialRange: IRange = {
    from: moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
    to: moment().endOf('day').subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
    label: 'LAST_THIRTY_DAYS',
  }

  const [isDateCustom, setIsDateCustom] = useState(false)
  const [range, setRange] = useState(initialRange) as any
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  interface IRange {
    from: string
    to: string
    label: string
  }

  useEffect(() => {
    if (washerId && range) {
      dispatch(getStatistics(washerId, encodeURIComponent(range.from), encodeURIComponent(range.to)))
    }
  }, [dispatch, washerId, range])

  const handleClick = () => {
    setAnchorEl(buttonRef.current)
    setMenuOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMenuOpen(false)
  }

  const formatter = new Intl.NumberFormat('de-DE')

  const dateSelectorConfig = {
    today: {
      from: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
      to: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
      label: 'TODAY',
    },
    thisWeek: {
      from: moment().startOf('week').format('YYYY-MM-DDTHH:mm:ssZ'),
      to: moment().endOf('week').format('YYYY-MM-DDTHH:mm:ssZ'),
      label: 'THIS_WEEK',
    },
    thisMonth: {
      from: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ssZ'),
      to: moment().endOf('month').format('YYYY-MM-DDTHH:mm:ssZ'),
      label: 'THIS_MONTH',
    },
    lastWeek: {
      from: moment().startOf('week').subtract(7, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
      to: moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
      label: 'LAST_WEEK',
    },
    lastMonth: {
      from: moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ssZ'),
      to: moment().startOf('month').subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
      label: 'LAST_MONTH',
    },
    lastYear: {
      from: moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ssZ'),
      to: moment().startOf('year').subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
      label: 'LAST_YEAR',
    },
    lastThirtyDays: {
      from: moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
      to: moment().endOf('day').subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
      label: 'LAST_THIRTY_DAYS',
    },
    lastThreeSixFiveDays: {
      from: moment().startOf('day').subtract(365, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
      to: moment().endOf('day').subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
      label: 'LAST_THREESIXFIVE_DAYS',
    },
  }

  const datePickerOptions = [
    {
      id: 'oijsd',
      value: dateSelectorConfig.today,
      label: t('TODAY'),
    },
    {
      id: 'tyyttty',
      value: dateSelectorConfig.thisWeek,
      label: t('THIS_WEEK'),
    },
    {
      id: 'sdsddsd',
      value: dateSelectorConfig.thisMonth,
      label: t('THIS_MONTH'),
    },
    {
      id: 'weewweewe',
      value: dateSelectorConfig.lastWeek,
      label: t('LAST_WEEK'),
    },
    {
      id: 'kookokook',
      value: dateSelectorConfig.lastMonth,
      label: t('LAST_MONTH'),
    },
    {
      id: 'lpplplplp',
      value: dateSelectorConfig.lastThirtyDays,
      label: t('LAST_THIRTY_DAYS'),
    },
    {
      id: 'vggvgvvigj',
      value: dateSelectorConfig.lastThreeSixFiveDays,
      label: t('LAST_THREESIXFIVE_DAYS'),
    },
    {
      id: 'ijijijisjai',
      value: dateSelectorConfig.lastYear,
      label: t('LAST_YEAR'),
    },
  ]

  if (!roles)
    return (
      <Grid $marginTop="100px" $justifyContent="center" $justifyItems="center">
        <CircularProgress />
      </Grid>
    )

  return (
    <Container maxWidth="xl">
      {roles?.filter((roleitem) => roleitem === 'CHIEF' || roleitem === 'USER').length ? (
        <>
          <Grid $mdCol="250px 1fr">
            <DropDownMenuButton
              $withAvatar
              onClick={handleClick}
              id="date-range-select"
              $open={!!anchorEl}
              ref={buttonRef}
            >
              <Event fontSize="medium" style={{ color: colors.grey }} />
              <Text $textColor={colors.cyan} $weight={600}>
                {t(range.label)}
              </Text>
              <Icon $menuDown $size="20px" />
            </DropDownMenuButton>
            <Grid $justifyContent="start" $mdCol="auto auto auto" $alignItems="center" $marginLeft="20px">
              <DashboardDatePicker
                onChange={(inputDate: MaterialUiPickersDate) =>
                  setRange({
                    from: moment(inputDate).format('YYYY-MM-DDTHH:mm:ssZ'),
                    to: range.to,
                    label: t('CUSTOM_DATE'),
                  })
                }
                format="YYYY MMMM DD"
                KeyboardButtonProps={{ 'aria-label': 'change starting date' }}
                value={moment(range.from)}
                inputVariant="standard"
                id="date-picker-from"
                margin="none"
                disabled={!isDateCustom}
                $disabled={!isDateCustom}
                hiddenLabel
                key="start"
              />
              <Text $lineHeight="24px" $xxsSize="24px">
                -
              </Text>
              <DashboardDatePicker
                onChange={(inputDate: MaterialUiPickersDate) =>
                  setRange({
                    from: range.from,
                    to: moment(inputDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
                    label: t('CUSTOM_DATE'),
                  })
                }
                format="YYYY MMMM DD"
                KeyboardButtonProps={{ 'aria-label': 'change ending date' }}
                value={moment(range.to)}
                inputVariant="standard"
                disabled={!isDateCustom}
                $disabled={!isDateCustom}
                id="date-picker-to"
                margin="none"
                hiddenLabel
                key="end"
              />
            </Grid>
            {!!datePickerOptions && (
              <UserMenu
                onClose={handleClose}
                anchorEl={anchorEl}
                open={menuOpen}
                id="datepicker-menu"
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {datePickerOptions.map((option) => (
                  <MenuItem
                    style={{ width: '290px' }}
                    onClick={() => {
                      setRange(option.value)
                      setMenuOpen(false)
                      setIsDateCustom(false)
                    }}
                    key={option.id}
                  >
                    <Grid $alignItems="center" $xsCol="20px 1fr" $gap="10px" $padding="8px">
                      <Text $textColor={colors.cyan} $weight={600}>
                        {t(option.label)}
                      </Text>
                    </Grid>
                  </MenuItem>
                ))}
                <MenuItem
                  style={{ width: '290px' }}
                  onClick={() => {
                    setMenuOpen(false)
                    setIsDateCustom(true)
                    setRange({ ...range, label: t('CUSTOM_DATE') })
                  }}
                >
                  <Grid $alignItems="center" $xsCol="20px 1fr" $gap="10px" $padding="8px">
                    <Text $textColor={colors.cyan} $weight={600}>
                      {t('CUSTOM_DATE')}
                    </Text>
                  </Grid>
                </MenuItem>
              </UserMenu>
            )}
          </Grid>
          <Grid $gap="30px" $marginTop="20px">
            <Grid $col={4} $gap="20px">
              <Box>
                <Grid $xxsCol={2} $alignItems="center" $textColor={colors.grey}>
                  <MonetizationOn fontSize="large" color="inherit" style={iconStyle} />
                  <Grid $justifyItems="end" $xxsRow="auto auto 1fr">
                    <Text $weight={500} $textColor={colors.grey} $lineHeight="1">
                      {t('PROFIT')}
                    </Text>
                    <Text $xxsSize="32px" $weight={600} $textColor={colors.body} $lineHeight="1">
                      {formatter.format(statistics?.income || 0)}
                    </Text>
                    <Text $xxsSize="12px" $weight={500} $textColor={colors.grey} $lineHeight="1">
                      {`${Math.round(statistics.avgIncome || 0)} ${t('AVG_SHORT')}`}
                    </Text>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid $xxsCol={2} $alignItems="center" $alignContent="stretch" $textColor={colors.grey}>
                  <LocalCarWash fontSize="large" color="inherit" style={iconStyle} />
                  <Grid $justifyItems="end" $xxsRow="auto auto 1fr">
                    <Text $weight={500} $textColor={colors.grey} $lineHeight="1">
                      {t('NUMBER_OF_WASHES')}
                    </Text>
                    <Text $xxsSize="32px" $weight={600} $textColor={colors.body} $lineHeight="1">
                      {formatter.format(statistics?.washesCount || 0)}
                    </Text>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid $xxsCol={2} $alignItems="center" $textColor={colors.grey}>
                  <StarRate fontSize="large" color="inherit" style={iconStyle} />
                  <Grid $justifyItems="end" $xxsRow="auto auto 1fr">
                    <Text $weight={500} $textColor={colors.grey} $lineHeight="1">
                      {t('AVERAGE_RATING')}
                    </Text>
                    <Text $xxsSize="32px" $weight={600} $textColor={colors.body} $lineHeight="1">
                      {statistics?.avgRating?.toFixed(2) || 0}
                    </Text>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid $xxsCol={2} $alignItems="center" $textColor={colors.grey}>
                  <People fontSize="large" color="inherit" style={iconStyle} />
                  <Grid $justifyItems="end" $xxsRow="auto auto 1fr">
                    <Text $weight={500} $textColor={colors.grey} $lineHeight="1">
                      {t('CLIENT_COUNT')}
                    </Text>
                    <Text $xxsSize="32px" $weight={600} $textColor={colors.body} $lineHeight="1">
                      {statistics?.clientCount || 0}
                    </Text>
                    <Text $xxsSize="12px" $weight={500} $textColor={colors.grey} $lineHeight="1">
                      {`${t('FROM_THIS')} ${statistics?.newClientCount} ${t('NEW_CLIENT')}`}
                    </Text>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid $col={3} $gap="20px" $alignContent="start" $alignItems="start">
              <Box>
                <PackageDurationsChart />
              </Box>
              <Box>
                <PackageValuesChart />
              </Box>
              <Box>
                <CapacityUsageChart />
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Text $margin="40px" $align="center">
          {t('ACCESS_DENIED')}
        </Text>
      )}
    </Container>
  )
}
export default DashboardPage
