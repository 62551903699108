import React, { ReactElement } from 'react'
import {
  FinishWithExistingCustomerModal,
  CompleteWithNewCustomerModal,
  NewReservationResultModal,
  CancelNewReservationModal,
  ResetUserPasswordModal,
  UniversalResultModal,
  NewReservationModal,
  NewPromotionModal,
  PromoChangeModal,
  NewProfileModal,
  CancelWashModal,
  EventMoveModal,
  NewUserModal,
} from '../../pages/modals'

const ModalsModule = (): ReactElement => (
  <div>
    <FinishWithExistingCustomerModal />
    <CompleteWithNewCustomerModal />
    <NewReservationResultModal />
    <CancelNewReservationModal />
    <ResetUserPasswordModal />
    <UniversalResultModal />
    <NewReservationModal />
    <NewPromotionModal />
    <PromoChangeModal />
    <NewProfileModal />
    <CancelWashModal />
    <EventMoveModal />
    <NewUserModal />
  </div>
)

export default ModalsModule
