import { Avatar, Button, Select, Menu, AppBar } from '@material-ui/core'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Grid, Icon, media } from '../../components/layout'
import { userColors } from '../../mockdata/userColors'
import { colors } from '../../theme.styles'

export const Logo = styled.img`
  margin-right: 30px;
  width: 140px;
`

export const UserSelect = styled(Select)`
  border: 1px solid ${colors.veryLightGrey};
  padding: 5px 7px;
`

export const Appbar = styled(AppBar)`
  padding: 10px 20px;
  border-bottom: 1px solid #e1e7f0;
  &.MuiPaper-root {
    background-color: white;
  }
`

export const UserAvatar = styled(Avatar)<{ $colorSelector?: number }>`
  margin-right: 10px;
  height: 30px;
  width: 30px;
  ${({ $colorSelector }) =>
    $colorSelector &&
    `
   background-color: ${userColors[$colorSelector]};
  `}
`

export const NavBtn = styled(NavLink)<{ $disabled?: boolean }>`
  padding: 18px 18px 28px 18px;
  color: ${colors.cyan};
  user-select: none;
  text-decoration: none;
  font-weight: 600;
  &.active {
    color: ${colors.lime};
    ${media.lg} {
      border-bottom: 3px solid ${colors.lime};
    }
  }
  text-align: center;
  ${media.lg} {
    text-align: left;
  }
  ${({ $disabled }) =>
    $disabled &&
    `
    corsor: not-allowed;
    pointer-events: none;
    color: ${colors.veryLightGrey};
    `}
`

export const NewCustomerBtn = styled(Button)`
  margin-left: 18px;

  &.MuiButton-root {
    padding: 10px;
    ${media.lg} {
      padding: 16px 20px;
    }
  }
`
export const BarWrapper = styled(Appbar)<{ $disabled?: boolean }>`
  position: sticky;
  top: 0;
  padding: 5px 10px;
  ${media.lg} {
    padding: 0px 30px;
  }
  ${({ $disabled }) =>
    $disabled &&
    `
   display: none;
  `}
`

export const UserMenu = styled(Menu)`
  & .MuiPopover-paper {
    transform: translate3d(-30px, 55px, 100px) !important;
    border-radius: 6px;
    overflow-y: auto;
    overflow-x: hidden;
    & i {
      width: 20px;
    }
    & .MuiList-padding {
      padding: 0;
    }
    &:before {
      filter: drop-shadow(0px -1px 1px rgba(94, 108, 128, 0.3));
      transform: translate3d(-10px, -20px, 0);
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid white;
      position: absolute;
      display: block;
      z-index: 999;
      height: 0px;
      content: '';
      width: 0px;
      left: 50%;
      top: 0px;
    }
  }
`

export const DesktopNav = styled(Grid)`
  display: none;
  ${media.lg} {
    display: grid;
  }
`

export const MenuButton = styled.button`
  border: none;
  color: ${colors.lime};
  font-size: 20px;
  min-width: 30px;
  background: transparent;
  ${media.lg} {
    display: none;
  }
`

export const LogoWrapper = styled(Grid)`
  display: none;
  ${media.lg} {
    display: grid;
  }
`

export const MobileLogo = styled(Icon)`
  ${media.lg} {
    display: none;
  }
`

export const MobileMenu = styled(motion.div)`
  width: 100vw;
  height: calc(100vh - 55px);
  background: #fff;
  position: absolute;
  backface-visibility: hidden;
  top: 55px;
  left: 0;
  transform: translateZ(0);
  overflow-y: scroll;
  ${media.lg} {
    display: none;
  }
`
