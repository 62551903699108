/* eslint-disable react/jsx-props-no-spreading */
import { CircularProgress } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import Zendesk, { ZendeskAPI } from 'react-zendesk'
import { WASHERID_COOKIE_KEY } from '../../config'
import { IStore } from '../../interfaces/store.interfaces'
import { colors } from '../../theme.styles'

const ZendeskModule = (): ReactElement => {
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = cookies[WASHERID_COOKIE_KEY]
  const washerdata = useSelector((state: IStore) => state.user?.washers?.find(({ id }) => id === Number(washerId)))
  const defaultEmail = washerdata?.contacts?.find(({ channel }) => channel === 'EMAIL')?.value
  const ZDK_KEY = '90245320-b308-4a87-9374-f90e32629d6d'
  ZendeskAPI('webWidget', 'setLocale', 'hu')

  const setting = {
    color: {
      theme: colors.lime,
    },
    launcher: {
      chatLabel: {
        'en-US': 'Need Help',
      },
    },
    contactForm: {
      fields: [
        { id: 'description', prefill: { '*': '' } },
        { id: 'email', prefill: { '*': defaultEmail } },
      ],
    },
    webWidget: {
      offset: { horizontal: '5px', vertical: '5px' },
    },
  }

  if (washerdata) {
    return <Zendesk zendeskKey={ZDK_KEY} {...setting} onLoaded={() => ZendeskAPI('webWidget', 'hide')} />
  }
  return <CircularProgress color="primary" />
}

export default ZendeskModule
