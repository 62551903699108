/* eslint-disable react/jsx-props-no-spreading */
import { Radio, RadioProps } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { CarSizeRadioChckedIcon, CarSizeRadioIcon } from './styledRadio.styles'

const CarSizeRadio = (props: RadioProps): ReactElement => (
  <Radio
    disableRipple
    color="primary"
    checkedIcon={<CarSizeRadioChckedIcon />}
    icon={<CarSizeRadioIcon />}
    {...props}
  />
)

export default CarSizeRadio
