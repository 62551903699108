import React, { ReactElement } from 'react'
import { Breadcrumbs, LinearProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { IModalBreadcrumbNavigation } from '../../interfaces/modalBreadcrumbNavigation.interfaces'
import { BreadCrumb, ModalNavigation } from '../../components/layout/modal'
import { Button } from '../../components/layout/button/button'
import { Grid } from '../../components/layout'

const ModalBreadcrumbNavigation = ({
  onCustomFunction,
  onHandleFinish,
  onHandleSteps,
  nextDisabled,
  customDisabled,
  customButton,
  loading,
  steps,
  step,
}: IModalBreadcrumbNavigation): ReactElement => {
  const { t } = useTranslation()
  return (
    <>
      {!!loading && <LinearProgress color="primary" />}
      <ModalNavigation>
        <Grid $xsCol="1fr auto" alignItems="center">
          <Breadcrumbs separator=">">
            {steps.slice(0, step).map(({ id, value, number }) => (
              <BreadCrumb key={id} onClick={() => onHandleSteps(number)}>
                {t(value)}
              </BreadCrumb>
            ))}
          </Breadcrumbs>
          <Grid $xxsCol="auto auto" $gap="20px">
            {step > 1 && (
              <Button color="primary" onClick={() => onHandleSteps(step - 1)}>
                {`< ${t('BACK')}`}
              </Button>
            )}
            {!!customButton && customButton.visible && (
              <Button onClick={() => onCustomFunction()} disabled={customDisabled} variant="contained" color="primary">
                {`${t(customButton.text)} >`}
              </Button>
            )}
            {step < steps.length && (
              <Button
                onClick={() => onHandleSteps(step + 1)}
                disabled={nextDisabled || !!loading}
                variant="contained"
                color="primary"
              >
                {`${t('NEXT')} >`}
              </Button>
            )}
            {step === steps.length && (
              <Button
                disabled={nextDisabled || !!loading}
                onClick={() => onHandleFinish()}
                variant="contained"
                color="primary"
              >
                {loading ? t('LOADING_BTN') : `${t('FINISH')} >`}
              </Button>
            )}
          </Grid>
        </Grid>
      </ModalNavigation>
    </>
  )
}
export default ModalBreadcrumbNavigation
