const customMediaQuery = (minWidth: number) => `@media (min-width: ${minWidth / 16}rem)`

export const media = {
  lg: customMediaQuery(992),
  md: customMediaQuery(768),
  xl: customMediaQuery(1200),
  xs: customMediaQuery(576),
  xxl: customMediaQuery(1600),
  xxs: customMediaQuery(20),
}
