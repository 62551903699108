import { INewOrder } from '../interfaces/newOrder.interfaces'
import { IAction } from '../interfaces/store.interfaces'

const initialState = {
  selectedProfileId: undefined,
  selectedPromotion: undefined,
  selectedSlot: undefined,
  vehicleSize: undefined,
  categoryId: undefined,
  packageId: undefined,
  vehicleId: undefined,
  licensePlate: undefined,
  startTime: undefined,
  step: 1,
} as INewOrder

export const newOrderReducer = (state = initialState, action: IAction): INewOrder => {
  const { type, payload } = action
  switch (type) {
    case 'UPDATED_SELECTED_PROMOTION':
      return { ...state, selectedPromotion: payload.selectedPromotion }
    case 'UPDATED_CUSTOMER_PROFILE_ID':
      return { ...state, selectedProfileId: payload.selectedProfileId }
    case 'UPDATED_SELECTED_SLOT':
      return { ...state, selectedSlot: payload.selectedSlot }
    case 'UPDATED_START_TIME':
      return { ...state, startTime: payload.startTime }
    case 'UPDATED_VEHICLE_ID':
      return { ...state, vehicleId: payload.vehicleId }
    case 'UPDATED_VEHICLE_SIZE':
      return { ...state, vehicleSize: payload.vehicleSize }
    case 'UPDATED_LICENSE_PLATE':
      return { ...state, licensePlate: payload.licensePlate }
    case 'UPDATED_PACKAGE_ID':
      return { ...state, packageId: payload.packageId }
    case 'UPDATED_CATEGORY_ID':
      return { ...state, categoryId: payload.categoryId }
    case 'UPDATED_STEP':
      return { ...state, step: payload.step }
    case 'CLEARED_NEW_ORDER_DATA':
      return { ...state, ...payload }
    default:
      return state
  }
}
