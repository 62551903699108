/* eslint-disable react/jsx-props-no-spreading */
import { Radio, RadioProps } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { RadioChckedIcon, RadioIcon } from './styledRadio.styles'

const StyledRadio = (props: RadioProps): ReactElement => (
  <Radio disableRipple color="primary" checkedIcon={<RadioChckedIcon />} icon={<RadioIcon />} {...props} />
)

export default StyledRadio
