import { cancelledReservations as serviceName } from '../actions'
import { IReservations } from '../interfaces/event.interfaces'
import { IAction } from '../interfaces/store.interfaces'

const initialState = { reservationlist: [], loading: false, error: false } as IReservations

export const getCancelledReservationsReducer = (state = initialState, action: IAction): IReservations => {
  const { type, payload } = action
  switch (type) {
    case `GET_${serviceName}_BEGIN`:
      return { ...state, error: false, loading: true }
    case `GET_${serviceName}_SUCCESS`:
      return { ...payload, error: false, loading: false }
    case `GET_${serviceName}_FAILED`:
      return { ...state, error: true, loading: false }
    default:
      return state
  }
}
