import React, { ReactElement } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, Text, LicensePlate, BorderedItem } from '../../components/layout'
import { CustomerInfo, Item, OrderSummary } from './newOrderSummary.styles'
import { setSelectedPromotion } from '../../actions/newReservationActions'
import { IPromotion } from '../../interfaces/promotion.interfaces'
import { IStore } from '../../interfaces/store.interfaces'
import { langMap } from '../../helpers/language.helper'
import SelectedPackage from '../selectedPackage'
import { colors } from '../../theme.styles'
import Promotions from '../promotions'

const NewOrderSummary = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selectedProfileId = useSelector((state: IStore) => state.newOrderDetails.selectedProfileId)
  const selectedPromotion = useSelector((state: IStore) => state.newOrderDetails.selectedPromotion)
  const selectedSlot = useSelector((state: IStore) => state.newOrderDetails.selectedSlot)
  const packageId = useSelector((state: IStore) => state.newOrderDetails.packageId)
  const vehicleSize = useSelector((state: IStore) => state.newOrderDetails.vehicleSize)
  const startTime = useSelector((state: IStore) => state.newOrderDetails.startTime)
  const packages = useSelector((state: IStore) => state.packages)
  const slots = useSelector((state: IStore) => state.slots)
  const profileList = useSelector((state: IStore) => state.profileList)
  const price = useSelector((state: IStore) => state.price)
  const user = useSelector((state: IStore) => state.user)
  const userLang = user.user?.language || 'en'

  const selectedCustomer = profileList.find(({ id: cID }) => cID === selectedProfileId)
  const promotionValue = selectedPromotion ? selectedPromotion.amount : 0
  const selectedPackage = packages.find(({ id }) => id === packageId)
  const selectedSlotTitle = slots.find(({ slot }) => slot === selectedSlot)?.resourceTitle

  return (
    <OrderSummary>
      <Grid $padding="20px 20px 0 20px">
        <Text $weight={600} $size="20px">
          {t('CUSTOMER_STEP')}
        </Text>
      </Grid>
      <CustomerInfo $xsCol="auto 1fr 1fr 1fr 1fr" $xxsCol="auto" $margin="5px 20px" $gap="30px">
        <Grid $alignItems="center" $justifyItems="start">
          {selectedCustomer?.vehicle?.licensePlate && (
            <LicensePlate $large>{selectedCustomer.vehicle.licensePlate}</LicensePlate>
          )}
        </Grid>
        <Item>
          <Text $weight={600} $xxsSize="12px" $lgSize="14px">
            {t('FULL_NAME')}
          </Text>
          <Text $weight={500} $xxsSize="12px" $lgSize="14px">
            {`${selectedCustomer?.firstName} ${selectedCustomer?.lastName}`}
          </Text>
        </Item>
        <Item $gap="11px">
          <Text $weight={600} $xxsSize="12px" $lgSize="14px">
            {t('EMAIL_ADDRESS')}
          </Text>
          <Text $weight={500} $xxsSize="12px" $lgSize="14px">
            {selectedCustomer?.contacts?.length &&
              selectedCustomer.contacts.find(({ channel }) => channel === 'EMAIL')?.value}
          </Text>
        </Item>
        <Item $gap="11px">
          <Text $weight={600} $xxsSize="12px" $lgSize="14px">
            {t('PHONE_NUMBER')}
          </Text>
          <Text $weight={500} $xxsSize="12px" $lgSize="14px">
            {selectedCustomer?.contacts?.length &&
              selectedCustomer.contacts.find(({ channel }) => channel === 'PHONE')?.value}
          </Text>
        </Item>
        <Item $gap="11px">
          <Text $weight={600} $xxsSize="12px" $lgSize="14px">
            {t('SIZE')}
          </Text>
          <Text $weight={500} $xxsSize="12px" $lgSize="14px">
            {vehicleSize || selectedCustomer?.vehicle?.size}
          </Text>
        </Item>
      </CustomerInfo>
      <Grid $padding="20px 20px 0 20px">
        <Text $weight={600} $xxsSize="20px" $lgSize="20px">
          {t('PACKAGE_STEP')}
        </Text>
      </Grid>
      <CustomerInfo>
        <SelectedPackage />
      </CustomerInfo>
      <Promotions
        onHandlePromotion={(promo?: IPromotion) => dispatch(setSelectedPromotion(promo))}
        onRemovePromotion={(promo?: IPromotion) => dispatch(setSelectedPromotion(promo))}
      />
      <Grid $xsCol={2} $margin="5px 20px" $alignItems="start" $gap="20px">
        <Grid>
          <Text $weight={600} $size="20px">
            {t('APPOINTMENT_STEP')}
          </Text>
          <BorderedItem $selected>
            <Text $size="30px" $weight={600} $textColor={colors.lime}>
              {selectedSlotTitle}
            </Text>
            <Text $size="16px">
              {moment(startTime)
                .locale(langMap[userLang] || langMap.en)
                .format('YYYY. MMMM D')}
            </Text>
            <Text $size="16px" $weight={500}>
              {`${moment(startTime).format('HH:mm')} - ${moment(startTime)
                .add(+(selectedPackage?.duration || 0), 'minutes')
                .format('HH:mm')}`}
            </Text>
          </BorderedItem>
        </Grid>
        <Grid>
          <Text $weight={600} $size="20px">
            {t('PAYMENT')}
          </Text>
          <BorderedItem $xsCol="1fr auto" alignItems="center">
            {!!price?.amount && (
              <>
                <Text $weight={500} $xxsSize="14px" $lgSize="16px">
                  {t('SUBTOTAL')}
                </Text>
                <Text align="right" $weight={500} $xxsSize="14px" $lgSize="16px">
                  {`${price?.amount} ${price?.currency}`}
                </Text>
                {!!promotionValue && selectedPromotion && (
                  <>
                    <Text $weight={500} $size="16px">
                      {t('PROMO_CODE')}
                    </Text>
                    <Text align="right" $weight={500} $xxsSize="14px" $lgSize="16px" $textColor={colors.lime}>
                      {`-${promotionValue} ${selectedPromotion.unit === 'PERCENT' ? '%' : selectedPromotion.currency}`}
                    </Text>
                    <Text $weight={500} $xxsSize="14px" $lgSize="16px">
                      {t('TOTAL')}
                    </Text>
                    <Text
                      align="right"
                      $weight={500}
                      $xxsSize="14px"
                      $lgSize="16px"
                      $textColor="rgba(0, 0, 0, 0.4)"
                      $strikeThrough
                    >
                      {`${price?.amount} ${price?.currency}`}
                    </Text>
                  </>
                )}
                <Text $weight={600} $xxsSize="14px" $lgSize="16px">
                  {t('TOTAL_W_DISCOUNT')}
                </Text>
                {typeof price?.discountedPrice === 'number' && (
                  <Text align="right" $weight={600} $xxsSize="20px" $lgSize="30px">
                    {`${price?.discountedPrice || 0} ${price?.currency}`}
                  </Text>
                )}
                {typeof price?.discountedPrice !== 'number' && (
                  <Text align="right" $weight={600} $xxsSize="20px" $lgSize="30px">
                    {`${price?.amount} ${price?.currency}`}
                  </Text>
                )}
              </>
            )}
          </BorderedItem>
        </Grid>
      </Grid>
    </OrderSummary>
  )
}

export default NewOrderSummary
