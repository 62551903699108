import { user as serviceName, userSignup as userSignupServiceName, userMe as userMeServiceName } from '../actions'
import { IUser } from '../interfaces/user.interfaces'
import { IAction } from '../interfaces/store.interfaces'

const initialState = {} as IUser
const washerinitialState = 0 as number

export const userReducer = (state = initialState, action: IAction): IUser => {
  const { type, payload } = action
  switch (type) {
    case `GET_${serviceName}_BEGIN`:
      return { ...state, loading: true }
    case `GET_${serviceName}_SUCCESS`:
      return { ...state, ...payload, loading: false, loaded: true }
    case `GET_${serviceName}_FAILED`:
      return { ...payload, loading: false }
    case `GET_${userMeServiceName}_BEGIN`:
      return { ...state }
    case `GET_${userMeServiceName}_SUCCESS`:
      return { ...payload }
    case `GET_${userMeServiceName}_FAILED`:
      return { ...payload }
    case `GET_${userSignupServiceName}_BEGIN`:
      return { ...state }
    case `GET_${userSignupServiceName}_SUCCESS`:
      return { ...payload, loading: false, loaded: true }
    case `GET_${userSignupServiceName}_FAILED`:
      return { ...payload, loading: false }
    case `CLEAR_${serviceName}_SUCCESS`:
      return { ...payload }
    default:
      return state
  }
}

export const selectedWasherReducer = (state = washerinitialState, action: IAction): number => {
  const { type, payload } = action
  switch (type) {
    case 'SET_SELECTED_WASHER_SUCCESS':
      return payload
    default:
      return state
  }
}
