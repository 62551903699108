/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  QueryBuilderOutlined,
  LocalOfferOutlined,
  SettingsOutlined,
  VolumeUpOutlined,
  PersonOutline,
  HelpOutline,
  LocalOfferRounded,
} from '@material-ui/icons'
import { DesktopNavigation, MobileNavButton, MobileNavigation, NavBtn } from './settings.styles'
import { PaperWrapper } from '../reservationdetails/reservationdetails.styles'
import { settingsPages } from '../../mockdata/settingsPages.data'
import { IStore } from '../../interfaces/store.interfaces'
import { Grid, Text } from '../../components/layout'
import {
  MarketingCampaignSettings,
  OpenHoursSettings,
  PromotionSettings,
  GeneralSettings,
  HelpSettings,
  UserSettings,
} from '../../modules'
import PriceListSettings from '../../modules/priceListSettings'

const SettingsPage = (): ReactElement => {
  const { tab = 'general' } = useParams<{ tab: string }>()
  const { t } = useTranslation()
  const roles = useSelector((state: IStore) => state.user?.roles)

  const iconsMapper: any = {
    general: SettingsOutlined,
    'open-hours': QueryBuilderOutlined,
    promotions: LocalOfferOutlined,
    'marketing-campaigns': VolumeUpOutlined,
    help: HelpOutline,
    users: PersonOutline,
    'price-list': LocalOfferRounded,
  }

  return (
    <Container>
      <Text $weight={500} $size="30px" $margin="30px 0">
        {t('SETTINGS')}
      </Text>
      <Grid $col="220px 1fr" $gap="15px">
        <DesktopNavigation $marginTop="50px" $alignContent="start">
          {!!roles &&
            settingsPages
              .filter(({ role }) => !role || roles.find((roleItem) => roleItem === role))
              .map(({ id, title, url }) => (
                <NavBtn key={id} activeClassName="active" to={`/settings/${url}`}>
                  {t(title)}
                </NavBtn>
              ))}
        </DesktopNavigation>
        <MobileNavigation
          $xxsCol={settingsPages.length > 4 ? 4 : settingsPages.length}
          $xsCol={settingsPages.length}
          $alignItems="end"
        >
          {settingsPages.map(({ id, url, shortTitle }) => {
            const DynamicIcon = iconsMapper[url]
            return (
              <MobileNavButton key={id} activeClassName="active" to={`/settings/${url}`}>
                <DynamicIcon fontSize="large" />
                {t(shortTitle)}
              </MobileNavButton>
            )
          })}
        </MobileNavigation>
        <PaperWrapper elevation={0}>
          {tab === 'general' && <GeneralSettings />}
          {tab === 'open-hours' && <OpenHoursSettings />}
          {tab === 'promotions' && <PromotionSettings />}
          {tab === 'marketing-campaigns' && <MarketingCampaignSettings />}
          {tab === 'help' && <HelpSettings />}
          {tab === 'users' && !!roles?.filter((roleitem) => roleitem === 'CHIEF' || roleitem === 'USER') && (
            <UserSettings />
          )}
          {tab === 'price-list' && <PriceListSettings />}
        </PaperWrapper>
      </Grid>
    </Container>
  )
}

export default SettingsPage
