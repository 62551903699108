/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react'
import { Icon } from '../icons'
import { Text } from '../text'
import { CarSizeWrapper } from './styledRadioLabel.styles'

const CarSizeRadioLabel = ({ description = '', active }: { description: string; active?: string }): ReactElement => {
  const iconName = `$car${active === description ? 'White' : 'Green'}${description}`
  return (
    <CarSizeWrapper $active={active === description}>
      <Icon $size="28px" {...{ [iconName]: true }} />
      <Text $align="center" $textColor={active === description ? 'white' : 'black'} $size="12px" $weight={500}>
        {description}
      </Text>
    </CarSizeWrapper>
  )
}
export default CarSizeRadioLabel
