import React, { ReactElement, useEffect } from 'react'
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { StyledTableBody, StyledTableHead } from '../../components/layout/table/table'
import { SettingsSheet } from '../../components/layout/commonUiElements'
import { Grid, Text, TextBlock } from '../../components/layout'
import { IStore } from '../../interfaces/store.interfaces'
import { WASHERID_COOKIE_KEY } from '../../config'
import { getRegionalPriceList } from '../../actions/getRegionalPriceListActions'

const PriceListSettings = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const priceList = useSelector((state: IStore) => state.priceList)
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])

  useEffect(() => {
    if (washerId) {
      dispatch(getRegionalPriceList(washerId))
    }
  }, [washerId])

  priceList?.solutions.sort((lhs, rhs) => lhs.orderNr - rhs.orderNr)
  priceList?.tasks.sort((lhs, rhs) => lhs.orderNr - rhs.orderNr)

  return (
    <Grid>
      <SettingsSheet $xxsCol="1fr auto">
        <Text $size="20px" $weight={600}>
          {t('PRICE_LIST_SETTINGS')}
        </Text>
        <br />
        <Text $size="20px" $weight={400}>
          {`${priceList?.regionName ?? ''}`}
        </Text>
      </SettingsSheet>
      {priceList ? (
        <>
          <TableContainer elevation={0} component={Paper}>
            <Table size="medium">
              <StyledTableHead>
                <TableRow>
                  <TableCell>{t('SERVICES')}</TableCell>
                  <TableCell>{t('SURFACE')}</TableCell>
                  <TableCell>{t('PRICE')}</TableCell>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {priceList.solutions.map(({ title, retailPrice, currency, surface, vehicleSize, orderNr }) => (
                  <TableRow key={`${title}${surface}${vehicleSize}`}>
                    <TableCell>{`${title} ${t('WASHING')} (${t(vehicleSize)}) `}</TableCell>
                    <TableCell>{t(surface)}</TableCell>
                    <TableCell>{`${retailPrice} ${currency}`}</TableCell>
                  </TableRow>
                ))}
              </StyledTableBody>
            </Table>
          </TableContainer>
          <br />
          <TableContainer elevation={0} component={Paper}>
            <Table size="medium">
              <StyledTableHead>
                <TableRow>
                  <TableCell>{t('SERVICES')}</TableCell>
                  <TableCell>{t('SURFACE')}</TableCell>
                  <TableCell>{t('PRICE')}</TableCell>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {priceList.tasks.map(({ title, retailPrice, currency, surface, orderNr }) => (
                  <TableRow key={`${title} ${orderNr}`}>
                    <TableCell>{title}</TableCell>
                    <TableCell>{t(surface)}</TableCell>
                    <TableCell>{`${retailPrice} ${currency}`}</TableCell>
                  </TableRow>
                ))}
              </StyledTableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Grid $padding="40px 0px">
          <TextBlock $align="center" dangerouslySetInnerHTML={{ __html: t('PRICE_LIST_LOADING') }} />
        </Grid>
      )}
    </Grid>
  )
}

export default PriceListSettings
