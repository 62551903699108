/* eslint-disable import/prefer-default-export */
import { Avatar, Card, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { Grid, media } from '../../components/layout'
import { colors } from '../../theme.styles'

export const UserAvatar = styled(Avatar)`
  font-weight: 600;
  font-size: 25px;
  height: 50px;
  width: 50px;
  ${media.lg} {
    font-size: 30px;
    height: 120px;
    width: 120px;
  }
`

export const UserCard = styled(Card)<{ $active?: boolean }>`
  transition: all 0.15s ease-in-out;
  border: 1px solid #e1e7f0;
  padding-bottom: 20px;
  border-radius: 15px;
  padding-top: 20px;
  ${media.lg} {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  & button {
    position: absolute;
    top: 0;
    transition: all 0.15s ease-in-out;
    opacity: 0;
  }
  & .MuiTypography-body1 {
    transition: all 0.15s ease-in-out;
  }
  &:hover {
    box-shadow: 0px 30px 30px -20px rgba(94, 108, 128, 0.3);
    transform: translateY(-11px);
    cursor: pointer;
    & button {
      opacity: 1;
    }
    & .MuiTypography-body1 {
      opacity: 0;
    }
  }
  ${({ $active }) =>
    $active &&
    `
  border: 2px solid ${colors.lime};
  `}
`

export const SigninDescription = styled(Typography)`
  text-align: center;
  line-height: 15px;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
`

export const InfoWrapper = styled(Grid)`
  position: relative;
`

export const Logo = styled.img`
  margin-bottom: 60px;
  margin-top: 40px;
  width: 282px;
`
