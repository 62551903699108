import React, { ReactElement } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { IHeaderProps } from '../../interfaces/calendar.interfaces'
import { HeaderWrapper, Avatar } from './weekHeader.styles'
import { IStore } from '../../interfaces/store.interfaces'
import { langMap } from '../../helpers/language.helper'
import { Grid, Text } from '../../components/layout'
import { colors } from '../../theme.styles'

const WeekHeader = (props: IHeaderProps): ReactElement => {
  const { date } = props
  const isToday = moment().format('YYYY-DDD') === moment(date).format('YYYY-DDD')
  const user = useSelector((state: IStore) => state.user)
  const userLang = user.user?.language || 'en'

  return (
    <HeaderWrapper>
      <Grid $col="1fr auto" $alignItems="center">
        <Text
          $textColor={isToday ? colors.magenta : 'black'}
          $lettercase="capitalize"
          $weight={500}
          $align="left"
          $size="14px"
        >
          {moment(date)
            .locale(langMap[userLang] || langMap.en)
            .format('dddd')}
        </Text>
        <Avatar $today={isToday}>
          <Text $xxsSize="20px" $lgSize="30px" $weight={500}>
            {moment(date)
              .locale(langMap[userLang] || langMap.en)
              .format('DD')}
          </Text>
        </Avatar>
      </Grid>
    </HeaderWrapper>
  )
}
export default WeekHeader
