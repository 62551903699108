/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreator, Dispatch } from 'redux'
import { marketingCampaigns as serviceName } from '.'
import { API_URI } from '../config'
import { headers } from '../helpers/headers.helper'

export const getMarketingCampaigns: ActionCreator<any> = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: `GET_${serviceName}_BEGIN`,
    })
    const res = await fetch(`${API_URI}/marketing-campaigns`, {
      headers,
    })
    const data = await res.json()
    dispatch({
      type: `GET_${serviceName}_SUCCESS`,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: `GET_${serviceName}_FAILED`,
      payload: error,
    })
  }
}
