import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import { setCategoryId, setPackageId } from '../../actions/newReservationActions'
import { BorderedItem, Tag } from '../../components/layout/commonUiElements'
import { getPackages } from '../../actions/getPackagesActions'
import { IStore } from '../../interfaces/store.interfaces'
import { Grid, Icon, Text } from '../../components/layout'
import { TaskDescription } from '../../components/layout/modal'
import { WASHERID_COOKIE_KEY } from '../../config'
import { SelectorWrapper } from './packageSelector.styles'
import { langMap } from '../../helpers/language.helper'

const PackageSelector = (): ReactElement => {
  const [loading, setLoading] = useState(false)
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const packageId = useSelector((state: IStore) => state.newOrderDetails.packageId)
  const packages = useSelector((state: IStore) => state.packages)
  const vehicleId = useSelector((state: IStore) => state.newOrderDetails.vehicleId)
  const licensePlate = useSelector((state: IStore) => state.newOrderDetails.licensePlate)
  const profileId = useSelector((state: IStore) => state.newOrderDetails.selectedProfileId)
  const profileList = useSelector((state: IStore) => state.profileList)
  const selectedVehicle = profileList.find(({ vehicle }) => vehicle?.id === vehicleId)
  const selectedVehicleSize = selectedVehicle?.vehicle?.size
  const vehicleSize = useSelector((state: IStore) => state.newOrderDetails?.vehicleSize)
  const user = useSelector((state: IStore) => state.user)
  const userLang = user.user?.language || 'en'
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const today = encodeURIComponent(moment().format('YYYY-MM-DDTHH:mm:ssZ'))

  moment.updateLocale('hu', {
    relativeTime: {
      s() {
        return 'most'
      },
      future(relTime) {
        if (relTime.includes('most')) {
          return relTime
        }
        return `${relTime} múlva`
      },
    },
  })

  useEffect(() => {
    if (washerId) {
      setLoading(true)
      dispatch(getPackages(washerId, selectedVehicleSize || vehicleSize, today, profileId, licensePlate))
    }
  }, [])

  useEffect(() => {
    if (packages.length) {
      setLoading(false)
    }
  }, [packages])

  return (
    <SelectorWrapper>
      <Text $weight={600} $size="14px">
        {t('SELECT_PACKAGE')}
      </Text>
      {!!loading && (
        <Grid $justifyItems="center" $marginTop="130px">
          <CircularProgress color="primary" />
        </Grid>
      )}
      {!!packages.length &&
        packages.map(({ id, category, nextAvailableTime, slot, price, surface }) => (
          <BorderedItem
            onClick={() => {
              dispatch(getPackages(washerId, selectedVehicleSize || vehicleSize, today, profileId, licensePlate))
              dispatch(setPackageId(id))
              dispatch(setCategoryId(category.id))
            }}
            $selected={packageId === id}
            $colGap="60px"
            $selectable
            $xsCol={2}
            key={id}
          >
            <Text $xxsSize="16px" $lgSize="20px" $weight={600}>
              {`${t(surface)} ${t(category.title)}`}
            </Text>
            <Text $xxsSize="14px" $lgSize="16px" $weight={600} align="right">
              {`${price.discountedPrice ?? price.amount} ${price.currency}`}
            </Text>
            <TaskDescription>
              {!!category.tasks.length &&
                category.tasks
                  .filter(({ surface: cSurface }) =>
                    surface === 'INTERIOR_EXTERIOR'
                      ? cSurface === 'EXTERIOR' || cSurface === 'INTERIOR'
                      : cSurface === surface,
                  )
                  .map(({ id: cId, title: cTitle }) => <span key={cId}>{`${t(cTitle)}, `}</span>)}
            </TaskDescription>
            <Grid $justifyContent="end" $xxsCol="auto" $alignContent="center">
              <Tag
                $orange={moment(nextAvailableTime).diff(moment(), 'minutes') > 120}
                $red={moment(nextAvailableTime).diff(moment(), 'minutes') > 190}
                $xxsPadding="5px 10px"
                $padding="13px 19px"
                $radius={6}
              >
                <Grid $gap="15px" $xsCol="auto auto auto" $xxsCol="auto auto auto" $alignItems="center">
                  <Text $xxsSize="14px" $lgSize="20px" $weight={600}>{`0${slot}`}</Text>
                  <Grid $xxsCol="auto auto" alignItems="center">
                    <Icon $time $width="15px" $height="15px" />
                    <Text $weight={600} $xxsSize="12px" $lgSize="14px">
                      {moment(nextAvailableTime)
                        .locale(langMap[userLang] || langMap.en)
                        .fromNow()}
                    </Text>
                  </Grid>
                  <Text $xxsSize="12px" $lgSize="16px" $lettercase="capitalize">
                    {moment(nextAvailableTime)
                      .locale(langMap[userLang] || langMap.en)
                      .format('MMM DD. hh:mm')}
                  </Text>
                </Grid>
              </Tag>
            </Grid>
          </BorderedItem>
        ))}
    </SelectorWrapper>
  )
}
export default PackageSelector
