import { useDispatch, useSelector } from 'react-redux'
import React, { ReactElement, useState } from 'react'
import { Button, Modal } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { ModalWrapper, ModalCloseBtn, ButtonWrapper, ModalResultContent } from '../../../components/layout/modal'
import { API_URI, WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY } from '../../../config'
import { clearNewReservationResultModal } from '../../../actions/uiStatesAction'
import { getReservations } from '../../../actions/getReservationsActions'
import { IStore } from '../../../interfaces/store.interfaces'
import { TextBlock } from '../../../components/layout/text'
import { headers } from '../../../helpers/headers.helper'
import { Text, Icon } from '../../../components/layout'
import { getTodaysReservations } from '../../../actions/getTodaysReservationsActions'

const NewReservationResultModal = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const isOpen = useSelector((state: IStore) => state.uiStates.newReservationResultModal.open)
  const modalType = useSelector((state: IStore) => state.uiStates.newReservationResultModal.type)
  const washId = useSelector((state: IStore) => state.uiStates.newReservationResultModal.id)
  const dayOffset = useSelector((state: IStore) => state.uiStates.dayOffset)

  const postUrl = `${API_URI}/washers/${washerId}/reservations/${washId}/status`
  const daysToGet = calView === 'week' ? 7 : 1
  const onModifyState = async (action: string) => {
    setLoading(true)
    const response = await fetch(`${postUrl}/${action}`, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
    })
    const result = await response.json()
    if (result) {
      setLoading(false)
      setSuccess(true)
    }
  }

  const onStartWash = async () => {
    await onModifyState('start')
    dispatch(clearNewReservationResultModal())
    if (calView === 'day') {
      dispatch(getReservations(washerId, dayOffset, daysToGet))
      dispatch(getTodaysReservations(washerId))
    }
    if (calView === 'week') {
      const weekStartOffsetFromToday = moment().add(dayOffset, 'day').weekday()
      const weekStartOffsetFromDate = weekStartOffsetFromToday * -1 + dayOffset
      dispatch(getReservations(washerId, weekStartOffsetFromDate, daysToGet))
      dispatch(getTodaysReservations(washerId))
    }
  }

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper $small>
          <ModalResultContent $justifyItems="center">
            <ModalCloseBtn onClick={() => dispatch(clearNewReservationResultModal())}>×</ModalCloseBtn>
            <Icon $success={modalType === 'SUCCESS'} $cancel={modalType === 'ERROR'} $width="100px" $height="100px" />
            <Text $xxsSize="20px" $lgSize="30px" $weight={500}>
              {modalType === 'SUCCESS' ? t('RESERVATION_SUCCESS_TITLE') : t('RESERVATION_FAIL_TITLE')}
            </Text>
            <TextBlock
              $align="center"
              $xxsSize="12px"
              $lgSize="20px"
              dangerouslySetInnerHTML={{
                __html:
                  modalType === 'SUCCESS' ? t('RESERVATION_SUCCESS_DESCRIPTION') : t('RESERVATION_FAIL_DESCRIPTION'),
              }}
            />
            <ButtonWrapper>
              <Button
                onClick={() => dispatch(clearNewReservationResultModal())}
                variant="contained"
                disableElevation
                color="primary"
              >
                {t('RESERVATION_BTN_OK')}
              </Button>
              {modalType === 'SUCCESS' && (
                <Button
                  startIcon={
                    <Icon $play={!success} $success={success} $glasshour={loading} $width="15px" $height="15px" />
                  }
                  onClick={() => onStartWash()}
                  variant="outlined"
                  disabled={success || loading}
                  disableElevation
                  color="primary"
                >
                  {success ? t('WASH_STARTED') : t('RESERVATION_BTN_START_WASH')}
                </Button>
              )}
            </ButtonWrapper>
          </ModalResultContent>
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default NewReservationResultModal
