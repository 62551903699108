import { Avatar, Menu } from '@material-ui/core'
import styled from 'styled-components'
import { Grid, media } from '../../components/layout'
import { colors } from '../../theme.styles'

export const CancelledReservationsButton = styled('button')<{ $open?: boolean }>`
  border: 1px solid ${colors.veryLightGrey};
  transition: transform 0.3s ease-in-out;
  grid-template-columns: 1fr auto auto;
  background-color: white;
  align-items: center;
  border-radius: 6px;
  margin: 8px 0px;
  position: relative;
  appearance: none;
  min-width: 140px;
  min-height: 40px;
  padding: 10px;
  outline: none;
  display: grid;
  gap: 12px;
  ${({ $open }) =>
    $open &&
    `
    border: 1px solid ${colors.lime};
  i {
    transform: rotate(180deg);
  }
  `}
`

export const CancelledMenu = styled(Menu)`
  & .MuiPopover-paper {
    width: 98%;
    transform: translate3d(-10px, 50px, 100px) !important;
    ${media.lg} {
      width: auto;
      transform: translate3d(-30px, 95px, 100px) !important;
    }
    border-radius: 15px;
    & i {
      width: 20px;
    }
    & .MuiList-padding {
      padding: 0;
    }
    &:before {
      filter: drop-shadow(0px -1px 1px rgba(94, 108, 128, 0.3));
      transform: translate3d(-10px, -20px, 0);
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid white;
      position: absolute;
      display: block;
      z-index: 999;
      height: 0px;
      content: '';
      width: 0px;
      left: 50%;
      top: 0px;
    }
  }
`

export const ReservationItem = styled(Grid)`
  border-top: 1px solid ${colors.lightGrey};
  padding: 10px 10px;
  ${media.lg} {
    padding: 14px 40px;
  }
`

export const WashSize = styled(Avatar)`
  background-color: ${colors.lime};
  color: ${colors.white};
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  width: 60px;
`

export const ListHeader = styled(Grid)`
  padding: 15px 10px;
  ${media.lg} {
    padding: 40px;
  }
`

export const CancelWrapper = styled(Grid)`
  ${media.lg} {
    justify-content: end;
    justify-items: end;
  }
`
