/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { getSelectorReservations } from '../../actions/getSelectorReservationsActions'
import { SliderInput } from '../../components/layout/form/form.styles'
import { Loading } from '../../pages/appointments/appointments.styles'
import { CalendarWrapper } from './selectAppointmentCalendar.styles'
import { setStartTime } from '../../actions/newReservationActions'
import { CompactEvent, CompactToolbar, SlotSelector } from '..'
import { IDragEvent } from '../../interfaces/event.interfaces'
import { IStore } from '../../interfaces/store.interfaces'
import { WASHERID_COOKIE_KEY } from '../../config'

const SelectAppointmentCalendar = (): ReactElement => {
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const selectedProfileId = useSelector((state: IStore) => state.newOrderDetails.selectedProfileId)
  const selectedSlot = useSelector((state: IStore) => state.newOrderDetails.selectedSlot)
  const packageId = useSelector((state: IStore) => state.newOrderDetails.packageId)
  const startTime = useSelector((state: IStore) => state.newOrderDetails.startTime)
  const packages = useSelector((state: IStore) => state.packages)
  const slots = useSelector((state: IStore) => state.slots)
  const reservations = useSelector((state: IStore) => state.selectorReservations.reservationlist)
  const reservationsLoading = useSelector((state: IStore) => state.selectorReservations.loading)
  const washers = useSelector((state: IStore) => state.user?.washers)
  const selectedWasherData = washers?.find(({ id }) => id === washerId)

  const selectedVehicle = useSelector((state: IStore) => state.newOrderDetails.vehicleId)
  const profileList = useSelector((state: IStore) => state.profileList)
  const selectedCustomer = profileList.find(
    ({ id: cID, vehicle }) => cID === selectedProfileId && vehicle?.id === selectedVehicle,
  )

  const dispatch = useDispatch()
  const selectedPackage = packages.find(({ id }) => id === packageId)

  const DnDCalendar = withDragAndDrop<any, any>(Calendar)
  const localizer = momentLocalizer(moment)
  const suggestedEvent = {
    start: moment(selectedPackage?.nextAvailableTime).toDate(),
    end: moment(selectedPackage?.nextAvailableTime).add(selectedPackage?.duration, 'm').toDate(),
    resourceId: selectedPackage?.slot.toString(),
    slot: selectedPackage?.slot.toString(),
    vehicle: { licensePlate: selectedCustomer?.vehicle?.licensePlate },
    active: true,
    ...selectedPackage,
  }
  const [calendarEvent, setCalendarEvent] = useState<any>(suggestedEvent)

  const calendarEvents = reservations?.map(({ startTime: start, duration, slot, ...rest }) => ({
    start: moment(start).toDate(),
    end: moment(start).add(duration, 'm').toDate(),
    resourceId: slot.toString(),
    duration,
    slot: slot.toString(),
    ...rest,
  }))
  useEffect(() => {
    dispatch(setStartTime(suggestedEvent.start))
  }, [])

  useEffect(() => {
    setCalendarEvent({
      ...calendarEvent,
      resourceId: selectedSlot,
      slot: selectedSlot,
      start: startTime,
      end: moment(startTime).add(calendarEvent.duration, 'm').toDate(),
    })
  }, [selectedSlot, startTime])

  const allEvents = [calendarEvent, ...calendarEvents]
  const slotsInUse = slots.slice(0, selectedWasherData?.calendar?.slotNr)
  const min = new Date(2021, 1, 8, 0, 0, 0)
  const handleDragEvent = (e: IDragEvent) => {
    const { start } = e
    dispatch(setStartTime(moment(start).toDate()))
  }

  const startDay = moment(startTime).format('YYYY.MM.DD')
  const todayDay = moment().format('YYYY.MM.DD')

  const dayOffset = moment(new Date(startDay)).diff(new Date(todayDay), 'days')

  useEffect(() => {
    if (washerId) {
      dispatch(getSelectorReservations(washerId, dayOffset))
    }
  }, [washerId, dayOffset])
  const sliderValue = moment.duration(moment(startTime).format('HH:mm')).asMinutes() / 15

  return (
    <CalendarWrapper slots={selectedWasherData?.calendar?.slotNr}>
      {reservationsLoading && (
        <Loading>
          <CircularProgress color="primary" />
        </Loading>
      )}
      <DnDCalendar
        className={`active${selectedSlot}`}
        // style={{ height: 500 }}
        defaultDate={(startTime as Date) || moment(selectedPackage?.nextAvailableTime).toDate()}
        draggableAccessor={(event) => event.active}
        resourceTitleAccessor="resourceTitle"
        max={new Date(2021, 1, 8, 23, 59, 59)}
        min={min}
        onEventDrop={handleDragEvent}
        resourceIdAccessor="slot"
        dayLayoutAlgorithm="no-overlap"
        longPressThreshold={0}
        localizer={localizer}
        resources={slotsInUse}
        events={allEvents}
        defaultView="day"
        views={['day']}
        timeslots={4}
        selectable
        step={15}
        components={{
          resourceHeader: (resourceHeader) => SlotSelector(resourceHeader),
          toolbar: (props) => CompactToolbar(props),
          event: (event) => CompactEvent(event),
        }}
      />
      <SliderInput
        type="range"
        defaultValue={sliderValue}
        step={1}
        min={0}
        max={95}
        onChange={(e: any) =>
          dispatch(
            setStartTime(
              moment(startDay, 'YYYY.MM.DD')
                .add(e.target.value * 15, 'minutes')
                .toDate(),
            ),
          )
        }
      />
    </CalendarWrapper>
  )
}

export default SelectAppointmentCalendar
