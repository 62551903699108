import React, { ReactElement } from 'react'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { useDispatch, useSelector } from 'react-redux'
import { ToolbarProps } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { getCancelledReservations } from '../../actions/getCancelledReservationsActions'
import { setDayOffset, toggleCalendarPagerDragOver } from '../../actions/uiStatesAction'
import { ButtonGrid, ButtonGroup, ToolbarWrapper, ViewSwitcher } from './toolbar.styles'
import { WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY } from '../../config'
import { NavButton } from '../../pages/reservationdetails/reservationdetails.styles'
import { ToolbarDatePicker } from '../../components/layout/form/form.styles'
import { PagerDiv } from '../../pages/appointments/appointments.styles'
import { getReservations } from '../../actions/getReservationsActions'
import { IStore } from '../../interfaces/store.interfaces'
import { Text } from '../../components/layout'

const Toolbar = (props: ToolbarProps, small?: boolean): ReactElement => {
  const [cookies, setCookie] = useCookies([WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const { t } = useTranslation()
  const { onNavigate, view, onView } = props
  const eventCalendarEditing = useSelector((state: IStore) => state.uiStates.eventCalendarEditing)
  const dispatch = useDispatch()
  const dayOffset = useSelector((state: IStore) => state.uiStates.dayOffset)
  const calendarPagerDragOver = useSelector((state: IStore) => state.uiStates?.calendarPagerDragOver?.active)

  const onPrevious = () => {
    if (washerId) {
      if (view === 'day') {
        dispatch(getReservations(washerId, dayOffset - 1))
        dispatch(getCancelledReservations(washerId, dayOffset - 1))
        dispatch(setDayOffset(dayOffset - 1))
        onNavigate('PREV')
      }
      if (view === 'week') {
        const weekStartOffsetFromToday = moment().add(dayOffset, 'day').weekday()
        const weekStartOffsetFromDate = weekStartOffsetFromToday * -1 + dayOffset
        dispatch(getReservations(washerId, weekStartOffsetFromDate - 7, 7))
        dispatch(getCancelledReservations(washerId, weekStartOffsetFromDate - 7, 7))
        dispatch(setDayOffset(dayOffset - 7))
        onNavigate('PREV')
      }
    }
  }

  const onNext = () => {
    if (washerId) {
      if (view === 'day') {
        dispatch(getReservations(washerId, dayOffset + 1))
        dispatch(getCancelledReservations(washerId, dayOffset + 1))
        dispatch(setDayOffset(dayOffset + 1))
        onNavigate('NEXT')
      }
      if (view === 'week') {
        const weekStartOffsetFromToday = moment().add(dayOffset, 'day').weekday()
        const weekStartOffsetFromDate = weekStartOffsetFromToday * -1 + dayOffset
        dispatch(getReservations(washerId, weekStartOffsetFromDate + 7, 7))
        dispatch(getCancelledReservations(washerId, weekStartOffsetFromDate + 7, 7))
        dispatch(setDayOffset(dayOffset + 7))
        onNavigate('NEXT')
      }
    }
  }

  const onToday = () => {
    if (washerId) {
      if (view === 'day') {
        dispatch(getReservations(washerId, 0, 1))
        dispatch(getCancelledReservations(washerId, 0, 1))
      }
      if (view === 'week') {
        const weekStartOffsetFromToday = moment().weekday()
        const weekStartOffsetFromDate = weekStartOffsetFromToday * -1
        dispatch(getReservations(washerId, weekStartOffsetFromDate, 7))
        dispatch(getCancelledReservations(washerId, weekStartOffsetFromDate, 7))
      }
      dispatch(setDayOffset(0))
      onNavigate('TODAY')
    }
  }

  const onWeekView = () => {
    if (washerId) {
      onView('week')
      setCookie(PREFERRED_CALENDARVIEW_COOKIE_KEY, 'week', { maxAge: 31536000, path: '/' })
      const weekStartOffsetFromToday = moment().add(dayOffset, 'day').weekday()
      const weekStartOffsetFromDate = weekStartOffsetFromToday * -1 + dayOffset
      dispatch(getReservations(washerId, weekStartOffsetFromDate, 7))
      dispatch(getCancelledReservations(washerId, weekStartOffsetFromDate, 7))
    }
  }

  const onDateInput = (dateInput: string) => {
    if (washerId) {
      const dateFromInput = moment(dateInput)
      if (view === 'day') {
        dispatch(getReservations(washerId, moment(dateFromInput).diff(moment(), 'days')))
        dispatch(getCancelledReservations(washerId, moment(dateFromInput).diff(moment(), 'days')))
        dispatch(setDayOffset(moment(dateFromInput).diff(moment(), 'days')))
        onNavigate('DATE', moment(dateFromInput).toDate())
      }
      if (view === 'week') {
        const weekStartOffsetFromToday = moment(dateFromInput).weekday()
        const dateInputOffset = moment(dateFromInput).diff(moment(), 'days')
        const weekStartOffsetFromDate = weekStartOffsetFromToday * -1 + dateInputOffset
        dispatch(getReservations(washerId, weekStartOffsetFromDate, 7))
        dispatch(getCancelledReservations(washerId, weekStartOffsetFromDate, 7))
        dispatch(setDayOffset(dateInputOffset))
        onNavigate('DATE', moment(dateFromInput).toDate())
      }
    }
  }

  const onDayView = () => {
    if (washerId) {
      onView('day')
      setCookie(PREFERRED_CALENDARVIEW_COOKIE_KEY, 'day', { maxAge: 31536000, path: '/' })
      dispatch(getReservations(washerId, dayOffset, 1))
      dispatch(getCancelledReservations(washerId, dayOffset, 1))
    }
  }

  return (
    <ToolbarWrapper $small={small} $xxsCol="auto auto auto" $alignItems="center" $justifyContent="start">
      <ButtonGrid $xxsCol="auto auto">
        <ButtonGroup>
          <NavButton $fontSize="30px" onClick={onPrevious} variant="contained" disableElevation>
            ‹
          </NavButton>
          <NavButton $fontSize="30px" onClick={onNext} variant="contained" disableElevation>
            ›
          </NavButton>
        </ButtonGroup>
        <NavButton onClick={onToday} variant="contained" disableElevation>
          {t('TODAY')}
        </NavButton>
      </ButtonGrid>
      <ToolbarDatePicker
        onChange={(inputDate: MaterialUiPickersDate) => onDateInput(moment(inputDate).format('YYYY-MM-DDTHH:mm:ssZ'))}
        format={view === 'day' ? 'MMMM DD. dddd' : 'MMMM, YYYY'}
        KeyboardButtonProps={{ 'aria-label': 'change time' }}
        value={moment().add(dayOffset, 'days')}
        inputVariant="standard"
        id="date-picker"
        $small={!!small}
        margin="none"
        $view={view}
        hiddenLabel
        key="end"
      />
      <ViewSwitcher color="primary">
        <NavButton
          variant={view === 'day' ? 'contained' : 'outlined'}
          onClick={() => onDayView()}
          $active={view === 'day'}
          disableElevation
        >
          {t('DAYVIEW')}
        </NavButton>
        <NavButton
          variant={view === 'week' ? 'contained' : 'outlined'}
          onClick={() => onWeekView()}
          $active={view === 'week'}
          disableElevation
        >
          {t('WEEKVIEW')}
        </NavButton>
      </ViewSwitcher>
      <PagerDiv
        $visible={eventCalendarEditing.type === 'DRAG'}
        $direction="prev"
        onDragEnter={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setTimeout(() => {
            dispatch(toggleCalendarPagerDragOver(true))
            if (calendarPagerDragOver) {
              onPrevious()
              dispatch(toggleCalendarPagerDragOver(false))
            }
          }, 300)
        }}
      >
        <Text
          style={{ transform: 'rotate(-90deg)', position: 'absolute' }}
          $textColor="white"
          $weight={500}
          $size="18px"
          noWrap
        >
          {t('JUMP_TO_PREV_WEEK')}
        </Text>
      </PagerDiv>
      <PagerDiv
        $visible={eventCalendarEditing.type === 'DRAG'}
        $direction="next"
        onDragEnter={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setTimeout(() => {
            dispatch(toggleCalendarPagerDragOver(true))
            if (calendarPagerDragOver) {
              onNext()
              dispatch(toggleCalendarPagerDragOver(false))
            }
          }, 300)
        }}
      >
        <Text
          style={{ transform: 'rotate(-90deg)', position: 'absolute' }}
          $textColor="white"
          $weight={500}
          $size="18px"
          noWrap
        >
          {t('JUMP_TO_NEXT_WEEK')}
        </Text>
      </PagerDiv>
    </ToolbarWrapper>
  )
}

export default Toolbar
