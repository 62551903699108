import React, { ReactElement, useEffect, useState } from 'react'
import { Modal, Box, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { ModalWrapper, ModalHeader, ModalCloseBtn, ModalBody } from '../../../components/layout/modal'
import { API_URI, WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY } from '../../../config'
import { getReservationById, getReservations } from '../../../actions/getReservationsActions'
import { finishWashSteps } from '../../../modules/modalBreadcrumbNavigaion/steps.data'
import { clearFinishWithExistingCustomerModal } from '../../../actions/uiStatesAction'
import { getTodaysReservations } from '../../../actions/getTodaysReservationsActions'
import { TextInput } from '../../../components/layout/form/form.styles'
import { IStore } from '../../../interfaces/store.interfaces'
import { ModalBreadcrumbNavigation } from '../../../modules'
import { headers } from '../../../helpers/headers.helper'
import { Text, Grid } from '../../../components/layout'
import { colors } from '../../../theme.styles'

const FinishWithExistingCustomerModal = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const onDetailsPage = useSelector((state: IStore) => state.uiStates.finishWithExistingCustomerModal.onDetailsPage)
  const reservationId = useSelector((state: IStore) => state.uiStates.finishWithExistingCustomerModal.id)
  const isOpen = useSelector((state: IStore) => state.uiStates.finishWithExistingCustomerModal.open)
  const dayOffset = useSelector((state: IStore) => state.uiStates.dayOffset)
  const reservations = useSelector((state: IStore) => state.reservations?.reservationlist)
  const reservation = !!reservationId && reservations.find(({ id }) => id === reservationId)
  const daysToGet = calView === 'week' ? 7 : 1

  const postUrl = `${API_URI}/washers/${washerId}/reservations/${reservationId}/status`
  const paymentOptions = ['CASH', 'CARD', 'MONTHLY']
  const incomingPriceAdjustment =
    (reservation && reservation.priceAdjustment && reservation.priceAdjustment?.toString()) ?? ''
  const incomingPriceAdjustmentInfo = (reservation && reservation.priceAdjustmentInfo) ?? ''

  const [priceAdjustmentInfo, setPriceAdjustmentInfo] = useState('')
  const [priceAdjustment, setPriceAdjustment] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState<number>(1)

  useEffect(() => {
    if (incomingPriceAdjustment) {
      setPriceAdjustment(incomingPriceAdjustment)
    }
  }, [incomingPriceAdjustment])

  useEffect(() => {
    if (incomingPriceAdjustmentInfo) {
      setPriceAdjustmentInfo(incomingPriceAdjustmentInfo)
    }
  }, [incomingPriceAdjustmentInfo])

  const onModifyState = async (action: string) => {
    setLoading(true)
    const response = await fetch(`${postUrl}/${action}`, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify({ priceAdjustment, priceAdjustmentInfo, paymentMethod }),
    })

    const result = await response.json()
    if (result.error) {
      setLoading(false)
    }
    if (result.status === 'FINISHED' && onDetailsPage && washerId) {
      setLoading(false)
      setPriceAdjustmentInfo('')
      setPriceAdjustment('')
      setPaymentMethod('')
      dispatch(clearFinishWithExistingCustomerModal())
      dispatch(getReservationById(washerId, reservationId?.toString()))
    }
    if (result.status === 'FINISHED' && !onDetailsPage && washerId) {
      setLoading(false)
      setPriceAdjustmentInfo('')
      setPriceAdjustment('')
      setPaymentMethod('')
      dispatch(clearFinishWithExistingCustomerModal())
      dispatch(getTodaysReservations(washerId))
      if (calView === 'day') {
        dispatch(getReservations(washerId, dayOffset, daysToGet))
      }
      if (calView === 'week') {
        const weekStartOffsetFromToday = moment().add(dayOffset, 'day').weekday()
        const weekStartOffsetFromDate = weekStartOffsetFromToday * -1 + dayOffset
        dispatch(getReservations(washerId, weekStartOffsetFromDate, daysToGet))
      }
    }
  }

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper>
          <ModalHeader>
            <Text $mdSize="30px" $xxsSize="20px" $weight={500}>
              {t('FINISH_WITH_PRICE_ADJUSTMENT')}
            </Text>
            <ModalCloseBtn
              onClick={() => {
                dispatch(clearFinishWithExistingCustomerModal())
                setPaymentMethod('')
              }}
            >
              ×
            </ModalCloseBtn>
          </ModalHeader>
          <ModalBody>
            <Grid $padding="40px">
              {!!reservation && (
                <Grid $xxsCol="1fr auto">
                  <Box>
                    <Typography variant="h5">{t('PRICE')}</Typography>
                    <Text $size="20px" $weight={500}>
                      {`${
                        typeof reservation.discountedPrice === 'number'
                          ? reservation.discountedPrice || 0
                          : reservation.price
                      } ${t(reservation.currency || 'HUF')}`}
                    </Text>
                    <Grid $mdCol={2}>
                      <Text $size="14px" $weight={500} $strikeThrough={typeof reservation.discountedPrice === 'number'}>
                        {typeof reservation.discountedPrice === 'number'
                          ? `${reservation.price} ${t(reservation.currency || 'HUF')}`
                          : ''}
                      </Text>
                      <Text $size="14px" $weight={600} $textColor={colors.lime}>
                        {reservation.priceAdjustment &&
                          `= ${
                            (typeof reservation.discountedPrice === 'number'
                              ? reservation.discountedPrice || 0
                              : reservation?.price || 0) + (reservation.priceAdjustment || 0)
                          } ${t(reservation.currency || 'HUF')}`}
                      </Text>
                    </Grid>
                    {!!reservation.promotionalCode && (
                      <Text $weight={500} $xxsSize="14px" $lgSize="16px" $textColor={colors.lime}>
                        {`-${reservation.promotionalCode.amount} ${
                          reservation.promotionalCode.unit === 'PERCENT' ? '%' : reservation.promotionalCode.currency
                        } ${t('PROMOTION_CODE')}`}
                      </Text>
                    )}
                  </Box>
                  {!!priceAdjustment && (
                    <Box>
                      <Text $xxsSize="14px" $lgSize="16px" $weight={600}>
                        {t('PRICE_AFTER_ADJUSTMENT')}
                      </Text>
                      <Text $xxsSize="20px" $lgSize="24px" $weight={600}>
                        {`${
                          (typeof reservation.discountedPrice === 'number'
                            ? reservation.discountedPrice || 0
                            : reservation.price || 0) + parseFloat(priceAdjustment)
                        } ${t(reservation.currency || 'HUF')}`}
                      </Text>
                    </Box>
                  )}
                </Grid>
              )}
              <Text $weight={600} $size="20px">
                {t('PRICE_ADJUSTMENT_TITLE')}
              </Text>
              <Text $xxsSize="12px" $lgSize="16px">
                {t('PRICE_ADJUSTMENT_DESCRIPTION')}
              </Text>
              <Grid $xsCol={2} $gap="20px">
                <TextInput
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPriceAdjustment(e.target.value)}
                  error={!!priceAdjustment && priceAdjustment.length < 2}
                  label={t('PRICE_ADJUSTMENT')}
                  defaultValue={incomingPriceAdjustment}
                  name="priceAdjustment"
                  id="priceAdjustment"
                  variant="outlined"
                  type="text"
                  $search
                />
                <TextInput
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPriceAdjustmentInfo(e.target.value)}
                  error={!!priceAdjustmentInfo && priceAdjustmentInfo.length < 2}
                  defaultValue={incomingPriceAdjustmentInfo}
                  label={t('PRICE_ADJUSTMENT_INFO')}
                  name="priceAdjustmentInfo"
                  id="priceAdjustmentInfo"
                  variant="outlined"
                  type="text"
                  $search
                />
              </Grid>
              <Grid padding="30px 0px">
                <Text $weight={600} $size="20px">
                  {`${t('PAYMENT_METHOD_TITLE')} *`}
                </Text>
                <Text $xxsSize="12px" $lgSize="16px">
                  {t('PAYMENT_METHOD_DESCRIPTION')}
                </Text>
                <Grid padding="10px 0px">
                  <RadioGroup value={paymentMethod} style={{ display: 'grid' }}>
                    <Grid
                      $mdCol="auto auto auto"
                      $justifyContent="start"
                      $padding="0px"
                      $margin="0px"
                      $xxsCol={1}
                      $gap="20px"
                    >
                      {paymentOptions.map((type) => (
                        <FormControlLabel
                          onChange={() => setPaymentMethod(type)}
                          control={<Radio color="primary" />}
                          label={t(type)}
                          value={type}
                          key={type}
                        />
                      ))}
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
          </ModalBody>
          <ModalBreadcrumbNavigation
            onHandleFinish={() => onModifyState('finish')}
            nextDisabled={!paymentMethod}
            steps={finishWashSteps}
            onHandleSteps={setStep}
            loading={loading}
            step={step}
          />
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default FinishWithExistingCustomerModal
