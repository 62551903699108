/* eslint-disable prettier/prettier */
import { Box } from '@material-ui/core'
import styled from 'styled-components/macro'
import { media } from '../../components/layout'
import { colors } from '../../theme.styles'

const tolerance = 2
export const CalendarWrapper = styled(Box)<{slots?: number}>`
  box-sizing: border-box;
  min-width: 350px;
  & .rbc-time-header-content {
    border: none;
    background-color: ${colors.white};
    display: flex;
  }
  & .rbc-calendar {
    margin-top: 0px;
    ${({ slots })=> slots && slots < tolerance &&`
      margin-top: 0px;
    `}
    ${media.md} {
      margin-top: 0px;
    }
  }
  & .rbc-row-resource {
    top-right-border-radius: 6px;
    border-bottom: none;
    width: 100%;
  }
  & .rbc-allday-cell {
    display: none;
    border: none;
    height: 0px;
  }
  & .rbc-time-column:not(.rbc-time-gutter) {
    border: 1px solid ${colors.veryLightGrey};
    border-top: none;
    display: none;
  }
  & .rbc-slot-selection {
    display: none;
  }
  & .rbc-time-slot {
    height: 80px;
  }
  & .rbc-time-header-gutter {
    display: none;
  }
  & .rbc-time-view-resources {
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
  }
  & .rbc-time-header {
    ${({ slots })=> slots &&`
      grid-template-columns: repeat(${slots}, 52px);
      ${media.lg} {
        grid-template-columns: repeat(${slots}, 72px);
      }
    `}
    gap: 9px;
    justify-content: end;
    background-color: transparent;
    display: grid;
    border: none;
    justify-self: end;
    transform: translateY(-2px);
    margin-right: 15px!important;
    ${media.md} {
      transform: translateY(-15px);
      margin-right: 40px!important;
      transform: translateY(-75px);
    }
    ${({ slots })=> slots && slots < tolerance &&`
      display: none;
    `}
  }
  & .rbc-time-view {
    background-color: transparent;
    border: none;
  }
  & .rbc-addons-dnd-resizable {
    height: 100%;
  }
  & .rbc-time-column {
    background-color: transparent;
    position: relative;
    border: none;
    width: auto;
  }
  & .rbc-time-gutter > .rbc-timeslot-group {
    transform: translateY(-10px);
    border-bottom: none;
    color: ${colors.grey};
    z-index: 100;
  }
  & .rbc-day-slot {
    width: auto;
  }
  & .rbc-day-slot > .rbc-timeslot-group > .rbc-time-slot {
    background-color: transparent;
    position: relative;
    border: none;
    &:before {
      transform: translate3d(-50%; -50%; 0);
      transition: opacity 0.1s ease-in-out;
      position: absolute;
      color: ${colors.grey};
      left: 50%;
      top: 50%;
      opacity: 0;
    }
    &:hover:before {
      opacity: 1;
    }
  }
  & .rbc-day-slot > .rbc-timeslot-group {
    border: none;
  }
  & .rbc-timeslot-group {
    height: 80px;
  }
  & .rbc-day-slot > .rbc-timeslot-group {
    background-color: ${colors.almostWhite};
  }
  & .rbc-day-slot > .rbc-timeslot-group:nth-of-type(2n) {
    background-color: ${colors.white};
  }

  & .rbc-time-content {
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 15px;
    ${media.lg} {
      margin-top: -65px;
      margin-left: 40px;
      margin-right: 40px;
    }
    ${({ slots })=> slots && slots < tolerance &&`
    margin-top: 0px;
    ${media.lg} {
      margin-top: 30px;
    }
    `}
    grid-template-columns: auto 1fr;
    gap: 6px;
    box-sizing: border-box;
    overflow: initial;
    display: grid;
    border: none;
  }
  & .rbc-events-container {
    position: absolute;
    border: none;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  & .rbc-header {
    border-bottom: none;
    padding: 0px;
  }
  & .rbc-event-label {
    display: none;
  }
  & .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: none;
  }
  & .rbc-current-time-indicator {
    box-shadow: 0px 0px 5px rgba(216;36;138;0.2);
    background-color: ${colors.magenta};
    border: 1px solid white;
    pointer-events: none;
    position: absolute;
    border-right: none;
    border-left: none;
    right: -10px;
    height: 3px;
    left: -10px;
    z-index: 3;
  }
  & .rbc-event {
    transition: all 0.05s ease-in-out;
    background-color: transparent;
    padding: 0px !important;
    box-sizing: border-box;
    border-radius: 15px;
    position: absolute;
    overflow: visible;
    backface-visibility: hidden;
    transform: translateZ(0);
    border: none;
  }
  & .rbc-addons-dnd-dragged-event {
    color: pink !important;
    border: 3px solid magenta;
    opacity: 0;
  }
  & .rbc-addons-dnd-drag-preview .rbc-event-content button {
    box-shadow: 0 10px 20px 0 rgba(149, 193, 31, 0.2);
    transform: translateX(10px);
  }
  & .rbc-time-header-cell {
    display: none;
  }
  & .rbc-event-content {
    height: 100%;
    width: 97%;
  }
  & .active0 {
    & .rbc-time-column:not(.rbc-time-gutter):nth-child(1) {
      display: block;
    }
  }
  & .active1 {
    & .rbc-time-column:not(.rbc-time-gutter):nth-child(2) {
      display: block;
    }
  }
  & .active2 {
    & .rbc-time-column:not(.rbc-time-gutter):nth-child(3) {
      display: block;
    }
  }
  & .active3 {
    & .rbc-time-column:not(.rbc-time-gutter):nth-child(4) {
      display: block;
    }
  }
  & .active4 {
    & .rbc-time-column:not(.rbc-time-gutter):nth-child(5) {
      display: block;
    }
  }
  & .active5 {
    & .rbc-time-column:not(.rbc-time-gutter):nth-child(6) {
      display: block;
    }
  }
  & .active6 {
    & .rbc-time-column:not(.rbc-time-gutter):nth-child(7) {
      display: block;
    }
  }
  & .active7 {
    & .rbc-time-column:not(.rbc-time-gutter):nth-child(8) {
      display: block;
    }
  }
  & .active8 {
    & .rbc-time-column:not(.rbc-time-gutter):nth-child(9) {
      display: block;
    }
  }
  & .active9 {
    & .rbc-time-column:not(.rbc-time-gutter):nth-child(10) {
      display: block;
    }
  }
  & .active10 {
    & .rbc-time-column:not(.rbc-time-gutter):nth-child(11) {
      display: block;
    }
  }
  position: relative;
`
