import { washerHistory as serviceName } from '../actions'
import { IAction, IWasherHistory } from '../interfaces/store.interfaces'

const initialState = { reservationlist: [], loading: false, error: false } as IWasherHistory

export const getWasherHistoryReducer = (state = initialState, action: IAction): IWasherHistory => {
  const { type, payload } = action
  switch (type) {
    case `GET_${serviceName}_BEGIN`:
      return { ...state, error: false, loading: true }
    case `GET_${serviceName}_SUCCESS`:
      return { reservationlist: payload, error: false, loading: false }
    case `GET_${serviceName}_FAILED`:
      return { ...state, error: true, loading: false }
    case `GET_MORE_${serviceName}_BEGIN`:
      return { ...state, error: false, loading: true }
    case `GET_MORE_${serviceName}_SUCCESS`:
      return { reservationlist: [...state.reservationlist, ...payload], error: false, loading: false }
    case `GET_MORE_${serviceName}_FAILED`:
      return { ...state, error: true, loading: false }
    default:
      return state
  }
}
