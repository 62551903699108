import { Typography, Tab as MUITab } from '@material-ui/core'
import styled from 'styled-components/macro'
import { ITag, IBorderedItem, ILicensePlate } from '../../interfaces/layout.interfaces'
import { colors } from '../../theme.styles'
import { Grid } from './grid'
import { media } from './mediaqueries'

export const BorderedItem = styled(Grid)<IBorderedItem>`
  border: 1px solid ${colors.veryLightGrey};
  transition: all 0.1s ease-in-out;
  border-radius: 15px;
  padding: 10px;
  ${media.md} {
    padding: 20px;
  }
  ${({ $selected }) =>
    $selected &&
    `
    border: 2px solid ${colors.lime};
  `}
  ${({ $selectable }) =>
    $selectable &&
    `
    cursor: pointer;
  `}
`

export const Tag = styled('span')<ITag>`
  background-color: rgba(149, 193, 31, 0.1);
  color: ${colors.black};
  display: flex;
  align-items: center;
  margin-right: 10px;
  white-space: nowrap;
  margin-bottom: 10px;
  position: relative;
  padding: 5px 14px;
  font-weight: 500;
  ${({ $orange }) =>
    $orange &&
    `
  background-color: RGBA(239, 157, 63, 0.1);
  `}
  ${({ $red }) =>
    $red &&
    `
   background-color: RGBA(216, 36, 84, 0.1);
  `}
  ${({ $radius }) =>
    $radius &&
    `
   border-radius:${$radius}px;
  `}
  ${media.xxs} {
    ${({ $xxsPadding }) =>
      $xxsPadding &&
      `
     padding:${$xxsPadding};
    `}
  }
  ${media.md} {
    ${({ $padding }) =>
      $padding &&
      `
     padding:${$padding};
    `}
  }
`

export const LicensePlate = styled(Typography)<ILicensePlate>`
  background-color: rgba(0, 88, 77, 0.1);
  text-transform: uppercase;
  color: ${colors.cyan};
  border-radius: 6px;
  white-space: nowrap;
  padding: 6px 10px;
  font-weight: 500;
  font-size: 12px;
  ${({ $large }) =>
    $large &&
    `
    border-radius: 10px;
    font-weight: 600;
    padding: 8px 5px;
    font-size: 14px;
    ${media.lg} {
      padding: 13px 15px;
      font-size: 20px;
    }
  `}

  ${({ $dark }) =>
    $dark &&
    `
      color: ${colors.white};
      background-color: ${colors.cyan};
    `}

  ${({ $disabled }) =>
    $disabled &&
    `
      color: ${colors.white};
      background-color: ${colors.grey};
    `}

  ${({ $event }) =>
    $event &&
    `
    background-color: rgba(0, 88, 77, 0.2);
    color: ${colors.white};
    `}

  ${({ $weekEvent }) =>
    $weekEvent &&
    `
    background-color: transparent;
    color: ${colors.white};
    padding: 0px;
  `}

    ${({ $search }) =>
    $search &&
    `
    background-color: rgba(0, 88, 77, 0.1);
    color: ${colors.cyan};
    border-radius: 6px;
    font-weight: 600;
    width: max-content;
    font-size: 12px;
    padding: 5px;
    ${media.lg} {
      padding: 13px 15px;
      font-size: 15px;
    }
    `}
`

export const InfiniteScrollWrapper = styled.div`
  overflow-y: scroll;
  max-height: 1100px;
  height: 75vh;
`

export const SettingsGroup = styled(Grid)`
  border: 1px solid #e1e7f0;
  border-radius: 15px;
`

export const SettingsListItem = styled(Grid)`
  padding: 10px;
  ${media.lg} {
    padding: 8px;
  }
  border-bottom: 1px solid #e1e7f0;

  &:last-of-type {
    border-bottom: none;
  }
`

export const SettingsSheet = styled(Grid)`
  margin: 10px;
  ${media.lg} {
    margin: 40px;
  }
`

export const ItemWrapper = styled(Grid)`
  padding: 15px;
  ${media.lg} {
    padding: 15px 40px;
  }
`

export const DropDownMenuButton = styled('button')<{ $open?: boolean; $withAvatar?: boolean }>`
  border: 1px solid ${colors.veryLightGrey};
  transition: transform 0.3s ease-in-out;
  grid-template-columns: 1fr auto;
  background-color: white;
  align-items: center;
  border-radius: 6px;
  margin: 8px 0px;
  position: relative;
  appearance: none;
  min-width: 140px;
  min-height: 52px;
  padding: 10px;
  outline: none;
  gap: 12px;
  ${({ $open }) =>
    $open &&
    `
  border: 1px solid ${colors.lime};
  i {
    transform: rotate(180deg);
  }
  `}
  &:hover {
    border: 1px solid ${colors.lime};
  }
  display: none;
  ${media.lg} {
    display: grid;
  }
  ${({ $withAvatar }) =>
    $withAvatar &&
    `
    grid-template-columns: auto 1fr auto; 
  `}
`

export const Tab = styled(MUITab)`
  span {
    text-transform: initial;
    font-size: 12px;
    ${media.md} {
      font-size: 14px;
    }
  }
`

export const Thumbnail = styled.img`
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
`

export const ImageContainer = styled.button`
  transition: all 0.1s ease-in-out;
  background-color: transparent;
  border-radius: 15px;
  position: relative;
  max-height: 156px;
  appearance: none;
  cursor: pointer;
  outline: none;
  height: 100%;
  border: none;
  &:hover:after {
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    height: 100%;
    content: '';
    width: 100%;
    left: 0;
    top: 0;
  }
`

export const ImagePreviewContainer = styled.div`
  transition: all 0.1s ease-in-out;
  background-color: transparent;
  max-height: 95vh;
  appearance: none;
  max-width: 95vw;
  display: flex;
  height: 100%;
  width: 100%;
`
