import React, { ReactElement, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  CircularProgress,
  InputAdornment,
  TableContainer,
  IconButton,
  Container,
  TableCell,
  TableRow,
  Table,
} from '@material-ui/core'
import { getMoreWasherHistory, getWasherHistory } from '../../actions/getWasherHistoryActions'
import { InfiniteScrollWrapper } from '../../components/layout/commonUiElements'
import { Grid, Icon, LicensePlate, Text } from '../../components/layout'
import { TextInput } from '../../components/layout/form/form.styles'
import { Loading } from '../appointments/appointments.styles'
import { useDebounce } from '../../helpers/debounce.helper'
import { IStore } from '../../interfaces/store.interfaces'
import { langMap } from '../../helpers/language.helper'
import { WASHERID_COOKIE_KEY } from '../../config'
import { colors } from '../../theme.styles'
import {
  TransparentTableHead,
  RoundedTableBody,
  StyledTableCell,
  DeviderCell,
  DividerRow,
} from '../../components/layout/table/table'

const WasherDiaryPage = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const washerHistory = useSelector((state: IStore) => state.washerHistory.reservationlist)
  const loading = useSelector((state: IStore) => state.washerHistory.loading)
  const user = useSelector((state: IStore) => state.user)
  const userLang = user.user?.language || 'en'
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(2)
  const debouncedSearchValue = useDebounce(searchValue || '', 500)

  useEffect(() => {
    if (washerId && (debouncedSearchValue || debouncedSearchValue === '')) {
      // setIsSearching(true)
      setPage(2)
      dispatch(getWasherHistory(washerId, debouncedSearchValue, 1, 10))
      // setIsSearching(false)
    }
  }, [debouncedSearchValue, washerId])

  const getMoreHistory = () => {
    if (washerId) {
      dispatch(getMoreWasherHistory(washerId, debouncedSearchValue, page, 10))
      setPage(page + 1)
    }
  }

  let memoryDate = ''
  return (
    <Container maxWidth="xl">
      <Grid $xsCol="1fr 320px" $alignItems="center" $marginBottom="10px">
        <Text $size="30px" $weight={500}>
          {t('WASHING_DIARY')}
        </Text>
        <TextInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          placeholder={t('FIND_BY_CUSTOMER')}
          variant="outlined"
          id="user-search"
          $semiRounded
          $search
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon $size="19px" $search />
              </InputAdornment>
            ),
            type: 'search',
          }}
        />
      </Grid>
      <InfiniteScrollWrapper id="scrollable">
        <InfiniteScroll
          dataLength={washerHistory.length}
          scrollableTarget="scrollable"
          next={() => getMoreHistory()}
          scrollThreshold="50px"
          loader={undefined}
          hasMore
        >
          <Grid>
            {!!loading && (
              <Loading>
                <CircularProgress color="primary" />
              </Loading>
            )}
            {!!washerHistory.length && (
              <TableContainer>
                <Table size="medium">
                  <TransparentTableHead>
                    <TableRow>
                      <TableCell>{t('LICENSE_PLATE')}</TableCell>
                      <StyledTableCell $mobileHidden>{t('NAME')}</StyledTableCell>
                      <StyledTableCell $mobileHidden>{t('PHONE_NUMBER')}</StyledTableCell>
                      <StyledTableCell $mobileHidden>{t('CAR_SIZE')}</StyledTableCell>
                      <StyledTableCell $mobileVisible>{t('CONTACT')}</StyledTableCell>
                      <StyledTableCell>{t('SURFACE')}</StyledTableCell>
                      <StyledTableCell $mobileHidden>{t('START_TIME')}</StyledTableCell>
                      <StyledTableCell $mobileHidden>{t('END_TIME')}</StyledTableCell>
                      <StyledTableCell $mobileVisible>{t('WASH_TIME')}</StyledTableCell>
                      <StyledTableCell $mobileHidden>{t('NET_DURATION')}</StyledTableCell>
                      <StyledTableCell $mobileVisible>{t('NET_DURATION_SHORT')}</StyledTableCell>
                      <StyledTableCell>{t('PRICE')}</StyledTableCell>
                      <StyledTableCell $mobileHidden>{t('ACTIONS')}</StyledTableCell>
                    </TableRow>
                  </TransparentTableHead>
                  <RoundedTableBody>
                    {washerHistory.map(
                      ({
                        workingTimeInSeconds,
                        customerProfile,
                        reservationId,
                        startTime,
                        currency,
                        vehicle,
                        surface,
                        endTime,
                        price,
                        id,
                      }) => {
                        if (memoryDate !== moment(startTime).format('YYYY-MM-DD')) {
                          memoryDate = moment(startTime).format('YYYY-MM-DD')
                          return (
                            <React.Fragment key={id}>
                              <DividerRow>
                                <TableCell colSpan={10}>
                                  <DeviderCell>
                                    <Text
                                      variant="inherit"
                                      $lettercase="uppercase"
                                      $textColor="white"
                                      $size="14px"
                                      $weight={600}
                                    >
                                      {moment(memoryDate)
                                        .locale(langMap[userLang] || langMap.en)
                                        .calendar({
                                          sameDay: `[${t('TODAY')}], MMMM D.`,
                                          lastDay: `[${t('YESTERDAY')}], MMMM D.`,
                                          sameElse: 'MMMM D.',
                                          lastWeek: 'MMMM D.',
                                        })}
                                    </Text>
                                    <Text
                                      $lettercase="initial"
                                      $textColor="white"
                                      variant="inherit"
                                      $size="14px"
                                      $weight={600}
                                      $align="right"
                                    >
                                      {`${
                                        washerHistory.filter(({ startTime: sTime }) =>
                                          moment(sTime).isSame(memoryDate, 'day'),
                                        ).length
                                      } ${t('ORDER')}`}
                                    </Text>
                                  </DeviderCell>
                                </TableCell>
                              </DividerRow>
                              <TableRow key={id}>
                                <TableCell>
                                  <LicensePlate variant="inherit" $large>
                                    {vehicle.licensePlate}
                                  </LicensePlate>
                                </TableCell>
                                <StyledTableCell $mobileHidden>
                                  {`${customerProfile.firstName} ${customerProfile.lastName}`}
                                </StyledTableCell>
                                <StyledTableCell $mobileHidden>
                                  {!!customerProfile?.contacts?.length &&
                                    customerProfile.contacts.find(({ channel }) => channel === 'PHONE')?.value}
                                </StyledTableCell>
                                <StyledTableCell $mobileHidden align="center">
                                  {vehicle.size}
                                </StyledTableCell>
                                <StyledTableCell
                                  to={`reservations/${reservationId}`}
                                  style={{ textDecoration: 'none' }}
                                  component={Link}
                                  $mobileVisible
                                  align="left"
                                >
                                  <Grid $gap="0px">
                                    <Text $xxsSize="14px" $weight={600}>
                                      {`${customerProfile.firstName} ${customerProfile.lastName}`}
                                    </Text>
                                    <Grid $xxsCol="1fr auto">
                                      <Text $xxsSize="12px" $textColor={colors.grey}>
                                        {!!customerProfile?.contacts?.length &&
                                          customerProfile.contacts.find(({ channel }) => channel === 'PHONE')?.value}
                                      </Text>
                                      <Text $textColor={colors.lime} $weight={600} $xxsSize="12px">
                                        {vehicle.size}
                                      </Text>
                                    </Grid>
                                  </Grid>
                                </StyledTableCell>
                                <StyledTableCell $mobileHidden align="center">
                                  {`${t(surface)} ${t('WASHING')}`}
                                </StyledTableCell>
                                <StyledTableCell $mobileVisible align="center">
                                  <Text $xxsSize="13px">{t(surface)}</Text>
                                </StyledTableCell>
                                <StyledTableCell $mobileHidden align="center">
                                  {moment(startTime)
                                    .locale(langMap[userLang] || langMap.en)
                                    .format('MMM D. HH:mm')}
                                </StyledTableCell>
                                <StyledTableCell $mobileHidden align="center">
                                  {moment(endTime)
                                    .locale(langMap[userLang] || langMap.en)
                                    .format('MMM D. HH:mm')}
                                </StyledTableCell>
                                <StyledTableCell $mobileVisible align="center">
                                  <Grid $gap="0px" $height="auto" $row="auto 7px auto">
                                    <Text $xxsSize="12px">
                                      {moment(startTime)
                                        .locale(langMap[userLang] || langMap.en)
                                        .format('MMM D. HH:mm')}
                                    </Text>
                                    <Text $xxsSize="12px" $lineHeight="7px">
                                      -
                                    </Text>
                                    <Text $xxsSize="12px">
                                      {moment(endTime)
                                        .locale(langMap[userLang] || langMap.en)
                                        .format('MMM D. HH:mm')}
                                    </Text>
                                  </Grid>
                                </StyledTableCell>
                                <StyledTableCell $mobileVisible align="center">
                                  <Text $xxsSize="13px" style={{ whiteSpace: 'nowrap' }}>
                                    {`${Math.floor(workingTimeInSeconds / 60)} ${t('MINUTE')} ${
                                      workingTimeInSeconds % 60
                                    } ${t('SEC')}`}
                                  </Text>
                                </StyledTableCell>
                                <StyledTableCell $mobileHidden align="center">
                                  {`${Math.floor(workingTimeInSeconds / 60)} ${t('MINUTE')} ${
                                    workingTimeInSeconds % 60
                                  } ${t('SEC')}`}
                                </StyledTableCell>
                                <StyledTableCell align="center">{`${price} ${currency}`}</StyledTableCell>
                                <StyledTableCell $mobileHidden align="center">
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    to={`reservations/${reservationId}`}
                                    component={Link}
                                  >
                                    <Icon $eyeGreen $size="35px" />
                                  </IconButton>
                                </StyledTableCell>
                              </TableRow>
                            </React.Fragment>
                          )
                        }
                        return (
                          <TableRow key={id}>
                            <TableCell>
                              <LicensePlate variant="inherit" $large>
                                {vehicle.licensePlate}
                              </LicensePlate>
                            </TableCell>
                            <StyledTableCell $mobileHidden>
                              {`${customerProfile.firstName} ${customerProfile.lastName}`}
                            </StyledTableCell>
                            <StyledTableCell $mobileHidden>
                              {!!customerProfile?.contacts?.length &&
                                customerProfile.contacts.find(({ channel }) => channel === 'PHONE')?.value}
                            </StyledTableCell>
                            <StyledTableCell $mobileHidden align="center">
                              {vehicle.size}
                            </StyledTableCell>
                            <StyledTableCell
                              to={`reservations/${reservationId}`}
                              style={{ textDecoration: 'none' }}
                              component={Link}
                              $mobileVisible
                              align="left"
                            >
                              <Grid>
                                <Text $xxsSize="14px" $weight={600}>
                                  {`${customerProfile.firstName} ${customerProfile.lastName}`}
                                </Text>
                                <Grid $xxsCol="1fr auto">
                                  <Text $xxsSize="12px" $textColor={colors.grey}>
                                    {!!customerProfile?.contacts?.length &&
                                      customerProfile.contacts.find(({ channel }) => channel === 'PHONE')?.value}
                                  </Text>
                                  <Text $textColor={colors.lime} $weight={600} $xxsSize="12px">
                                    {vehicle.size}
                                  </Text>
                                </Grid>
                              </Grid>
                            </StyledTableCell>
                            <StyledTableCell $mobileHidden align="center">
                              {`${t(surface)} ${t('WASHING')}`}
                            </StyledTableCell>
                            <StyledTableCell $mobileVisible align="center">
                              <Text $xxsSize="13px">{t(surface)}</Text>
                            </StyledTableCell>
                            <StyledTableCell $mobileHidden align="center">
                              {moment(startTime)
                                .locale(langMap[userLang] || langMap.en)
                                .format('MMM D. HH:mm')}
                            </StyledTableCell>
                            <StyledTableCell $mobileHidden align="center">
                              {moment(endTime)
                                .locale(langMap[userLang] || langMap.en)
                                .format('MMM D. HH:mm')}
                            </StyledTableCell>
                            <StyledTableCell $mobileVisible align="center">
                              <Grid $gap="0px" $height="auto" $row="auto 7px auto">
                                <Text $xxsSize="12px">
                                  {moment(startTime)
                                    .locale(langMap[userLang] || langMap.en)
                                    .format('MMM D. HH:mm')}
                                </Text>
                                <Text $xxsSize="12px" $lineHeight="7px">
                                  -
                                </Text>
                                <Text $xxsSize="12px">
                                  {moment(endTime)
                                    .locale(langMap[userLang] || langMap.en)
                                    .format('MMM D. HH:mm')}
                                </Text>
                              </Grid>
                            </StyledTableCell>
                            <StyledTableCell $mobileVisible align="center">
                              <Text $xxsSize="13px" style={{ whiteSpace: 'nowrap' }}>
                                {`${Math.floor(workingTimeInSeconds / 60)} ${t('MINUTE')} ${
                                  workingTimeInSeconds % 60
                                } ${t('SEC')}`}
                              </Text>
                            </StyledTableCell>
                            <StyledTableCell $mobileHidden align="center">
                              {`${Math.floor(workingTimeInSeconds / 60)} ${t('MINUTE')} ${
                                workingTimeInSeconds % 60
                              } ${t('SEC')}`}
                            </StyledTableCell>
                            <StyledTableCell align="center">{`${price} ${currency}`}</StyledTableCell>
                            <StyledTableCell $mobileHidden align="center">
                              <IconButton
                                color="primary"
                                size="small"
                                to={`reservations/${reservationId}`}
                                component={Link}
                              >
                                <Icon $eyeGreen $size="35px" />
                              </IconButton>
                            </StyledTableCell>
                          </TableRow>
                        )
                      },
                    )}
                  </RoundedTableBody>
                </Table>
              </TableContainer>
            )}
            {!loading && !washerHistory.length && (
              <Container maxWidth="md">
                <Text $margin="100PX 0 0 0" $weight={500} $size="20px">
                  {t('NO_RESULT')}
                </Text>
              </Container>
            )}
          </Grid>
        </InfiniteScroll>
      </InfiniteScrollWrapper>
    </Container>
  )
}
export default WasherDiaryPage
