/* eslint-disable no-plusplus */
import React, { ReactElement, useEffect, useState } from 'react'
import { Container, IconButton, InputAdornment } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useCookies } from 'react-cookie'
import { AUTH_COOKIE_KEY, WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY } from '../../config'
import { PaperWrapper } from '../reservationdetails/reservationdetails.styles'
import { TextInput } from '../../components/layout/form/form.styles'
import { Grid, Icon, Text, Button } from '../../components/layout'
import { clearUser, signupUser } from '../../actions/userActions'
import { IStore } from '../../interfaces/store.interfaces'
import { LoginWrapper } from '../login/login.styles'

const Signup = (): ReactElement => {
  const [cookies, setCookie] = useCookies([AUTH_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const user = useSelector((state: IStore) => state.user)
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState('')
  const [password, setPassword] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const signupToken = query.get('token')
  const language = query.get('l')

  const encodedCredentials = btoa(`${signupToken}:${password}`)

  useEffect(() => {
    dispatch(clearUser())
    if (!calView) {
      setCookie(PREFERRED_CALENDARVIEW_COOKIE_KEY, 'day', { maxAge: 31536000, path: '/' })
    }
  }, [])

  useEffect(() => {
    if (user?.washers?.[0]?.id) {
      setCookie(WASHERID_COOKIE_KEY, user.washers[0].id, { maxAge: 31536000, path: '/' })
      for (let index = 0; index < user.washers.length; index++) {
        setCookie(`${AUTH_COOKIE_KEY}${user.washers[index].id}`, user.washers[index].accessToken, {
          maxAge: 31536000,
          path: '/',
        })
      }
    }
    if (user.loaded) {
      window.location.replace('/reservations')
    }
  }, [user])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <LoginWrapper $alignContent="center" $justifyItems="center" $gap="80px" $marginTop="-130px">
      <Icon $logo $width="280px" $height="84px" />
      <Container maxWidth="xs">
        <PaperWrapper elevation={0}>
          <Grid $padding="40px" $gap="20px">
            <>
              <Text $weight={500} $size="30px" $align="center">
                {t('SIGNUP_TITLE')}
              </Text>
              <Text $size="16px" $align="center" $margin="0 10px">
                {t('SIGNUP_DESCRIPTION')}
              </Text>
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                error={!!firstName && firstName.length < 2}
                label={t('FIRSTNAME')}
                variant="outlined"
                name="firstName"
                type="text"
                id="firstName"
                $form
              />
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                error={!!lastName && lastName.length < 2}
                label={t('LASTNAME')}
                variant="outlined"
                name="lastName"
                type="text"
                id="lastName"
                $form
              />
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                error={!!phone && phone.length < 2}
                label={t('PHONE')}
                variant="outlined"
                name="phone"
                type="text"
                id="phone"
                $form
              />
              <TextInput
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    dispatch(signupUser(encodedCredentials, firstName, lastName, phone, language))
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                error={!!password && password.length <= 6}
                type={showPassword ? 'text' : 'password'}
                label={t('PASSWORD')}
                variant="outlined"
                name="password"
                id="password"
                $semiRounded
                $search
                $form
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <Icon $eyeGreen={showPassword} $eye={!showPassword} $width="20px" $height="20px" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                onClick={() => dispatch(signupUser(encodedCredentials, firstName, lastName, phone, language))}
                disabled={!firstName || !lastName || !phone || !password || user.loading}
                variant="contained"
                color="primary"
              >
                {user.loading ? 'Loading...' : t('SIGNUP_TITLE')}
              </Button>
              {user.error && (
                <Text $textColor="red" $size="12px" $weight={600}>
                  {t('SIGNUP_ERROR')}
                </Text>
              )}
            </>
          </Grid>
        </PaperWrapper>
      </Container>
    </LoginWrapper>
  )
}

export default Signup
