/* eslint-disable eqeqeq */
import { useDispatch, useSelector } from 'react-redux'
import React, { ReactElement, useEffect } from 'react'
import { ISlotSelectorProps } from '../../interfaces/calendar.interfaces'
import { setSelectedSlot } from '../../actions/newReservationActions'
import { Title, HeaderWrapper } from './slotSelector.styles'
import { IStore } from '../../interfaces/store.interfaces'
import { Grid } from '../../components/layout'

const SlotSelector = (props: ISlotSelectorProps): ReactElement => {
  const { resource } = props
  const { slot, resourceTitle } = resource
  const selectedSlot = useSelector((state: IStore) => state.newOrderDetails.selectedSlot) as string
  const packages = useSelector((state: IStore) => state.packages)
  const packageId = useSelector((state: IStore) => state.newOrderDetails.packageId)
  const selectedPackage = packages.find(({ id }) => id === packageId)
  const suggestedSlot = selectedPackage?.slot || 0
  const dispatch = useDispatch()

  useEffect(() => {
    if (!selectedSlot) {
      dispatch(setSelectedSlot(suggestedSlot.toString()))
    }
  }, [suggestedSlot])

  return (
    <HeaderWrapper $active={selectedSlot == slot} onClick={() => dispatch(setSelectedSlot(slot))}>
      <Grid $alignItems="center" justifyItems="center">
        <Title $active={selectedSlot == slot}>{resourceTitle}</Title>
      </Grid>
    </HeaderWrapper>
  )
}
export default SlotSelector
