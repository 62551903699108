/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useState } from 'react'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { getOpenHours } from '../../../actions/getOpenHoursActions'
import { IShift } from '../../../interfaces/openHours.interfaces'
import { API_URI, WASHERID_COOKIE_KEY } from '../../../config'
import { headers } from '../../../helpers/headers.helper'
import { ShiftWrapper } from './openHours.styles'
import { Icon } from '../icons'
import { Text } from '../text'
import { Grid } from '../grid'

const Shift = ({ shift }: { shift: IShift }): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = cookies[WASHERID_COOKIE_KEY]
  const { openingHour, openingMinute, durationInMinutes, id } = shift
  const start = new Date(2020, 11, 11, openingHour || 0, openingMinute || 0, 0)
  const [loading, setLoading] = useState(false)

  const onDeleteShift = async (shiftId: number) => {
    setLoading(true)
    const postUrl = `${API_URI}/washers/${washerId}/opening-hours/${shiftId}`
    const response = await fetch(postUrl, {
      method: 'DELETE',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
    })
    if (response.status !== 200) {
      setLoading(false)
    }
    if (response.status === 200) {
      setLoading(false)
      dispatch(getOpenHours(washerId))
    }
  }

  return (
    <ShiftWrapper $xxsCol="125px auto" $justifyItems="start" $justifyContent="start" $alignItems="center">
      <Text $xxsSize="14px" $mdSize="18px">
        <span>
          {`${moment(start).format('HH:mm')} - ${moment(start)
            .add(durationInMinutes || 0, 'minutes')
            .format('HH:mm')}`}
        </span>
      </Text>
      <Button title={t('REMOVE_SHIFT_BTN')} size="small" onClick={() => onDeleteShift(id)}>
        <Grid $xxsCol="auto auto" $alignItems="center" $gap="7px">
          <Icon $trashGray $size="20px" $width="20px" $height="20px" />
          <Text $lettercase="none">{t('REMOVE_SHIFT_BTN')}</Text>
        </Grid>
      </Button>
    </ShiftWrapper>
  )
}

export default Shift
