import { IAction } from '../interfaces/store.interfaces'
import { IUiStates } from '../interfaces/uiState.interfaces'

const initialState = {
  eventCalendarEditing: { type: 'UNSET', id: undefined, duration: undefined, startTime: undefined },
  universalResultModal: { open: false, type: 'UNDEFINED', title: '', description: '' },
  newReservationResultModal: { open: false, id: undefined, type: 'success' },
  promoChangeModal: { open: false, id: undefined, type: 'CHANGE' },
  finishWithExistingCustomerModal: { open: false, id: undefined },
  completeWithNewCustomerModal: { open: false, id: undefined },
  eventMoveModalOpen: { open: false, startTime: undefined },
  cancelWashModal: { open: false, id: undefined },
  cancelNewReservationModal: { open: false },
  resetUserPasswordModal: { open: false, uID: undefined },
  calendarPagerDragOver: { active: true },
  newReservationModalOpen: false,
  newPromotionModalOpen: false,
  newProfileModalOpen: false,
  newUserModalOpen: false,
  compactDayOffset: 0,
  stampTimes: [],
  dayOffset: 0,
} as IUiStates

export const uiStatesReducer = (state = initialState, action: IAction): IUiStates => {
  const { type, payload } = action
  switch (type) {
    case 'TOGGLE_NEW_RESERVATION_MODAL_OPEN':
      return { ...state, newReservationModalOpen: !state.newReservationModalOpen }
    case 'TOGGLE_EVENT_MOVE_MODAL_OPEN':
      return { ...state, eventMoveModalOpen: { open: !state.eventMoveModalOpen.open, startTime: payload.startTime } }
    case 'TOGGLE_NEW_PROFILE_MODAL_OPEN':
      return { ...state, newProfileModalOpen: !state.newProfileModalOpen }
    case 'TOGGLE_NEW_USER_MODAL_OPEN':
      return { ...state, newUserModalOpen: !state.newUserModalOpen }
    case 'TOGGLE_CALENDAR_PAGER_DRAGOVER':
      return { ...state, calendarPagerDragOver: { active: payload.active } }
    case 'TOGGLE_EVENT_CALENDAR_EDITING':
      return {
        ...state,
        eventCalendarEditing: {
          type: payload.type,
          id: payload.id,
          duration: payload.duration,
          startTime: payload.startTime,
        },
      }
    case 'TOGGLE_PROMO_CHANGE_MODAL_OPEN':
      return { ...state, promoChangeModal: { open: !state.promoChangeModal.open, id: payload.id, type: payload.type } }
    case 'TOGGLE_NEW_RESERVATION_RESULT_MODAL_OPEN':
      return {
        ...state,
        newReservationResultModal: { open: !state.newReservationResultModal.open, id: payload.id, type: payload.type },
      }
    case 'TOGGLE_NEW_PROMOTION_MODAL_OPEN':
      return { ...state, newPromotionModalOpen: !state.newPromotionModalOpen }
    case 'TOGGLE_CANCEL_NEW_RESERVATION_MODAL_OPEN':
      return { ...state, cancelNewReservationModal: { open: !state.cancelNewReservationModal.open } }
    case 'TOGGLE_UNIVERSAL_RESULT_MODAL_OPEN':
      return {
        ...state,
        universalResultModal: {
          open: !state.universalResultModal.open,
          type: payload.type,
          title: payload.title,
          description: payload.description,
        },
      }
    case 'TOGGLE_RESET_USER_PASSWORD_MODAL_OPEN':
      return {
        ...state,
        resetUserPasswordModal: { open: !state.resetUserPasswordModal.open, uID: payload.uID },
      }
    case 'TOGGLE_COMLPETE_WITH_NEW_CUSTOMER_MODAL_OPEN':
      return {
        ...state,
        completeWithNewCustomerModal: {
          open: !state.completeWithNewCustomerModal.open,
          id: payload.id,
          onDetailsPage: payload.onDetailsPage,
        },
      }
    case 'TOGGLE_FINISH_WITH_EXISTING_CUSTOMER_MODAL_OPEN':
      return {
        ...state,
        finishWithExistingCustomerModal: {
          open: !state.finishWithExistingCustomerModal.open,
          id: payload.id,
          onDetailsPage: payload.onDetailsPage,
        },
      }
    case 'TOGGLE_CANCEL_WASH_MODAL_OPEN':
      return {
        ...state,
        cancelWashModal: {
          open: !state.cancelWashModal.open,
          id: payload.id,
          onDetailsPage: payload.onDetailsPage,
        },
      }
    case 'SET_DAY_OFFSET':
      return { ...state, dayOffset: payload }
    case 'SET_COMPACT_DAY_OFFSET':
      return { ...state, compactDayOffset: payload }
    case 'SET_ONGOING_STAMP_TIME':
      return { ...state, stampTimes: [...state.stampTimes, payload] }
    case 'DELETE_ONGOING_STAMP_TIME':
      return { ...state, stampTimes: state.stampTimes.filter((time) => time.reservationId !== payload.reservationId) }
    default:
      return state
  }
}
