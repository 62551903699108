import styled from 'styled-components'
import { Grid, media } from '../../components/layout'
import { colors } from '../../theme.styles'

export const Item = styled(Grid)`
  grid-gap: 5px;
  ${media.lg} {
    grid-gap: 1px;
  }
`

export const CustomerInfo = styled(Grid)`
  margin: 5px 20px;
  border: 1px solid ${colors.veryLightGrey};
  border-radius: 15px;
  gap: 12px;
  padding: 10px;
  ${media.lg} {
    padding: 20px;
    margin: 5px 20px;
    gap: 30px;
  }
`

export const OrderSummary = styled(Grid)`
  padding: 0px;
  ${media.lg} {
    padding: 20px;
  }
`
