import React, { ReactElement } from 'react'
import { HighlightWrapper, DefaultWrapper, Wrapper } from './styledRadioLabel.styles'

const StyledRadioLabel = ({
  description = '',
  amount = '',
}: {
  description?: string
  amount?: string
}): ReactElement => (
  <Wrapper>
    <HighlightWrapper>{amount}</HighlightWrapper>
    <DefaultWrapper>{description}</DefaultWrapper>
  </Wrapper>
)

export default StyledRadioLabel
