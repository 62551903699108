/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Container } from '@material-ui/core'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { getCancelledReservations } from '../../actions/getCancelledReservationsActions'
import { WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY } from '../../config'
import { getTodaysReservations } from '../../actions/getTodaysReservationsActions'
import { setDayOffset, toggleEventMoveModal } from '../../actions/uiStatesAction'
import { ControlPanelWrapper, Loading, Scheduler } from './appointments.styles'
import ResourceHeader from '../../modules/resourceHeader/resourceHeader'
import { getReservations } from '../../actions/getReservationsActions'
import { OngoingWashes, CancelledWashes } from '../../modules'
import { IStore } from '../../interfaces/store.interfaces'
import WeekHeader from '../../modules/weekHeader'
import Toolbar from '../../modules/toolbar'
import Event from '../../modules/event'

const AppointmentsPage = (): ReactElement => {
  const localizer = momentLocalizer(moment)
  const DnDCalendar = withDragAndDrop<any, any>(Calendar)
  const reservations = useSelector((state: IStore) => state.reservations?.reservationlist)
  const reservationsLoading = useSelector((state: IStore) => state.reservations?.loading)
  const [cookies] = useCookies([WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const slots = useSelector((state: IStore) => state.slots)

  const washers = useSelector((state: IStore) => state.user.washers)
  const selectedWasherData = washers?.find(({ id }) => id === washerId)
  const dayOffset = useSelector((state: IStore) => state.uiStates.dayOffset)
  const slotsInUse = slots.slice(0, selectedWasherData?.calendar?.slotNr)
  const todaysReservations = useSelector((state: IStore) => state.todaysReservations.reservationlist)

  const calendarEvents = reservations?.map(({ startTime, category, duration, slot, ...rest }) => ({
    start: moment(startTime).toDate(),
    end: moment(startTime).add(duration, 'm').toDate(),
    startTime: moment(startTime).toDate(),
    resourceId: slot.toString(),
    duration,
    slot: slot.toString(),
    title: category.title,
    category,
    ...rest,
  }))

  const defaultDay = moment().add(dayOffset, 'day').toDate()

  const [currentDay, setCurrentDay] = useState<Date>(new Date())

  const activeSlots = reservations
    ?.filter(
      ({ lastStatus, startTime }) => lastStatus === 'ON_GOING' && moment(currentDay).isSame(moment(startTime), 'day'),
    )
    .map(({ slot }) => slot && slot.toString())

  const activeClassnames = activeSlots?.map((id) => `active${id}`)
  const dispatch = useDispatch()

  useEffect(() => {
    const weekStartOffsetFromSelectedDay = moment().add(dayOffset, 'day').weekday()
    const weekStartOffsetFromToday = moment().weekday()
    if (washerId) {
      dispatch(getTodaysReservations(washerId))
    }
    if (washerId && dayOffset) {
      if (calView === 'day') {
        dispatch(getReservations(washerId, dayOffset))
        dispatch(getCancelledReservations(washerId, dayOffset))
      }
      if (calView === 'week') {
        dispatch(getReservations(washerId, weekStartOffsetFromSelectedDay * -1 + dayOffset, 7))
        dispatch(getCancelledReservations(washerId, weekStartOffsetFromSelectedDay * -1 + dayOffset, 7))
      }
    }
    if (washerId && !dayOffset) {
      if (calView === 'day') {
        dispatch(getReservations(washerId, 0))
        dispatch(getCancelledReservations(washerId, 0))
      }
      if (calView === 'week') {
        dispatch(getReservations(washerId, weekStartOffsetFromToday * -1, 7))
        dispatch(getCancelledReservations(washerId, weekStartOffsetFromToday * -1, 7))
      }
      dispatch(setDayOffset(0))
    }
  }, [washerId])

  const formats = {
    dayFormat: (date: any) => localizer.format(date, 'MM.DD dddd ', ''),
  }

  return (
    <Container maxWidth="xl" style={{ position: 'relative' }}>
      <ControlPanelWrapper $xsCol="auto" $ongoing={!!todaysReservations?.length}>
        <CancelledWashes />
        {!!todaysReservations?.length && <OngoingWashes />}
      </ControlPanelWrapper>
      {selectedWasherData?.calendar?.slotNr && (
        <Scheduler $weekView={calView === 'week'} $slotNr={selectedWasherData?.calendar?.slotNr}>
          {reservationsLoading && (
            <Loading>
              <CircularProgress color="primary" />
            </Loading>
          )}
          {calendarEvents && (
            <DnDCalendar
              max={calView === 'day' ? new Date(2021, 1, 8, 23, 59, 59) : new Date(2021, 1, 8, 20, 59, 59)}
              min={calView === 'day' ? new Date(2021, 1, 8, 0, 0, 0) : new Date(2021, 1, 8, 7, 0, 0)}
              onDropFromOutside={(e) => {
                dispatch(toggleEventMoveModal(moment(e.start).format('YYYY-MM-DDTHH:mm:ssZ')))
              }}
              resourceIdAccessor={calView === 'day' ? 'slot' : undefined}
              resources={calView === 'day' ? slotsInUse : undefined}
              onNavigate={(date) => setCurrentDay(date)}
              className={activeClassnames.join(' ')}
              resourceTitleAccessor="resourceTitle"
              draggableAccessor={(event) => !event}
              dayLayoutAlgorithm="no-overlap"
              defaultView={calView || 'day'}
              defaultDate={defaultDay}
              events={calendarEvents}
              views={['day', 'week']}
              localizer={localizer}
              formats={formats}
              timeslots={4}
              step={15}
              components={{
                resourceHeader: (resourceHeader) => ResourceHeader(resourceHeader, currentDay),
                header: (header) => WeekHeader(header),
                toolbar: (props) => Toolbar(props),
                event: (event) => Event(event),
              }}
            />
          )}
        </Scheduler>
      )}
    </Container>
  )
}
export default AppointmentsPage
