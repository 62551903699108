/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
import React, { ReactElement, useEffect } from 'react'
import { LinearProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { Button } from '../../components/layout/button/button'
import { Grid, Icon, Text } from '../../components/layout'
import { FullscreenWrapper } from './versionUpdate.styles'
import { SW_VERSION } from '../../version'

const VersionUpdate = (): ReactElement => {
  const lastUpdated = localStorage.getItem('lst_upd')
  const version = localStorage.getItem('cwo_ver')
  const { t } = useTranslation()
  const [cookies, setCookie, removeCookie] = useCookies()

  const progress: any = {
    main: 10,
    reservations: 20,
    settings: 30,
    general: 40,
    'open-hours': 50,
    promotions: 60,
    'price-list': 70,
    'marketing-campaigns': 80,
    washerselect: 90,
    done: 100,
  }

  const removeCookies = () => {
    const cookiesToRemove = document.cookie.split('; ')
    for (let c = 0; c < cookiesToRemove.length; c++) {
      const cookieKey = cookiesToRemove[c].split('=')[0].trim()
      const p = location.pathname
      removeCookie(cookieKey, { path: p })
    }
  }

  const onUpdate = async () => {
    if (version !== SW_VERSION) {
      if (!lastUpdated) {
        window.location.replace(window.location.origin)
        removeCookies()
        localStorage.setItem('lst_upd', 'main')
      }
    }
  }

  useEffect(() => {
    if (version !== SW_VERSION) {
      if (lastUpdated === 'main') {
        window.location.replace(`${window.location.origin}/reservations`)
        removeCookies()
        localStorage.setItem('lst_upd', 'reservations')
      }
      if (lastUpdated === 'reservations') {
        window.location.replace(`${window.location.origin}/settings`)
        removeCookies()
        localStorage.setItem('lst_upd', 'settings')
      }
      if (lastUpdated === 'settings') {
        window.location.replace(`${window.location.origin}/settings/general`)
        removeCookies()
        localStorage.setItem('lst_upd', 'general')
      }
      if (lastUpdated === 'general') {
        window.location.replace(`${window.location.origin}/settings/open-hours`)
        removeCookies()
        localStorage.setItem('lst_upd', 'open-hours')
      }
      if (lastUpdated === 'open-hours') {
        window.location.replace(`${window.location.origin}/settings/promotions`)
        removeCookies()
        localStorage.setItem('lst_upd', 'promotions')
      }
      if (lastUpdated === 'promotions') {
        window.location.replace(`${window.location.origin}/settings/price-list`)
        removeCookies()
        localStorage.setItem('lst_upd', 'price-list')
      }
      if (lastUpdated === 'price-list') {
        window.location.replace(`${window.location.origin}/settings/marketing-campaigns`)
        removeCookies()
        localStorage.setItem('lst_upd', 'marketing-campaigns')
      }
      if (lastUpdated === 'marketing-campaigns') {
        window.location.replace(`${window.location.origin}/washerselect`)
        removeCookies()
        localStorage.setItem('lst_upd', 'washerselect')
      }
      if (lastUpdated === 'washerselect') {
        window.location.replace(`${window.location.origin}/wash-diary`)
        removeCookies()
        localStorage.setItem('lst_upd', 'done')
      }
      if (lastUpdated === 'done') {
        localStorage.setItem('cwo_ver', SW_VERSION)
        window.location.replace(`${window.location.origin}/reservations`)
        localStorage.removeItem('lst_upd')
      }
    }
  }, [])

  return (
    <FullscreenWrapper>
      <Grid $justifyContent="center" $justifyItems="center" $alignContent="center" $gap="40px" $padding="15px">
        <Icon $logo $width="200px" $height="54px" />
        <Text $xxsSize="16px" $lgSize="20px" $weight={600}>
          {!lastUpdated ? t('VERSION_UPDATE_TITLE') : t('VERSION_UPDATE_PROGRESS_TITLE')}
        </Text>
        <Text $xxsSize="13px" $lgSize="16px">
          {!lastUpdated ? t('VERSION_UPDATE_DESCRIPTION') : t('VERSION_UPDATE_PROGRESS_DESCRIPTION')}
        </Text>
        {!lastUpdated && (
          <Button variant="contained" color="primary" disableElevation onClick={() => onUpdate()}>
            {t('VERSION_UPDATE_BTN')}
          </Button>
        )}
        {!!lastUpdated && (
          <LinearProgress
            style={{ width: '250px' }}
            color="primary"
            variant="determinate"
            value={Number(progress[lastUpdated])}
          />
        )}
      </Grid>
    </FullscreenWrapper>
  )
}

export default VersionUpdate
