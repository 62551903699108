import styled from 'styled-components/macro'
import checkbox_on from '../../../assets/checkbox_on.svg'
import checkbox_off from '../../../assets/checkbox_off.svg'
import { colors } from '../../../theme.styles'

export const RadioIcon = styled.span`
  background: url(${checkbox_off});
  transform: translate3d(40px, -4px, 0px);
  position: absolute;
  height: 24px;
  width: 24px;
  left: 0;
  top: 0;
`

export const RadioChckedIcon = styled.div`
  transform: translate3d(40px, -4px, 0px);
  position: absolute;
  height: 24px;
  width: 24px;
  left: 0;
  top: 0;
  background: url(${checkbox_on});
`

export const CarSizeRadioIcon = styled.span`
  background: ${colors.white};
  transform: translate3d(0, -50%, 0px);
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
`

export const CarSizeRadioChckedIcon = styled.div`
  transform: translate3d(0, -50%, 0px);
  background: ${colors.lime};
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
`
