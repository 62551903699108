import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { getReservations } from '../../actions/getReservationsActions'
import MonitorCard from '../../components/layout/monitorCard'
import { setDayOffset } from '../../actions/uiStatesAction'
import { IStore } from '../../interfaces/store.interfaces'
import { Grid, Icon, Text } from '../../components/layout'
import { IWasher } from '../../interfaces/user.interfaces'
import { WASHERID_COOKIE_KEY } from '../../config'
import { HeaderBar } from './monitor.styles'
import { colors } from '../../theme.styles'

const MonitorPage = (): ReactElement => {
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const reservations = useSelector((state: IStore) => state.reservations?.reservationlist)
  const sWasherData = useSelector((state: IStore) => state.user?.washers?.find(({ id }) => id === washerId)) as IWasher
  const slots = useSelector((state: IStore) => state.slots)
  const slotsInUse = slots.slice(0, sWasherData?.calendar?.slotNr)

  const [clock, setClock] = useState<number>()
  useEffect(() => {
    const nowTime = setInterval(() => setClock(Date.now()), 1000)
    return () => {
      clearInterval(nowTime)
    }
  }, [])

  useEffect(() => {
    if (washerId) {
      dispatch(getReservations(washerId, 0))
      dispatch(setDayOffset(0))
    }
  }, [washerId])

  return (
    <Grid>
      <HeaderBar $xxsCol="auto 1fr auto" alignItems="center" $padding="20px 30px">
        <Icon $logo $width="188px" $height="41px" />
        <Text $size="30px" $weight={600} $align="center" $textColor={colors.cyan}>
          {sWasherData?.name}
        </Text>
        <Text $size="30px" $weight={600} $align="right" $textColor={colors.black}>
          {moment(clock).format('HH:mm')}
        </Text>
      </HeaderBar>
      <Grid $mdCol={slotsInUse.length} $padding="30px" $gap="30px">
        {!!slotsInUse.length &&
          slotsInUse.map(({ slot: oSlot, resourceTitle }) => (
            <Grid key={oSlot} $gap="20px">
              {!!reservations.length &&
                reservations
                  .filter(({ slot, startTime, duration }) => {
                    const endTime = moment(startTime).add(duration, 'minutes')
                    const isAfter = moment(endTime).isAfter(Date.now())
                    return slot.toString() === oSlot && isAfter
                  })
                  .sort((a, b) => (a.startTime > b.startTime ? 1 : -1))
                  .map((reservation, i) => (
                    <React.Fragment key={reservation.id}>
                      <MonitorCard
                        resourceTitle={resourceTitle}
                        reservation={reservation}
                        small={i !== 0}
                        hidden={i > 1}
                      />
                    </React.Fragment>
                  ))}
            </Grid>
          ))}
      </Grid>
    </Grid>
  )
}
export default MonitorPage
