import React, { ReactElement, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ZendeskAPI } from 'react-zendesk'
import { SettingsSheet } from '../../components/layout/commonUiElements'
import { Button, Grid, Text } from '../../components/layout'
import ZendeskModule from '../zendesk'

const HelpSettings = (): ReactElement => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      ZendeskAPI('webWidget', 'show')
      ZendeskAPI('webWidget', 'open')
    }
    if (!isOpen) {
      ZendeskAPI('webWidget', 'close')
      ZendeskAPI('webWidget', 'hide')
    }
  }, [isOpen])

  ZendeskAPI('webWidget:on', 'close', () => setIsOpen(false))
  ZendeskAPI('webWidget:on', 'open', () => setIsOpen(true))
  return (
    <SettingsSheet>
      <Text $size="20px" $weight={600}>
        {t('HELP_SETTINGS')}
      </Text>
      <Text $xxsSize="12px" $mdSize="14px">
        {t('HELP_DESCRIPTION')}
      </Text>
      <ZendeskModule />
      <Grid $justifyContent="start" $mdCol="auto" $paddingTop="20px">
        <Button color="primary" disableElevation variant="contained" onClick={() => setIsOpen(!isOpen)}>
          {t('HELP_BTN')}
        </Button>
      </Grid>
    </SettingsSheet>
  )
}

export default HelpSettings
