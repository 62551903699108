/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreator, Dispatch } from 'redux'
import { washerHistory as serviceName } from '.'
import { API_URI } from '../config'
import { headers } from '../helpers/headers.helper'

export const getWasherHistory: ActionCreator<any> =
  (washerId: number, query?: string, page?: number, count?: number) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: `GET_${serviceName}_BEGIN`,
      })
      const res = await fetch(`${API_URI}/washers/${washerId}/history?query=${query}&page=${page}&count=${count}`, {
        headers,
      })
      const reservationlist = await res.json()
      if (res.status === 200) {
        dispatch({
          type: `GET_${serviceName}_SUCCESS`,
          payload: reservationlist,
        })
      }
      if (res.status !== 200) {
        dispatch({
          type: `GET_${serviceName}_FAILED`,
        })
      }
    } catch (error) {
      dispatch({
        type: `GET_${serviceName}_FAILED`,
      })
    }
  }

export const getMoreWasherHistory: ActionCreator<any> =
  (washerId: number, query?: string, page?: number, count?: number) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: `GET_MORE_${serviceName}_BEGIN`,
      })
      const res = await fetch(`${API_URI}/washers/${washerId}/history?query=${query}&page=${page}&count=${count}`, {
        headers,
      })
      const reservationlist = await res.json()
      if (res.status === 200) {
        dispatch({
          type: `GET_MORE_${serviceName}_SUCCESS`,
          payload: reservationlist,
        })
      }
      if (res.status !== 200) {
        dispatch({
          type: `GET_MORE_${serviceName}_FAILED`,
        })
      }
    } catch (error) {
      dispatch({
        type: `GET_MORE_${serviceName}_FAILED`,
      })
    }
  }
