/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useState } from 'react'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { weekTypes } from '../../../interfaces/openHours.interfaces'
import { getOpenHours } from '../../../actions/getOpenHoursActions'
import { API_URI, WASHERID_COOKIE_KEY } from '../../../config'
import { IStore } from '../../../interfaces/store.interfaces'
import { KeyboardTimePickerInput } from '../form/form.styles'
import { headers } from '../../../helpers/headers.helper'
import { DayContentWrapper } from './openHours.styles'
import { Grid, Text, Icon } from '../index'
import Shift from './shift.component'

interface IDayComponent {
  dayKey: string
  weekKey: weekTypes
  weekType: string
}

const Day = ({ dayKey: pDayKey, weekKey, weekType }: IDayComponent): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = cookies[WASHERID_COOKIE_KEY]
  const openingHours = useSelector((state: IStore) => state.openingHours)
  const [addNew, setAddNew] = useState(false)
  const [shiftStart, setShiftStart] = useState<string | null>()
  const [shiftEnd, setShiftEnd] = useState<string | null>()
  const [loading, setLoading] = useState(false)
  const [closed, setClosed] = useState(false)

  const openingHour = Number(moment(shiftStart).format('H'))
  const openingMinute = Number(moment(shiftStart).format('m'))
  const durationInMinutes = moment(shiftEnd).diff(shiftStart, 'minutes')

  const onCreateShift = async () => {
    setLoading(true)
    const createData = {
      dayKey: pDayKey,
      weekKey: weekType,
      closed,
      openingHour,
      openingMinute,
      durationInMinutes,
    }

    const postUrl = `${API_URI}/washers/${washerId}/opening-hours`
    const response = await fetch(postUrl, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(createData),
    })
    if (response.status !== 200) {
      setLoading(false)
    }
    if (response.status === 200) {
      setLoading(false)
      dispatch(getOpenHours(washerId))
    }
  }

  const week = openingHours[weekKey]
  const dayData = week?.find(({ dayKey }) => dayKey === pDayKey)

  return (
    <DayContentWrapper>
      <Grid $mdCol="110px 1fr">
        <Text $margin="10px 0 0 0" $xxsSize="14px" $mdSize="16px" $weight={600}>
          {t(`${pDayKey}_SHORT`)}
        </Text>
        <Grid>
          {!!dayData?.shifts?.length && dayData.shifts.map((shift) => <Shift key={shift.id} shift={shift} />)}
        </Grid>
      </Grid>
      {!!addNew && (
        <>
          <Grid
            $mdCol="90px 150px auto 150px auto 1fr"
            justifyContent="start"
            $alignItems="center"
            justifyItems="start"
            $gap="20px"
          >
            <div />
            <KeyboardTimePickerInput
              onChange={(date: MaterialUiPickersDate) => setShiftStart(moment(date).format('YYYY-MM-DDTHH:mm:ssZ'))}
              KeyboardButtonProps={{ 'aria-label': 'change time' }}
              inputVariant="outlined"
              id="start-time-picker"
              value={shiftStart}
              margin="none"
              ampm={false}
              hiddenLabel
              key="start"
              $noLabel
              $form
            />
            <Text $mdSize="20px">{' - '}</Text>
            <KeyboardTimePickerInput
              onChange={(date: MaterialUiPickersDate) => setShiftEnd(moment(date).format('YYYY-MM-DDTHH:mm:ssZ'))}
              KeyboardButtonProps={{ 'aria-label': 'change time' }}
              inputVariant="outlined"
              id="end-time-picker"
              value={shiftEnd}
              margin="none"
              ampm={false}
              hiddenLabel
              key="end"
              $noLabel
              $form
            />
            <Grid $xxsCol="auto auto">
              <IconButton title={t('SAVE_NEW_SHIFT_BTN')} color="primary" size="small" onClick={() => onCreateShift()}>
                <Icon $success $size="30px" />
              </IconButton>
              <IconButton
                title={t('CANCEL_NEW_SHIFT_BTN')}
                color="secondary"
                size="small"
                onClick={() => setAddNew(false)}
              >
                <Icon $cancel $size="30px" />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
      {!addNew && (
        <Grid $justifyContent="start" $justifyItems="start" $xxsCol="auto auto" $col="110px auto 1fr">
          <div />
          <Button className="addNew" title={t('ADD_SHIFT_BTN')} size="small" onClick={() => setAddNew(true)}>
            <Grid $xxsCol="auto auto" $alignItems="center" $gap="7px">
              <Icon $plusGray $size="20px" $width="20px" $height="20px" />
              <Text $lettercase="none">{t('ADD_SHIFT_BTN')}</Text>
            </Grid>
          </Button>
        </Grid>
      )}
    </DayContentWrapper>
  )
}

export default Day
