import { Select, TextField } from '@material-ui/core'
import styled from 'styled-components/macro'
import { KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { colors } from '../../../theme.styles'
import Close from '../../../assets/x_black.svg'
import { media } from '../mediaqueries'

export const TextInput = styled(TextField)<{
  $rounded?: boolean
  $semiRounded?: boolean
  $search?: boolean
  $form?: boolean
}>`
  margin-top: 27px;
  input {
    box-shadow: 0 0 0px 1000px white inset;
    margin-bottom: 4px;
    margin-right: 4px;
    border: 1px solid ${colors.lightGrey};
  }
  & .MuiOutlinedInput-notchedOutline {
  }
  & .MuiInputLabel-outlined {
    transform: translate(10px, -25px) scale(1);
    font-size: 14px;
    font-weight: 600;
    color: ${colors.black};
  }
  & .MuiInputLabel-shrink {
    transform: translate(10px, -25px) scale(1);
  }
  & legend {
    display: none;
  }
  & fieldset {
    z-index: -1;
    background-color: white;
    border-radius: 6px;
    ${({ $rounded }) =>
      $rounded &&
      `
    border-radius: 15px;
    `}
    ${({ $semiRounded }) =>
      $semiRounded &&
      `
      border-radius: 10px;
    `}
  }
  & input::-webkit-search-cancel-button {
    position: relative;
    right: 20px;
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 10px;
    background: url(${Close});
    z-index: 2;
  }
  & .MuiOutlinedInput-adornedStart {
    padding-left: 20px;
    z-index: 2;
    ${media.md} {
      padding-left: 30px;
    }
  }
  & .MuiOutlinedInput-inputAdornedStart {
    padding: 10px 8px;
    z-index: 2;
    ${media.md} {
      padding: 18.5px 14px;
      padding-left: 20px;
    }
  }
  ${({ $search }) =>
    $search &&
    `
   & input {
      border: none;
      margin-left: 2px;
      padding: 9px 7px;
      ${media.lg} {
        padding: 18px 14px;
      }
   }
   `}
  ${({ $form }) =>
    $form &&
    `
   & input {
      border: none;
      margin-left: 2px;
      z-index: 1;
      padding: 9px 7px;
      ${media.lg} {
        padding: 18px 14px;
      }
   }
   & fieldset {
     z-index:0;
   }
   & button {
     z-index: 2;
   }
  `}
`

export const SelectInput = styled(Select)<{
  $rounded?: boolean
  $semiRounded?: boolean
  $search?: boolean
  $form?: boolean
}>`
  margin-top: 27px;
  input {
    box-shadow: 0 0 0px 1000px white inset;
    margin-bottom: 4px;
    margin-right: 4px;
    border: 1px solid ${colors.lightGrey};
  }
  & .MuiOutlinedInput-notchedOutline {
  }
  & .MuiInputLabel-outlined {
    transform: translate(10px, -25px) scale(1);
    font-size: 14px;
    font-weight: 600;
    color: ${colors.black};
  }
  & .MuiInputLabel-shrink {
    transform: translate(10px, -25px) scale(1);
  }
  & legend {
    display: none;
  }
  & fieldset {
    z-index: -1;
    background-color: white;
    border-radius: 6px;
    ${({ $rounded }) =>
      $rounded &&
      `
    border-radius: 15px;
    `}
    ${({ $semiRounded }) =>
      $semiRounded &&
      `
      border-radius: 10px;
    `}
  }
  & input::-webkit-search-cancel-button {
    position: relative;
    right: 20px;
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 10px;
    background: url(${Close});
    z-index: 2;
  }
  & .MuiOutlinedInput-adornedStart {
    padding-left: 30px;
    z-index: 2;
  }
  & .MuiSelect-outlined {
    padding-bottom: 18.5px;
  }
  & .MuiOutlinedInput-input {
    padding: 9px 7px;
    ${media.lg} {
      padding: 18px 32px 18px 14px;
    }
  }
  & .MuiOutlinedInput-inputAdornedStart {
    padding-left: 20px;
    z-index: 2;
  }
  ${({ $search }) =>
    $search &&
    `
   & input {
      border: none;
      margin-left: 2px;
   }
   `}
  ${({ $form }) =>
    $form &&
    `
   & input {
      border: none;
      margin-left: 2px;
      z-index: 1;
   }
   & fieldset {
     z-index:0;
   }
  `}
`

export const KeyboardDateTimePickerInput = styled(KeyboardDateTimePicker)<{
  $rounded?: boolean
  $semiRounded?: boolean
  $search?: boolean
  $form?: boolean
  $noLabel?: boolean
}>`
  margin-top: 27px;
  input {
    box-shadow: 0 0 0px 1000px white inset;
    margin-bottom: 4px;
    margin-right: 4px;
    border: 1px solid ${colors.lightGrey};
    padding: 9px 7px;
    ${media.lg} {
      padding: 18.5px 14px;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
  }
  & .MuiInputLabel-outlined {
    transform: translate(10px, -25px) scale(1);
    font-size: 14px;
    font-weight: 600;
    color: ${colors.black};
  }
  & .MuiInputLabel-shrink {
    transform: translate(10px, -25px) scale(1);
  }
  & legend {
    display: none;
  }
  & fieldset {
    z-index: -1;
    background-color: white;
    border-radius: 6px;
    ${({ $rounded }) =>
      $rounded &&
      `
    border-radius: 15px;
    `}
    ${({ $semiRounded }) =>
      $semiRounded &&
      `
      border-radius: 10px;
    `}
  }
  & input::-webkit-search-cancel-button {
    position: relative;
    right: 20px;
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 10px;
    background: url(${Close});
    z-index: 2;
  }
  & .MuiOutlinedInput-adornedStart {
    padding-left: 30px;
    z-index: 2;
  }
  & .MuiSelect-outlined {
    padding-bottom: 18.5px;
  }
  & .MuiOutlinedInput-inputAdornedStart {
    padding-left: 20px;
    z-index: 2;
  }
  ${({ $search }) =>
    $search &&
    `
  & input {
    border: none;
    margin-left: 2px;
  }
  `}
  ${({ $form }) =>
    $form &&
    `
  & .MuiInputAdornment-positionEnd {
    padding-left: 0px;
    z-index: 2;
  }
   & input {
      border: none;
      margin-left: 2px;
      z-index: 1;
   }
   & fieldset {
     z-index:0;
   }
  `}
  ${({ $noLabel }) =>
    $noLabel &&
    `
    margin-top: 0px;
  `}
`

export const KeyboardTimePickerInput = styled(KeyboardTimePicker)<{
  $rounded?: boolean
  $semiRounded?: boolean
  $search?: boolean
  $form?: boolean
  $noLabel?: boolean
}>`
  margin-top: 27px;
  input {
    box-shadow: 0 0 0px 1000px white inset;
    margin-bottom: 4px;
    margin-right: 4px;
    border: 1px solid ${colors.lightGrey};
    padding: 9px 7px;
    ${media.lg} {
      padding: 18.5px 14px;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
  }
  & .MuiInputLabel-outlined {
    transform: translate(10px, -25px) scale(1);
    font-size: 14px;
    font-weight: 600;
    color: ${colors.black};
  }
  & .MuiInputLabel-shrink {
    transform: translate(10px, -25px) scale(1);
  }
  & legend {
    display: none;
  }
  & fieldset {
    z-index: -1;
    background-color: white;
    border-radius: 6px;
    ${({ $rounded }) =>
      $rounded &&
      `
    border-radius: 15px;
    `}
    ${({ $semiRounded }) =>
      $semiRounded &&
      `
      border-radius: 10px;
    `}
  }
  & input::-webkit-search-cancel-button {
    position: relative;
    right: 20px;
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 10px;
    background: url(${Close});
    z-index: 2;
  }
  & .MuiOutlinedInput-adornedStart {
    padding-left: 30px;
    z-index: 2;
  }
  & .MuiSelect-outlined {
    padding-bottom: 18.5px;
  }
  & .MuiOutlinedInput-inputAdornedStart {
    padding-left: 20px;
    z-index: 2;
  }
  ${({ $search }) =>
    $search &&
    `
  & input {
    border: none;
    margin-left: 2px;
  }
  `}
  ${({ $form }) =>
    $form &&
    `
  & .MuiInputAdornment-positionEnd {
    padding-left: 0px;
    z-index: 2;
  }
   & input {
      border: none;
      margin-left: 2px;
      z-index: 1;
   }
   & fieldset {
     z-index:0;
   }
  `}
  ${({ $noLabel }) =>
    $noLabel &&
    `
  margin-top: 0px;
`}
`

export const SliderInput = styled.input`
  ${media.lg} {
    display: none;
  }
  background: transparent;
  -webkit-appearance: none;
  position: absolute;
  transform-origin: left;
  top: 72px;
  left: 0px;
  transform: rotate(90deg) translate3d(0, calc((100vw - 120px) * -1), 0);
  width: 1920px;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background: transparent;
  }
  &::-moz-range-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background: transparent;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: calc(100vw - 100px);
    width: 80px;
    border-radius: 3px;
    background: transparent;
    cursor: pointer;
    margin-top: -74px;
    margin-left: 0px;
  }
  &:focus::-webkit-slider-runnable-track {
    background: transparent;
  }
`

export const ToolbarDatePicker = styled(KeyboardDatePicker)<{ $small: boolean; $view?: string }>`
  border: none !important;
  width: auto !important;
  display: grid;
  grid-template-columns: auto;
  justify-self: start;
  position: relative;
  & .MuiInputBase-inputAdornedEnd {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    margin: 5px 0 !important;
    padding: 0px;
    ${media.md} {
      margin-left: 20px;
      margin-right: 20px;
      font-size: 30px;
      width: 400px !important;
      ${({ $view }) =>
        $view === 'week' &&
        `
        width: 300px !important; 
      `}
    }
    ${({ $small }) =>
      $small &&
      `
    width: 300px !important;
    font-size: 14px;
    ${media.md} {
      font-size: 18px;
    }
    `}
  }
  button {
    width: 100%;
    border-radius: 0;
    opacity: 0;
  }
  & .MuiInputAdornment-positionEnd {
    position: absolute;
    width: 100%;
    border-radius: 0;
  }
  & .MuiOutlinedInput-adornedEnd {
    border-radius: 0 !important;
    border: none !important;
    color: pink;
    width: auto;
  }
`

export const DashboardDatePicker = styled(KeyboardDatePicker)<{ $disabled?: boolean }>`
  border: 1px solid ${colors.lightGrey};
  ${({ $disabled }) =>
    $disabled &&
    `
    border: 1px solid transparent;
  `}
  border-radius: 6px;
  background-color: white;
  text-align: right;
  padding: 7px;
  width: 230px;
  position: relative;
  overflow: hidden;
  & .MuiInputBase-inputAdornedEnd {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    margin: 5px 0 !important;
    padding: 0px;
    ${media.md} {
      margin-left: 20px;
      margin-right: 20px;
      font-size: 21px;
      width: 400px !important;
    }
  }
  button {
    width: 100%;
    border-radius: 0;
    opacity: 0;
  }
  & .MuiInputAdornment-positionEnd {
    position: absolute;
    width: 100%;
    border-radius: 0;
  }
  & .MuiOutlinedInput-adornedEnd {
    border-radius: 0 !important;
    border: none !important;
    color: pink;
    width: auto;
  }
`
