import { marketingCampaigns as serviceName } from '../actions'
import { IMarketingCampaign } from '../interfaces/marketingCampaign.interfaces'
import { IAction } from '../interfaces/store.interfaces'

const initialState = [] as IMarketingCampaign[]

export const getMarketingCampaignsReducer = (state = initialState, action: IAction): IMarketingCampaign[] => {
  const { type, payload } = action
  switch (type) {
    case `GET_${serviceName}_BEGIN`:
      return state
    case `GET_${serviceName}_SUCCESS`:
      return payload
    case `GET_${serviceName}_FAILED`:
      return state
    default:
      return state
  }
}
