import { Avatar, Card as MUICard } from '@material-ui/core'
import styled from 'styled-components'
import { Grid } from '../../../components/layout'
import { colors } from '../../../theme.styles'

export const Card = styled(MUICard)`
  border: 1px solid #e1e7f0;
  border-radius: 15px;
`

export const CardHeader = styled(Grid)`
  border-bottom: 1px solid #e1e7f0;
`

export const PercentBg = styled.div`
  background-color: rgba(149, 193, 31, 0.1);
  color: ${colors.lime};
  justify-items: center;
  align-items: center;
  border-radius: 100%;
  font-weight: 600;
  font-size: 30px;
  display: grid;
  height: 90px;
  width: 90px;
`

export const WashSize = styled(Avatar)`
  background-color: rgba(0, 88, 77, 0.1);
  color: ${colors.cyan};
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  width: 60px;
`
