import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { Line } from '../../pages/reservationdetails/reservationdetails.styles'
import { SettingsSheet } from '../../components/layout/commonUiElements'
import { TextInput } from '../../components/layout/form/form.styles'
import { API_URI, WASHERID_COOKIE_KEY } from '../../config'
import { IStore } from '../../interfaces/store.interfaces'
import { IWasher } from '../../interfaces/user.interfaces'
import { headers } from '../../helpers/headers.helper'
import { getUserMe } from '../../actions/userActions'
import { Grid, Text } from '../../components/layout'
import { Button } from '../../components/layout/button/button'

const GeneralSettings = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector((state: IStore) => state.user?.user)
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const washers = useSelector((state: IStore) => state.user?.washers)
  const sWasherData = washers?.find(({ id }) => id === washerId) as IWasher

  const email = sWasherData?.contacts?.find(({ channel }) => channel === 'EMAIL')?.value
  const phone = sWasherData?.contacts?.find(({ channel }) => channel === 'PHONE')?.value
  const facebook = sWasherData?.contacts?.find(({ channel }) => channel === 'FACEBOOK')?.value

  const [savingStatus, setSavingStatus] = useState('INIT')
  const [scity, setCity] = useState('')
  const [sstate, setState] = useState('')
  const [sdistrict, setDistrict] = useState('')
  const [spostalCode, setPostalCode] = useState('')
  const [sstreet, setStreet] = useState('')
  const [shouse, setHouse] = useState('')
  const [sfloor, setFloor] = useState('')
  const [sdoor, setDoor] = useState('')

  useEffect(() => {
    if (sWasherData) {
      if (sWasherData.address.city) {
        setCity(sWasherData.address.city)
      }
      if (sWasherData.address.state) {
        setState(sWasherData.address.state)
      }
      if (sWasherData.address.district) {
        setDistrict(sWasherData.address.district)
      }
      if (sWasherData.address.postalCode) {
        setPostalCode(sWasherData.address.postalCode)
      }
      if (sWasherData.address.street) {
        setStreet(sWasherData.address.street)
      }
      if (sWasherData.address.house) {
        setHouse(sWasherData.address.house)
      }
      if (sWasherData.address.floor) {
        setFloor(sWasherData.address.floor)
      }
      if (sWasherData.address.door) {
        setDoor(sWasherData.address.door)
      }
    }
  }, [washerId])

  const addressData = {
    postalCode: spostalCode,
    city: scity,
    country: sWasherData?.address.country,
    district: sdistrict,
    state: sstate,
    street: sstreet,
    house: shouse,
    floor: sfloor,
    door: sdoor,
  }

  const onSubmitAddress = async () => {
    setSavingStatus('SAVING')
    const addressPostUrl = `${API_URI}/washers/${washerId}/address`
    const response = await fetch(addressPostUrl, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(addressData),
    })
    const result = await response
    if (result.status === 200) {
      setSavingStatus('DONE')
    }
    if (result.status !== 200) {
      setSavingStatus('ERROR')
    }
  }

  useEffect(() => {
    if (savingStatus === 'DONE') {
      dispatch(getUserMe())
      setTimeout(() => {
        setSavingStatus('INIT')
      }, 3000)
    }
  }, [savingStatus])

  return (
    <SettingsSheet>
      <Text $size="20px" $weight={600}>
        {t('GENERAL_SETTINGS')}
      </Text>
      {!!user && !!sWasherData && (
        <>
          <Grid $gap="30px" $marginTop="30px">
            <Grid $xsCol={1} $gap="20px">
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
                error={!!scity && scity.length < 2}
                defaultValue={sWasherData.address.city}
                label={t('CITY')}
                variant="outlined"
                name="city"
                type="text"
                id="city"
                $form
              />
            </Grid>
            <Grid $xsCol={3} $gap="20px">
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setState(e.target.value)}
                error={!!sstate && sstate.length < 2}
                defaultValue={sWasherData.address.state}
                label={t('STATE')}
                variant="outlined"
                name="state"
                type="text"
                id="state"
                $form
              />
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDistrict(e.target.value)}
                error={!!sdistrict && sdistrict.length < 1}
                defaultValue={sWasherData.address.district}
                label={t('DISTRICT')}
                variant="outlined"
                name="district"
                type="text"
                id="district"
                $form
              />
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPostalCode(e.target.value)}
                error={!!spostalCode && spostalCode.length < 4}
                defaultValue={sWasherData.address.postalCode}
                label={t('POSTAL_CODE')}
                variant="outlined"
                name="postalCode"
                type="text"
                id="postalCode"
                $form
              />
            </Grid>
            <Grid $xsCol={4} $gap="20px">
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStreet(e.target.value)}
                error={!!sstreet && sstreet.length < 2}
                defaultValue={sWasherData.address.street}
                label={t('STREET')}
                variant="outlined"
                name="street"
                type="text"
                id="street"
                $form
              />
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHouse(e.target.value)}
                error={!!shouse && shouse.length < 1}
                defaultValue={sWasherData.address.house}
                label={t('HOUSE')}
                variant="outlined"
                name="house"
                type="text"
                id="house"
                $form
              />
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFloor(e.target.value)}
                error={!!sfloor && sfloor.length < 1}
                defaultValue={sWasherData.address.floor}
                label={t('FLOOR')}
                variant="outlined"
                name="floor"
                type="text"
                id="floor"
                $form
              />
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDoor(e.target.value)}
                error={!!sdoor && sdoor.length < 1}
                defaultValue={sWasherData.address.door}
                label={t('DOOR')}
                variant="outlined"
                name="door"
                type="text"
                id="door"
                $form
              />
            </Grid>
            <Grid $justifyItems="end" $margin="30px 0 0 0">
              {savingStatus === 'ERROR' && (
                <Text $textColor="red" $size="14px">
                  {t('SETTINGS_SAVE_ERROR')}
                </Text>
              )}
              <Button
                disabled={savingStatus === 'SAVING'}
                variant="contained"
                color="primary"
                onClick={() => onSubmitAddress()}
              >
                {savingStatus === 'DONE' ? t('SAVING_DONE') : t('SAVE')}
              </Button>
            </Grid>
            <Line />
          </Grid>
          <Text $size="20px" $weight={600}>
            {t('CONTACTS')}
          </Text>
          <Grid $xsCol={2} $gap="20px" $marginTop="30px">
            <Grid>
              <Text $xxsSize="14px" $weight={600}>
                {t('WASHER_NAME')}
              </Text>
              <Text $xxsSize="16px">{sWasherData?.name}</Text>
            </Grid>
            <Grid>
              <Text $xxsSize="14px" $weight={600}>
                {t('PHONE_NUMBER')}
              </Text>
              <Text $xxsSize="16px">{phone}</Text>
            </Grid>
            <Grid>
              <Text $xxsSize="14px" $weight={600}>
                {t('EMAIL_ADDRESS')}
              </Text>
              <Text $xxsSize="16px">{email}</Text>
            </Grid>
            <Grid>
              <Text $xxsSize="14px" $weight={600}>
                {t('FACEBOOK')}
              </Text>
              <Text $xxsSize="16px">{facebook}</Text>
            </Grid>
          </Grid>
        </>
      )}
    </SettingsSheet>
  )
}

export default GeneralSettings
