import React, { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'
import { ModalWrapper, ModalHeader, ModalCloseBtn, ModalBody } from '../../../components/layout/modal'
import { TextInput } from '../../../components/layout/form/form.styles'
import { IStore } from '../../../interfaces/store.interfaces'
import { headers } from '../../../helpers/headers.helper'
import { Text, Grid } from '../../../components/layout'
import { API_URI } from '../../../config'
import {
  toggleUniversalResultModal,
  toggleNewProfileModal,
  clearNewProfileModal,
} from '../../../actions/uiStatesAction'

const NewProfileModal = (): ReactElement => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const dispatch = useDispatch()
  const isOpen = useSelector((state: IStore) => state.uiStates.newProfileModalOpen)

  const postUrl = `${API_URI}/profiles/`

  const data = { firstName, lastName, email, phone }
  const onSubmit = async () => {
    setLoading(true)
    const response = await fetch(`${postUrl}`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })

    if (response.status !== 200) {
      setLoading(false)
      dispatch(toggleUniversalResultModal('ERROR', 'PROFILE_FAIL_TITLE', 'PROFILE_FAIL_DESCRIPTION'))
    }
    if (response.status === 200) {
      setLoading(false)
      dispatch(toggleNewProfileModal())
      dispatch(toggleUniversalResultModal('SUCCESS', 'PROFILE_SUCCESS_TITLE', 'PROFILE_SUCCESS_DESCRIPTION'))
    }
  }

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper>
          <ModalHeader>
            <Text $mdSize="30px" $xxsSize="20px" $weight={500}>
              {t('NEW_PROFILE')}
            </Text>
            <ModalCloseBtn onClick={() => dispatch(clearNewProfileModal())}>×</ModalCloseBtn>
          </ModalHeader>
          <ModalBody>
            <Grid $padding="40px">
              <Text $weight={600} $size="20px">
                {t('PROFILE_DETAILS')}
              </Text>
              <Grid $xsCol={2} $gap="20px">
                <TextInput
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setlastName(e.target.value)}
                  error={!!lastName && lastName.length < 2}
                  label={t('LAST_NAME')}
                  variant="outlined"
                  name="lastName"
                  type="text"
                  required
                  id="lastName"
                  $search
                />
                <TextInput
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                  error={!!firstName && firstName.length < 2}
                  label={t('FIRST_NAME')}
                  variant="outlined"
                  name="firstName"
                  type="text"
                  required
                  id="firstName"
                  $search
                />
                <TextInput
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  error={!!email && email.length < 6}
                  label={t('EMAIL_ADDRESS')}
                  variant="outlined"
                  name="email"
                  type="text"
                  required
                  id="email"
                  $search
                />
                <TextInput
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                  error={!!phone && phone.length < 6}
                  label={t('PHONE')}
                  variant="outlined"
                  name="phone"
                  required
                  type="text"
                  id="phone"
                  $search
                />
              </Grid>
              <Grid $justifyItems="end" $marginTop="40px">
                <Button
                  onClick={() => onSubmit()}
                  variant="contained"
                  disabled={!!loading || !email || !phone || !firstName || !lastName}
                  disableElevation
                  color="primary"
                >
                  {loading ? t('LOADING_BTN') : t('SAVE')}
                </Button>
              </Grid>
            </Grid>
          </ModalBody>
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default NewProfileModal
