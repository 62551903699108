/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreator, Dispatch } from 'redux'
import { openHours as serviceName } from '.'
import { API_URI } from '../config'
import { headers } from '../helpers/headers.helper'

export const getOpenHours: ActionCreator<any> = (washerId: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: `GET_${serviceName}_BEGIN`,
    })
    const postUrl = `${API_URI}/washers/${washerId}/opening-hours`
    const response = await fetch(postUrl, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
    })
    if (response.status !== 200) {
      dispatch({
        type: `GET_${serviceName}_FAILED`,
        payload: {},
      })
    }
    if (response.status === 200) {
      const result = await response.json()
      dispatch({
        type: `GET_${serviceName}_SUCCESS`,
        payload: result,
      })
    }
  } catch (error) {
    dispatch({
      type: `GET_${serviceName}_FAILED`,
      payload: error,
    })
  }
}
