/* eslint-disable no-nested-ternary */
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { API_URI, WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY } from '../../../config'
import { getCancelledReservations } from '../../../actions/getCancelledReservationsActions'
import { getTodaysReservations } from '../../../actions/getTodaysReservationsActions'
import { getReservations } from '../../../actions/getReservationsActions'
import { ButtonWrapper, Button, SlotTitle } from './actionButton.styles'
import { IStore } from '../../../interfaces/store.interfaces'
import { headers } from '../../../helpers/headers.helper'
import { LicensePlate } from '../commonUiElements'
import { colors } from '../../../theme.styles'
import { Icon, Grid, Text } from '../index'
import {
  toggleFinishWithExistingCustomerModal,
  toggleCompleteWithNewCustomerModal,
  toggleCancelWashModal,
} from '../../../actions/uiStatesAction'

export interface IActionButton {
  startTime?: string | Date
  licenseNumber?: string
  reservationId: number
  lastStatus: string
  duration: number
  surface: string
  slot?: number
}

const ActionButton = ({
  licenseNumber,
  reservationId,
  lastStatus,
  startTime,
  surface,
  duration,
  slot,
}: IActionButton): ReactElement => {
  const [now, setNow] = useState<number>()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const slots = useSelector((state: IStore) => state.slots)
  const [cookies] = useCookies([WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const dayOffset = useSelector((state: IStore) => state.uiStates.dayOffset)
  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const washerId = cookies[WASHERID_COOKIE_KEY]
  const slotTitle = slots.find(({ slot: qSlot }) => qSlot === slot?.toString())?.resourceTitle
  const dispatch = useDispatch()
  const postUrl = `${API_URI}/washers/${washerId}/reservations/${reservationId}/status`
  const endTime = moment(startTime).add(duration, 'minutes')
  const daysToGet = calView === 'week' ? 7 : 1

  useEffect(() => {
    const nowTime = setInterval(() => setNow(Date.now()), 1000)
    return () => {
      clearInterval(nowTime)
    }
  }, [])

  const minutesLeft = moment(endTime).diff(moment(now), 'minutes')
  const secondsLeft = moment(endTime).diff(moment(now), 'seconds') % 60
  // washing state modifier function. give it a string of 'start' | 'finish' | 'cancel' | 'pause'
  const onModifyState = async (action: string) => {
    setLoading(true)
    const response = await fetch(`${postUrl}/${action}`, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
    })

    const result = await response.json()
    if (result && washerId) {
      dispatch(getTodaysReservations(washerId))
      setLoading(false)
      if (calView === 'day') {
        dispatch(getReservations(washerId, dayOffset, daysToGet))
        dispatch(getCancelledReservations(washerId, dayOffset, daysToGet))
      }
      if (calView === 'week') {
        const weekStartOffsetFromToday = moment().add(dayOffset, 'day').weekday()
        const weekStartOffsetFromDate = weekStartOffsetFromToday * -1 + dayOffset
        dispatch(getReservations(washerId, weekStartOffsetFromDate, daysToGet))
        dispatch(getCancelledReservations(washerId, weekStartOffsetFromDate, daysToGet))
      }
    }
  }

  return (
    <ButtonWrapper $loading={loading} $active={lastStatus === 'ON_GOING'}>
      <Grid $xxsCol="auto 1fr" $alignItems="center" $gap="20px">
        <Grid>
          <Grid $xxsCol="auto">
            {!loading && (lastStatus === 'NEW' || lastStatus === 'PAUSED') && (
              <Button title="Start" onClick={() => onModifyState('start')}>
                <Icon $play $height="30px" $width="30px" />
              </Button>
            )}

            {!loading && lastStatus === 'ON_GOING' && (
              <Button title="Pause" onClick={() => onModifyState('pause')}>
                <Icon $pause $height="30px" $width="30px" />
              </Button>
            )}

            {!loading && lastStatus === 'ON_GOING' && !!licenseNumber && (
              <Button
                title="Complete"
                onClick={() => {
                  onModifyState('complete')
                  dispatch(toggleFinishWithExistingCustomerModal(reservationId))
                }}
              >
                <Icon $checkboxOn $height="30px" $width="30px" />
              </Button>
            )}

            {!loading && lastStatus === 'ON_GOING' && !licenseNumber && (
              <Button title="Complete" onClick={() => dispatch(toggleCompleteWithNewCustomerModal(reservationId))}>
                <Icon $checkboxOn $height="30px" $width="30px" />
              </Button>
            )}

            {!loading && lastStatus === 'COMPLETED' && !!licenseNumber && (
              <Button title="Finish" onClick={() => dispatch(toggleFinishWithExistingCustomerModal(reservationId))}>
                <Icon $checkboxOn $height="30px" $width="30px" />
              </Button>
            )}

            {!loading && (lastStatus === 'NEW' || lastStatus === 'PAUSED') && (
              <Button title="Cancel" onClick={() => dispatch(toggleCancelWashModal(reservationId))}>
                <Icon $cancel $height="30px" $width="30px" />
              </Button>
            )}

            {loading && (
              <Button title="Loading...">
                <Icon $glasshour $height="30px" $width="30px" />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid $gap="0px">
          <Text $textColor={loading ? colors.lightGrey : 'black'} $size="14px" $weight={600} $margin="0 20px 0 0">
            {`${t(surface)} ${t('WASHING')}`}
          </Text>
          {licenseNumber && (
            <Grid $justifyItems="start">
              <LicensePlate $dark>{licenseNumber}</LicensePlate>
            </Grid>
          )}
          <Grid $xxsCol="auto 65px" $alignItems="center" $gap="20px">
            <Text $size="14px" $mono $textColor={colors.grey}>
              {`${moment(startTime).format('HH:mm')} - ${moment(startTime)
                .add(duration || 0, 'minutes')
                .format('HH:mm')}`}
            </Text>
            <Text $textColor={loading ? colors.lightGrey : 'black'} $weight={500} $size="20px" $align="right" $mono>
              {minutesLeft >= duration
                ? `${duration}:00`
                : minutesLeft < 0
                ? '00:00'
                : `${minutesLeft}:${secondsLeft > 9 ? secondsLeft : `0${secondsLeft}`}`}
            </Text>
          </Grid>
          <SlotTitle $active={lastStatus === 'ON_GOING'} $disabled={loading}>
            {slotTitle}
          </SlotTitle>
        </Grid>
      </Grid>
    </ButtonWrapper>
  )
}
export default ActionButton
