import { useDispatch, useSelector } from 'react-redux'
import { ToolbarProps } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import React, { ReactElement } from 'react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/hu'
import 'moment/locale/de'
import { useCookies } from 'react-cookie'
import { getSelectorReservations } from '../../actions/getSelectorReservationsActions'
import { ButtonGroup, CompactToolbarWrapper, DayText } from './compactToolbar.styles'
import { NavButton } from '../../pages/reservationdetails/reservationdetails.styles'
import { setStartTime } from '../../actions/newReservationActions'
import { setCompactDayOffset } from '../../actions/uiStatesAction'
import { IStore } from '../../interfaces/store.interfaces'
import { langMap } from '../../helpers/language.helper'
import { WASHERID_COOKIE_KEY } from '../../config'
import { Grid } from '../../components/layout'

const CompactToolbar = (props: ToolbarProps): ReactElement => {
  const { date, onNavigate } = props
  const startTime = useSelector((state: IStore) => state.newOrderDetails.startTime)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const compactDayOffset = useSelector((state: IStore) => state.uiStates.compactDayOffset)
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = cookies[WASHERID_COOKIE_KEY]
  const user = useSelector((state: IStore) => state.user)
  const userLang = user.user?.language || 'en'

  const onPrevious = () => {
    if (washerId) {
      dispatch(getSelectorReservations(washerId, compactDayOffset - 1))
      dispatch(setStartTime(moment(startTime).subtract(1, 'day').toDate()))
      dispatch(setCompactDayOffset(compactDayOffset - 1))
      onNavigate('PREV')
    }
  }

  const onNext = () => {
    if (washerId) {
      dispatch(getSelectorReservations(washerId, compactDayOffset + 1))
      dispatch(setStartTime(moment(startTime).add(1, 'day').toDate()))
      dispatch(setCompactDayOffset(compactDayOffset + 1))
      onNavigate('NEXT')
    }
  }

  const onToday = () => {
    if (washerId) {
      dispatch(getSelectorReservations(washerId, 0))
      dispatch(setStartTime(moment(startTime).set('date', moment().get('date')).toDate()))
      dispatch(setCompactDayOffset(0))
      onNavigate('TODAY')
    }
  }

  return (
    <CompactToolbarWrapper $xxsCol="auto 1fr" $alignItems="center">
      <Grid $xxsCol="auto auto" $gap="10px">
        <ButtonGroup>
          <NavButton $fontSize="30px" onClick={onPrevious} variant="contained" disableElevation>
            ‹
          </NavButton>
          <NavButton $fontSize="30px" onClick={onNext} variant="contained" disableElevation>
            ›
          </NavButton>
        </ButtonGroup>
        <NavButton onClick={onToday} variant="contained" disableElevation>
          {t('TODAY')}
        </NavButton>
      </Grid>
      <DayText $small>
        {moment(date)
          .locale(langMap[userLang] || langMap.en)
          .format('MMMM DD. dddd')}
      </DayText>
    </CompactToolbarWrapper>
  )
}

export default CompactToolbar
