import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { WeekWrapper, DayWrapper, DayActivitySign } from './openHours.styles'
import { weekTypes } from '../../../interfaces/openHours.interfaces'
import { IStore } from '../../../interfaces/store.interfaces'
import { LicensePlate } from '../commonUiElements'
import Day from './day.component'
import { Grid } from '../grid'

interface IWeekComponent {
  weekType: string
  weekKey: weekTypes
}

const Week = ({ weekType, weekKey }: IWeekComponent): ReactElement => {
  const { t } = useTranslation()
  const openingHours = useSelector((state: IStore) => state.openingHours)
  const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
  const weekdata = openingHours[weekKey]
  return (
    <WeekWrapper>
      <Grid $marginLeft="20px" $marginTop="20px" $gap="30px" justifyItems="start" $xxsCol="auto">
        {!!weekType && weekType !== 'ANY' && <LicensePlate>{t(weekType)}</LicensePlate>}
        <Grid $xxsCol="auto 1fr">
          <Grid $gap="10px" $xxsCol="repeat(7, auto)">
            {days.map((day) => (
              <DayActivitySign $active={!!weekdata?.find(({ dayKey }) => dayKey === day)} key={day}>
                {t(`${day}_SHORT`)}
              </DayActivitySign>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <DayWrapper>
        {days.map((dayKey) => (
          <Day dayKey={dayKey} key={dayKey} weekType={weekType} weekKey={weekKey} />
        ))}
      </DayWrapper>
    </WeekWrapper>
  )
}

export default Week
