import styled from 'styled-components/macro'
import { AppBar } from '@material-ui/core'
import { Grid } from '../../components/layout'

export const Appbar = styled(AppBar)`
  padding: 10px 20px;
  border-bottom: 1px solid #e1e7f0;
  &.MuiPaper-root {
    background-color: white;
  }
`

export const Box = styled(Grid)`
  border: 1px solid #e1e7f0;
  border-radius: 15px;
  background-color: #ffffff;
  padding: 32px;
`
