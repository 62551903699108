import styled from 'styled-components/macro'
import { colors } from '../../../theme.styles'

export const Wrapper = styled.div`
  border: 1px solid ${colors.veryLightGrey};
  grid-template-columns: auto 5fr;
  border-radius: 6px;
  display: grid;
`

export const HighlightWrapper = styled.div`
  padding: 20px 35px 20px 30px;
  background-color: ${colors.almostWhite};
  color: #a8b1bd;
  font-weight: 600;
`
export const DefaultWrapper = styled.span`
  padding: 18px 22px;
  font-weight: 600;
`

export const CarSizeWrapper = styled('div')<{ $active?: boolean }>`
  padding: 15px 22px;
  border-radius: 15px;
  grid-template-columns: 1fr;
  display: grid;
  ${({ $active }) =>
    $active &&
    `
  background-color: ${colors.lime};
   
  `}
`
