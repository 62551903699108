/* eslint-disable import/prefer-default-export */
import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { media } from '../../components/layout'
import { colors } from '../../theme.styles'

export const Title = styled(Typography)<{ $active?: boolean }>`
  font-family: fonts.main;
  color: ${colors.lime};
  font-weight: 600;
  ${({ $active }) =>
    $active &&
    `
    color: ${colors.magenta};
    `}
  padding: 20px 0px 0px 0px;
  font-size: 30px;
  text-align: center;
  ${media.lg} {
    padding: 44px 0px 16px 31px;
    text-align: left;
    font-size: 50px;
  }
`

export const HeaderWrapper = styled(Box)`
  position: relative;
`

export const AppointmentsCount = styled.p`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin: 0px;
  ${media.lg} {
    text-align: left;
    margin-right: 31px;
    margin-top: 84px;
  }
`

export const OngoingWrapper = styled(Box)`
  border-radius: 15px 15px 0px 0px;
  grid-template-columns: auto auto;
  background-color: ${colors.magenta};
  justify-content: center;
  position: absolute;
  align-items: center;
  display: grid;
  color: white;
  height: 30px;
  width: 100%;
  gap: 6px;
  margin: 0px;
  left: 0;
  top: -3px;
`
