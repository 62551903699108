import styled from 'styled-components/macro'
import { Typography } from '@material-ui/core'
import { colors, fonts } from '../../theme.styles'
import { media } from '../../components/layout'

export const EventItem = styled('button')<{ $active?: boolean }>`
  background-color: ${colors.veryLightGrey};
  margin: 7px 14px 7px 7px;
  box-sizing: border-box;
  height: 90% !important;
  width: 98% !important;
  appearance: none;
  text-align: start;
  display: grid;
  border: none;
  grid-template-columns: auto 1fr;
  ${media.md} {
    grid-template-columns: auto 1fr auto;
  }
  gap: 20px;
  pointer-events: none;
  align-items: center;
  padding: 5px 5px;
  ${media.lg} {
    padding: 11px 18px;
  }
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  color: ${colors.grey};
  touch-action: none;
  ${({ $active }) =>
    $active &&
    `
    background-color: ${colors.white};
    border: 3px solid ${colors.lime};
    color: ${colors.black};
    pointer-events: auto;
  `}
`

export const Title = styled(Typography)`
  font-family: ${fonts.main};
  font-size: 16px;
  font-weight: 600;
`
