import { combineReducers } from 'redux'
// mockdata reducers
// local reducers
import { newOrderReducer } from './newOrderReducer'
import { uiStatesReducer } from './uiStatesReducer'
import { slotsReducer } from './slotsReducer'

// API fetching reducers
import { getCancelledReservationsReducer } from './getCancelledReservationsReducer'
import { getSelectorReservationsReducer } from './getSelectorReservationsReducer'
import { getTodaysReservationsReducer } from './getTodaysReservationsReducer'
import { getMarketingCampaignsReducer } from './getMarketingCampaignsReducer'
import { getWasherHistoryReducer } from './getWasherHistoryReducer'
import { userReducer, selectedWasherReducer } from './userReducer'
import { getReservationsReducer } from './getReservationsReducer'
import { getInvitedUsersReducer } from './getInvitedUsersReducer'
import { getPromotionsReducer } from './getPromotionsReducer'
import { getStatisticsReducer } from './getStatisticsReducer'
import { getOpenHoursReducer } from './getOpenHoursReducer'
import { getDowntimesReducer } from './getDowntimesReducer'
import { findProfileReducer } from './findProfileReducer'
import { getPackagesReducer } from './getPackagesReducer'
import { getUsersReducer } from './getUsersReducer'
import { getPriceReducer } from './getPriceReducer'
import { getRegionalPriceListReducer } from './getRegionalPriceListReducer'

const rootReducer = combineReducers({
  statistics: getStatisticsReducer,
  cancelledReservations: getCancelledReservationsReducer,
  selectorReservations: getSelectorReservationsReducer,
  todaysReservations: getTodaysReservationsReducer,
  reservations: getReservationsReducer,
  marketingCampaigns: getMarketingCampaignsReducer,
  washerHistory: getWasherHistoryReducer,
  selectedWasher: selectedWasherReducer,
  openingHours: getOpenHoursReducer,
  newOrderDetails: newOrderReducer,
  promotions: getPromotionsReducer,
  downtimes: getDowntimesReducer,
  profileList: findProfileReducer,
  packages: getPackagesReducer,
  uiStates: uiStatesReducer,
  price: getPriceReducer,
  slots: slotsReducer,
  users: getUsersReducer,
  invitedUsers: getInvitedUsersReducer,
  user: userReducer,
  priceList: getRegionalPriceListReducer,
})
export default rootReducer
