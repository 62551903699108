import { RadioGroup } from '@material-ui/core'
import styled from 'styled-components/macro'
import { colors } from '../../../theme.styles'
import { media } from '../mediaqueries'

export const StyledRadiogroup = styled(RadioGroup)`
  & .MuiFormControlLabel-root {
    & .Mui-checked ~ span > div > div {
      background-color: rgba(149, 193, 31, 0.1) !important;
      color: ${colors.lime};
      border-right: 1px solid ${colors.lime};
    }
    & .Mui-checked ~ span > div {
      border-color: ${colors.lime};
    }
  }
  & .MuiFormControlLabel-label {
    width: 100%;
  }
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  ${media.md} {
    grid-template-columns: 1fr 1fr;
  }
`

export const CarSizeRadiogroup = styled(RadioGroup)`
  & .MuiFormControlLabel-root {
    width: 70px;
    height: 70px;
    & .Mui-checked ~ span > div > div {
      background-color: rgba(149, 193, 31, 0.1) !important;
      color: ${colors.lime};
      border-right: 1px solid ${colors.lime};
    }
    & .Mui-checked ~ span > div {
      border-color: ${colors.lime};
    }
  }
  & .MuiFormControlLabel-label {
    width: 100%;Ç
  }
  display: flex;
  flex-direction: row;
  gap: 10px;
`
