/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import styled from 'styled-components/macro'
import { colors } from '../../../theme.styles'
import { media } from '../mediaqueries'

export const StyledTableHead = styled(TableHead)`
  border: none;
  border-bottom: 1px solid ${colors.veryLightGrey};
  text-transform: uppercase;
  padding-bottom: 5px;
  & .MuiTableCell-head {
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px 5px 5px;
    ${media.md} {
      padding: 16px 16px 10px 15px;
    }
  }
  & .MuiTableCell-head:first-child {
    padding-left: 15px;
    ${media.md} {
      padding-left: 40px;
    }
  }
  & .MuiTableCell-head:last-child {
    padding-right: 15px;
    ${media.md} {
      padding-right: 40px;
    }
  }
`

export const StyledTableBody = styled(TableBody)<{ $interactive?: boolean }>`
  & .MuiTableCell-body {
    font-weight: 500;
    font-size: 14px;
    word-break: break-word;
    padding: 8px;
    ${media.md} {
      padding: 25px;
    }
  }
  & .MuiTableCell-body:first-child {
    padding-left: 15px;
    ${media.md} {
      padding-left: 40px;
    }
  }
  & .MuiTableCell-body:last-child {
    padding-right: 15px;
    ${media.md} {
      padding-right: 40px;
    }
  }
  & .Mui-selected {
    background-color: transparent;
  }
  & tr {
    height: 50px;
  }
  ${media.lg} {
    & tr {
      height: auto;
    }
  }
  & .MuiTableRow-root:hover {
    background-color: transparent;
  }
  ${({ $interactive }) =>
    $interactive &&
    `
  cursor: pointer;
  & .Mui-selected {
    background-color: RGBA(149, 193, 31, 0.1);
  }
  & .Mui-selected:hover {
    background-color: RGBA(149, 193, 31, 0.2);
  }
  & .MuiTableRow-root:hover {
    background-color: RGBA(149, 193, 31, 0.1);
  }
  `}
`

export const StyledTableCell = styled(TableCell)<{
  $mobileHidden?: boolean
  $mobileVisible?: boolean
  component?: any
  to?: string
}>`
  ${({ $mobileHidden }) =>
    $mobileHidden &&
    `
    display: none;
    ${media.md} {
      display: table-cell;
    }
  `}
  ${({ $mobileVisible }) =>
    $mobileVisible &&
    `
    display: table-cell;
    ${media.md} {
      display: none;
    }
  `}
`

export const DividerRow = styled(TableRow)`
  background-color: ${colors.cyan};
  color: white;
  height: auto !important;
  & .MuiTableCell-body:first-child {
    padding: 5px 10px !important;
    ${media.lg} {
      padding: 11px 30px !important;
    }
  }
`

export const DeviderCell = styled.div`
  grid-template-columns: 1fr 1fr;
  display: grid;
  height: auto;
`

export const RoundedTableBody = styled(TableBody)`
  background-color: white;
  & .MuiTableCell-body {
    padding: 5px;
    ${media.md} {
      padding: 25px;
    }
    font-weight: 500;
    font-size: 14px;
  }
  & .MuiTableCell-body:first-child {
    padding-left: 10px;
    ${media.lg} {
      padding-left: 15px;
    }
  }
  & .MuiTableRow-root:first-child .MuiTableCell-body {
    border-radius: 16px 16px 0px 0px;
  }
  & .MuiTableCell-body:last-child {
    padding-right: 10px;
    ${media.lg} {
      padding-right: 15px;
    }
  }
  & .Mui-selected {
    background-color: RGBA(149, 193, 31, 0.1);
  }
  & tr {
    height: 50px;
  }
  ${media.lg} {
    & tr {
      height: auto;
    }
  }
  overflow: hidden;
  & .MuiTableCell-root {
    border-bottom: none !important;
  }
`

export const TransparentTableHead = styled(TableHead)`
  background-color: transparent;
  border: none;
  border-bottom: none;
  text-transform: uppercase;
  padding-bottom: 5px;
  & .MuiTableCell-head {
    font-weight: 600;
    font-size: 12px;
    padding: 5px;
    ${media.lg} {
      padding: 16px 16px 10px;
    }
  }
  & .MuiTableCell-head:first-child {
    padding-left: 20px;
    ${media.lg} {
      padding-left: 40px;
    }
  }
  & .MuiTableCell-head:last-child {
    padding-right: 20px;
    ${media.lg} {
      padding-right: 40px;
    }
  }
  & .MuiTableCell-root {
    border-bottom: none !important;
  }
`
