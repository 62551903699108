import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Grid, media } from '../../components/layout'
import { colors } from '../../theme.styles'

export const unused = styled.div`
  color: red;
`
export const NavBtn = styled(NavLink)`
  padding: 10px 18px 10px 10px;
  color: ${colors.cyan};
  text-decoration: none;
  font-weight: 600;
    &.active {
      color: ${colors.lime};
      border-left: 3px solid ${colors.lime};
    },
`

export const DesktopNavigation = styled(Grid)`
  display: none;
  ${media.lg} {
    display: grid;
  }
`

export const MobileNavigation = styled(Grid)`
  display: grid;
  position: sticky;
  top: 55px;
  background-color: ${colors.almostWhite};
  z-index: 3;
  ${media.lg} {
    display: none;
  }
`

export const MobileNavButton = styled(NavLink)`
  padding: 6px;
  color: ${colors.cyan};
  text-decoration: none;
  font-weight: 500;
  justify-items: center;
  display: grid;
  grid-gap: 5px;
  font-size: 9px;
  &.active {
    color: ${colors.lime};
    border-bottom: 2px solid ${colors.lime};
  }
`

export const TabNavButton = styled(NavLink)`
  padding: 6px;
  color: ${colors.cyan};
  text-decoration: none;
  font-weight: 500;
  justify-items: center;
  display: grid;
  grid-gap: 5px;
  font-size: 9px;
  &.active {
    color: ${colors.lime};
    border-bottom: 2px solid ${colors.lime};
  }
  ${media.md} {
    padding: 10px 13px;
    font-size: 16px;
  }
`
