import { promotions as serviceName } from '../actions'
import { IPromotion } from '../interfaces/promotion.interfaces'
import { IAction } from '../interfaces/store.interfaces'

const initialState = [] as IPromotion[]

export const getPromotionsReducer = (state = initialState, action: IAction): IPromotion[] => {
  const { type, payload } = action
  switch (type) {
    case `GET_${serviceName}_BEGIN`:
      return state
    case `GET_${serviceName}_SUCCESS`:
      return payload
    case `GET_${serviceName}_FAILED`:
      return state
    default:
      return state
  }
}
