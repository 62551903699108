import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { englishDict, hungarianDict, atDict, testDict } from './services/dictionary/dictionary'

// the translations
const resources = {
  en: {
    translation: englishDict,
  },
  hu: {
    translation: hungarianDict,
  },
  at: {
    translation: atDict,
  },
  de: {
    translation: atDict,
  },
  test: {
    translation: testDict,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    lng: 'hu',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
