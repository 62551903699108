/* eslint-disable import/prefer-default-export */
import { Box } from '@material-ui/core'
import styled from 'styled-components/macro'
import { IWrapper } from '../../interfaces/layout.interfaces'
import { media } from './mediaqueries'

const converter = (val: string | number): string => {
  if (typeof val === 'number') return `${val}px`
  return val
}
export const Wrapper = styled(Box)<IWrapper>`
  ${media.xxs} {
    ${({ $xxsMarginBottom }) =>
      $xxsMarginBottom &&
      `
   margin-bottom: ${converter($xxsMarginBottom)};
  `}
    ${({ $margin }) =>
      $margin &&
      `
   margin: ${converter($margin)};
  `}
    ${({ $xxsMarginTop }) =>
      $xxsMarginTop &&
      `
   margin-top: ${converter($xxsMarginTop)};
  `}
    ${({ $xxsPaddingBottom }) =>
      $xxsPaddingBottom &&
      `
   padding-bottom: ${converter($xxsPaddingBottom)};
  `}
    ${({ $xxsPaddingTop }) =>
      $xxsPaddingTop &&
      `
   padding-top: ${converter($xxsPaddingTop)};
  `}
  }
  ${media.md} {
    ${({ $desktopHidden }) =>
      !!$desktopHidden &&
      `
      display: none;
  `}

    ${({ $mdMarginBottom }) =>
      $mdMarginBottom &&
      `
   margin-bottom: ${converter($mdMarginBottom)};
  `}

  ${({ $mdMarginTop }) =>
      $mdMarginTop &&
      `
   margin-top: ${converter($mdMarginTop)};
  `}

    ${({ $mdPaddingBottom }) =>
      $mdPaddingBottom &&
      `
   padding-bottom: ${converter($mdPaddingBottom)};
  `}

    ${({ $mdPaddingTop }) =>
      $mdPaddingTop &&
      `
   padding-top: ${converter($mdPaddingTop)};
  `}
  }

  ${media.lg} {
    ${({ $marginBottom }) =>
      $marginBottom &&
      `
   margin-bottom: ${converter($marginBottom)};
  `}

    ${({ $marginTop }) =>
      $marginTop &&
      `
   margin-top: ${converter($marginTop)};
  `}

    ${({ $paddingBottom }) =>
      $paddingBottom &&
      `
   padding-bottom: ${converter($paddingBottom)};
  `}

    ${({ $paddingTop }) =>
      $paddingTop &&
      `
   padding-top: ${converter($paddingTop)};
  `}
  }

  background-image: ${({ $backgroundImage }) => $backgroundImage && `url('${$backgroundImage}')`};
  background-color: ${({ $backgroundColor = 'white' }) => $backgroundColor && $backgroundColor};
  color: ${({ color = '' }) => color && color};
  background-position: center center;
  box-sizing: border-box;
  background-size: cover;
  position: relative;
  width: auto;
  @media (max-width: ${768 / 16}rem) {
    ${({ $mobileHidden }) =>
      !!$mobileHidden &&
      `
    display: none;
`}
  }
`
