import styled from 'styled-components'
import { Grid, media } from '../../components/layout'

export const StickySearchBar = styled(Grid)`
  background-color: white;
  top: 0;
  padding: 5px 15px 5px 15px;
  z-index: 1;
  ${media.lg} {
    position: sticky;
    padding: 40px 40px 10px 40px;
  }
`
