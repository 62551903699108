import React, { ReactElement, useState } from 'react'
import { Box, Modal } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { ModalWrapper, ModalHeader, ModalBody, ModalResultContent } from '../../../components/layout/modal'
import { API_URI, WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY } from '../../../config'
import { WashSize } from '../../../modules/cancelledReservations/cancelledReservations.styles'
import { getCancelledReservations } from '../../../actions/getCancelledReservationsActions'
import { Text, Grid, TextBlock, LicensePlate, Button } from '../../../components/layout'
import { getTodaysReservations } from '../../../actions/getTodaysReservationsActions'
import { getReservations } from '../../../actions/getReservationsActions'
import { clearCancelWashModal } from '../../../actions/uiStatesAction'
import { IStore } from '../../../interfaces/store.interfaces'
import { headers } from '../../../helpers/headers.helper'

const CancelWashModal = (): ReactElement => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const reservations = useSelector((state: IStore) => state.reservations?.reservationlist)
  const isOpen = useSelector((state: IStore) => state.uiStates.cancelWashModal.open)
  const reservationId = useSelector((state: IStore) => state.uiStates.cancelWashModal.id)
  const onDetailsPage = useSelector((state: IStore) => state.uiStates.cancelWashModal.onDetailsPage)
  const dayOffset = useSelector((state: IStore) => state.uiStates.dayOffset)
  const daysToGet = calView === 'week' ? 7 : 1

  const postUrl = `${API_URI}/washers/${washerId}/reservations/${reservationId}/status`

  const selectedReservation = reservations?.find(({ id }) => id === reservationId)

  const onModifyState = async (action: string) => {
    setLoading(true)
    const response = await fetch(`${postUrl}/${action}`, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
    })

    const result = await response.json()
    if (result.error) {
      setLoading(false)
    }
    if (result.status === 'CANCELLED' && onDetailsPage && washerId) {
      setLoading(false)
      dispatch(clearCancelWashModal())
    }
    if (result.status === 'CANCELLED' && !onDetailsPage && washerId) {
      setLoading(false)
      dispatch(clearCancelWashModal())
      dispatch(getTodaysReservations(washerId))
      if (calView === 'day') {
        dispatch(getReservations(washerId, dayOffset, daysToGet))
        dispatch(getCancelledReservations(washerId, dayOffset, daysToGet))
      }
      if (calView === 'week') {
        const weekStartOffsetFromToday = moment().add(dayOffset, 'day').weekday()
        const weekStartOffsetFromDate = weekStartOffsetFromToday * -1 + dayOffset
        dispatch(getReservations(washerId, weekStartOffsetFromDate, daysToGet))
        dispatch(getCancelledReservations(washerId, weekStartOffsetFromDate, daysToGet))
      }
    }
  }

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper $small>
          <ModalHeader>
            <Text $mdSize="30px" $xxsSize="20px" $weight={500}>
              {t('CANCEL_WASH_TITLE')}
            </Text>
          </ModalHeader>
          {!!selectedReservation && (
            <ModalBody>
              <ModalResultContent $gap="40px">
                <TextBlock dangerouslySetInnerHTML={{ __html: t('CANCEL_WASH_DESCRIPTION') }} $size="18px" />
                <Grid $xxsCol="1fr 60px" $margin="40px 0">
                  <Box>
                    <Grid $xxsCol="auto 1fr" $gap="8px">
                      <Text $size="14px">
                        {`${moment(selectedReservation?.startTime).format('HH:mm')} - ${moment(
                          selectedReservation?.startTime,
                        )
                          .add(+(selectedReservation?.duration || 0), 'minutes')
                          .format('HH:mm')}`}
                      </Text>
                      <Text $size="14px" $weight={600}>{`${selectedReservation?.duration} perc`}</Text>
                    </Grid>
                    <Text $size="14px" $weight={600} $margin="5px 0px">
                      {t(selectedReservation?.category?.title || 'DEFAULT_CATEGORY_TITLE')}
                    </Text>
                    {!!selectedReservation?.vehicle?.licensePlate && (
                      <Grid $xsCol="auto 1fr" $justifyItems="start">
                        <LicensePlate $search>{selectedReservation?.vehicle?.licensePlate}</LicensePlate>
                      </Grid>
                    )}
                  </Box>
                  {selectedReservation?.vehicle?.size && <WashSize>{selectedReservation?.vehicle.size}</WashSize>}
                </Grid>
                <Grid $xxsCol={2}>
                  <Button
                    onClick={() => onModifyState('cancel')}
                    disabled={!!loading}
                    variant="contained"
                    color="primary"
                  >
                    {loading ? t('LOADING_BTN') : t('OK_CANCEL')}
                  </Button>
                  <Button onClick={() => dispatch(clearCancelWashModal())} color="secondary" variant="text">
                    {t('NO_CANCEL')}
                  </Button>
                </Grid>
              </ModalResultContent>
            </ModalBody>
          )}
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default CancelWashModal
