import { Box } from '@material-ui/core'
import styled from 'styled-components/macro'
import { Grid, media } from '../../components/layout'
import { colors } from '../../theme.styles'

const slotHeight = 80
const weekSlotHeight = 50

export const Scheduler = styled(Box)<{ $slotNr: number; $weekView?: boolean }>`
  // COMMON

  box-sizing: border-box;
  & .rbc-time-header-content {
    border: 1px solid ${colors.veryLightGrey};
    background-color: ${colors.white};
    border-radius: 15px 15px 0px 0px;
    border-bottom: none;
    margin-right: 3px;
    display: flex;
    ${media.lg} {
      margin-right: 16px;
    }
  }

  & .rbc-row-resource {
    top-right-border-radius: 6px;
    border-bottom: none;
    height: 121px;
    width: 100%;
  }

  & .rbc-allday-cell {
    display: none;
    border: none;
    height: 0px;
  }

  & .rbc-time-column:not(.rbc-time-gutter) {
    border: 1px solid ${colors.veryLightGrey};
    margin-right: 3px;
    border-top: none;
    ${media.lg} {
      margin-right: 16px;
    }
  }

  & .rbc-time-slot {
    height: ${slotHeight}px;
  }

  & .rbc-time-header-gutter {
    background-color: transparent;
    border: none;
  }

  & .rbc-time-header {
    background-color: transparent;
    display: grid;
    border: none;
  }

  & .rbc-time-view {
    background-color: transparent;
    border: none;
  }

  & .rbc-slot-selection {
    display: none;
  }

  & .rbc-time-column {
    background-color: transparent;
    position: relative;
    border: none;
    width: auto;
  }

  & .rbc-time-gutter > .rbc-timeslot-group {
    transform: translateY(-10px);
    border-bottom: none;
    color: ${colors.grey};
    z-index: 100;
  }

  & .rbc-day-slot {
    width: auto;
  }

  & .rbc-day-slot > .rbc-timeslot-group > .rbc-time-slot {
    background-color: transparent;
    position: relative;
    border: none;
    &:before {
      transform: translate3d(-50%; -50%; 0);
      transition: opacity 0.1s ease-in-out;
      color: ${colors.grey};
      position: absolute;
      opacity: 0;
      left: 50%;
      top: 50%;
    }
    &:hover:before {
      opacity: 1;
    }
  }

  & .rbc-timeslot-group {
    height: ${slotHeight * 2}px;
  }

  & .rbc-day-slot > .rbc-timeslot-group > .rbc-time-slot:nth-of-type(2n) {
    background-color: white;
  }

  & .rbc-time-content {
    box-sizing: border-box;
    overflow: hidden;
    display: grid;
    border: none;
  }

  & .rbc-events-container {
    position: absolute;
    border: none;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  & .rbc-header {
    border-bottom: none;
    padding: 0px;
  }

  & .rbc-event-label {
    display: none;
  }

  & .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: none;
  }

  & .rbc-current-time-indicator {
    box-shadow: 0px 0px 5px rgba(216;36;138;0.2);
    background-color: ${colors.magenta};
    border: 1px solid white;
    pointer-events: none;
    position: absolute;
    border-right: none;
    border-left: none;
    right: -10px;
    height: 3px;
    left: -10px;
    zindex: 3;
  }

  & .rbc-event {
    background-color: transparent;
    padding: 0px !important;
    box-sizing: border-box;
    border-radius: 15px;
    position: absolute;
    overflow: hidden;
    border: none;
    margin: 5px;
  }

  & .rbc-time-header-cell {
    display: none;
  }

  & .rbc-event-content {
    height: 100%;
    width: 97%;
  }

  // DAYVIEW

  ${({ $weekView, $slotNr }) =>
    !$weekView &&
    !!$slotNr &&
    `
    & .rbc-time-header {
      grid-template-columns: auto repeat(${$slotNr}, 1fr);
    }

    & .rbc-time-content {
      grid-template-columns: auto repeat(${$slotNr}, 1fr);
    }

    & .rbc-day-slot > .rbc-timeslot-group {
      border: none;
    }

    & .active0 {
      & .rbc-time-column:not(.rbc-time-gutter):nth-child(1) {
        border: 3px solid ${colors.magenta};
        margin-right: 3px;
        border-top: none;
        ${media.lg} {
          margin-right: 16px;
        }
      }
      & .rbc-time-header-content:nth-child(1) {
        border: 3px solid ${colors.magenta};
        box-sizing: border-box;
        border-bottom: none;
        margin-right: 3px;
        ${media.lg} {
          margin-right: 16px;
        }
      }
    }

    & .active1 {
      & .rbc-time-column:not(.rbc-time-gutter):nth-child(2) {
        border: 3px solid ${colors.magenta};
        margin-right: 3px;
        border-top: none;
        ${media.lg} {
          margin-right: 16px;
        }
      }
      & .rbc-time-header-content:nth-child(2) {
        border: 3px solid ${colors.magenta};
        box-sizing: border-box;
        border-bottom: none;
        margin-right: 3px;
        ${media.lg} {
          margin-right: 16px;
        }
      }
    }

    & .active2 {
      & .rbc-time-column:not(.rbc-time-gutter):nth-child(3) {
        border: 3px solid ${colors.magenta};
        margin-right: 3px;
        border-top: none;
        ${media.lg} {
          margin-right: 16px;
        }
      }
      & .rbc-time-header-content:nth-child(3) {
        border: 3px solid ${colors.magenta};
        box-sizing: border-box;
        border-bottom: none;
        margin-right: 3px;
        ${media.lg} {
          margin-right: 16px;
        }
      }
    }

    & .active3 {
      & .rbc-time-column:not(.rbc-time-gutter):nth-child(4) {
        border: 3px solid ${colors.magenta};
        margin-right: 3px;
        border-top: none;
        ${media.lg} {
          margin-right: 16px;
        }
      }
      & .rbc-time-header-content:nth-child(4) {
        border: 3px solid ${colors.magenta};
        box-sizing: border-box;
        border-bottom: none;
        margin-right: 3px;
        ${media.lg} {
          margin-right: 16px;
        }
      }
    }

    & .active4 {
      & .rbc-time-column:not(.rbc-time-gutter):nth-child(5) {
        border: 3px solid ${colors.magenta};
        margin-right: 3px;
        border-top: none;
        ${media.lg} {
          margin-right: 16px;
        }
      }
      & .rbc-time-header-content:nth-child(5) {
        border: 3px solid ${colors.magenta};
        box-sizing: border-box;
        border-bottom: none;
        margin-right: 3px;
        ${media.lg} {
          margin-right: 16px;
        }
      }
    }
`}

  // WEEKVIEW

${({ $weekView, $slotNr }) =>
    !!$weekView &&
    !!$slotNr &&
    `
  & .rbc-time-header {
    grid-template-columns: auto 1fr;
    margin-right: 0px!important;
  }
  & .rbc-time-content {
    grid-template-columns: auto repeat(7, 1fr);
  }
  & .rbc-time-column:not(.rbc-time-gutter) {
    margin-right: 0px;
  }
  & .rbc-time-slot {
    height: calc(${weekSlotHeight}px / 4);
  }
  & .rbc-timeslot-group {
    height: ${weekSlotHeight}px;
  }
  & .rbc-time-header-cell {
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    grid-gap: 0px;
    display: grid;
    ${media.lg} {
      height: 75px;
    }
    a {
      text-decoration: none;
      color: ${colors.grey};
      pointer-events: none;
      font-size: 16px;
    }
  }
  & .rbc-time-header-cell :first-child {
    border-radius: 15px 0 0 0;
  }
  & .rbc-time-header-cell :not(:first-child) {
    border-radius: 0px;
  }
  & .rbc-day-slot > .rbc-timeslot-group > .rbc-time-slot {
    background-color: white;
    border-top: 0.5px solid ${colors.white};
  }
  // & .rbc-day-slot > .rbc-timeslot-group > .rbc-time-slot:hover {
  //   border-top: 5.5px solid ${colors.lime};
  // }
  & .rbc-day-slot > .rbc-timeslot-group {
    background-color: white;
    border-top: 0.5px solid ${colors.veryLightGrey};
  }
  & .rbc-timeslot-group {
    height: ${weekSlotHeight + 1}px;
  }
  & .rbc-header {
    border: 1px solid ${colors.veryLightGrey};
    border-radius: 15px 15px 0px 0px;
    background-color: white;
    align-items: center;
    border-bottom: none;
    display: grid;
  }
  & .rbc-event {
    margin: 2px 4px;
    margin: 0px;
  }
  & .rbc-event-content {
    margin: 0px;
    width: 100%;
  }
  & .rbc-events-container {
    margin: 0px 3px;
    box-sizing: border-box;
    width: 95%;
  }
  & .rbc-time-header-content {
    background-color: transparent;
    grid-template-columns: 1fr;
    display: grid;
    border: none;
    margin-right: 0px;
  }
  & .active0, .active1, .active2, .active3, .active4 {
    & .rbc-today {
      border: 3px solid ${colors.magenta};
      border-top: none;
      border-bottom: none;
    }
    & .rbc-time-header-content .rbc-today {
      border-left: 3px solid ${colors.magenta};
      border-right: 3px solid ${colors.magenta};
      box-sizing: border-box;
      border-top: none;
      border-bottom: none;
      margin-right: 0px;
    }
  }
`}


${({ $slotNr, $weekView }) =>
    $weekView &&
    $slotNr > 1 &&
    `
    & .rbc-event {
      width: calc(${100 / $slotNr}% - 0px)!important;
    }
  `}
`

export const PagerDiv = styled('div')<{ $direction: string; $visible?: boolean }>`
  background-color: rgba(0, 92, 83, 0.3);
  transition: opacity 0.2s ease-in-out;
  grid-template-columns: auto;
  height: calc(100% - 262px);
  height: 715px;
  justify-items: center;
  align-items: center;
  position: absolute;
  display: none;
  width: 80px;
  z-index: 55;
  top: 190px;
  opacity: 0;
  & :after {
    border-left: 2px dashed rgba(0, 88, 77, 0.2);
    transform: translateX(-5px);
    position: absolute;
    height: 100%;
    content: '';
    width: 5px;
    left: 0px;
    top: 0px;
  }
  ${({ $direction }) =>
    $direction === 'prev' &&
    `
    transform: rotate(180deg);
    left: 40px;
  `}
  ${({ $direction }) =>
    $direction === 'next' &&
    `
    right: 0px;
  `}
  ${({ $visible }) =>
    !!$visible &&
    `
    display: grid;
    opacity: 1;
  `}
`

export const ControlPanelWrapper = styled(Grid)<{ $ongoing?: boolean }>`
  ${media.xl} {
    justify-content: end;
    justify-self: end;
    justify-items: end;
    align-items: center;
    z-index: 30;
    width: auto;
    margin-right: 0;
    margin-left: auto;
    position: relative;
    ${({ $ongoing }) =>
      !$ongoing &&
      `
    margin-bottom: -90px;
    max-width: 400px;
    margin-top: 10px;
    
    `}
    ${({ $ongoing }) =>
      !!$ongoing &&
      `
      max-width: 600px;
      margin-bottom: -100px;
    `}
  }
`

export const Loading = styled.div`
  position: absolute;
  z-index: 3;
  pointer-events: none;
  height: 100%;
  max-height: 100vh;
  width: 95%;
  display: grid;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
`
