import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { InputAdornment, TableContainer, LinearProgress, TableCell, TableRow, Table, Paper } from '@material-ui/core'
import {
  setCustomerProfileId,
  setVehicleId,
  setVehicleSize,
  setLicensePlate,
} from '../../actions/newReservationActions'
import { StyledTableBody, StyledTableCell, StyledTableHead } from '../../components/layout/table/table'
import { Grid, Icon, LicensePlate, Text, TextBlock } from '../../components/layout'
import { TextInput } from '../../components/layout/form/form.styles'
import { findProfile } from '../../actions/findProfileActions'
import { useDebounce } from '../../helpers/debounce.helper'
import { StickySearchBar } from './customerSelector.styles'
import { IStore } from '../../interfaces/store.interfaces'
import { langMap } from '../../helpers/language.helper'

const CustomerSelector = (): ReactElement => {
  const [isSearching, setIsSearching] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const newProfileResult = useSelector((state: IStore) => state.uiStates.universalResultModal)
  const customerProfileId = useSelector((state: IStore) => state.newOrderDetails.selectedProfileId)
  const vehicleId = useSelector((state: IStore) => state.newOrderDetails.vehicleId)
  const profileList = useSelector((state: IStore) => state.profileList)
  const user = useSelector((state: IStore) => state.user)
  const userLang = user.user?.language || 'en'
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (debouncedSearchValue && debouncedSearchValue.length >= 3) {
      setIsSearching(true)
      dispatch(findProfile(debouncedSearchValue))
    }
  }, [debouncedSearchValue])

  useEffect(() => {
    setIsSearching(false)
  }, [profileList])

  // UPDATE SEARCH RESULTS IF A NEW USER IS SUCCESSFULY ADDED
  useEffect(() => {
    if (
      !!newProfileResult.open &&
      newProfileResult.type === 'SUCCESS' &&
      debouncedSearchValue &&
      debouncedSearchValue.length >= 3
    ) {
      dispatch(findProfile(debouncedSearchValue))
    }
  }, [newProfileResult])

  return (
    <Grid>
      {!!isSearching && <LinearProgress color="primary" />}
      <StickySearchBar>
        <TextInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          placeholder={t('FIND_BY_CUSTOMER_OR_LICENSEPLATE')}
          label={t('CUSTOMER_OR_LICENSEPLATE')}
          variant="outlined"
          id="user-search"
          $semiRounded
          $search
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon $size="19px" $search />
              </InputAdornment>
            ),
            type: 'search',
          }}
        />
      </StickySearchBar>
      {profileList.length ? (
        <TableContainer elevation={0} component={Paper}>
          <Table size="medium">
            <StyledTableHead>
              <TableRow>
                <TableCell>{t('LICENSE_PLATE')}</TableCell>
                <StyledTableCell $mobileHidden>{t('NAME')}</StyledTableCell>
                <StyledTableCell $mobileHidden>{t('ADDRESS')}</StyledTableCell>
                <StyledTableCell $mobileHidden>{t('LAST_ORDER')}</StyledTableCell>
                <StyledTableCell $mobileVisible>{t('CONTACT')}</StyledTableCell>
              </TableRow>
            </StyledTableHead>
            <StyledTableBody $interactive>
              {profileList.map(({ id: cId, firstName, lastName, vehicle, contacts }) => (
                <TableRow
                  selected={customerProfileId === cId && vehicleId === vehicle?.id}
                  key={`${cId}${vehicle?.id}`}
                  onClick={() => {
                    dispatch(setCustomerProfileId(cId))
                    dispatch(setVehicleSize(vehicle?.size))
                    dispatch(setVehicleId(vehicle?.id))
                    dispatch(setLicensePlate(vehicle?.licensePlate))
                  }}
                >
                  <TableCell>
                    {vehicle?.licensePlate && (
                      <LicensePlate variant="inherit" $search>
                        {vehicle?.licensePlate}
                      </LicensePlate>
                    )}
                  </TableCell>
                  <StyledTableCell $mobileHidden>{`${firstName} ${lastName}`}</StyledTableCell>
                  <StyledTableCell $mobileHidden>
                    <Text $weight={600} $xxsSize="16px">
                      {!!contacts?.length && contacts.find(({ channel }) => channel === 'EMAIL')?.value}
                    </Text>
                    <Text $xxsSize="12px">
                      {!!contacts?.length && contacts.find(({ channel }) => channel === 'PHONE')?.value}
                    </Text>
                  </StyledTableCell>
                  <StyledTableCell $mobileHidden>
                    {!!vehicle?.lastOrder &&
                      moment(vehicle?.lastOrder)
                        .locale(langMap[userLang] || langMap.en)
                        .format('MMM DD. hh:mm')}
                  </StyledTableCell>
                  <StyledTableCell $mobileVisible>
                    <Text $weight={600} $xxsSize="14px">
                      {`${firstName} ${lastName}`}
                    </Text>
                    <Text $xxsSize="9px">
                      {!!contacts?.length && contacts.find(({ channel }) => channel === 'EMAIL')?.value}
                    </Text>
                  </StyledTableCell>
                </TableRow>
              ))}
            </StyledTableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid $alignItems="center" $justifyItems="center" $marginTop="60px" justifyItems="center">
          <Grid $xsCol="400px" justifyItems="center">
            <Icon $search $size="50px" $width="50px" />
            <TextBlock $size="16px" $align="center" dangerouslySetInnerHTML={{ __html: t('SEARCH_INFO') }} />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
export default CustomerSelector
