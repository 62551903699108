import { statistics as serviceName } from '../actions'
import { IStatistics } from '../interfaces/statistics.interfaces'
import { IAction } from '../interfaces/store.interfaces'

const initialState = {} as IStatistics

export const getStatisticsReducer = (state = initialState, action: IAction): IStatistics => {
  const { type, payload } = action
  switch (type) {
    case `GET_${serviceName}_BEGIN`:
      return state
    case `GET_${serviceName}_SUCCESS`:
      return payload
    case `GET_${serviceName}_FAILED`:
      return state
    default:
      return state
  }
}
