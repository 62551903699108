/* eslint-disable no-nested-ternary */
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { API_URI, WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY } from '../../../config'
import { getReservationById, getReservations } from '../../../actions/getReservationsActions'
import { getCancelledReservations } from '../../../actions/getCancelledReservationsActions'
import { getTodaysReservations } from '../../../actions/getTodaysReservationsActions'
import { ButtonWrapper, Button } from './detailsActionButton.styles'
import { IStore } from '../../../interfaces/store.interfaces'
import { headers } from '../../../helpers/headers.helper'
import { colors } from '../../../theme.styles'
import {
  toggleFinishWithExistingCustomerModal,
  toggleCompleteWithNewCustomerModal,
  deleteOngoingStampTime,
  toggleCancelWashModal,
  setOngoingStampTime,
} from '../../../actions/uiStatesAction'
import { Icon, Grid, Text } from '..'

export interface IDetailsActionButton {
  startTime?: string | Date
  onDetailsPage?: boolean
  reservationId?: number
  licenseNumber?: string
  workingTime?: number
  lastStatus?: string
  duration?: number
}

const DetailsActionButton = ({
  reservationId,
  licenseNumber,
  onDetailsPage,
  workingTime,
  lastStatus,
  startTime,
  duration,
}: IDetailsActionButton): ReactElement => {
  const [now, setNow] = useState<number>()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const washerId = cookies[WASHERID_COOKIE_KEY]
  const stampTimes = useSelector((state: IStore) => state.uiStates.stampTimes)
  const dayOffset = useSelector((state: IStore) => state.uiStates.dayOffset)
  const dispatch = useDispatch()
  const postUrl = `${API_URI}/washers/${washerId}/reservations/${reservationId}/status`
  const endTime = moment(startTime).add(duration, 'minutes')
  const currentStampTime = stampTimes.find((time) => time.reservationId === reservationId)
  const daysToGet = calView === 'week' ? 7 : 1

  // IF THE RESERVATION IS ONGOING BUT THIS ID HAS NO STAMPTIME CREATE ONE
  useEffect(() => {
    if (lastStatus === 'ON_GOING') {
      if (!currentStampTime) {
        dispatch(setOngoingStampTime(reservationId, workingTime))
      }
    }
  }, [])

  // NOWTIME FOR TIME LEFT
  useEffect(() => {
    const nowTime = setInterval(() => setNow(Date.now()), 1000)
    return () => {
      clearInterval(nowTime)
    }
  }, [])
  const minutesLeft = moment(endTime).diff(moment(now), 'minutes')
  const secondsLeft = moment(endTime).diff(moment(now), 'seconds') % 60

  const minutesPassed = Math.floor(
    moment(now).diff(moment(currentStampTime?.startTime), 'seconds') / 60 +
      (currentStampTime?.previousDuration || 0) / 60,
  )
  const secondsPassed = Math.floor(
    (moment(now).diff(moment(currentStampTime?.startTime), 'seconds') + (currentStampTime?.previousDuration || 0)) % 60,
  )

  // washing state modifier function. give it a string of 'start' | 'finish' | 'cancel' | 'pause'
  const onModifyState = async (action: string) => {
    setLoading(true)
    const response = await fetch(`${postUrl}/${action}`, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
    })

    const result = await response.json()
    // on details page
    if (result && onDetailsPage && washerId) {
      dispatch(getReservationById(washerId, reservationId?.toString()))
      setLoading(false)
    }
    // on calendar page
    if (result && !onDetailsPage && washerId) {
      dispatch(getTodaysReservations(washerId))
      setLoading(false)
      if (calView === 'day') {
        dispatch(getReservations(washerId, dayOffset, daysToGet))
        dispatch(getCancelledReservations(washerId, dayOffset, daysToGet))
      }
      if (calView === 'week') {
        const weekStartOffsetFromToday = moment().add(dayOffset, 'day').weekday()
        const weekStartOffsetFromDate = weekStartOffsetFromToday * -1 + dayOffset
        dispatch(getReservations(washerId, weekStartOffsetFromDate, daysToGet))
        dispatch(getCancelledReservations(washerId, weekStartOffsetFromDate, daysToGet))
      }
    }
    if (result && action === 'start') {
      dispatch(setOngoingStampTime(reservationId, workingTime))
    }
    if (result && action !== 'start') {
      dispatch(deleteOngoingStampTime(reservationId))
    }
  }

  return (
    <ButtonWrapper $loading={loading} $active={lastStatus === 'ON_GOING'} $completed={lastStatus === 'COMPLETED'}>
      <Grid $xxsCol="auto 1fr auto auto auto" $alignItems="center">
        {!loading && (lastStatus === 'NEW' || lastStatus === 'PAUSED') && (
          <>
            <Button title="Start" onClick={() => onModifyState('start')}>
              <Icon $play $height="30px" $width="30px" />
            </Button>
            <Text
              $textColor={loading ? colors.lightGrey : 'black'}
              $margin="0 8px 0 0"
              $xxsSize="12px"
              $lgSize="16px"
              $weight={600}
            >
              {t('START_WASHING')}
            </Text>
          </>
        )}

        {!loading && lastStatus === 'ON_GOING' && (
          <>
            <Button title="Pause" onClick={() => onModifyState('pause')}>
              <Icon $pause $height="30px" $width="30px" />
            </Button>
            <Text
              $textColor={loading ? colors.lightGrey : 'black'}
              $margin="0 8px 0 0"
              $xxsSize="12px"
              $lgSize="16px"
              $weight={600}
              noWrap
            >
              {t('PAUSE_WASHING')}
            </Text>
          </>
        )}

        {!loading && (lastStatus === 'NEW' || lastStatus === 'PAUSED') && (
          <Grid $xxsCol="auto auto" $alignItems="center">
            <Button title={t('CANCEL_WASHING')} onClick={() => dispatch(toggleCancelWashModal(reservationId))}>
              <Icon $cancel $height="30px" $width="30px" />
            </Button>
            <Text
              $textColor={loading ? colors.lightGrey : 'black'}
              $margin="0 8px 0 0"
              $xxsSize="12px"
              $lgSize="16px"
              $weight={600}
            >
              {t('CANCEL_WASHING')}
            </Text>
          </Grid>
        )}

        {!loading && lastStatus === 'COMPLETED' && !!licenseNumber && (
          <>
            <Button
              title={t('FINISH_WASHING')}
              onClick={() => dispatch(toggleFinishWithExistingCustomerModal(reservationId, onDetailsPage))}
            >
              <Icon $checkboxOn $height="24px" $width="24px" />
            </Button>
            <Text
              $textColor={loading ? colors.lightGrey : 'black'}
              $margin="0 8px 0 0"
              $xxsSize="12px"
              $lgSize="16px"
              $weight={600}
            >
              {t('FINISH_WASHING')}
            </Text>
          </>
        )}

        {!loading && lastStatus === 'ON_GOING' && !!licenseNumber && (
          <>
            <Button
              title={t('COMPLETE_WASHING')}
              onClick={() => {
                onModifyState('complete')
                dispatch(toggleFinishWithExistingCustomerModal(reservationId, onDetailsPage))
              }}
            >
              <Icon $checkboxOn $height="24px" $width="24px" />
            </Button>
            <Text
              $textColor={loading ? colors.lightGrey : 'black'}
              $margin="0 8px 0 0"
              $xxsSize="12px"
              $lgSize="16px"
              $weight={600}
            >
              {t('COMPLETE_WASHING')}
            </Text>
          </>
        )}

        {!loading && lastStatus === 'ON_GOING' && !licenseNumber && (
          <>
            <Button
              title="Finish"
              onClick={() => dispatch(toggleCompleteWithNewCustomerModal(reservationId, onDetailsPage))}
            >
              <Icon $checkboxOn $height="24px" $width="24px" />
            </Button>
            <Text
              $textColor={loading ? colors.lightGrey : 'black'}
              $margin="0 8px 0 0"
              $xxsSize="12px"
              $lgSize="16px"
              $weight={600}
            >
              {t('COMPLETE_WASHING')}
            </Text>
          </>
        )}

        {loading && (
          <Button title="Loading...">
            <Icon $glasshour $height="30px" $width="30px" />
          </Button>
        )}
        <Grid $xxsCol="auto auto" $gap="10px" $alignItems="center">
          <Grid $xsCol={1} $alignItems="center" $gap="0px">
            <Text noWrap $xxsSize="12px" $lgSize="14px">
              {t('TIME_LEFT')}
            </Text>
            <Text
              $textColor={loading ? colors.lightGrey : 'black'}
              $weight={500}
              $xxsSize="16px"
              $lgSize="20px"
              $align="right"
              $mono
            >
              {!!duration && minutesLeft >= duration
                ? `${duration}:00`
                : minutesLeft < 0
                ? '00:00'
                : `${minutesLeft}:${secondsLeft > 9 ? secondsLeft : `0${secondsLeft}`}`}
            </Text>
          </Grid>
          {lastStatus === 'ON_GOING' && (
            <Grid $xsCol={1} $alignItems="center" $gap="0px">
              <Text $xxsSize="12px" $lgSize="14px">
                {t('TIME_PASSED')}
              </Text>
              <Text
                $textColor={loading ? colors.lightGrey : 'black'}
                $weight={500}
                $xxsSize="16px"
                $lgSize="20px"
                $align="right"
                $mono
              >
                {minutesPassed < 0
                  ? '00:00'
                  : `${minutesPassed}:${secondsPassed > 9 ? secondsPassed : `0${secondsPassed}`}`}
              </Text>
            </Grid>
          )}
        </Grid>
      </Grid>
    </ButtonWrapper>
  )
}
export default DetailsActionButton
