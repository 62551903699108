import { findProfile as serviceName } from '../actions'
import { IAction, IProfile } from '../interfaces/store.interfaces'

const initialState = [] as IProfile[]

export const findProfileReducer = (state = initialState, action: IAction): IProfile[] => {
  const { type, payload } = action
  switch (type) {
    case `GET_${serviceName}_BEGIN`:
      return state
    case `GET_${serviceName}_SUCCESS`:
      return payload
    case `EMPTY_PROFILE_LIST_SUCCESS`:
      return payload
    case `GET_${serviceName}_FAILED`:
      return state
    default:
      return state
  }
}
