import { Card, Box } from '@material-ui/core'
import styled from 'styled-components/macro'
import { media } from '.'
import { colors, fonts } from '../../theme.styles'
import { Grid } from './grid'

export const ModalWrapper = styled(Card)<{ $small?: boolean; $fullWidth?: boolean }>`
  border: 1px solid ${colors.veryLightGrey};
  transform: perspective(1px) translate3d(-50%, -50%, 0);
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  grid-template-rows: auto 1fr auto;
  border-radius: 15px;
  position: absolute;
  display: grid;
  left: 50%;
  top: 50%;

  max-width: 100%;
  max-height: 99%;
  min-width: 200px;
  width: 99%;
  min-height: 100px;
  ${media.md} {
    width: auto;
    min-width: 750px;
    min-height: 300px;
    max-width: 100%;
  }
  ${({ $small }) =>
    $small &&
    `
   min-width: 200px;
   max-width: 100%;
   ${media.md} {
     min-width: 300px;
     max-width: 480px;
   }
  `}
  ${({ $fullWidth }) =>
    $fullWidth &&
    `
    width: 100%!important;
    height: 100%!important;
    min-width: none;
    max-width: none;
  `}
`

export const ModalHeader = styled(Box)<{ $small?: boolean }>`
  border-bottom: 1px solid ${colors.veryLightGrey};
  position: relative;
  padding: 7px 15px;
  box-sizing: border-box;
  ${media.md} {
    padding: 20px 40px;
  }
  ${({ $small }) =>
    $small &&
    `
  padding-right: 60px;
  border-bottom: none;
  ${media.md} {
    padding: 20px 40px;
  }
  `}
`

export const ModalCloseBtn = styled.button`
  background-color: transparent;
  width: 36px;
  height: 36px;
  color: ${colors.grey};
  position: absolute;
  appearance: none;
  font-size: 30px;
  outline: none;
  border: none;
  right: 0px;
  top: 10px;
  ${media.md} {
    right: 30px;
    top: 20px;
  }
  &:hover {
    color: ${colors.black};
  }
`

export const ModalBody = styled(Box)`
  font-family: ${fonts.main};
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch;
  height: 100%;
`

export const ModalNavigation = styled(Box)`
  border-top: 1px solid ${colors.veryLightGrey};
  padding: 6px 15px;
  bottom: 0;
  position: sticky;
  ${media.lg} {
    padding: 16px 30px;
  }
`

export const BreadCrumb = styled.button`
  background-color: transparent;
  color: ${colors.grey};
  appearance: none;
  font-size: 12px;
  ${media.lg} {
    font-size: 14px;
  }
  border: none;
  &:hover {
    text-decoration: underline;
  }
`

export const TaskDescription = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  ${media.lg} {
    font-size: 14px;
  }
`

export const ButtonWrapper = styled.div`
  display: grid;
  max-width: 260px;
  gap: 8px;
`

export const ModalResultContent = styled(Grid)`
  grid-gap: 10px;
  padding: 30px;
  ${media.lg} {
    grid-gap: 30px;
    padding: 60px;
  }
`

export const ImageModalWrapper = styled.div`
  position: relative;
  overflow: hidden;
  outline: none;
  display: grid;
  height: 95vh;
  margin: auto;
  width: 95vw;
  top: 2.5%;
`
