/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useState } from 'react'
import { Modal } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'
import { toggleCancelNewReservationModal, toggleNewReservationModal } from '../../../actions/uiStatesAction'
import { ModalWrapper, ModalHeader, ModalBody, ModalResultContent } from '../../../components/layout/modal'
import { Text, Grid, TextBlock, Button } from '../../../components/layout'
import { setNewOrderEmpty } from '../../../actions/newReservationActions'
import { IStore } from '../../../interfaces/store.interfaces'
import { emptyProfileList } from '../../../actions/findProfileActions'

const CancelNewReservationModal = (): ReactElement => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isOpen = useSelector((state: IStore) => state.uiStates.cancelNewReservationModal.open)

  const onCancelNewReservation = async () => {
    dispatch(setNewOrderEmpty())
    dispatch(toggleNewReservationModal())
    dispatch(toggleCancelNewReservationModal())
    dispatch(emptyProfileList())
  }

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper $small>
          <ModalHeader>
            <Text $mdSize="30px" $xxsSize="20px" $weight={500}>
              {t('CANCEL_NEW_RESERVATION_TITLE')}
            </Text>
          </ModalHeader>
          <ModalBody>
            <ModalResultContent $gap="40px">
              <TextBlock dangerouslySetInnerHTML={{ __html: t('CANCEL_NEW_RESERVATION_DESCRIPTION') }} $size="18px" />
              <Grid $xxsCol={2}>
                <Button
                  onClick={() => onCancelNewReservation()}
                  disabled={!!loading}
                  variant="contained"
                  color="primary"
                >
                  {loading ? t('LOADING_BTN') : t('OK_CANCEL')}
                </Button>
                <Button onClick={() => dispatch(toggleCancelNewReservationModal())} color="secondary" variant="text">
                  {t('NO_CANCEL')}
                </Button>
              </Grid>
            </ModalResultContent>
          </ModalBody>
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default CancelNewReservationModal
