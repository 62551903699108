export const slots = [
  { slot: '1', resourceTitle: '01' },
  { slot: '2', resourceTitle: '02' },
  { slot: '3', resourceTitle: '03' },
  { slot: '4', resourceTitle: '04' },
  { slot: '5', resourceTitle: '05' },
  { slot: '6', resourceTitle: '06' },
  { slot: '7', resourceTitle: '07' },
  { slot: '8', resourceTitle: '08' },
]
