import styled from 'styled-components/macro'
import Search from '../../assets/magnify.svg'
import AccordionDown from '../../assets/icon_accordion_down.svg'
import CheckboxOn from '../../assets/checkbox_on.svg'
import CheckboxOff from '../../assets/checkbox_off.svg'
import Time from '../../assets/time-clock-circle.svg'
import Logo from '../../assets/logo.svg'
import MenuDown from '../../assets/arrow_button_green_down.svg'
import Logout from '../../assets/icon_logout.svg'
import Users from '../../assets/icon_users.svg'
import Car from '../../assets/car.svg'
import Play from '../../assets/button-play.svg'
import Pause from '../../assets/button-pause.svg'
import Cancel from '../../assets/icon-cancel.svg'
import Stop from '../../assets/icon_stop.svg'
import Galsshour from '../../assets/icon_glasshour.svg'
import Plus from '../../assets/icon_add.svg'
import PlusGray from '../../assets/icon_add_gray.svg'
import TrashGray from '../../assets/icon_trash_gray.svg'
import Success from '../../assets/icon_success.svg'
import { IIcon } from '../../interfaces/layout.interfaces'
import CarGreenL from '../../assets/car/car_icon_green_L.svg'
import CarGreenM from '../../assets/car/car_icon_green_M.svg'
import CarGreenS from '../../assets/car/car_icon_green_S.svg'
import CarGreenXL from '../../assets/car/car_icon_green_XL.svg'
import CarGreenXXL from '../../assets/car/car_icon_green_XXL.svg'
import CarWhiteL from '../../assets/car/car_icon_white_L.svg'
import CarWhiteM from '../../assets/car/car_icon_white_M.svg'
import CarWhiteS from '../../assets/car/car_icon_white_S.svg'
import CarWhiteXL from '../../assets/car/car_icon_white_XL.svg'
import CarWhiteXXL from '../../assets/car/car_icon_white_XXL.svg'
import Eye from '../../assets/icon_eye.svg'
import EyeGreen from '../../assets/icon_eye_green.svg'

export const Icon = styled('i')<IIcon>`
  background-repeat: no-repeat;
  position: relative;
  display: block;

  ${({ $position }) =>
    $position &&
    `
    position: ${$position};
  `}
  ${({ $size = '20px' }) =>
    $size &&
    `
    width: ${$size};
    height: ${$size};
  `}
  ${({ $search }) =>
    $search &&
    `
    background: url(${Search});
  `}
  ${({ $accordionDown }) =>
    $accordionDown &&
    `
  background: url(${AccordionDown});
  `}
  ${({ $checkboxOn }) =>
    $checkboxOn &&
    `
  background: url(${CheckboxOn});
  `}
  ${({ $checkboxOff }) =>
    $checkboxOff &&
    `
  background: url(${CheckboxOff});
  `}
  ${({ $time }) =>
    $time &&
    `
    background: url(${Time});
  `}
  ${({ $logo }) =>
    $logo &&
    `
    background: url(${Logo}) no-repeat center;
  `}
  ${({ $logout }) =>
    $logout &&
    `
    background: url(${Logout}) no-repeat center;
  `}
  ${({ $users }) =>
    $users &&
    `
    background: url(${Users}) no-repeat center;
  `}
  ${({ $menuDown }) =>
    $menuDown &&
    `
    background: url(${MenuDown}) no-repeat center;
  `}
  ${({ $play }) =>
    $play &&
    `
    background: url(${Play}) no-repeat center;
  `}
  ${({ $pause }) =>
    $pause &&
    `
    background: url(${Pause}) no-repeat center;
  `}
  ${({ $cancel }) =>
    $cancel &&
    `
    background: url(${Cancel}) no-repeat center;
  `}
  ${({ $stop }) =>
    $stop &&
    `
    background: url(${Stop}) no-repeat center;
  `}
  ${({ $glasshour }) =>
    $glasshour &&
    `
    background: url(${Galsshour}) no-repeat center;
  `}
  ${({ $car }) =>
    $car &&
    `
    background: url(${Car}) no-repeat center;
  `}
  ${({ $plus }) =>
    $plus &&
    `
    background: url(${Plus}) no-repeat center;
  `}
  ${({ $plusGray }) =>
    $plusGray &&
    `
    background: url(${PlusGray}) no-repeat center;
  `}
  ${({ $trashGray }) =>
    $trashGray &&
    `
    background: url(${TrashGray}) no-repeat center;
  `}
  ${({ $eye }) =>
    $eye &&
    `
    background: url(${Eye}) no-repeat center;
  `}
  ${({ $eyeGreen }) =>
    $eyeGreen &&
    `
    background: url(${EyeGreen}) no-repeat center;
  `}
  ${({ $success }) =>
    $success &&
    `
    background: url(${Success}) no-repeat center;
  `}
  ${({ $width }) =>
    $width &&
    `
    width: ${$width}!important;
  `}
  ${({ $height }) =>
    $height &&
    `
    height: ${$height}!important;
  `}

  ${({ $carGreenL }) =>
    $carGreenL &&
    `
  background: url(${CarGreenL}) no-repeat center;
`}
  ${({ $carGreenM }) =>
    $carGreenM &&
    `
  background: url(${CarGreenM}) no-repeat center;
`}
  ${({ $carGreenS }) =>
    $carGreenS &&
    `
  background: url(${CarGreenS}) no-repeat center;
`}
  ${({ $carGreenXL }) =>
    $carGreenXL &&
    `
  background: url(${CarGreenXL}) no-repeat center;
`}
  ${({ $carGreenXXL }) =>
    $carGreenXXL &&
    `
  background: url(${CarGreenXXL}) no-repeat center;
`}

  ${({ $carWhiteL }) =>
    $carWhiteL &&
    `
  background: url(${CarWhiteL}) no-repeat center;
`}
  ${({ $carWhiteM }) =>
    $carWhiteM &&
    `
  background: url(${CarWhiteM}) no-repeat center;
`}
  ${({ $carWhiteS }) =>
    $carWhiteS &&
    `
  background: url(${CarWhiteS}) no-repeat center;
`}
  ${({ $carWhiteXL }) =>
    $carWhiteXL &&
    `
  background: url(${CarWhiteXL}) no-repeat center;
`}
  ${({ $carWhiteXXL }) =>
    $carWhiteXXL &&
    `
  background: url(${CarWhiteXXL}) no-repeat center;
`}

  background-size: contain;
`
