import React, { ReactElement, useEffect, useState } from 'react'
import { Button, Modal } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'
import { useCookies } from 'react-cookie'
import { ModalWrapper, ModalHeader, ModalCloseBtn, ModalBody } from '../../../components/layout/modal'
import { toggleResetUserPasswordModal, toggleUniversalResultModal } from '../../../actions/uiStatesAction'
import { TextInput } from '../../../components/layout/form/form.styles'
import { API_URI, WASHERID_COOKIE_KEY } from '../../../config'
import { IStore } from '../../../interfaces/store.interfaces'
import { headers } from '../../../helpers/headers.helper'
import { Text, Grid } from '../../../components/layout'

const ResetUserPasswordModal = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const isOpen = useSelector((state: IStore) => state.uiStates.resetUserPasswordModal.open)
  const uID = useSelector((state: IStore) => state.uiStates.resetUserPasswordModal.uID)
  const users = useSelector((state: IStore) => state.users)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [password, setPassword] = useState('')
  const [userName, setUserName] = useState('')
  useEffect(() => {
    if (uID) {
      const selectedUser = users?.find(({ userId }) => userId === uID)
      if (selectedUser) {
        setUserName(`${selectedUser.firstName} ${selectedUser.lastName}`)
      }
    }
  }, [uID])

  const onSubmit = async () => {
    setLoading(true)

    const secret = btoa(password)
    const data = { secret }

    const postUrl = `${API_URI}/washers/${washerId}/users/${uID}/secret`
    const response = await fetch(postUrl, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })

    if (response.status !== 200) {
      setLoading(false)
      setError(true)
      dispatch(toggleUniversalResultModal('ERROR', 'RESET_PASSWORD_FAIL_TITLE', 'RESET_PASSWORD_FAIL_DESCRIPTION'))
    }
    if (response.status === 200) {
      setLoading(false)
      dispatch(toggleResetUserPasswordModal(uID))
      setUserName('')
      setPassword('')
      dispatch(
        toggleUniversalResultModal('SUCCESS', 'RESET_PASSWORD_SUCCESS_TITLE', 'RESET_PASSWORD_SUCCESS_DESCRIPTION'),
      )
    }
  }

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper $small>
          <ModalHeader $small>
            <Text $mdSize="30px" $xxsSize="20px" $weight={500} $align="center">
              {t('RESET_USER_PASSWORD_TITLE')}
            </Text>
            <ModalCloseBtn onClick={() => dispatch(toggleResetUserPasswordModal(uID))}>×</ModalCloseBtn>
          </ModalHeader>
          <ModalBody>
            <Grid $gap="20px" $padding="40px">
              <Text $size="16px" $align="center">
                {t('RESET_USER_PASSWORD_DESCRIPTION')}
              </Text>
              <Text $size="16px" $align="center">
                {userName}
              </Text>
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                placeholder={t('NEW_PASSWORD')}
                label={t('NEW_PASSWORD')}
                variant="outlined"
                id="us-pwr-d"
                $semiRounded
                $search
              />
              <Grid $mdCol={2}>
                <Button
                  onClick={() => onSubmit()}
                  variant="contained"
                  disabled={loading}
                  disableElevation
                  color="primary"
                >
                  {loading ? t('LOADING_BTN') : t('PASSWORD_BTN_CREATE')}
                </Button>
                <Button
                  onClick={() => dispatch(toggleResetUserPasswordModal(uID))}
                  variant="contained"
                  disabled={loading}
                  disableElevation
                  color="secondary"
                >
                  {t('PASSWORD_BTN_CANCEL')}
                </Button>
              </Grid>
              {!!error && <Text $textColor="red">{t('NEW_PASSWORD_ERROR')}</Text>}
            </Grid>
          </ModalBody>
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default ResetUserPasswordModal
