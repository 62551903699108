import styled from 'styled-components/macro'
import { Grid } from '../../components/layout'

export const HeaderBar = styled(Grid)`
  background-color: white;
  border-bottom: 1px solid #e1e7f0;
  position: sticky;
  top: 0;
  left: 0;
  backface-visibility: none;
  z-index: 2;
`
