import React, { ReactElement, useEffect } from 'react'
import { FormControlLabel, Paper, Switch, Table, TableCell, TableContainer, TableRow } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import { StyledTableBody, StyledTableHead } from '../../components/layout/table/table'
import { getMarketingCampaigns } from '../../actions/getMarketingCampaignsActions'
import { SettingsSheet } from '../../components/layout/commonUiElements'
import { Grid, Text, TextBlock } from '../../components/layout'
import { API_URI, WASHERID_COOKIE_KEY } from '../../config'
import { IStore } from '../../interfaces/store.interfaces'
import { headers } from '../../helpers/headers.helper'
import { langMap } from '../../helpers/language.helper'

const MarketingCampaignSettings = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const marketingCampaigns = useSelector((state: IStore) => state.marketingCampaigns)
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const user = useSelector((state: IStore) => state.user)
  const userLang = user.user?.language || 'en'

  useEffect(() => {
    dispatch(getMarketingCampaigns())
  }, [])

  const onEnable = async (marketingCampaignId: number) => {
    const data = {
      marketingCampaignId,
    }

    const postUrl = `${API_URI}/washers/${washerId}/marketing-campaigns`
    const response = await fetch(postUrl, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })
    if (response.status === 200) {
      dispatch(getMarketingCampaigns())
    }
  }

  const onDisable = async (marketingCampaignId: number) => {
    const postUrl = `${API_URI}/washers/${washerId}/marketing-campaigns/${marketingCampaignId}`
    const response = await fetch(postUrl, {
      method: 'DELETE',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
    })
    if (response.status === 200) {
      dispatch(getMarketingCampaigns())
    }
  }

  return (
    <Grid>
      <SettingsSheet $xxsCol="1fr auto">
        <Text $size="20px" $weight={600}>
          {t('MARKETING_CAMPAIGN_SETTINGS')}
        </Text>
      </SettingsSheet>
      {marketingCampaigns.length ? (
        <TableContainer elevation={0} component={Paper}>
          <Table size="medium">
            <StyledTableHead>
              <TableRow>
                <TableCell>{t('MARKETING_TABLE_TITLE')}</TableCell>
                <TableCell>{t('MARKETING_TABLE_DURATION')}</TableCell>
                <TableCell>{t('MARKETING_TABLE_PACKAGE')}</TableCell>
                <TableCell>{t('MARKETING_TABLE_PROMO_AMOUNT')}</TableCell>
                <TableCell>{t('ACTIVITY')}</TableCell>
              </TableRow>
            </StyledTableHead>
            <StyledTableBody>
              {marketingCampaigns.map(({ title, id: cID, startTime, endTime, assigned, services, promotionalCode }) => (
                <TableRow key={cID}>
                  <TableCell>{title}</TableCell>
                  <TableCell>
                    <span>{`${moment(startTime)
                      .locale(langMap[userLang] || langMap.en)
                      .format('YYYY. MMMM DD. HH:mm')} - `}</span>
                    {endTime ? (
                      <span>
                        {moment(endTime)
                          .locale(langMap[userLang] || langMap.en)
                          .format('YYYY. MMMM DD. HH:mm')}
                      </span>
                    ) : (
                      <span style={{ opacity: '30%' }}>{t('NO_ENDTIME')}</span>
                    )}
                  </TableCell>
                  <TableCell>
                    {!!services &&
                      services.map(
                        ({ surface = '', category }, i) =>
                          `${t(surface)} ${category && t(category?.title)}${i === services.length - 1 ? '' : ', '}`,
                      )}
                  </TableCell>
                  <TableCell>
                    {!!promotionalCode && promotionalCode.unit === 'COIN'
                      ? `${promotionalCode.amount} ${promotionalCode.currency}`
                      : `${promotionalCode?.amount}%`}
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={() => (assigned ? onDisable(cID) : onEnable(cID))}
                          checked={assigned}
                          color="primary"
                          name={title}
                        />
                      }
                      label=""
                    />
                  </TableCell>
                </TableRow>
              ))}
            </StyledTableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid $padding="40px 0px">
          <TextBlock $align="center" dangerouslySetInnerHTML={{ __html: t('NO_MARKETINGCAMPAIGNS') }} />
        </Grid>
      )}
    </Grid>
  )
}

export default MarketingCampaignSettings
