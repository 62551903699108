import React, { ReactElement, useEffect, useState } from 'react'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { CheckOutlined } from '@material-ui/icons'
import { KeyboardDateTimePickerInput, TextInput } from '../form/form.styles'
import { DowntimeWrapper, DtButtonWrapper } from './openHours.styles'
import { IDownTime } from '../../../interfaces/openHours.interfaces'
import { getDowntimes } from '../../../actions/getDowntimesActions'
import { API_URI, WASHERID_COOKIE_KEY } from '../../../config'
import { headers } from '../../../helpers/headers.helper'
import { Grid, Text, Icon } from '../index'

interface IDowntimeComponent {
  downtime: IDownTime
}

const Downtime = ({ downtime }: IDowntimeComponent): ReactElement => {
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = cookies[WASHERID_COOKIE_KEY]
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [downStart, setDownStart] = useState<string | null>()
  const [downEnd, setDownEnd] = useState<string | null>()
  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [title, setTitle] = useState('')

  const onModifyDowntime = async () => {
    setLoading(true)
    const startTime = moment(downStart).format('YYYY-MM-DDTHH:mm:ssZ')
    const durationInMinutes = moment(downEnd).diff(downStart, 'minutes')
    const modifyData = {
      durationInMinutes,
      startTime,
      title,
    }

    const postUrl = `${API_URI}/washers/${washerId}/downtimes/${downtime.id}`
    const response = await fetch(postUrl, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(modifyData),
    })
    if (response.status !== 200) {
      setLoading(false)
    }
    if (response.status === 200) {
      setLoading(false)
      dispatch(getDowntimes(washerId))
    }
  }

  const onDeleteDowntime = async () => {
    setLoading(true)
    const postUrl = `${API_URI}/washers/${washerId}/downtimes/${downtime.id}`
    const response = await fetch(postUrl, {
      method: 'DELETE',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
    })
    if (response.status !== 200) {
      setLoading(false)
    }
    if (response.status === 200) {
      setLoading(false)
      dispatch(getDowntimes(washerId))
    }
  }

  useEffect(() => {
    if (downtime) {
      setTitle(downtime.title)
      setDownStart(downtime?.startTime)
      setDownEnd(moment(downtime?.startTime).add(downtime?.durationInMinutes, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ'))
      setDirty(false)
    }
  }, [])

  useEffect(() => {
    if (downtime) {
      if (downtime.title !== title) {
        setDirty(true)
      }
      if (
        downtime.title === title &&
        moment(downtime?.startTime).add(downtime?.durationInMinutes, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ') ===
          downEnd &&
        downtime.startTime === downStart
      ) {
        setDirty(false)
      }
      if (downtime.startTime !== downStart) {
        setDirty(true)
      }
      if (
        moment(downtime?.startTime).add(downtime?.durationInMinutes, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ') !==
        downEnd
      ) {
        setDirty(true)
      }
    }
  }, [title, downStart, downEnd, downtime])

  return (
    <DowntimeWrapper $dirty={dirty} $mdCol="auto auto 1fr" $alignItems="center">
      <Grid $gap="20px">
        <TextInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
          error={!!title && title.length < 2}
          value={title}
          label={t('NAMING')}
          variant="outlined"
          name="title"
          type="text"
          id={`title${downtime.id}`}
          $form
        />
        <Grid>
          <Grid $mdCol="1fr auto 1fr" $alignItems="center" justifyItems="start" justifyContent="start" $gap="20px">
            <KeyboardDateTimePickerInput
              onChange={(date: MaterialUiPickersDate) => setDownStart(moment(date).format('YYYY-MM-DDTHH:mm:ssZ'))}
              KeyboardButtonProps={{ 'aria-label': 'change time' }}
              id={`start-picker${downtime.id}`}
              key={`start${downtime.id}`}
              inputVariant="outlined"
              label={t('DATE_FROM')}
              value={downStart}
              margin="none"
              ampm={false}
              $form
            />
            <Text $mdSize="20px" $margin="20px 0 0 0">
              {' - '}
            </Text>
            <KeyboardDateTimePickerInput
              onChange={(date: MaterialUiPickersDate) => setDownEnd(moment(date).format('YYYY-MM-DDTHH:mm:ssZ'))}
              KeyboardButtonProps={{ 'aria-label': 'change time' }}
              id={`end-picker${downtime.id}`}
              key={`end${downtime.id}`}
              inputVariant="outlined"
              label={t('DATE_TO')}
              value={downEnd}
              margin="none"
              ampm={false}
              $form
            />
          </Grid>
        </Grid>
      </Grid>
      <DtButtonWrapper className="btns" $xxsCol="auto auto" $justifyContent="start">
        <Button onClick={() => onDeleteDowntime()} title={t('DELETE_DOWNTIME_BTN')} disabled={!!loading} size="small">
          <Grid $xxsCol="auto auto" $alignItems="center" $gap="7px">
            <Icon $trashGray $size="20px" $width="20px" $height="20px" />
            <Text $lettercase="none">{t('DELETE_DOWNTIME_BTN')}</Text>
          </Grid>
        </Button>
        <Button
          title={t('SAVE_NEW_DOWNTIME_BTN')}
          onClick={() => onModifyDowntime()}
          disabled={!!loading || !dirty}
          size="small"
        >
          <Grid $xxsCol="auto auto" $alignItems="center" $gap="7px">
            <CheckOutlined fontSize="medium" />
            <Text $lettercase="none">{t('SAVE_NEW_DOWNTIME_BTN')}</Text>
          </Grid>
        </Button>
      </DtButtonWrapper>
    </DowntimeWrapper>
  )
}

export default Downtime
