export const findProfile = 'PROFILE'
export const cancelledReservations = 'CANCELLED_RESERVATIONS'
export const todaysreservations = 'TODAYS_RESERVATIONS'
export const selectorReservations = 'SELECTOR_RESERVATIONS'
export const marketingCampaigns = 'MARKETING_CAMPAIGNS'
export const basicInformation = 'BASIC_INFORMATION'
export const newReservation = 'NEW_RESERVATION'
export const washerHistory = 'WASHER_HISTORY'
export const ureservations = 'URESERVATIONS'
export const workStatuses = 'WORK_STATUSES'
export const invitedUsers = 'Invited_USERS'
export const userSignup = 'USERSIGNUP'
export const statistics = 'STATISTICS'
export const promotions = 'PROMOTIONS'
export const openHours = 'OPEN_HOURS'
export const priceList = 'PRICE_LIST'
export const downtimes = 'DOWNTIMES'
export const packages = 'PACKAGES'
export const comment = 'COMMENT'
export const adress = 'ADDRESS'
export const userMe = 'USERME'
export const price = 'PRICE'
export const users = 'USERS'
export const user = 'USER'
