/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreator, Dispatch } from 'redux'
import { user as serviceName, userSignup as signupServiceName, userMe as userMeServiceName } from '.'
import { API_URI } from '../config'
import { headers } from '../helpers/headers.helper'

export const getUser: ActionCreator<any> = (credentials: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: `GET_${serviceName}_BEGIN`,
    })
    const loginHeader = `Basic ${credentials}`
    const res = await fetch(`${API_URI}/access`, { headers: { Authorization: loginHeader } })
    const data = await res.json()
    if (res.status !== 200) {
      dispatch({
        type: `GET_${serviceName}_FAILED`,
        payload: { error: data },
      })
    }
    if (res.status === 200) {
      dispatch({
        type: `GET_${serviceName}_SUCCESS`,
        payload: data,
      })
    }
  } catch (error) {
    dispatch({
      type: `GET_${serviceName}_FAILED`,
      payload: { error },
    })
  }
}

export const signupUser: ActionCreator<any> =
  (credentials: string, firstName?: string, lastName?: string, phone?: string, language?: string) =>
  async (dispatch: Dispatch) => {
    const dataToPost = {
      firstName,
      lastName,
      language,
      phone,
    }
    try {
      dispatch({
        type: `GET_${signupServiceName}_BEGIN`,
      })
      const loginHeader = `Basic ${credentials}`
      const res = await fetch(`${API_URI}/access/me`, {
        headers: { Authorization: loginHeader, 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(dataToPost),
      })
      const data = await res.json()
      if (res.status !== 200) {
        dispatch({
          type: `GET_${signupServiceName}_FAILED`,
          payload: { error: data },
        })
      }
      if (res.status === 200) {
        dispatch({
          type: `GET_${signupServiceName}_SUCCESS`,
          payload: data,
        })
      }
    } catch (error) {
      dispatch({
        type: `GET_${signupServiceName}_FAILED`,
        payload: { error },
      })
    }
  }

export const getUserMe: ActionCreator<any> = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: `GET_${userMeServiceName}_BEGIN`,
    })
    const res = await fetch(`${API_URI}/access/me`, {
      headers,
    })
    const data = await res.json()
    if (res.status !== 200) {
      dispatch({
        type: `GET_${userMeServiceName}_FAILED`,
        payload: { error: data },
      })
    }
    if (res.status === 200) {
      dispatch({
        type: `GET_${userMeServiceName}_SUCCESS`,
        payload: data,
      })
    }
  } catch (error) {
    dispatch({
      type: `GET_${userMeServiceName}_FAILED`,
      payload: { error },
    })
  }
}

export const clearUser: ActionCreator<any> = () => async (dispatch: Dispatch) => {
  dispatch({
    type: `CLEAR_${serviceName}_SUCCESS`,
    payload: {},
  })
}

export const setSelectedWasher: ActionCreator<any> = (id: number) => async (dispatch: Dispatch) =>
  dispatch({
    type: 'SET_SELECTED_WASHER_SUCCESS',
    payload: id,
  })
