export const newReservationSteps = [
  {
    id: 'sddsdd',
    number: 1,
    value: 'CUSTOMER_STEP',
  },
  {
    id: 'husdiuhs',
    number: 2,
    value: 'PACKAGE_STEP',
  },
  {
    id: 'sddsdd',
    number: 3,
    value: 'APPOINTMENT_STEP',
  },
  {
    id: 'sddqsdd',
    number: 4,
    value: 'SUMMARY_STEP',
  },
]

export const newCustomerSteps = [
  {
    id: 'sddsssdd',
    number: 1,
    value: 'NEW_CUSTOMER_STEP',
  },
]

export const finishWashSteps = [
  {
    id: 'sddsssdd',
    number: 1,
    value: 'FINISH_WASH_STEP',
  },
]

export const completeWashSteps = [
  {
    id: 'sddsssdd',
    number: 1,
    value: 'COMPLETE_WASH_STEP',
  },
]
