/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, ReactElement, useEffect } from 'react'
import { AccordionDetails, AccordionSummary, FormControlLabel, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { StyledAccordion } from '../../components/layout/accordion/styledAccordion'
import { StyledRadiogroup } from '../../components/layout/radio/radioGroup.styles'
import StyledRadioLabel from '../../components/layout/radio/styledRadioLabel'
import { ExpandIcon } from '../../components/layout/accordion/expandIcon'
import StyledRadio from '../../components/layout/radio/styledRadio'
import { Wrapper, Grid, Text } from '../../components/layout'
import { IStore } from '../../interfaces/store.interfaces'
import { getPromotions } from '../../actions/getPromotionsActions'
import { IPromotion } from '../../interfaces/promotion.interfaces'
import { WASHERID_COOKIE_KEY } from '../../config'

const Promotions = ({
  onHandlePromotion,
  onRemovePromotion,
  selectedValue,
}: {
  onHandlePromotion: (promo?: IPromotion) => any
  onRemovePromotion: (promo?: IPromotion) => any
  selectedValue?: string
}): ReactElement => {
  const promotions = useSelector((state: IStore) => state.promotions)
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (washerId) {
      dispatch(getPromotions(washerId))
    }
  }, [washerId])

  return (
    <div>
      {!!promotions?.length && (
        <Wrapper $margin="30px 20px">
          <StyledAccordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandIcon />}>
              <Grid $paddingLeft="10px">
                <Typography variant="h2">{t('PROMOTION_CODE')}</Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid $gap="20px" $marginBottom="20px">
                <Text $size="14px">{t('PROMOTION_DESCRIPTION')}</Text>
                <StyledRadiogroup value={selectedValue}>
                  <FormControlLabel
                    key={90006}
                    value={undefined}
                    onChange={() => {
                      onRemovePromotion(undefined)
                    }}
                    label={<StyledRadioLabel description={t('NO_PROMOTION')} />}
                    control={<StyledRadio />}
                  />
                  {!!promotions.length &&
                    promotions.map(({ id: promoId, currency, amount, unit }) => (
                      <FormControlLabel
                        key={promoId.toString()}
                        value={promoId.toString()}
                        onChange={(event: ChangeEvent<Record<string, never>>) => {
                          onHandlePromotion(
                            promotions.find((promotion) => promotion.id.toString() === event.target.value),
                          )
                        }}
                        label={
                          <StyledRadioLabel description={unit === 'COIN' ? `${amount} ${currency}` : `${amount}%`} />
                        }
                        control={<StyledRadio />}
                      />
                    ))}
                </StyledRadiogroup>
              </Grid>
            </AccordionDetails>
          </StyledAccordion>
        </Wrapper>
      )}
    </div>
  )
}
export default Promotions
