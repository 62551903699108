/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/macro'
import { Avatar, Box, Button, Divider, Paper, TextField } from '@material-ui/core'
import { Grid, media } from '../../components/layout'
import { colors } from '../../theme.styles'
import { userColors } from '../../mockdata/userColors'

export const PaperWrapper = styled(Paper)`
  border: 1px solid ${colors.veryLightGrey};
  border-radius: 15px;
`

export const HeaderSection = styled(Box)`
  border-bottom: 1px solid ${colors.veryLightGrey};
  padding: 17px 15px 14px 15px;
  ${media.lg} {
    padding: 42px 40px 14px 40px;
  }
`

export const MainSection = styled(Grid)`
  padding: 15px;
  grid-gap: 10px;
  ${media.lg} {
    padding: 30px 40px;
    grid-gap: 30px;
  }
`

export const TitleSection = styled(Grid)`
  margin: 30px 0px 20px 0px;
  ${media.lg} {
    margin: 30px 25px 20px 25px;
  }
`

export const Tags = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`

export const NavButton = styled(Button)<{ component?: any; to?: string; $fontSize?: string; $active?: boolean }>`
  border: 1px solid ${colors.veryLightGrey};
  background-color: white;
  border-radius: 6px;
  margin-right: 0px;
  padding: 5px 0px;
  & .MuiButton-label {
    font-size: ${({ $fontSize }) => $fontSize && `${$fontSize}`};
    text-transform: capitalize;
    color: ${colors.cyan};
    margin: 0px -5px;
    line-height: 1;
  }
  ${({ $active }) =>
    $active &&
    `
    background-color: ${colors.lime};
    & .MuiButton-label {
      color: white;
    }
  `}
`

export const NavigationWrapper = styled(Box)`
  alignitems: center;
  display: flex;
  margin: 25px 0;
  ${media.lg} {
    margin: 35px 0;
  }
`

export const Line = styled(Divider)`
  border-top: 1px solid ${colors.veryLightGrey};
`

export const HistoryItem = styled(Grid)`
  border: 1px solid ${colors.veryLightGrey};
  border-radius: 15px;
  padding: 20px;
`

export const AuthorAvatar = styled(Avatar)<{ $colorSelector?: number }>`
  height: 40px;
  width: 40px;
  ${({ $colorSelector }) =>
    $colorSelector &&
    `
 background-color: ${userColors[$colorSelector]};
`}
`

export const CommentField = styled(TextField)`
  width: 100%;
`
