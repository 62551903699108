import React, { ReactElement, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { ModalWrapper, ModalHeader, ModalCloseBtn, ModalBody } from '../../../components/layout/modal'
import { clearEventCalEditing, clearEventMoveModal } from '../../../actions/uiStatesAction'
import { getReservations } from '../../../actions/getReservationsActions'
import { TextInput } from '../../../components/layout/form/form.styles'
import { API_URI, WASHERID_COOKIE_KEY } from '../../../config'
import { IStore } from '../../../interfaces/store.interfaces'
import { langMap } from '../../../helpers/language.helper'
import { headers } from '../../../helpers/headers.helper'
import { Text, Grid } from '../../../components/layout'

const EventMoveModal = (): ReactElement => {
  const { t } = useTranslation()
  const [roundedDuration, setRoundedDuration] = useState('')
  const [loading, setLoading] = useState(false)
  const [duration, setDuration] = useState('0')

  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])

  const newStartTime = useSelector((state: IStore) => state.uiStates?.eventMoveModalOpen?.startTime)
  const draggedEvent = useSelector((state: IStore) => state.uiStates?.eventCalendarEditing)
  const isOpen = useSelector((state: IStore) => state.uiStates.eventMoveModalOpen?.open)
  const dayOffset = useSelector((state: IStore) => state.uiStates.dayOffset)
  const user = useSelector((state: IStore) => state.user)
  const userLang = user.user?.language || 'en'

  useEffect(() => {
    if (draggedEvent?.duration) {
      setDuration(draggedEvent.duration.toString())
    }
    if (roundedDuration) {
      setDuration(roundedDuration)
    }
  }, [draggedEvent, roundedDuration])

  const postUrl = `${API_URI}/washers/${washerId}/reservations/${draggedEvent?.id}/start-time`
  const data = { startTime: newStartTime, duration: Number(duration) }

  const onSubmit = async () => {
    setLoading(true)
    const response = await fetch(`${postUrl}`, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })

    if (response.status !== 200) {
      setLoading(false)
      dispatch(clearEventCalEditing())
      dispatch(clearEventMoveModal())
      setDuration('0')
      setRoundedDuration('')
    }
    if (response.status === 200) {
      setLoading(false)
      dispatch(clearEventCalEditing())
      dispatch(clearEventMoveModal())
      const weekStartOffsetFromToday = moment().add(dayOffset, 'day').weekday()
      const weekStartOffsetFromDate = weekStartOffsetFromToday * -1 + dayOffset
      dispatch(getReservations(washerId, weekStartOffsetFromDate, 7))
      setRoundedDuration('')
      setDuration('0')
    }
  }

  const onRoundUp = () => {
    const remainder = Number(duration) % 15
    const difference = 15 - remainder
    setRoundedDuration((Number(duration) + difference).toString())
  }

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper $small>
          <ModalHeader $small>
            <Text $mdSize="30px" $xxsSize="20px" $weight={500} $align="center">
              {t('MOVE_EVENT_TITLE')}
            </Text>
            <ModalCloseBtn
              onClick={() => {
                dispatch(clearEventMoveModal())
                dispatch(clearEventCalEditing())
              }}
            >
              ×
            </ModalCloseBtn>
          </ModalHeader>
          <ModalBody>
            <Grid $gap="20px" $padding="40px">
              <Text $size="16px" $align="center">
                {t('MOVE_EVENT_DESCRIPTION')}
              </Text>
              <Text $size="16px" $align="center" $weight={600}>
                {t('MOVE_EVENT_NEW_START_TIME')}
              </Text>
              <Text $size="20px" $align="center">
                {moment(newStartTime)
                  .locale(langMap[userLang] || langMap.en)
                  .format('YYYY.MMMM.DD HH:mm')}
              </Text>
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDuration(e.target.value)}
                placeholder={t('DURATION')}
                helperText={(Number(duration) % 15 > 0 || !duration.length) && t('MOVE_EVENT_DURATION_ERROR')}
                value={duration.toString()}
                error={Number(duration) % 15 > 0 || !duration.length}
                label={t('DURATION')}
                variant="outlined"
                id="duration"
                $semiRounded
                $search
              />
              <Button
                disabled={Number(duration) % 15 === 0 || !duration.length}
                onClick={() => onRoundUp()}
                variant="contained"
                disableElevation
                color="primary"
              >
                {loading ? t('LOADING_BTN') : t('ROUND_UP_DURATION_BTN')}
              </Button>
              <Button
                disabled={Number(duration) % 15 > 0 || !duration.length}
                onClick={() => onSubmit()}
                variant="contained"
                disableElevation
                color="primary"
              >
                {loading ? t('LOADING_BTN') : t('MOVE_EVENT_BTN')}
              </Button>
            </Grid>
          </ModalBody>
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default EventMoveModal
