/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useRef } from 'react'
import { headers } from '../../../helpers/headers.helper'
import { Thumbnail } from '../commonUiElements'
import { API_URI } from '../../../config'

const WashImage = ({ image, thumbnail }: { image: string; thumbnail?: boolean }): ReactElement => {
  const imageref = useRef<any>(null)
  const displayImage = async (imageKey: string) => {
    const postUrl = `${API_URI}/media/images${thumbnail ? '/thumbnail' : ''}?key=${imageKey}`
    const response = await fetch(postUrl, {
      method: 'GET',
      headers: { Accept: 'image/png', 'Content-Type': 'image/png', ...headers },
    })

    if (response) {
      const blob = await response.blob()
      const objectUrl = URL.createObjectURL(blob)
      if (imageref.current) {
        imageref.current.src = objectUrl
        imageref.current.onload = () => URL.revokeObjectURL(objectUrl)
      }
    }
  }
  useEffect(() => {
    displayImage(image)
  }, [image])
  return <Thumbnail ref={imageref} key={image} src="" id={image} />
}

export default WashImage
