import { priceList as serviceName } from '../actions'
import { IRegionalPriceList } from '../interfaces/price.interfaces'
import { IAction } from '../interfaces/store.interfaces'

const initialState: IRegionalPriceList | null = null

export const getRegionalPriceListReducer = (state = initialState, action: IAction): IRegionalPriceList | null => {
  const { type, payload } = action
  switch (type) {
    case `GET_${serviceName}_BEGIN`:
      return state
    case `GET_${serviceName}_SUCCESS`:
      return payload
    case `GET_${serviceName}_FAILED`:
      return state
    default:
      return state
  }
}
