import React, { ReactElement } from 'react'
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { StyledTableBody, StyledTableCell } from '../../components/layout/table/table'
import { LicensePlate, Text } from '../../components/layout'
import { IStore } from '../../interfaces/store.interfaces'
import { langMap } from '../../helpers/language.helper'

const SelectedCustomer = (): ReactElement => {
  const { t } = useTranslation()
  const selectedProfileId = useSelector((state: IStore) => state.newOrderDetails.selectedProfileId)
  // const vehicleSize = useSelector((state: IStore) => state.newOrderDetails.vehicleSize)
  const profileList = useSelector((state: IStore) => state.profileList)
  const selectedCustomer = profileList.find(({ id: cID }) => cID === selectedProfileId)
  const user = useSelector((state: IStore) => state.user)
  const userLang = user.user?.language || 'en'

  return (
    <div>
      {selectedProfileId && selectedCustomer ? (
        <TableContainer elevation={0} component={Paper}>
          <Table size="medium">
            <StyledTableBody>
              <TableRow key={selectedCustomer.id}>
                {!!selectedCustomer.vehicle?.licensePlate && (
                  <TableCell align="left">
                    <LicensePlate $search>{selectedCustomer.vehicle?.licensePlate}</LicensePlate>
                  </TableCell>
                )}
                <StyledTableCell
                  $mobileHidden
                >{`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}</StyledTableCell>
                <StyledTableCell $mobileHidden>
                  {!!selectedCustomer?.contacts?.length &&
                    selectedCustomer.contacts.find(({ channel }) => channel === 'EMAIL')?.value}
                </StyledTableCell>
                {!!selectedCustomer.vehicle?.lastOrder && (
                  <StyledTableCell $mobileHidden>
                    {moment(selectedCustomer.vehicle?.lastOrder)
                      .locale(langMap[userLang] || langMap.en)
                      .format('MMM DD. hh:mm')}
                  </StyledTableCell>
                )}
                <StyledTableCell $mobileVisible>
                  <Text $weight={600} $xxsSize="14px">
                    {`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
                  </Text>
                  <Text $xxsSize="9px">
                    {!!selectedCustomer.contacts?.length &&
                      selectedCustomer.contacts.find(({ channel }) => channel === 'EMAIL')?.value}
                  </Text>
                </StyledTableCell>
              </TableRow>
            </StyledTableBody>
          </Table>
        </TableContainer>
      ) : (
        <Text $weight={600} $align="center" $size="20px">
          {t('NO_CUSTOMER_SELECTED')}
        </Text>
      )}
    </div>
  )
}
export default SelectedCustomer
