/* eslint-disable no-console */
/* eslint-disable no-plusplus */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '@material-ui/core'
import { useCookies } from 'react-cookie'
import { AUTH_COOKIE_KEY, WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY, API_URI } from '../../config'
import { Line } from '../../pages/reservationdetails/reservationdetails.styles'
import { DropDownMenuButton } from '../../components/layout/commonUiElements'
import { toggleNewReservationModal } from '../../actions/uiStatesAction'
import { TextInput } from '../../components/layout/form/form.styles'
import { clearUser, getUserMe } from '../../actions/userActions'
import { IStore } from '../../interfaces/store.interfaces'
import { headers } from '../../helpers/headers.helper'
import { Grid, Icon } from '../../components/layout'
import { Text } from '../../components/layout/text'
import { colors } from '../../theme.styles'
import {
  NewCustomerBtn,
  LogoWrapper,
  UserAvatar,
  BarWrapper,
  DesktopNav,
  MenuButton,
  MobileLogo,
  MobileMenu,
  UserMenu,
  NavBtn,
} from './header.styles'

const Header = (): ReactElement => {
  const washerbuttonRef = useRef(null)
  const buttonRef = useRef(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [washermenuOpen, setwasherMenuOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [washeranchorEl, setWasherAnchorEl] = useState(null)
  const [washerSearch, setWasherSearch] = useState('')
  const [menuOpen, setMenuOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const allCookies = document.cookie.split('; ') || []
  const washerCookie = (!!allCookies.length && allCookies.find((value) => value.includes(WASHERID_COOKIE_KEY))) || ''
  const washerId = washerCookie?.split('=').pop() || ''

  const [cookies, setCookie, removeCookie] = useCookies([
    PREFERRED_CALENDARVIEW_COOKIE_KEY,
    WASHERID_COOKIE_KEY,
    AUTH_COOKIE_KEY,
  ])

  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const token = cookies[AUTH_COOKIE_KEY]

  const washers = useSelector((state: IStore) => state.user?.washers)
  const washerName = washers?.find(({ id }) => id === Number(washerId))?.name
  const user = useSelector((state: IStore) => state.user)
  const roles = useSelector((state: IStore) => state.user?.roles)

  useEffect(() => {
    if (!user.washers && !!token) {
      dispatch(getUserMe())
    }
  }, [token])

  useEffect(() => {
    if (window.location.pathname === '/monitor') {
      setDisabled(true)
    }
    if (!calView) {
      setCookie(PREFERRED_CALENDARVIEW_COOKIE_KEY, 'day', { maxAge: 31536000, path: '/' })
    }
  }, [])

  if (user.error && user.error.invalid_client === 'invalid-token') {
    dispatch(clearUser())
    removeCookie(WASHERID_COOKIE_KEY, { path: '/' })
    removeCookie(AUTH_COOKIE_KEY, { path: '/' })
    console.log('problem with token, logging out...')
  }

  const handleClick = () => {
    setAnchorEl(buttonRef.current)
    setMenuOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMenuOpen(false)
  }

  const handleWasherClick = () => {
    setWasherAnchorEl(washerbuttonRef.current)
    setwasherMenuOpen(true)
  }

  const handleWasherClose = () => {
    setWasherAnchorEl(null)
    setwasherMenuOpen(false)
  }

  const onLogOut = () => {
    setAnchorEl(null)
    setMenuOpen(false)
    dispatch(clearUser())
    removeCookie(WASHERID_COOKIE_KEY, { path: '/' })
    removeCookie(AUTH_COOKIE_KEY, { path: '/' })
  }

  const onSwitchWasher = async (id: number) => {
    setCookie(WASHERID_COOKIE_KEY, id.toString(), { maxAge: 31536000, path: '/' })
    const data = {
      selectedWasherId: id,
    }

    const postUrl = `${API_URI}/access`
    const response = await fetch(postUrl, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })
    if (response.status === 200) {
      const jsonValue = await response.json()
      const { accessToken, selectedWasherId } = jsonValue

      if (accessToken && selectedWasherId) {
        setCookie(WASHERID_COOKIE_KEY, selectedWasherId, {
          maxAge: 31536000,
          path: '/',
        })
        setCookie(AUTH_COOKIE_KEY, accessToken, {
          maxAge: 31536000,
          path: '/',
        })
        if (window.location.pathname.includes('reservations/')) {
          window.location.replace('/reservations')
        } else window.location.reload()
      }
    }
  }

  return (
    <BarWrapper color="inherit" elevation={0} position="static" $disabled={disabled}>
      <Grid $xxsCol="1fr auto" $alignItems="center">
        <Grid $xxsCol="auto auto auto" $alignItems="center" $justifyContent="start">
          <LogoWrapper $gap="0">
            <Icon $logo $width="140px" $height="35px" />
            <Text $size="12px" $weight={500} $align="right" $textColor={colors.cyan}>
              {washerName}
            </Text>
          </LogoWrapper>
          <MobileLogo $logo $width="100px" $height="30px" />
          {user?.user && (
            <DropDownMenuButton
              onClick={handleClick}
              id="select-outlined"
              $open={!!anchorEl}
              ref={buttonRef}
              $withAvatar
            >
              <UserAvatar $colorSelector={user?.user?.id && user.user.id % 10}>
                <Text $size="14px" $weight={500}>{`${user?.user?.firstName[0]}${user?.user?.lastName[0]}`}</Text>
              </UserAvatar>
              <Text $size="14px" $weight={600} $textColor={colors.cyan}>
                {`${user?.user?.firstName} ${user?.user?.lastName}`}
              </Text>
              <Icon $menuDown $size="20px" />
            </DropDownMenuButton>
          )}
          <UserMenu
            onClose={handleClose}
            anchorEl={anchorEl}
            open={menuOpen}
            id="user-menu"
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={onLogOut}>
              <Grid $alignItems="center" $xsCol="20px 1fr" $gap="10px" $padding="8px">
                <Icon $logout $size="20px" />
                <Text $textColor={colors.cyan} $weight={600}>
                  {t('LOG_OUT_OF_WASHER')}
                </Text>
              </Grid>
            </MenuItem>
          </UserMenu>

          {washers?.length > 1 && (
            <>
              <DropDownMenuButton
                onClick={handleWasherClick}
                id="washer-select"
                $open={!!washeranchorEl}
                ref={washerbuttonRef}
              >
                <Text $size="14px" $weight={600} $textColor={colors.cyan}>
                  {washerName}
                </Text>
                <Icon $menuDown $size="20px" />
              </DropDownMenuButton>
              <UserMenu
                onClose={handleWasherClose}
                anchorEl={washeranchorEl}
                open={washermenuOpen}
                id="washer-menu"
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Grid $padding="0px 24px">
                  <TextInput
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWasherSearch(e.target.value)}
                    placeholder={t('SEARCH')}
                    value={washerSearch}
                    autoComplete="off"
                    variant="outlined"
                    name="search"
                    id="search"
                    type="text"
                    $form
                  />
                </Grid>
                {washers
                  .filter(({ name }) => name?.toLowerCase()?.includes(washerSearch.toLowerCase()))
                  .map(({ id, name, active }) => (
                    <div key={id}>
                      <MenuItem onClick={() => onSwitchWasher(id)}>
                        <Grid $alignItems="center" $xsCol="1fr" $gap="10px" $padding="8px">
                          <Text $textColor={active ? colors.cyan : 'rgb(229, 146, 170)'} $weight={600}>
                            {name}
                          </Text>
                        </Grid>
                      </MenuItem>
                      <Line />
                    </div>
                  ))}
              </UserMenu>
            </>
          )}
        </Grid>
        <Grid $xxsCol="repeat(2, auto)" $alignItems="center">
          <DesktopNav $xsCol="repeat(5, auto)" $alignItems="center">
            <NavBtn
              $disabled={!roles?.filter((roleitem) => roleitem === 'CHIEF' || roleitem === 'USER').length}
              activeClassName="active"
              to="/dashboard"
            >
              {t('MENU_DASHBOARD')}
            </NavBtn>
            <NavBtn activeClassName="active" to="/reservations">
              {t('MENU_RESERVATIONS')}
            </NavBtn>
            <NavBtn activeClassName="active" to="/wash-diary">
              {t('MENU_WASH_DIARY')}
            </NavBtn>
            <NavBtn $disabled activeClassName="active" to="/monitor">
              {t('MENU_MONITOR')}
            </NavBtn>
            <NavBtn
              activeClassName="active"
              to="/settings/general"
              strict={false}
              isActive={(match, location) => {
                if (location.pathname.includes('settings')) return true
                return false
              }}
            >
              {t('MENU_SETTINGS')}
            </NavBtn>
          </DesktopNav>
          <NewCustomerBtn
            onClick={() => dispatch(toggleNewReservationModal())}
            variant="contained"
            disableElevation
            color="primary"
          >
            {t('MENU_NEW_RESERVATION')}
          </NewCustomerBtn>
          <AnimatePresence>
            {!!mobileMenuOpen && (
              <MobileMenu
                transition={{ type: 'spring', damping: 25, stiffness: 170 }}
                initial={{ opacity: 0, y: -500 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -500 }}
              >
                <Grid $paddingTop="40px">
                  <NavBtn
                    $disabled={!roles?.filter((roleitem) => roleitem === 'CHIEF' || roleitem === 'USER').length}
                    onClick={() => setMobileMenuOpen(false)}
                    activeClassName="active"
                    to="/dashboard"
                  >
                    {t('MENU_DASHBOARD')}
                  </NavBtn>
                  {washers?.length > 1 && (
                    <NavBtn onClick={() => setMobileMenuOpen(false)} activeClassName="active" to="/washerselect">
                      {t('SWITCH_WASHER')}
                    </NavBtn>
                  )}
                  <NavBtn onClick={() => setMobileMenuOpen(false)} activeClassName="active" to="/reservations">
                    {t('MENU_RESERVATIONS')}
                  </NavBtn>
                  <NavBtn onClick={() => setMobileMenuOpen(false)} activeClassName="active" to="/wash-diary">
                    {t('MENU_WASH_DIARY')}
                  </NavBtn>
                  <NavBtn onClick={() => setMobileMenuOpen(false)} $disabled activeClassName="active" to="/monitor">
                    {t('MENU_MONITOR')}
                  </NavBtn>
                  <NavBtn
                    onClick={() => setMobileMenuOpen(false)}
                    activeClassName="active"
                    to="/settings/general"
                    strict={false}
                    isActive={(match, location) => {
                      if (location.pathname.includes('settings')) return true
                      return false
                    }}
                  >
                    {t('MENU_SETTINGS')}
                  </NavBtn>
                  <NavBtn onClick={() => onLogOut()} activeClassName="actives" to="/">
                    {t('LOG_OUT_OF_WASHER')}
                  </NavBtn>
                </Grid>
              </MobileMenu>
            )}
          </AnimatePresence>
          <MenuButton onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>{mobileMenuOpen ? '✕' : '☰'}</MenuButton>
        </Grid>
      </Grid>
    </BarWrapper>
  )
}
export default Header
