/* eslint-disable no-plusplus */
import { Container, IconButton, InputAdornment } from '@material-ui/core'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { AUTH_COOKIE_KEY, WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY } from '../../config'
import { PaperWrapper } from '../reservationdetails/reservationdetails.styles'
import { TextInput } from '../../components/layout/form/form.styles'
import { Grid, Icon, Text, Button } from '../../components/layout'
import { clearUser, getUser } from '../../actions/userActions'
import { IStore } from '../../interfaces/store.interfaces'
import { LoginWrapper } from './login.styles'

const Login = (): ReactElement => {
  const [cookies, setCookie] = useCookies([AUTH_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isForgotPassword, setIsForgotPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')

  const user = useSelector((state: IStore) => state.user)

  const mailformat = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const encodedCredentials = btoa(`${email}:${password}`)

  useEffect(() => {
    dispatch(clearUser())
  }, [])

  useEffect(() => {
    if (user?.washers && user?.access) {
      setCookie(WASHERID_COOKIE_KEY, user.access.selectedWasherId, { maxAge: 31536000, path: '/' })
      for (let index = 0; index < user.washers.length; index++) {
        setCookie(`${AUTH_COOKIE_KEY}`, user.access.token, {
          maxAge: 31536000,
          path: '/',
        })
      }
    }
    if (user.loaded) {
      window.location.reload()
    }

    if (!calView) {
      setCookie(PREFERRED_CALENDARVIEW_COOKIE_KEY, 'day', { maxAge: 31536000, path: '/' })
    }
  }, [user])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <LoginWrapper $alignContent="center" $justifyItems="center" $gap="80px" $marginTop="-130px">
      <Icon $logo $width="280px" $height="84px" />
      <Container maxWidth="xs">
        <PaperWrapper elevation={0}>
          <Grid $padding="40px" $gap="20px">
            {isForgotPassword ? (
              <>
                <Text $weight={500} $size="30px" $align="center">
                  {t('FORGOT_PASSWORD_TITLE')}
                </Text>
                <Text $size="16px" $align="center" $margin="0 10px">
                  {t('FORGOT_PASSWORD_DESCRIPTION')}
                </Text>
                <TextInput
                  helperText={!!email && !email.match(mailformat) && t('EMAIL_ERROR')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  error={!!email && !email.match(mailformat)}
                  label={t('EMAIL_ADDRESS')}
                  variant="outlined"
                  name="email"
                  type="email"
                  id="email"
                />
                <Button variant="contained" color="primary">
                  {t('FORGOT_PASSWORD_BTN_TITLE')}
                </Button>
                <Button color="secondary" disableRipple onClick={() => setIsForgotPassword(false)}>
                  {t('FORGOT_PASSWORD_BACK_BTN_TITLE')}
                </Button>
              </>
            ) : (
              <>
                <Text $weight={500} $size="30px" $align="center">
                  {t('LOGIN_TITLE')}
                </Text>
                <Text $size="16px" $align="center" $margin="0 10px">
                  {t('LOGIN_DESCRIPTION')}
                </Text>
                <TextInput
                  helperText={!!email && !email.match(mailformat) && t('EMAIL_ERROR')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  error={!!email && !email.match(mailformat)}
                  label={t('EMAIL_ADDRESS')}
                  variant="outlined"
                  name="email"
                  type="email"
                  id="email"
                  $form
                />
                <TextInput
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      dispatch(getUser(encodedCredentials))
                    }
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                  error={!!password && password.length <= 6}
                  label={t('PASSWORD')}
                  variant="outlined"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  $semiRounded
                  $search
                  $form
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <Icon $eyeGreen={showPassword} $eye={!showPassword} $width="20px" $height="20px" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <Button color="secondary" onClick={() => setIsForgotPassword(true)} disableRipple>
                  {t('FORGOT_PASSWORD_TITLE')}
                </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!email || !password || user.loading}
                  onClick={() => dispatch(getUser(encodedCredentials))}
                >
                  {user.loading ? 'Loading...' : t('LOGIN_TITLE')}
                </Button>
                {user.error && (
                  <Text $textColor="red" $size="12px" $weight={600}>
                    {t('LOGIN_ERROR')}
                  </Text>
                )}
              </>
            )}
          </Grid>
        </PaperWrapper>
      </Container>
    </LoginWrapper>
  )
}

export default Login
