import styled from 'styled-components/macro'
import { colors } from '../../../theme.styles'
import { Grid } from '../grid'
import { media } from '../mediaqueries'

export const WeekWrapper = styled(Grid)`
  border: 1px solid #e1e7f0;
  border-radius: 15px;
`

export const DayWrapper = styled(Grid)`
  grid-gap: 0px;
  & > div {
    padding: 10px;
    ${media.lg} {
      padding: 13px 23px;
    }
    border-bottom: 1px solid #e1e7f0;
  }
  & > div:last-of-type {
    border-bottom: none;
  }
`
export const ShiftWrapper = styled(Grid)`
  height: 45px;
  ${media.lg} {
    > button {
      display: none;
    }
    :hover > button {
      display: block;
    }
  }
`

export const DayActivitySign = styled('div')<{ $active?: boolean }>`
  display: grid;
  border-radius: 100%;
  color: #a8b1bd;
  font-weight: 500;
  background-color: #f4f7fb;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-size: 12px;
  width: 25px;
  height: 25px;
  ${media.lg} {
    font-size: 16px;
    width: 40px;
    height: 40px;
  }
  ${({ $active }) =>
    $active &&
    `
   color: white;
   background-color: ${colors.lime};
  `}
`

export const DtButtonWrapper = styled(Grid)`
  transition: opacity 0.1s ease-in-out;
  ${media.lg} {
    opacity: 0;
  }
`

export const DowntimeWrapper = styled(Grid)<{ $dirty?: boolean }>`
  border-radius: 15px;
  padding: 8px;
  &:hover .btns {
    opacity: 1;
  }
  &:hover .btns button:nth-of-type(2) {
    opacity: 0.3;
  }
  ${({ $dirty }) =>
    $dirty &&
    `
  &:hover .btns button:nth-of-type(2) {
    opacity: 1;
  }
  & .btns {
    opacity: 1;
  }
  background-color: ${colors.almostWhite};
  `}
`

export const DayContentWrapper = styled(Grid)`
  ${media.lg} {
    & .addNew {
      opacity: 0;
    }
    &:hover  .addNew {
      opacity: 1;
    }

`
