/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreator, Dispatch } from 'redux'
import { todaysreservations as serviceName } from '.'
import { API_URI } from '../config'
import { headers } from '../helpers/headers.helper'

const urlQuery = '/reservations?day-offset=0'

export const getTodaysReservations: ActionCreator<any> = (washerId: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: `GET_${serviceName}_BEGIN`,
    })
    const res = await fetch(`${API_URI}/washers/${washerId}${urlQuery}`, {
      headers,
    })
    const reservationlist = await res.json()
    if (res.status === 200) {
      dispatch({
        type: `GET_${serviceName}_SUCCESS`,
        payload: { reservationlist },
      })
    }
    if (res.status !== 200) {
      dispatch({
        type: `GET_${serviceName}_FAILED`,
      })
    }
  } catch (error) {
    dispatch({
      type: `GET_${serviceName}_FAILED`,
    })
  }
}
