import React, { ReactElement, useEffect } from 'react'
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { StyledTableBody, StyledTableHead } from '../../components/layout/table/table'
import { SettingsSheet } from '../../components/layout/commonUiElements'
import { toggleNewPromotionModal } from '../../actions/uiStatesAction'
import { getPromotions } from '../../actions/getPromotionsActions'
import { Grid, Text, TextBlock } from '../../components/layout'
import { IStore } from '../../interfaces/store.interfaces'
import { WASHERID_COOKIE_KEY } from '../../config'
import { Button } from '../../components/layout/button/button'

const PromotionSettings = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const promotions = useSelector((state: IStore) => state.promotions)
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])

  useEffect(() => {
    if (washerId) {
      dispatch(getPromotions(washerId))
    }
  }, [washerId])

  return (
    <Grid>
      <SettingsSheet $xxsCol="1fr auto">
        <Text $size="20px" $weight={600}>
          {t('PROMOTION_SETTINGS')}
        </Text>
        <Button onClick={() => dispatch(toggleNewPromotionModal())} variant="contained" color="primary">
          {t('ADD_NEW_PROMOTION_BUTTON')}
        </Button>
      </SettingsSheet>
      {promotions.length ? (
        <TableContainer elevation={0} component={Paper}>
          <Table size="medium">
            <StyledTableHead>
              <TableRow>
                <TableCell>{t('PROMO_TABLE_AMOUNT')}</TableCell>
                <TableCell>{t('PROMO_TABLE_FIX_OR_PERCENT')}</TableCell>
              </TableRow>
            </StyledTableHead>
            <StyledTableBody>
              {promotions.map(({ currency, amount, unit, id: pID }) => (
                <TableRow key={pID}>
                  <TableCell>{unit === 'COIN' ? `${amount} ${currency}` : `${amount}%`}</TableCell>
                  <TableCell>{t(unit)}</TableCell>
                </TableRow>
              ))}
            </StyledTableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid $padding="40px 0px">
          <TextBlock $align="center" dangerouslySetInnerHTML={{ __html: t('NO_PROMOTIONS') }} />
        </Grid>
      )}
    </Grid>
  )
}

export default PromotionSettings
