import { Button as MUIButton } from '@material-ui/core'
import styled from 'styled-components/macro'
import { media } from '../mediaqueries'

export const Button = styled(MUIButton)`
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
  &.MuiButton-root {
    font-size: 13px;
    padding: 10px 12px;
    ${media.lg} {
      font-size: 14px;
      padding: 16px 20px;
    }
  }
`
