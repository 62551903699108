import { users as serviceName } from '../actions'
import { IUsers } from '../interfaces/users.interfaces'
import { IAction } from '../interfaces/store.interfaces'

const initialState = {} as IUsers

export const getUsersReducer = (state = initialState, action: IAction): IUsers => {
  const { type, payload } = action
  switch (type) {
    case `GET_${serviceName}_BEGIN`:
      return state
    case `GET_${serviceName}_SUCCESS`:
      return payload
    case `GET_${serviceName}_FAILED`:
      return state
    default:
      return state
  }
}
