import React, { ReactElement, useState } from 'react'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { KeyboardDateTimePickerInput, TextInput } from '../form/form.styles'
import { getDowntimes } from '../../../actions/getDowntimesActions'
import { API_URI, WASHERID_COOKIE_KEY } from '../../../config'
import { headers } from '../../../helpers/headers.helper'
import { DowntimeWrapper } from './openHours.styles'
import { Grid, Text, Icon } from '../index'

const NewDowntime = (): ReactElement => {
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = cookies[WASHERID_COOKIE_KEY]
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [downStart, setDownStart] = useState<string | null>()
  const [downEnd, setDownEnd] = useState<string | null>()
  const [loading, setLoading] = useState(false)
  const [addNew, setAddNew] = useState(false)
  const [title, setTitle] = useState('')

  const startTime = moment(downStart).format('YYYY-MM-DDTHH:mm:ssZ')
  const durationInMinutes = moment(downEnd).diff(downStart, 'minutes')
  const modifyData = {
    durationInMinutes,
    startTime,
    title,
  }

  const onCreateDowntime = async () => {
    setLoading(true)

    const postUrl = `${API_URI}/washers/${washerId}/downtimes`
    const response = await fetch(postUrl, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(modifyData),
    })
    if (response.status !== 200) {
      setLoading(false)
    }
    if (response.status === 200) {
      setLoading(false)
      dispatch(getDowntimes(washerId))
      setAddNew(false)
    }
  }

  return (
    <Grid>
      {addNew ? (
        <DowntimeWrapper $mdCol="auto auto 1fr" $dirty $alignItems="center">
          <Grid $gap="20px">
            <TextInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
              error={!!title && title.length < 2}
              label={t('NAMING')}
              variant="outlined"
              name="title"
              type="text"
              id="title"
              $form
            />
            <Grid>
              <Grid $mdCol="1fr auto 1fr" $alignItems="center" justifyItems="start" justifyContent="start" $gap="20px">
                <KeyboardDateTimePickerInput
                  onChange={(date: MaterialUiPickersDate) => setDownStart(moment(date).format('YYYY-MM-DDTHH:mm:ssZ'))}
                  KeyboardButtonProps={{ 'aria-label': 'change time' }}
                  inputVariant="outlined"
                  id="start-time-picker"
                  label={t('DATE_FROM')}
                  value={downStart}
                  margin="none"
                  ampm={false}
                  key="start"
                  $form
                />
                <Text $mdSize="20px" $margin="20px 0 0 0">
                  {' - '}
                </Text>
                <KeyboardDateTimePickerInput
                  onChange={(date: MaterialUiPickersDate) => setDownEnd(moment(date).format('YYYY-MM-DDTHH:mm:ssZ'))}
                  KeyboardButtonProps={{ 'aria-label': 'change time' }}
                  inputVariant="outlined"
                  id="end-time-picker"
                  label={t('DATE_TO')}
                  value={downEnd}
                  margin="none"
                  ampm={false}
                  key="end"
                  $form
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid $xxsCol="auto auto" $justifyContent="start">
            <IconButton
              title={t('CANCEL_NEW_DOWNTIME_BTN')}
              onClick={() => setAddNew(false)}
              disabled={!!loading}
              color="secondary"
              size="small"
            >
              <Icon $cancel $size="40px" />
            </IconButton>
            <IconButton title={t('SAVE_NEW_DOWNTIME_BTN')} size="small" onClick={() => onCreateDowntime()}>
              <Icon $checkboxOn $size="40px" />
            </IconButton>
          </Grid>
        </DowntimeWrapper>
      ) : (
        <Grid $justifyItems="center">
          <IconButton title={t('ADD_NEW_DOWNTIME_BTN')} size="small" onClick={() => setAddNew(true)}>
            <Icon $plusGray $size="20px" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

export default NewDowntime
