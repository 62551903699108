/* eslint-disable react/no-children-prop */
import React, { ReactElement, useEffect } from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Route, Switch } from 'react-router'
import { useCookies } from 'react-cookie'
import MomentUtils from '@date-io/moment'
import { useSelector } from 'react-redux'
import { AUTH_COOKIE_KEY, WASHERID_COOKIE_KEY } from './config'
import { IStore } from './interfaces/store.interfaces'
import { langMap } from './helpers/language.helper'
import VersionUpdate from './modules/versionUpdate'
import Header from './modules/header/header'
import { SW_VERSION } from './version'
import { theme } from './theme.styles'
import Modals from './modules/modals'
import {
  ReservationDetailsPage,
  AppointmentsPage,
  WasherSelectPage,
  WasherDiaryPage,
  UserSelectPage,
  DashboardPage,
  SettingsPage,
  MonitorPage,
  SignupPage,
  LoginPage,
} from './pages'

function App(): ReactElement {
  const { i18n } = useTranslation()
  const [cookies] = useCookies([AUTH_COOKIE_KEY, WASHERID_COOKIE_KEY])
  const washerId = cookies[WASHERID_COOKIE_KEY]
  const token = cookies[AUTH_COOKIE_KEY]
  const version = localStorage.getItem('cwo_ver')
  const user = useSelector((state: IStore) => state.user?.user)

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.language)
    }
  }, [user])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <div>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={langMap[user?.language || 'en'] || langMap.en}>
            {version !== SW_VERSION && <VersionUpdate />}
            {token && washerId ? (
              <>
                <Header />
                <Switch>
                  <Route path="/reservations/:id" children={<ReservationDetailsPage />} exact />
                  <Route path="/washerselect" component={WasherSelectPage} exact />
                  <Route path="/dashboard" component={DashboardPage} exact />
                  <Route path="/settings/:tab" children={<SettingsPage />} exact />
                  <Route path="/reservations" component={AppointmentsPage} exact />
                  <Route path="/wash-diary" component={WasherDiaryPage} exact />
                  <Route path="/userselect" component={UserSelectPage} exact />
                  <Route path="/settings" component={SettingsPage} exact />
                  <Route path="/sign-up" children={<AppointmentsPage />} />
                  <Route path="/monitor" component={MonitorPage} exact />
                  <Route path="/" component={AppointmentsPage} exact />
                </Switch>
                <Modals />
              </>
            ) : (
              <Switch>
                <Route path="/sign-up" children={<SignupPage />} />
                <Route path="/" component={LoginPage} />
              </Switch>
            )}
          </MuiPickersUtilsProvider>
        </div>
      </CssBaseline>
    </ThemeProvider>
  )
}

export default App
