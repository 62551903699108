import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TaskDescription } from '../../components/layout/modal'
import { IStore } from '../../interfaces/store.interfaces'
import { Text, Grid } from '../../components/layout'

const SelectedPackage = (): ReactElement => {
  const { t } = useTranslation()
  const packageId = useSelector((state: IStore) => state.newOrderDetails.packageId)
  const packages = useSelector((state: IStore) => state.packages)
  const selectedPackage = packages.find(({ id }) => id === packageId)
  return (
    <div>
      {selectedPackage ? (
        <Grid $xsCol="1fr auto">
          <Text $xxsSize="16px" $lgSize="20px" $weight={600}>
            {`${t(selectedPackage.surface)} ${t(selectedPackage.category.title)}`}
          </Text>
          <Text $xxsSize="14px" $lgSize="16px" $weight={600}>
            {`${selectedPackage.price?.amount} ${selectedPackage.price?.currency}`}
          </Text>
          <TaskDescription>
            {selectedPackage.category?.tasks?.length &&
              selectedPackage.category.tasks
                .filter(({ surface: cSurface }) =>
                  selectedPackage.surface === 'INTERIOR_EXTERIOR'
                    ? cSurface === 'EXTERIOR' || cSurface === 'INTERIOR'
                    : cSurface === selectedPackage.surface,
                )
                .map(({ id: cId, title: cTitle }) => <span key={cId}>{`${t(cTitle)}, `}</span>)}
          </TaskDescription>
          <Text $xxsSize="14px" $lgSize="16px" $weight={600}>
            {`${selectedPackage.duration} ${t('MINUTE')}`}
          </Text>
        </Grid>
      ) : (
        <Text $weight={600} $align="center" $size="20px">
          {t('NO_PACKAGE_SELECTED')}
        </Text>
      )}
    </div>
  )
}
export default SelectedPackage
