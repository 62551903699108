import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormControlLabel } from '@material-ui/core'
import { CarSizeRadiogroup } from '../../components/layout/radio/radioGroup.styles'
import CarSizeRadioLabel from '../../components/layout/radio/carSizeRadioLabel'
import CarSizeRadio from '../../components/layout/radio/carSizeRadio'
import { setVehicleSize } from '../../actions/newReservationActions'
import { Grid, Text } from '../../components/layout'

const VehicleSizeSelector = (): ReactElement => {
  const [SSize, setSSize] = useState('')
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (SSize) {
      dispatch(setVehicleSize(SSize))
    }
  }, [SSize])

  const sizeOptions = ['S', 'M', 'L', 'XL', 'XXL']

  return (
    <Grid>
      <Grid $alignItems="center" $justifyItems="center" $marginTop="60px" $marginBottom="60px" justifyItems="center">
        <Grid $xsCol="500px" justifyItems="center">
          <Text $weight={600} $size="14px" $margin="0 0 0 10px">
            {t('SIZE')}
          </Text>
          <CarSizeRadiogroup
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSSize(e.target.value)}
            value={SSize}
            name="size"
          >
            {sizeOptions.map((sizeItem) => (
              <FormControlLabel
                key={sizeItem}
                control={<CarSizeRadio />}
                value={sizeItem}
                label={<CarSizeRadioLabel description={sizeItem} active={SSize} />}
              />
            ))}
          </CarSizeRadiogroup>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default VehicleSizeSelector
