import React, { ReactElement } from 'react'
import { Button, Container } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import { API_URI, AUTH_COOKIE_KEY, WASHERID_COOKIE_KEY } from '../../config'
import { UserAvatar, UserCard, InfoWrapper } from './washerselect.styles'
import { IStore } from '../../interfaces/store.interfaces'
import { headers } from '../../helpers/headers.helper'
import { Text } from '../../components/layout/text'
import { Grid } from '../../components/layout'

const UserselectPage = (): ReactElement => {
  const { t } = useTranslation()
  const washers = useSelector((state: IStore) => state.user?.washers)
  const [cookies, setCookie] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = cookies[WASHERID_COOKIE_KEY]

  const onSwitchWasher = async (id: number) => {
    setCookie(WASHERID_COOKIE_KEY, id.toString(), { maxAge: 31536000, path: '/' })
    const data = {
      selectedWasherId: id,
    }

    const postUrl = `${API_URI}/access`
    const response = await fetch(postUrl, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })
    if (response.status === 200) {
      const jsonValue = await response.json()
      const { accessToken, selectedWasherId } = jsonValue

      if (accessToken && selectedWasherId) {
        setCookie(WASHERID_COOKIE_KEY, selectedWasherId, {
          maxAge: 31536000,
          path: '/',
        })
        setCookie(AUTH_COOKIE_KEY, accessToken, {
          maxAge: 31536000,
          path: '/',
        })
        if (window.location.pathname.includes('reservations/')) {
          window.location.replace('/reservations')
        } else window.location.reload()
      }
    }
  }

  return (
    <Container>
      <Grid $xsCol={3} $mdCol={3} $col={4} $gap="20px" $paddingTop="40px">
        {!!washers?.length &&
          washers.map(({ id: wid, name, address }) => (
            <UserCard elevation={0} key={wid} $active={wid === Number(washerId)}>
              <Grid $gap="16px" $justifyItems="center" $alignItems="center">
                <UserAvatar sizes="120px">{name ? name[0] : 'G'}</UserAvatar>
                <Text $weight={600} $xxsSize="16px" $size="20px" variant="h2" $align="center">
                  {name}
                </Text>
                <InfoWrapper $alignItems="center" $justifyItems="center" $paddingBottom="20px">
                  <Text $size="12px" $align="center" variant="body1">
                    {!!address &&
                      `${address.postalCode} ${address.city} ${
                        address.street ? `${address.street} ${t('STREET_SHORT')}` : ''
                      } ${address.door || ''}`}
                  </Text>
                  <Button disableElevation variant="contained" color="primary" onClick={() => onSwitchWasher(wid)}>
                    {t('SIGN_IN_BTN')}
                  </Button>
                </InfoWrapper>
              </Grid>
            </UserCard>
          ))}
      </Grid>
    </Container>
  )
}
export default UserselectPage
