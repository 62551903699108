import React, { ReactElement, useEffect, useState } from 'react'
import { CircularProgress, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { SettingsGroup, SettingsListItem, SettingsSheet } from '../../components/layout/commonUiElements'
import NewDowntime from '../../components/layout/openHours/newDowntime.component'
import Downtime from '../../components/layout/openHours/downtime.component'
import Week from '../../components/layout/openHours/week.component'
import { Grid, LicensePlate, Text } from '../../components/layout'
import { getOpenHours } from '../../actions/getOpenHoursActions'
import { getDowntimes } from '../../actions/getDowntimesActions'
import { API_URI, WASHERID_COOKIE_KEY } from '../../config'
import { IStore } from '../../interfaces/store.interfaces'
import { headers } from '../../helpers/headers.helper'

const OpenHoursSettings = (): ReactElement => {
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = cookies[WASHERID_COOKIE_KEY]
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const openingHours = useSelector((state: IStore) => state.openingHours)
  const downtimes = useSelector((state: IStore) => state.downtimes)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(getOpenHours(washerId))
    dispatch(getDowntimes(washerId))
  }, [])

  const onSetAlternating = async (alternating: boolean) => {
    setLoading(true)
    const dataToSend = {
      alternating,
    }
    const postUrl = `${API_URI}/washers/${washerId}/opening-hours/alternating`
    const response = await fetch(postUrl, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(dataToSend),
    })
    if (response.status !== 200) {
      setLoading(false)
    }
    if (response.status === 200) {
      setLoading(false)
      dispatch(getOpenHours(washerId))
    }
  }

  return (
    <SettingsSheet>
      <Text $size="20px" $weight={600}>
        {t('OPEN_HOURS_SETTINGS')}
      </Text>
      <Text $xxsSize="12px" $mdSize="14px">
        {t('OPEN_HOURS_DESCRIPTION')}
      </Text>
      {!!openingHours && (
        <Grid $margin="0px">
          <Grid padding="30px 0px">
            <RadioGroup value={!!openingHours.alternating} style={{ display: 'grid' }}>
              <Grid $padding="0px" $margin="0px" $xxsCol={1} $mdCol="auto auto auto" $justifyContent="start">
                <FormControlLabel
                  onChange={() => onSetAlternating(false)}
                  label={t('ALTERNATING_WEEKS_FALSE')}
                  control={<Radio color="primary" />}
                  value={false}
                  key={1}
                />
                <FormControlLabel
                  onChange={() => onSetAlternating(true)}
                  control={<Radio color="primary" />}
                  label={t('ALTERNATING_WEEKS_TRUE')}
                  key={2}
                  value
                />
                {!!loading && <CircularProgress color="primary" />}
              </Grid>
            </RadioGroup>
          </Grid>
          {!openingHours.alternating && <Week weekType="ANY" weekKey="allWeeks" />}
          {!!openingHours.alternating && <Week weekType="ODD" weekKey="oddWeeks" />}
          {!!openingHours.alternating && <Week weekType="EVEN" weekKey="evenWeeks" />}
        </Grid>
      )}

      <SettingsGroup $marginTop="40px">
        <Grid $gap="14px" $justifyItems="start" $justifyContent="start" $padding="20px">
          <LicensePlate $large>{t('DOWNTIMES_TITLE')}</LicensePlate>
          <Text $xxsSize="12px" $mdSize="14px">
            {t('DOWNTIMES_DESCRIPTION')}
          </Text>
        </Grid>
        {!!downtimes.length &&
          downtimes.map((downtime) => (
            <SettingsListItem key={downtime.id}>
              <Downtime downtime={downtime} />
            </SettingsListItem>
          ))}
        <SettingsListItem>
          <NewDowntime />
        </SettingsListItem>
      </SettingsGroup>
    </SettingsSheet>
  )
}

export default OpenHoursSettings
