import { Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { IText } from '../../interfaces/layout.interfaces'
import { colors } from '../../theme.styles'
import { media } from './mediaqueries'

export const Text = styled(Typography)<IText>`
  ${({ $size }) =>
    $size &&
    `
   font-size: ${$size};
  `}
  ${({ $lineHeight }) =>
    $lineHeight &&
    `
   line-height: ${$lineHeight};
  `}
  ${({ $margin }) =>
    $margin &&
    `
    margin: ${$margin};
  `}
  ${({ $textColor }) =>
    $textColor &&
    `
   color: ${$textColor};
  `}
  ${({ $weight }) =>
    $weight &&
    `
   font-weight: ${$weight};
  `}
  ${({ $align }) =>
    $align &&
    `
   text-align: ${$align};
  `}
  ${({ $lettercase }) =>
    $lettercase &&
    `
   text-transform: ${$lettercase};
  `}
  ${({ $strikeThrough }) =>
    $strikeThrough &&
    `
    text-decoration: line-through;;
  `}
  ${media.xxs} {
    ${({ $xxsSize }) =>
      $xxsSize &&
      `
   font-size: ${$xxsSize};
  `}
  }
  ${media.md} {
    ${({ $mdSize }) =>
      $mdSize &&
      `
   font-size: ${$mdSize};
  `}
  }
  ${media.lg} {
    ${({ $lgSize }) =>
      $lgSize &&
      `
   font-size: ${$lgSize};
  `}
  }
  ${({ $mono }) =>
    $mono &&
    `
   font-family: Open Sans, sans-serif;
  `}
`
export const TextBlock = styled('div')<IText>`
  ${({ $size }) =>
    $size &&
    `
   font-size: ${$size};
  `}
  ${({ $margin }) =>
    $margin &&
    `
    margin: ${$margin};
  `}
  ${({ $textColor }) =>
    $textColor &&
    `
   color: ${$textColor};
  `}
  ${({ $weight }) =>
    $weight &&
    `
   font-weight: ${$weight};
  `}
  ${({ $align }) =>
    $align &&
    `
   text-align: ${$align};
  `}
  ${({ $lettercase }) =>
    $lettercase &&
    `
   text-transform: ${$lettercase};
  `}
  ${({ $strikeThrough }) =>
    $strikeThrough &&
    `
    text-decoration: line-through;;
  `}
  ${media.xxs} {
    ${({ $xxsSize }) =>
      $xxsSize &&
      `
   font-size: ${$xxsSize};
  `}
  }
  ${media.md} {
    ${({ $mdSize }) =>
      $mdSize &&
      `
   font-size: ${$mdSize};
  `}
  }
  ${media.lg} {
    ${({ $lgSize }) =>
      $lgSize &&
      `
   font-size: ${$lgSize};
  `}
  }
  em {
    color: ${colors.lime};
    font-style: normal;
    font-weight: 600;
  }
  white-space: normal;
`
