import React, { ReactElement, useEffect, useState } from 'react'
import { FormControlLabel, Modal } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { ModalWrapper, ModalHeader, ModalCloseBtn, ModalBody } from '../../../components/layout/modal'
import { API_URI, WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY } from '../../../config'
import { getReservationById, getReservations } from '../../../actions/getReservationsActions'
import { completeWashSteps } from '../../../modules/modalBreadcrumbNavigaion/steps.data'
import { CarSizeRadiogroup } from '../../../components/layout/radio/radioGroup.styles'
import { getTodaysReservations } from '../../../actions/getTodaysReservationsActions'
import CarSizeRadioLabel from '../../../components/layout/radio/carSizeRadioLabel'
import {
  clearCompleteWithNewCustomerModal,
  toggleFinishWithExistingCustomerModal,
} from '../../../actions/uiStatesAction'
import CarSizeRadio from '../../../components/layout/radio/carSizeRadio'
import { TextInput } from '../../../components/layout/form/form.styles'
import { IStore } from '../../../interfaces/store.interfaces'
import { ModalBreadcrumbNavigation } from '../../../modules'
import { headers } from '../../../helpers/headers.helper'
import { Text, Grid } from '../../../components/layout'

const CompleteWithNewCustomerModal = (): ReactElement => {
  const { t } = useTranslation()
  const [step, setStep] = useState<number>(1)
  const [loading, setLoading] = useState(false)
  const [nextDisabled, setNextDisabled] = useState(false)
  const [make, setMake] = useState('')
  const [model, setModel] = useState('')
  const [licensePlate, setLicensePlate] = useState('')
  const [size, setSize] = useState('M')
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const onDetailsPage = useSelector((state: IStore) => state.uiStates.completeWithNewCustomerModal.onDetailsPage)
  const reservationId = useSelector((state: IStore) => state.uiStates.completeWithNewCustomerModal.id)
  const isOpen = useSelector((state: IStore) => state.uiStates.completeWithNewCustomerModal.open)
  const dayOffset = useSelector((state: IStore) => state.uiStates.dayOffset)
  const reservations = useSelector((state: IStore) => state.reservations?.reservationlist)
  const reservation = !!reservationId && reservations.find(({ id }) => id === reservationId)
  const daysToGet = calView === 'week' ? 7 : 1

  const postUrl = `${API_URI}/washers/${washerId}/reservations/${reservationId}/status`

  const vehicle = { make, model, licensePlate, size }
  const onModifyState = async (action: string) => {
    setLoading(true)
    const response = await fetch(`${postUrl}/${action}`, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify({ vehicle }),
    })

    const result = await response.json()
    if (result.error) {
      setLoading(false)
    }
    if (result.status === 'COMPLETED' && onDetailsPage && washerId) {
      setLoading(false)
      dispatch(clearCompleteWithNewCustomerModal())
      dispatch(toggleFinishWithExistingCustomerModal(reservationId, onDetailsPage))
      dispatch(getReservationById(washerId, reservationId?.toString()))
    }
    if (result.status === 'COMPLETED' && !onDetailsPage && washerId) {
      setLoading(false)
      dispatch(clearCompleteWithNewCustomerModal())
      dispatch(toggleFinishWithExistingCustomerModal(reservationId, onDetailsPage))
      dispatch(getTodaysReservations(washerId))
      if (calView === 'day') {
        dispatch(getReservations(washerId, dayOffset, daysToGet))
      }
      if (calView === 'week') {
        const weekStartOffsetFromToday = moment().add(dayOffset, 'day').weekday()
        const weekStartOffsetFromDate = weekStartOffsetFromToday * -1 + dayOffset
        dispatch(getReservations(washerId, weekStartOffsetFromDate, daysToGet))
      }
    }
  }

  useEffect(() => {
    setNextDisabled(!make || !model)
  }, [make, model, licensePlate])

  useEffect(() => {
    if (reservation && reservation.size) {
      setSize(reservation.size)
    }
  }, [reservation])

  const sizeOptions = ['S', 'M', 'L', 'XL', 'XXL']

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper>
          <ModalHeader>
            <Text $mdSize="30px" $xxsSize="20px" $weight={500}>
              {t('COMPLETE_WITH_NEW_CUSTOMER')}
            </Text>
            <ModalCloseBtn onClick={() => dispatch(clearCompleteWithNewCustomerModal())}>×</ModalCloseBtn>
          </ModalHeader>
          <ModalBody>
            <Grid $padding="40px">
              <Text $weight={600} $size="20px">
                {t('CAR_DETAILS')}
              </Text>
              <Grid $xsCol={2} $gap="20px">
                <TextInput
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMake(e.target.value)}
                  error={!!make && make.length < 2}
                  label={t('CAR_MAKE')}
                  variant="outlined"
                  name="make"
                  type="text"
                  id="make"
                  required
                  $search
                />
                <TextInput
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setModel(e.target.value)}
                  error={!!model && model.length < 2}
                  label={t('CAR_MODEL')}
                  variant="outlined"
                  name="model"
                  type="text"
                  id="model"
                  required
                  $search
                />
                <TextInput
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLicensePlate(e.target.value)}
                  label={t('LICENSE_PLATE')}
                  name="licensePlate"
                  variant="outlined"
                  id="licensePlate"
                  type="text"
                  $search
                />
                <Grid>
                  <Text $weight={600} $size="14px" $margin="0 0 0 10px">
                    {t('SIZE')}
                  </Text>
                  <CarSizeRadiogroup
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSize(e.target.value)}
                    value={size}
                    name="size"
                  >
                    {sizeOptions.map((sizeItem) => (
                      <FormControlLabel
                        label={<CarSizeRadioLabel description={sizeItem} active={size} />}
                        control={<CarSizeRadio />}
                        value={sizeItem}
                        key={sizeItem}
                      />
                    ))}
                  </CarSizeRadiogroup>
                </Grid>
              </Grid>
            </Grid>
          </ModalBody>
          <ModalBreadcrumbNavigation
            onHandleFinish={() => onModifyState('complete-with-new-vehicle')}
            nextDisabled={nextDisabled}
            steps={completeWashSteps}
            onHandleSteps={setStep}
            loading={loading}
            step={step}
          />
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default CompleteWithNewCustomerModal
