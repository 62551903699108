/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreator } from 'redux'
import { IPromotion } from '../interfaces/promotion.interfaces'

export const setSelectedPromotion: ActionCreator<any> = (promotion?: IPromotion) => ({
  type: 'UPDATED_SELECTED_PROMOTION',
  payload: {
    selectedPromotion: promotion,
  },
})

export const setSelectedSlot: ActionCreator<any> = (slot?: string) => ({
  type: 'UPDATED_SELECTED_SLOT',
  payload: {
    selectedSlot: slot,
  },
})

export const setStartTime: ActionCreator<any> = (time?: string | Date) => ({
  type: 'UPDATED_START_TIME',
  payload: {
    startTime: time,
  },
})

export const setPackageId: ActionCreator<any> = (packageId?: number) => ({
  type: 'UPDATED_PACKAGE_ID',
  payload: {
    packageId,
  },
})

export const setCategoryId: ActionCreator<any> = (categoryId?: number) => ({
  type: 'UPDATED_CATEGORY_ID',
  payload: {
    categoryId,
  },
})

export const setVehicleId: ActionCreator<any> = (vehicleId?: number) => ({
  type: 'UPDATED_VEHICLE_ID',
  payload: {
    vehicleId,
  },
})

export const setVehicleSize: ActionCreator<any> = (vehicleSize?: string) => ({
  type: 'UPDATED_VEHICLE_SIZE',
  payload: {
    vehicleSize,
  },
})

export const setLicensePlate: ActionCreator<any> = (licensePlate?: string) => ({
  type: 'UPDATED_LICENSE_PLATE',
  payload: {
    licensePlate,
  },
})

export const setCustomerProfileId: ActionCreator<any> = (selectedProfileId?: number) => ({
  type: 'UPDATED_CUSTOMER_PROFILE_ID',
  payload: {
    selectedProfileId,
  },
})

export const setStep: ActionCreator<any> = (step?: number) => ({
  type: 'UPDATED_STEP',
  payload: {
    step,
  },
})

export const setNewOrderEmpty: ActionCreator<any> = () => ({
  type: 'CLEARED_NEW_ORDER_DATA',
  payload: {
    selectedProfileId: undefined,
    selectedPromotion: undefined,
    vehicleSize: undefined,
    packageId: undefined,
    vehicleId: undefined,
    categoryId: undefined,
    startTime: undefined,
    selectedSlot: undefined,
    step: 1,
  },
})
