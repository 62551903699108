import { Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { colors } from '../../../theme.styles'
import { media } from '../mediaqueries'

export const ButtonWrapper = styled('div')<{ $active?: boolean; $loading?: boolean; $completed: boolean }>`
  border: 2px solid ${colors.lime};
  transition: all 0.1s ease-in-out;
  min-width: 245px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 15px;
  position: relative;
  padding: 8px;
  ${media.lg} {
    padding: 18px;
  }
  ${({ $active }) =>
    $active &&
    `
   border: 2px solid ${colors.magenta};
  `}
  ${({ $loading }) =>
    $loading &&
    `
   border: 2px solid ${colors.lightGrey};
  `}
  ${({ $completed }) =>
    $completed &&
    `
   border: 2px solid #2196f3;
  `}
`

export const Button = styled.button`
  transition: all 0.2s ease-in-out;
  border-radius: 100%;
  outline: none;
  position: relative;
  appearance: none;
  background: none;
  border: none;
  padding: 0px;
  width: auto;
  &:hover {
    box-shadow: 0px 0px 9px ${colors.lime};
  }
`

export const SlotTitle = styled(Typography)<{ $active?: boolean; $disabled?: boolean }>`
  color: ${colors.lime};
  position: absolute;
  font-weight: 600;
  font-size: 16px;
  right: 10px;
  top: 10px;
  ${({ $active }) =>
    $active &&
    `
   color: ${colors.magenta};
   `}
  ${({ $disabled }) =>
    $disabled &&
    `
   color: ${colors.lightGrey};
   
  `}
`
