import { EmailOutlined, DoneOutlined, CloseOutlined, HourglassEmptyOutlined } from '@material-ui/icons'
import { IconButton, TableCell, TableRow } from '@material-ui/core'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'
import { toggleUniversalResultModal } from '../../../actions/uiStatesAction'
import { API_URI, WASHERID_COOKIE_KEY } from '../../../config'
import { headers } from '../../../helpers/headers.helper'

interface IInvitedUserRowComponent {
  user: IUser
}

interface IUser {
  invitee: string
  key: number
}

const InvitedUserRow = ({ user }: IInvitedUserRowComponent): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { key, invitee } = user
  const [resendInvitationStatus, setResendInvitationStatus] = useState('INIT')
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = cookies[WASHERID_COOKIE_KEY]

  const onResendInvitation = async (iKey: number) => {
    setResendInvitationStatus('LOADING')
    const postUrl = `${API_URI}/washers/${washerId}/users/invitations/${iKey}`
    const response = await fetch(postUrl, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
    })
    if (response.status !== 200) {
      setResendInvitationStatus('FAIL')
      setTimeout(() => {
        setResendInvitationStatus('INIT')
      }, 10000)
      dispatch(
        toggleUniversalResultModal('ERROR', 'RESEND_INVITATION_FAIL_TITLE', 'RESEND_INVITATION_FAIL_DESCRIPTION'),
      )
    }
    if (response.status === 200) {
      setResendInvitationStatus('SUCCESS')
      setTimeout(() => {
        setResendInvitationStatus('INIT')
      }, 30000)
      dispatch(
        toggleUniversalResultModal(
          'SUCCESS',
          'RESEND_INVITATION_SUCCESS_TITLE',
          'RESEND_INVITATION_SUCCESS_DESCRIPTION',
        ),
      )
    }
  }

  return (
    <TableRow key={key}>
      <TableCell>{`${invitee}`}</TableCell>
      <TableCell align="right">
        <IconButton
          title={t(`INVITE_RESEND_STATUS_${resendInvitationStatus}`)}
          disabled={resendInvitationStatus === 'LOADING'}
          onClick={() => onResendInvitation(key)}
          color="primary"
          size="small"
        >
          {resendInvitationStatus === 'LOADING' && <HourglassEmptyOutlined fontSize="large" />}
          {resendInvitationStatus === 'SUCCESS' && <DoneOutlined fontSize="large" />}
          {resendInvitationStatus === 'INIT' && <EmailOutlined fontSize="large" />}
          {resendInvitationStatus === 'FAIL' && <CloseOutlined fontSize="large" />}
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default InvitedUserRow
