import styled from 'styled-components/macro'
import { Box, Typography } from '@material-ui/core'
import { Grid, media } from '../../components/layout'

export const DayText = styled(Typography)<{ $small?: boolean }>`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  margin-left: 0px;
  ${media.md} {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 30px;
  }
  ${({ $small }) =>
    $small &&
    `
  font-size: 14px;
  ${media.md} {
    font-size: 18px;
   }
  `}
`

export const ButtonGroup = styled(Box)`
  grid-template-columns: auto auto;
  justify-self: start;
  display: grid;
  gap: 0px;
  & > :nth-child(1) {
    border-radius: 6px 0px 0px 6px;
    margin-right: 0px;
  }
  & > :nth-child(2) {
    border-radius: 0px 6px 6px 0px;
    margin-right: 0px;
  }
`

export const ButtonGrid = styled(Grid)`
  grid-gap: 5px;
  ${media.lg} {
    grid-gap: 10px;
  }
`

export const ToolbarWrapper = styled(Grid)<{ $small?: boolean }>`
  padding: 15px 0px;
  position: relative;
  ${media.lg} {
    padding: 50px 35px 35px 35px;
  }
  ${({ $small }) =>
    $small &&
    `
    ${media.lg} {
      padding: 3px 3px 20px 40px;
    }
  `}
`

export const ViewSwitcher = styled(ButtonGroup)`
  display: none;
  ${media.lg} {
    display: inline-flex;
    height: 100%;
  }
`
