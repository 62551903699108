/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreator } from 'redux'

export const toggleNewReservationModal: ActionCreator<any> = () => ({
  type: 'TOGGLE_NEW_RESERVATION_MODAL_OPEN',
})

export const toggleNewReservationResultModal: ActionCreator<any> = (type: string, id?: number) => ({
  type: 'TOGGLE_NEW_RESERVATION_RESULT_MODAL_OPEN',
  payload: { id, type },
})

export const toggleNewPromotionModal: ActionCreator<any> = () => ({
  type: 'TOGGLE_NEW_PROMOTION_MODAL_OPEN',
})

export const togglePromoChangeModal: ActionCreator<any> = (type: string, id?: number) => ({
  type: 'TOGGLE_PROMO_CHANGE_MODAL_OPEN',
  payload: { id, type },
})

export const toggleUniversalResultModal: ActionCreator<any> = (type: string, title: string, description: string) => ({
  type: 'TOGGLE_UNIVERSAL_RESULT_MODAL_OPEN',
  payload: { type, title, description },
})

export const toggleCompleteWithNewCustomerModal: ActionCreator<any> = (id?: number, onDetailsPage?: boolean) => ({
  type: 'TOGGLE_COMLPETE_WITH_NEW_CUSTOMER_MODAL_OPEN',
  payload: { id, onDetailsPage },
})

export const toggleFinishWithExistingCustomerModal: ActionCreator<any> = (id?: number, onDetailsPage?: boolean) => ({
  type: 'TOGGLE_FINISH_WITH_EXISTING_CUSTOMER_MODAL_OPEN',
  payload: { id, onDetailsPage },
})

export const toggleNewProfileModal: ActionCreator<any> = () => ({
  type: 'TOGGLE_NEW_PROFILE_MODAL_OPEN',
})

export const toggleCancelWashModal: ActionCreator<any> = (id?: number, onDetailsPage?: boolean) => ({
  type: 'TOGGLE_CANCEL_WASH_MODAL_OPEN',
  payload: { id, onDetailsPage },
})

export const toggleCancelNewReservationModal: ActionCreator<any> = () => ({
  type: 'TOGGLE_CANCEL_NEW_RESERVATION_MODAL_OPEN',
})

export const toggleNewUserModal: ActionCreator<any> = () => ({
  type: 'TOGGLE_NEW_USER_MODAL_OPEN',
})

export const toggleCalendarPagerDragOver: ActionCreator<any> = (value: boolean) => ({
  type: 'TOGGLE_CALENDAR_PAGER_DRAGOVER',
  payload: { active: value },
})

export const toggleEventMoveModal: ActionCreator<any> = (startTime?: string) => ({
  type: 'TOGGLE_EVENT_MOVE_MODAL_OPEN',
  payload: { startTime },
})

export const clearEventMoveModal: ActionCreator<any> = () => ({
  type: 'TOGGLE_EVENT_MOVE_MODAL_OPEN',
  payload: { open: false },
})

export const toggleEventCalEditing: ActionCreator<any> = (
  type: string,
  id?: number,
  duration?: number,
  startTime?: string,
) => ({
  type: 'TOGGLE_EVENT_CALENDAR_EDITING',
  payload: { type, id, duration, startTime },
})

export const clearEventCalEditing: ActionCreator<any> = () => ({
  type: 'TOGGLE_EVENT_CALENDAR_EDITING',
  payload: { type: 'UNSET', id: undefined, duration: undefined, startTime: undefined },
})

export const clearNewReservationResultModal: ActionCreator<any> = () => ({
  type: 'TOGGLE_NEW_RESERVATION_RESULT_MODAL_OPEN',
  payload: {},
})

export const clearUniversalResultModal: ActionCreator<any> = () => ({
  type: 'TOGGLE_UNIVERSAL_RESULT_MODAL_OPEN',
  payload: { type: 'UNDEFINED', title: '', description: '' },
})

export const clearCompleteWithNewCustomerModal: ActionCreator<any> = () => ({
  type: 'TOGGLE_COMLPETE_WITH_NEW_CUSTOMER_MODAL_OPEN',
  payload: {},
})

export const clearFinishWithExistingCustomerModal: ActionCreator<any> = () => ({
  type: 'TOGGLE_FINISH_WITH_EXISTING_CUSTOMER_MODAL_OPEN',
  payload: {},
})

export const clearNewProfileModal: ActionCreator<any> = () => ({
  type: 'TOGGLE_NEW_PROFILE_MODAL_OPEN',
  payload: {},
})

export const clearCancelWashModal: ActionCreator<any> = () => ({
  type: 'TOGGLE_CANCEL_WASH_MODAL_OPEN',
  payload: {},
})

export const setDayOffset: ActionCreator<any> = (dayOffset: number) => ({
  type: 'SET_DAY_OFFSET',
  payload: dayOffset,
})

export const setCompactDayOffset: ActionCreator<any> = (compactDayOffset: number) => ({
  type: 'SET_COMPACT_DAY_OFFSET',
  payload: compactDayOffset,
})

export const setOngoingStampTime: ActionCreator<any> = (reservationId: number, previousDuration?: number) => ({
  type: 'SET_ONGOING_STAMP_TIME',
  payload: { reservationId, startTime: new Date(), previousDuration },
})

export const deleteOngoingStampTime: ActionCreator<any> = (reservationId: number) => ({
  type: 'DELETE_ONGOING_STAMP_TIME',
  payload: { reservationId },
})

export const toggleResetUserPasswordModal: ActionCreator<any> = (uID: number) => ({
  type: 'TOGGLE_RESET_USER_PASSWORD_MODAL_OPEN',
  payload: { uID },
})
