/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, LinearProgress } from '@material-ui/core'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IReservationItem } from '../../../interfaces/event.interfaces'
import { colors } from '../../../theme.styles'
import { LicensePlate } from '../commonUiElements'
import { Grid } from '../grid'
import { Text } from '../text'
import { Card, CardHeader, PercentBg, WashSize } from './monitorCard.styles'

const MonitorCard = ({
  resourceTitle,
  reservation,
  hidden,
  small,
}: {
  reservation: IReservationItem
  resourceTitle?: string
  hidden?: boolean
  small?: boolean
}): ReactElement => {
  const { t } = useTranslation()

  const [ongoingCounter, setOngoingCounter] = useState(0)
  const [stampDate, setStampDate] = useState<any>()

  useEffect(() => {
    if (reservation.lastStatus === 'ON_GOING') {
      setInterval(() => {
        setOngoingCounter((prevcount) => prevcount + 1)
      }, 60000)
    }
    return undefined
  }, [])
  useEffect(() => {
    if (reservation.lastStatus === 'ON_GOING') {
      const stampNow = Date.now()
      setStampDate(moment(stampNow).add(reservation.duration, 'minutes').subtract(reservation.workingTime, 'minutes'))
    }
    return undefined
  }, [reservation.lastStatus])
  const calculatedElapsedTime = reservation.workingTime || 0 + ongoingCounter
  // const timeRemaining = reservation.duration - calculatedElapsedTime

  const minutesLeft = moment(stampDate).diff(moment(), 'minutes')
  const secondsLeft = moment(stampDate).diff(moment(), 'seconds') % 60

  const startTime = moment(reservation.startTime).format('HH:mm')
  const endTime = moment(reservation.startTime).add(reservation.duration, 'minutes').format('HH:mm')
  const progressPercent = (calculatedElapsedTime / reservation.duration) * 100
  return (
    <Card elevation={0} hidden={hidden}>
      {small ? (
        <Grid $xsCol="1fr 40px" $padding="30px">
          <Box>
            <Grid $xsCol="auto 1fr" $gap="8px">
              <Text $size="14px">{`${startTime} - ${endTime}`}</Text>
              <Text $size="14px" $weight={600}>{`${reservation.duration} perc`}</Text>
            </Grid>
            <Text $size="30px" $weight={600}>
              {`${reservation.surface && t(reservation.surface)} ${
                reservation.category?.title && t(reservation.category.title)
              }`}
            </Text>
            {!!reservation.vehicle?.licensePlate && (
              <Grid $xsCol="auto 1fr">
                <LicensePlate>{reservation.vehicle?.licensePlate}</LicensePlate>
              </Grid>
            )}
          </Box>
          {reservation.vehicle?.size && <WashSize>{reservation.vehicle.size}</WashSize>}
        </Grid>
      ) : (
        <Grid $gap="0px">
          <CardHeader $padding="16px" $xxsCol="1fr auto">
            <Grid $xxsCol="auto 1fr" $alignItems="center">
              <Text $textColor={colors.lime} $size="50px" $weight={600}>
                {resourceTitle}
              </Text>
              <Text $size="40px" $weight={600} $align="center">
                {`${minutesLeft}:${secondsLeft > 9 ? secondsLeft : `0${secondsLeft}`}`}
              </Text>
            </Grid>
            <PercentBg>{`${progressPercent.toFixed()}%`}</PercentBg>
          </CardHeader>
          <LinearProgress variant="determinate" value={progressPercent} />
          <Grid $padding="30px">
            <Grid $xxsCol="1fr auto" alignItems="center">
              <Text $size="30px" $weight={500}>{`${startTime} - ${endTime}`}</Text>
              <WashSize>{reservation.vehicle?.size}</WashSize>
            </Grid>
            <Text $size="50px" $weight={600}>{`${t(reservation.surface)} ${reservation.category.title}`}</Text>
            {!!reservation.vehicle?.licensePlate && (
              <Grid $justifyItems="start">
                <LicensePlate $large>{reservation.vehicle?.licensePlate}</LicensePlate>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Card>
  )
}
export default MonitorCard
