import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { VpnKeyOutlined } from '@material-ui/icons'
import {
  FormControlLabel,
  TableContainer,
  IconButton,
  TableCell,
  TableRow,
  Switch,
  Paper,
  Table,
} from '@material-ui/core'
import { toggleNewUserModal, toggleResetUserPasswordModal } from '../../actions/uiStatesAction'
import { StyledTableBody, StyledTableHead } from '../../components/layout/table/table'
import { Tab, TabSwitcher } from '../../components/layout/tabs/tabs.styles'
import { SettingsSheet } from '../../components/layout/commonUiElements'
import { getInvitedUsers } from '../../actions/getInvitedUsersActions'
import InvitedUserRow from '../../components/layout/invitedUserRow'
import { Grid, Text, TextBlock } from '../../components/layout'
import { Button } from '../../components/layout/button/button'
import { API_URI, WASHERID_COOKIE_KEY } from '../../config'
import { IStore } from '../../interfaces/store.interfaces'
import { getUsers } from '../../actions/getUsersActions'
import { headers } from '../../helpers/headers.helper'

const UserSettings = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const users = useSelector((state: IStore) => state.users)
  const invitedUsers = useSelector((state: IStore) => state.invitedUsers)
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const [tab, setTab] = useState('ACTIVE')

  useEffect(() => {
    if (washerId) {
      dispatch(getUsers(washerId))
      dispatch(getInvitedUsers(washerId))
    }
  }, [washerId])

  const onToggleActivity = async (userID: number, active: boolean) => {
    const data = {
      active,
    }

    const postUrl = `${API_URI}/washers/${washerId}/users/${userID}/active`
    const response = await fetch(postUrl, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })
    if (response.status === 200) {
      dispatch(getUsers(washerId))
    }
  }

  const tabs = [
    {
      id: 'iusd',
      name: 'ACTIVE',
    },
    {
      id: 'sddsds',
      name: 'INACTIVE',
    },
    {
      id: 'fiyugdv',
      name: 'INVITED',
    },
  ]

  return (
    <Grid>
      <SettingsSheet $xxsCol="1fr auto">
        <Text $size="20px" $weight={600}>
          {t('USER_SETTINGS')}
        </Text>
        <Button onClick={() => dispatch(toggleNewUserModal())} variant="contained" color="primary">
          {t('ADD_NEW_USER_BUTTON')}
        </Button>
      </SettingsSheet>
      <Grid paddingLeft="40px">
        <TabSwitcher $items={3} color="primary">
          {tabs.map(({ id, name }) => (
            <Tab key={id} variant="outlined" onClick={() => setTab(name)} $active={tab === name} disableElevation>
              {t(`USERTAB_${name}`)}
            </Tab>
          ))}
        </TabSwitcher>
      </Grid>
      {users.length ? (
        <TableContainer elevation={0} component={Paper}>
          <Table size="medium">
            <StyledTableHead>
              <TableRow>
                <TableCell>{t('NAME')}</TableCell>
                {tab === 'ACTIVE' && <TableCell align="center">{t('CHANGE_USER_PASSWORD')}</TableCell>}
                {tab !== 'INVITED' && <TableCell align="right">{t('ACTIVITY')}</TableCell>}
                {tab === 'INVITED' && <TableCell align="right">{t('RESEND_INVITATION')}</TableCell>}
              </TableRow>
            </StyledTableHead>
            <StyledTableBody>
              {tab === 'ACTIVE' &&
                users
                  .filter(({ active }) => active === true)
                  .map(({ firstName, lastName, userId: uID, active }) => (
                    <TableRow key={uID}>
                      <TableCell>{`${firstName} ${lastName}`}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => dispatch(toggleResetUserPasswordModal(uID))}
                        >
                          <VpnKeyOutlined fontSize="large" />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={() => onToggleActivity(uID, !active)}
                              checked={active}
                              color="primary"
                              name="checkedA"
                            />
                          }
                          label=""
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              {tab === 'INACTIVE' &&
                users
                  .filter(({ active }) => active === false)
                  .map(({ firstName, lastName, userId: uID, active }) => (
                    <TableRow key={uID}>
                      <TableCell>{`${firstName} ${lastName}`}</TableCell>
                      <TableCell align="right">
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={() => onToggleActivity(uID, !active)}
                              checked={active}
                              color="primary"
                              name="checkedA"
                            />
                          }
                          label=""
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              {tab === 'INVITED' && invitedUsers.map((user) => <InvitedUserRow user={user} key={user.key} />)}
            </StyledTableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid $padding="40px 0px">
          <TextBlock $align="center" dangerouslySetInnerHTML={{ __html: t('NO_USERS') }} />
        </Grid>
      )}
    </Grid>
  )
}

export default UserSettings
