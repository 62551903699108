import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import { useCookies } from 'react-cookie'
import { ModalWrapper, ModalHeader, ModalCloseBtn, ModalBody } from '../../../components/layout/modal'
import { getReservationById } from '../../../actions/getReservationsActions'
import { togglePromoChangeModal } from '../../../actions/uiStatesAction'
import { API_URI, WASHERID_COOKIE_KEY } from '../../../config'
import { IStore } from '../../../interfaces/store.interfaces'
import { headers } from '../../../helpers/headers.helper'
import { Text, Grid } from '../../../components/layout'

const PromoChangeModal = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const isOpen = useSelector((state: IStore) => state.uiStates.promoChangeModal.open)
  const promotionalCodeId = useSelector((state: IStore) => state.uiStates.promoChangeModal.id)
  const type = useSelector((state: IStore) => state.uiStates.promoChangeModal.type)
  const reservations = useSelector((state: IStore) => state.reservations?.reservationlist)
  const reservation = reservations?.[0]

  const onChangePromotion = async () => {
    const data = {
      promotionalCodeId,
    }

    const postUrl = `${API_URI}/washers/${washerId}/reservations/${reservation.id}/promotional-codes`

    const response = await fetch(postUrl, {
      method: 'PUT',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })
    // const result = await response.json()
    if (response.status !== 200) {
      dispatch(getReservationById(reservation.id))
    }
    if (response.status === 200 && !!washerId) {
      dispatch(togglePromoChangeModal('CHANGE'))
      dispatch(getReservationById(washerId, reservation.id.toString()))
    }
  }

  const onRemovePromotion = async () => {
    const postUrl = `${API_URI}/washers/${washerId}/reservations/${reservation.id}/promotional-codes`

    const response = await fetch(postUrl, {
      method: 'DELETE',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
    })

    if (response.status !== 200) {
      dispatch(getReservationById(reservation.id))
    }
    if (response.status === 200 && !!washerId) {
      dispatch(togglePromoChangeModal('CHANGE'))
      dispatch(getReservationById(washerId, reservation.id.toString()))
    }
  }

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper $small>
          <ModalHeader $small>
            <Text $mdSize="30px" $xxsSize="20px" $weight={500} $align="center">
              {type === 'CHANGE' ? t('CHANGE_PROMOTION_TITLE') : t('REMOVE_PROMOTION_TITLE')}
            </Text>
            <ModalCloseBtn onClick={() => dispatch(togglePromoChangeModal(type))}>×</ModalCloseBtn>
          </ModalHeader>
          <ModalBody>
            <Grid $gap="20px" $padding="40px">
              <Text $size="16px" $align="center">
                {type === 'CHANGE' ? t('CHANGE_PROMO_DESCRIPTION') : t('REMOVE_PROMO_DESCRIPTION')}
              </Text>
              <Button
                onClick={type === 'CHANGE' ? onChangePromotion : onRemovePromotion}
                variant="contained"
                disableElevation
                color="primary"
              >
                {t('YES')}
              </Button>
            </Grid>
          </ModalBody>
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default PromoChangeModal
