import { LinearProgress } from '@material-ui/core'
import styled from 'styled-components'
import { chartColors } from '../../theme.styles'

export const LineChart = styled(LinearProgress)`
  height: 10px;
  border-radius: 6px;
  background-color: rgba(230, 230, 230, 0.7);
  & .MuiLinearProgress-bar1Determinate {
    background-color: ${chartColors[7]};
  }
`
