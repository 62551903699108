/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreator, Dispatch } from 'redux'
import { packages as serviceName } from '.'
import { API_URI } from '../config'
import { headers } from '../helpers/headers.helper'

const urlQuery = '/packages/next-available?'

export const getPackages: ActionCreator<any> =
  (washerId: number, vehicleSize?: string, from?: string, customerProfileId?: number, licensePlate?: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: `GET_${serviceName}_BEGIN`,
      })
      const res = await fetch(
        `${API_URI}/washers/${washerId}${urlQuery}${vehicleSize && `vehicle-size=${vehicleSize}`}${
          from && `&from=${from}${licensePlate !== undefined ? `&license-plate=${licensePlate}` : ''}`
        }${(!!customerProfileId && `&customerProfileId=${customerProfileId}`) || ''}`,
        {
          headers,
        },
      )
      const data = await res.json()
      dispatch({
        type: `GET_${serviceName}_SUCCESS`,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: `GET_${serviceName}_FAILED`,
        payload: error,
      })
    }
  }
