import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { useTranslation } from 'react-i18next'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import { IStore } from '../../interfaces/store.interfaces'
import { chartColors, colors } from '../../theme.styles'
import { Grid, Text } from '../../components/layout'

const PackageDurationsChart = (): ReactElement => {
  ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
  const { t } = useTranslation()
  const statistics = useSelector((state: IStore) => state.statistics)
  const workLabels = statistics?.workDurations?.map(({ surface, categoryTitle }) => `${t(surface)} ${categoryTitle}`)
  const workDurationValues = statistics?.workDurations?.map(({ avgDurationInSec }) => Math.round(avgDurationInSec / 60))

  const data = {
    labels: workLabels,
    datasets: [
      {
        label: t('DURATION_OF_PACKAGE'),
        data: workDurationValues || [0],
        backgroundColor: chartColors,
      },
    ],
  }

  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const,
        display: false,
      },
    },
  }
  return (
    <Grid $alignContent="start">
      <Text $weight={500} $textColor={colors.grey} $lineHeight="1">
        {t('PACKAGE_DURATIONS_CHART')}
      </Text>
      <Bar options={options} data={data} />
    </Grid>
  )
}

export default PackageDurationsChart
