import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getCancelledReservations } from '../../actions/getCancelledReservationsActions'
import { PREFERRED_CALENDARVIEW_COOKIE_KEY, WASHERID_COOKIE_KEY } from '../../config'
import { Grid, Icon, LicensePlate, Text } from '../../components/layout'
import { IStore } from '../../interfaces/store.interfaces'
import { colors } from '../../theme.styles'
import {
  CancelledReservationsButton,
  ReservationItem,
  CancelledMenu,
  CancelWrapper,
  ListHeader,
  WashSize,
} from './cancelledReservations.styles'

const cancelledReservationsModule = (): ReactElement => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const cButtonRef = useRef(null)
  const cancelledReservations = useSelector((state: IStore) => state.cancelledReservations.reservationlist)
  const dayOffset = useSelector((state: IStore) => state.uiStates.dayOffset)
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = cookies[WASHERID_COOKIE_KEY]
  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const dispatch = useDispatch()
  const { t } = useTranslation()
  useEffect(() => {
    if (washerId) {
      if (calView === 'day') {
        dispatch(getCancelledReservations(washerId, dayOffset, 1))
      }
      if (calView === 'week') {
        const weekStartOffsetFromToday = moment().weekday()
        const weekStartOffsetFromDate = weekStartOffsetFromToday * -1
        dispatch(getCancelledReservations(washerId, weekStartOffsetFromDate, 7))
      }
    }
  }, [washerId, calView])

  const handleClose = () => {
    setAnchorEl(null)
    setMenuOpen(false)
  }

  const handleClick = () => {
    setAnchorEl(cButtonRef.current)
    setMenuOpen(true)
  }

  return (
    <CancelWrapper>
      <CancelledReservationsButton onClick={handleClick} id="select-outlined" $open={!!anchorEl} ref={cButtonRef}>
        <Text $size="14px" $weight={600} $textColor={colors.cyan}>
          {t('CANCELLED_RESERVATIONS')}
        </Text>
        <Text $size="14px" $weight={600} $textColor={colors.lime}>
          {`${cancelledReservations.length} ${t('PCS')}`}
        </Text>
        <Icon $menuDown $size="20px" />
      </CancelledReservationsButton>
      <CancelledMenu
        onClose={handleClose}
        anchorEl={anchorEl}
        open={menuOpen}
        id="cancelled-reservations-menu"
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ListHeader $xxsCol="1fr auto" $padding="40px" $gap="15px">
          <Text $xxsSize="16px" $mdSize="30px" $weight={600} $textColor={colors.black}>
            {t('CANCELLED_RESERVATIONS')}
          </Text>
          <Text $xxsSize="13px" $lgSize="30px" $weight={600} $textColor={colors.lime}>
            {`${cancelledReservations.length} ${t('PCS')}`}
          </Text>
        </ListHeader>
        {!!cancelledReservations.length &&
          cancelledReservations.map(({ vehicle, startTime, surface, id, duration, category }) => (
            <Link key={id} to={`/reservations/${id}`} style={{ textDecoration: 'none', color: 'black' }}>
              <ReservationItem $xxsCol="1fr 60px" $alignItems="center">
                <Box>
                  <Grid $xxsCol="auto 1fr" $gap="8px">
                    <Text $size="14px">
                      {`${moment(startTime).format('HH:mm')} - ${moment(startTime)
                        .add(duration || 0, 'minutes')
                        .format('HH:mm')}`}
                    </Text>
                    <Text $size="14px" $weight={600}>{`${duration} perc`}</Text>
                  </Grid>
                  <Text $size="16px" $weight={600} $margin="0 0 4px 0">
                    {`${t(surface)} ${t(category?.title || 'DEFAULT_CATEGORY_TITLE')}`}
                  </Text>
                  {!!vehicle?.licensePlate && (
                    <Grid $xxsCol="auto 1fr">
                      <LicensePlate>{vehicle?.licensePlate}</LicensePlate>
                    </Grid>
                  )}
                </Box>
                {vehicle?.size && <WashSize>{vehicle.size}</WashSize>}
              </ReservationItem>
            </Link>
          ))}
      </CancelledMenu>
    </CancelWrapper>
  )
}

export default cancelledReservationsModule
