/* eslint-disable import/prefer-default-export */
import { Box } from '@material-ui/core'
import styled from 'styled-components/macro'
import rem from '../../helpers/rem.helper'
import { IGrid } from '../../interfaces/layout.interfaces'
import { media } from './mediaqueries'

const autoRows = ({ $minRowHeight = rem(20) }) => `minmax(${$minRowHeight}, auto)`

const frGetter = (value: string | number) => (typeof value === 'number' ? `repeat(${value}, 1fr)` : value)

const iefrGetter = (value: number | string | undefined, gap: number | string) =>
  typeof value === 'number' ? `1fr (${gap} 1fr)[${value - 1}]` : value

const formatAreas = (areas: { map: (arg0: (area: string) => string) => { join: (arg1: string) => void } }) =>
  areas.map((area: string) => `"${area}"`).join(' ')

const flexChildWidthGetter = (col: string | number, gap: string) =>
  typeof col === 'number' ? `calc(100% /${col} - ${gap === '0px' ? '0.1px' : gap})` : ''

export const Grid = styled(Box)<IGrid>`
  display: -ms-grid;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  display: grid;
  grid-auto-rows: ${autoRows};

  gap: ${({ $gap = '.5rem' }) => $gap && `${$gap}`};
  ${({ $colGap }) => $colGap && `column-gap: ${$colGap}`};
  ${({ $rowGap }) => $rowGap && `row-gap: ${$rowGap}`};

  ${({ $height }) => $height && `height: ${$height}`};
  ${({ $flow }) => $flow && `grid-auto-flow: ${$flow}`};

  margin: ${({ $margin }) => $margin && `${$margin}`};
  margin-bottom: ${({ $marginBottom }) => $marginBottom && `${$marginBottom}`};
  margin-right: ${({ $marginRight }) => $marginRight && `${$marginRight}`};
  margin-left: ${({ $marginLeft }) => $marginLeft && `${$marginLeft}`};
  margin-top: ${({ $marginTop }) => $marginTop && `${$marginTop}`};

  padding: ${({ $padding }) => $padding && `${$padding}`};
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom && `${$paddingBottom}`};
  padding-right: ${({ $paddingRight }) => $paddingRight && `${$paddingRight}`};
  padding-left: ${({ $paddingLeft }) => $paddingLeft && `${$paddingLeft}`};
  padding-top: ${({ $paddingTop }) => $paddingTop && `${$paddingTop}`};

  ${({ $backgroundColor }) => $backgroundColor && `background-color: ${$backgroundColor}`};
  ${({ $justifyContent }) => $justifyContent && `justify-content: ${$justifyContent}`};
  ${({ $justifyItems }) => $justifyItems && `justify-items: ${$justifyItems}`};
  ${({ $alignContent }) => $alignContent && `align-content: ${$alignContent}`};
  ${({ $justifySelf }) => $justifySelf && `justify-self: ${$justifySelf}`};
  ${({ $alignItems }) => $alignItems && `align-items: ${$alignItems}`};

  ${({ $areas }) => $areas && `grid-template-areas: ${formatAreas($areas)}`};
  ${({ $textColor }) => $textColor && `color: ${$textColor};`}
  ${({ $textAlign }) => $textAlign && `text-align: ${$textAlign};`}
  ${({ $row }) => $row && `grid-template-rows: ${frGetter($row)};`}

  /* Defining mediaquery based behaviour of items */

  ${media.xxs} {
    grid-template-columns: ${({ $xxsCol = 1 }) => frGetter($xxsCol)};
    ${({ $xxsRow }) => $xxsRow && `grid-template-rows: ${$xxsRow}`};
  }
  ${media.xs} {
    ${({ $xsCol }) => $xsCol && `grid-template-columns: ${frGetter($xsCol || 2)}`};
    ${({ $xsRow }) => $xsRow && `grid-template-rows: ${$xsRow}`};
  }
  ${media.md} {
    ${({ $mdCol }) => $mdCol && `grid-template-columns: ${frGetter($mdCol || 1)}`};
    ${({ $mdRow }) => $mdRow && `grid-template-rows: ${$mdRow}`};
  }
  ${media.lg} {
    -ms-grid-columns: ${({ $col, $gap = '.5rem' }: IGrid) => iefrGetter($col, $gap)};
    ${({ $lgRow }) => $lgRow && `grid-template-rows: ${$lgRow}`};

    ${({ $col }) => $col && `grid-template-columns: ${frGetter($col || 1)}`};
    & > * {
      box-sizing: border-box;
      width: ${({ $col = 1, $gap = '.5rem' }) => flexChildWidthGetter($col, $gap)};
      margin-bottom: ${({ $gap }) => $gap && $gap};
    }
    @supports (display: grid) {
      & > * {
        width: initial;
        margin-bottom: initial;
      }
    }
  }
`
