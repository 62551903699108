import React, { ChangeEvent, ReactElement, useState } from 'react'
import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'
import { useCookies } from 'react-cookie'
import { toggleNewPromotionModal, toggleUniversalResultModal } from '../../../actions/uiStatesAction'
import { ModalWrapper, ModalHeader, ModalCloseBtn, ModalBody } from '../../../components/layout/modal'
import { SelectInput, TextInput } from '../../../components/layout/form/form.styles'
import { API_URI, WASHERID_COOKIE_KEY } from '../../../config'
import { IStore } from '../../../interfaces/store.interfaces'
import { headers } from '../../../helpers/headers.helper'
import { Text, Grid } from '../../../components/layout'
import { getPromotions } from '../../../actions/getPromotionsActions'

const NewPromotionModal = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const washers = useSelector((state: IStore) => state.user.washers)
  const isOpen = useSelector((state: IStore) => state.uiStates.newPromotionModalOpen)
  const selectedWasherData = washers?.find(({ id }) => id === washerId)

  const [amount, setAmount] = useState('')
  const [unit, setUnit] = useState('COIN')
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    const currencyToSend = unit === 'COIN' ? selectedWasherData?.country?.officialCurrency : undefined
    const data = {
      washerId,
      currency: currencyToSend,
      amount: parseFloat(amount),
      unit,
    }

    const postUrl = `${API_URI}/washers/${washerId}/promotional-codes`
    const response = await fetch(postUrl, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })
    const result = await response.json()

    if (response.status !== 200) {
      setLoading(false)
      dispatch(toggleUniversalResultModal('ERROR', 'PROMOTION_FAIL_TITLE', 'PROMOTION_FAIL_DESCRIPTION'))
    }
    if (response.status === 200 && result.id && !!washerId) {
      setLoading(false)
      dispatch(toggleNewPromotionModal())
      dispatch(toggleUniversalResultModal('SUCCESS', 'PROMOTION_SUCCESS_TITLE', 'PROMOTION_SUCCESS_DESCRIPTION'))
      dispatch(getPromotions(washerId))
    }
  }

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper $small>
          <ModalHeader $small>
            <Text $mdSize="30px" $xxsSize="20px" $weight={500} $align="center">
              {t('NEW_PROMOTION_TITLE')}
            </Text>
            <ModalCloseBtn onClick={() => dispatch(toggleNewPromotionModal())}>×</ModalCloseBtn>
          </ModalHeader>
          <ModalBody>
            <Grid $gap="20px" $padding="40px">
              <Text $size="16px" $align="center">
                {t('NEW_PROMO_DESCRIPTION')}
              </Text>
              <FormControl variant="outlined">
                <InputLabel id="unit">
                  <Text $size="18px" $weight={600} $textColor="black">
                    {t('UNIT')}
                  </Text>
                </InputLabel>
                <SelectInput
                  labelId="unit"
                  id="unit"
                  value={unit}
                  onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                    setUnit(event.target.value as string)
                  }
                >
                  <MenuItem value="COIN">{t('COIN')}</MenuItem>
                  <MenuItem value="PERCENT">{t('PERCENT')}</MenuItem>
                </SelectInput>
              </FormControl>
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
                placeholder={t('AMOUNT')}
                label={t('AMOUNT')}
                variant="outlined"
                id="user-search"
                $semiRounded
                $search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <p>{unit === 'PERCENT' ? '%' : selectedWasherData?.country?.officialCurrency}</p>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                onClick={() => onSubmit()}
                variant="contained"
                disabled={loading}
                disableElevation
                color="primary"
              >
                {loading ? t('LOADING_BTN') : t('PROMOTION_BTN_CREATE')}
              </Button>
            </Grid>
          </ModalBody>
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default NewPromotionModal
