export const settingsPages = [
  {
    id: 'iweuh',
    title: 'GENERAL_SETTINGS',
    shortTitle: 'GENERAL_SETTINGS_SHORT',
    url: 'general',
    role: '',
  },
  {
    id: 'sddsdd',
    title: 'OPEN_HOURS_SETTINGS',
    shortTitle: 'OPEN_HOURS_SETTINGS_SHORT',
    url: 'open-hours',
    role: '',
  },
  {
    id: 'diuhsdhiu',
    title: 'PROMOTION_SETTINGS',
    shortTitle: 'PROMOTION_SETTINGS_SHORT',
    url: 'promotions',
    role: '',
  },
  {
    id: 'iodshdushius',
    title: 'MARKETING_CAMPAIGN_SETTINGS',
    shortTitle: 'MARKETING_CAMPAIGN_SETTINGS_SHORT',
    url: 'marketing-campaigns',
    role: '',
  },
  {
    id: 'dsiojisdiojsdjio',
    title: 'HELP_SETTINGS',
    shortTitle: 'HELP_SETTINGS_SHORT',
    url: 'help',
    role: '',
  },
  {
    id: 'ihuiiuhhuiiuh',
    title: 'USER_SETTINGS',
    shortTitle: 'USER_SETTINGS_SHORT',
    url: 'users',
    role: 'USER',
  },
  {
    id: 'kldsjfkldskfs',
    title: 'PRICE_LIST_SETTINGS',
    shortTitle: 'PRICE_LIST_SETTINGS_SHORT',
    url: 'price-list',
    role: '',
  },
]
