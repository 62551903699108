import { applyMiddleware, createStore } from 'redux'
import spy from 'redux-monitor-spy'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './rootReducer'

const middleWares = [thunk, spy]
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleWares)))

export default store
