import styled from 'styled-components'

export const FullscreenWrapper = styled.div`
  background-color: #fff;
  z-index: 999999;
  position: fixed;
  height: 100vh;
  display: grid;
  width: 100vw;
  left: 0;
  top: 0;
`
