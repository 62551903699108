/* eslint-disable import/prefer-default-export */
import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components/macro'
import { media } from '../../components/layout'
import { colors, fonts } from '../../theme.styles'

export const Title = styled(Typography)<{ $active?: boolean }>`
  font-family: ${fonts.main};
  color: ${colors.black};
  font-weight: 600;
  line-height: 1.5;
  font-size: 20px;
  ${media.lg} {
    line-height: 2.2;
    font-size: 30px;
  }
  ${({ $active }) =>
    $active &&
    `
   color: ${colors.lime};
  `}
`

export const HeaderWrapper = styled(Box)<{ $active?: boolean }>`
  cursor: pointer;
  position: relative;
  border: 1px solid ${colors.veryLightGrey};
  border-radius: 15px;
  ${({ $active }) =>
    $active &&
    `
  border: 3px solid ${colors.lime};
  `}
`

export const AppointmentsCount = styled.p`
  margin-right: 31px;
  margin-top: 14px;
  font-weight: 500;
  font-size: 14px;
`

export const OngoingWrapper = styled(Box)`
  border-radius: 15px 15px 0px 0px;
  grid-template-columns: auto auto;
  background-color: ${colors.magenta};
  justify-content: center;
  position: absolute;
  align-items: center;
  display: grid;
  color: white;
  height: 30px;
  width: 100%;
  gap: 6px;
  margin: 0px;
  left: 0;
  top: -3px;
`
