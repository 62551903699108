import { createTheme } from '@material-ui/core/styles'

export const fonts = {
  main: 'Montserrat, sans-serif',
  secondary: 'Prompt, sans-serif',
}

export const colors = {
  red: '#D82454',
  magenta: '#D8248A',
  orange: '#EF9D3F',
  lime: '#95C11F',
  cyanLight: '#005C53',
  cyan: '#00584D',
  blue: '#1F97C1',
  white: '#ffffff',
  almostWhite: '#F4F7FB',
  veryLightGrey: '#E1E7F0',
  lightGrey: '#D1DDE3',
  grey: '#5E6C80',
  black: '#000000',
  body: 'rgba(0, 0, 0, 0.87)',
}

export const chartColors = [
  '#EFA7BB',
  '#FFC7DB',
  '#D9B892',
  '#F9D8B2',
  '#F5F6C5',
  '#D5E6A5',
  '#D5E6E5',
  '#A5C4E6',
  '#BFC4CC',
]

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.lime,
    },
    secondary: {
      main: colors.magenta,
    },
    warning: {
      main: colors.red,
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    h1: {
      fontSize: '30px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '20px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  overrides: {
    MuiAccordionSummary: {
      expandIcon: {
        order: -1,
      },
    },
    MuiPaper: {
      rounded: {
        outline: 'none',
        borderRadius: 15,
      },
    },
    MuiAccordion: {
      rounded: {
        borderRadius: 15,
        border: `1px solid ${colors.veryLightGrey}`,
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: '15px',
        marginLeft: '10px',
        overflowY: 'auto',
        overflowX: 'hidden',
        boxShadow: '0 30px 30px -20px rgba(94,108,128,0.3)',
        border: `1px solid ${colors.veryLightGrey}`,
        '&:before': {
          content: '""',
          boxShadow: '0 30px 30px -20px rgba(94,108,128,0.3)',
          display: 'block',
          width: '0px',
          height: '0px',
          position: 'absolute',
          borderBottom: '10px solid transparent',
          borderRight: '10px solid white',
          borderTop: '10px solid transparent',
          top: '30px',
          left: 0,
          transform: 'translateX(-10px)',
          zIndex: 999,
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: 15,
        borderColor: colors.veryLightGrey,
      },
      root: {
        borderRadius: 6,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
          paddingBottom: '3px',
        },
        paddingBottom: '3px',
      },
    },
    MuiInput: {
      root: {
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
        borderRadius: 6,
      },
    },
    MuiButton: {
      textPrimary: {
        textTransform: 'none',
        color: colors.cyan,
        padding: '16px 20px',
        fontWeight: 600,
        borderRadius: '10px',
      },
      textSecondary: {
        textTransform: 'none',
        color: colors.grey,
        padding: '0px',
        fontWeight: 600,
        borderRadius: '0px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      containedPrimary: {
        fontWeight: 600,
        color: colors.white,
        textTransform: 'none',
        borderRadius: '10px',
      },
      containedSecondary: {
        fontWeight: 600,
        color: colors.white,
        borderRadius: '10px',
        textTransform: 'none',
      },
      outlinedPrimary: {
        padding: '16px 20px',
        fontWeight: 600,
        color: colors.cyan,
        textTransform: 'none',
        borderRadius: '10px',
      },
      outlinedSecondary: {
        padding: '16px 20px',
        fontWeight: 600,
        color: colors.white,
        borderRadius: '10px',
        textTransform: 'none',
      },
      root: {
        fontSize: '14px',
        padding: '16px 20px',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          '& *': {
            backfaceVisibility: 'hidden',
            webkitFontSmoothing: 'antialiased',
            mozOsxFontSmoothing: 'grayscale',
          },
          backgroundColor: colors.almostWhite,
          scrollbarColor: `${colors.lime} white`,
          scrollbarWidth: 'thin',
        },
        '::-webkit-scrollbar': {
          width: '7px',
        },
        '::-webkit-scrollbar-thumb': {
          background: colors.lime,
          borderRadius: '5px',
        },
      },
    },
  },
})
